import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RootState } from '../../../../../store/root-reducer';
import UpgradePlanBillingSection from './upgrade-plan-billing-section';
import { ApplyCouponCodeRequest } from '../../../../../components/settings/components/billing-subscription/types';
import { BillingOption } from '../../../../utils/billing-options';
import {
  getPostLoadMetaRequest,
  getUserSettingsRequest,
} from '../../../../../components/home/extra-actions';
import {
  applyCouponCodeRequest,
  getAccountSubscriptionRequest,
  getLeadFinderSubscriptionRequest,
} from '../../../../../components/settings/components/billing-subscription/extra-actions';
import {
  resetModifyLtdPlan,
  resetPurchaseLeadFinderModifySubscriptionResponse,
  resetPurchaseModifySubscriptionResponse,
  resetSubscription,
  resetUpgradeLtdPlan,
} from '../../../../../components/settings/components/billing-subscription/billing-subscription-slice';
import {
  PlanType,
  SubscriptionPlans,
} from '../../../../utils/subscription-plans';

const mapStateToProps = (state: RootState) => ({
  // Purchase Subscription request
  sendPurchaseSubscriptionRequestStatus:
    state.billingAndSubscription.purchaseSubscriptionRequest.status,
  sendPurchaseSubscriptionRequestMessage:
    state.billingAndSubscription.purchaseSubscriptionRequest.message,
  sendPurchaseSubscriptionRequestError:
    state.billingAndSubscription.purchaseSubscriptionRequest.error,
  // Purchase Lead Finder Subscription request
  sendPurchaseLeadFinderSubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderSubscriptionRequest.status,
  sendPurchaseLeadFinderSubscriptionRequestMessage:
    state.billingAndSubscription.purchaseLeadFinderSubscriptionRequest.message,
  sendPurchaseLeadFinderSubscriptionRequestError:
    state.billingAndSubscription.purchaseLeadFinderSubscriptionRequest.error,

  sendGetModifySubscriptionRequestStatus:
    state.billingAndSubscription.modifySubscriptionRequest.status,
  sendGetModifySubscriptionRequestError:
    state.billingAndSubscription.modifySubscriptionRequest.error,

  // Purchase modify subscription request
  sendGetPurchaseModifySubscriptionRequestStatus:
    state.billingAndSubscription.purchaseModifySubscriptionRequest.status,
  sendGetPurchaseModifySubscriptionRequestMessage:
    state.billingAndSubscription.purchaseModifySubscriptionRequest.message,
  // Purchase Lead Finder modify subscription request
  sendPurchaseLeadFinderModifySubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderModifySubscriptionRequest
      .status,
  sendPurchaseLeadFinderModifySubscriptionRequestMessage:
    state.billingAndSubscription.purchaseLeadFinderModifySubscriptionRequest
      .message,

  sendPurchaseModifySubscriptionResponse:
    state.billingAndSubscription.purchaseModifySubscriptionResponse,
  sendPurchaseLeadFinderModifySubscriptionResponse:
    state.billingAndSubscription.purchaseLeadFinderModifySubscriptionResponse,

  selectedPlanDetails: state.billingAndSubscription.selectedPlanDetails,
  sendGetModifySubscriptionResponse:
    state.billingAndSubscription.modifySubscriptionResponse,
  sendGetAccountSubscriptionResponse:
    state.billingAndSubscription.getAccountSubscriptionResponse,
  sendGetLeadFinderSubscriptionResponse:
    state.billingAndSubscription.getLeadFinderSubscriptionResponse,
  couponCodeDiscountType:
    state.billingAndSubscription.applyCouponCodeResponse?.discountType,
  couponCodeDiscount:
    state.billingAndSubscription.applyCouponCodeResponse?.discountValue,
  sendApplyCouponCodeRequestStatus:
    state.billingAndSubscription.applyCouponCodeRequest.status,
  couponCodeResponseMessage:
    state.billingAndSubscription.applyCouponCodeRequest.message,
  isSendApplyCouponCodeRequestError:
    state.billingAndSubscription.applyCouponCodeRequest.error?.error,

  // Calculate Pay
  sendGetCalculatePayRequestStatus:
    state.billingAndSubscription.calculatePayRequest.status,
  sendGetCalculatePayResponse:
    state.billingAndSubscription.getCalculatePayResponse,

  // Upgrade LTD Request Status
  upgradeLtdPlanRequestStatus:
    state.billingAndSubscription.upgradeLtdPlanRequest.status,
  upgradeLtdPlanRequestError:
    state.billingAndSubscription.upgradeLtdPlanRequest.error,
  upgradeLtdPlanResponse: state.billingAndSubscription.upgradeLtdPlanResponse,

  // Modify LTD Request Status
  modifyLtdPlanRequestStatus:
    state.billingAndSubscription.modifyLtdPlanRequest.status,
  modifyLtdPlanRequestError:
    state.billingAndSubscription.modifyLtdPlanRequest.error,
  modifyLtdPlanResponse: state.billingAndSubscription.modifyLtdPlanResponse,
  currentPlanName: state.home?.subscription?.planName,
});

const mapDispatchToProps = {
  sendGetAccountSubscriptionRequest: () => getAccountSubscriptionRequest(),
  sendGetLeadFinderSubscriptionRequest: () =>
    getLeadFinderSubscriptionRequest(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  sendGetPostLoadMetaRequest: () => getPostLoadMetaRequest(),
  sendApplyCouponCodeRequest: (payload: ApplyCouponCodeRequest) =>
    applyCouponCodeRequest(payload),
  resetSubscription: () => resetSubscription(),
  resetPurchaseModifySubscriptionResponse: () =>
    resetPurchaseModifySubscriptionResponse(),
  resetPurchaseLeadFinderModifySubscriptionResponse: () =>
    resetPurchaseLeadFinderModifySubscriptionResponse(),
  hideLoading: () => hideLoading(),
  showLoading: () => showLoading(),
  resetUpgradeLtdPlan: () => resetUpgradeLtdPlan(),
  resetModifyLtdPlan: () => resetModifyLtdPlan(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  planId: number;
  planName: string;
  planPrice: number;
  planCode: SubscriptionPlans;
  planType: PlanType;
  numberOfSlotsUserWant: number;
  minimumNumberOfSlotsUserCanHave: number;
  billingOption: BillingOption;
  couponCode: string;
  havePromoCode: boolean;
  isBillingSummaryPending: boolean;
  isUserModifyingSubscription: boolean;
  onPurchase: (token, amount) => void;
  onDeleteAppliedCouponCode: () => void;
  onCouponCodeChange: (value) => void;
  userHasPromoCode: (value) => void;
  isLeadFinderUpgrade?: boolean;
};

export default connector(UpgradePlanBillingSection);
