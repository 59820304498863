import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import type { IProps } from './domains-content-container';
import { Domain, DomainsFilters } from '../../types';
import { DomainAction } from '../../enums';

import toaster from '../../../../shared/toaster';

import DomainsActions from '../domains-actions';
import DomainsTable from '../domains-table';
import DeleteDomainModal from '../../modals/delete-domain-modal';
import RevokeCancellationModal from '../../modals/revoke-cancellation-modal';

import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../shared/utils';
import { NoSearchResult } from '../no-search-result';

const DomainsContent: React.FC<IProps> = ({
  domains,
  domainsFilters,

  sendGetDomainsRequest,
  resetGetDomainsRequestState,
  getDomainsRequestStatus,
  getDomainsRequestError,

  sendDeleteDomainRequest,
  resetDeleteDomainRequestState,
  deleteDomainRequestStatus,
  deleteDomainRequestError,

  updateDomainsFilters,
  setSelectedDomains,
}) => {
  const history = useHistory();

  const hasSearched = useRef(false);
  const searched = useRef('');
  const [action, setAction] = useState<DomainAction>(null);
  const [actionRow, setActionRow] = useState<Domain>(null);
  const [showDeleteDomainModal, setShowDeleteDomainModal] = useState(false);
  const [
    showRevokeCancellationModal,
    setShowRevokeCancellationModal,
  ] = useState(false);

  const onShowDeleteDomainModal = () => {
    setShowDeleteDomainModal(true);
  };

  const onCloseDeleteDomainModal = () => {
    setShowDeleteDomainModal(false);
    setAction(null);
    setActionRow(null);
  };

  const onShowRevokeCancellationModal = () => {
    setShowRevokeCancellationModal(true);
  };

  const onCloseRevokeCancellationModal = () => {
    setShowRevokeCancellationModal(false);
    setAction(null);
    setActionRow(null);
  };

  const onDomainsFiltersChange = (filters: Partial<DomainsFilters>) => {
    hasSearched.current = false;
    const payload = {
      ...domainsFilters,
      ...filters,
    };

    updateDomainsFilters(payload);

    const { page, pageSize, search } = payload;
    searched.current = filters.search;
    sendGetDomainsRequest({ page, pageSize, search });
  };

  const onAction = (key: string, row: Domain) => {
    setAction(key as DomainAction);
    setActionRow(row);

    if (key === DomainAction.DELETE) {
      onShowDeleteDomainModal();
      return;
    }

    if (key === DomainAction.REVOKE) {
      onShowRevokeCancellationModal();
    }
  };

  const onAddMoreEmailAccountClicked = (domain: Domain) => {
    setSelectedDomains(domain);
    history.push('/email-accounts/add-email-accounts');
  };

  useEffect(() => {
    const { page, pageSize, search } = domainsFilters;
    sendGetDomainsRequest({ page, pageSize, search });
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getDomainsRequestStatus,
      onSuccess: () => {
        if (searched !== undefined && searched.current !== '') {
          hasSearched.current = true;
        } else {
          hasSearched.current = false;
        }
        resetGetDomainsRequestState();
      },
      onFailed: () => {
        resetGetDomainsRequestState();
        executeOnErrorWithErrorCheck({
          error: getDomainsRequestError,
          onError: () => {
            toaster.error(getDomainsRequestError.message);
          },
        });
      },
    });
  }, [getDomainsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteDomainRequestStatus,
      onSuccess: () => {
        resetDeleteDomainRequestState();
        toaster.success(`Domain ${action.toLowerCase()}d successfully`);

        setAction(null);
        setActionRow(null);

        onCloseDeleteDomainModal();
        onCloseRevokeCancellationModal();

        const { page, pageSize, search } = domainsFilters;
        sendGetDomainsRequest({ page, pageSize, search });
      },
      onFailed: () => {
        resetDeleteDomainRequestState();
        setAction(null);
        setActionRow(null);
        onCloseDeleteDomainModal();
        onCloseRevokeCancellationModal();
        executeOnErrorWithErrorCheck({
          error: deleteDomainRequestError,
          onError: () => {
            toaster.error(deleteDomainRequestError.message);
          },
        });
      },
    });
  }, [deleteDomainRequestStatus]);

  const isLoading = getIsRequestPending(getDomainsRequestStatus);

  return (
    <div className="domains-content-container">
      <DomainsActions
        domainsFilters={domainsFilters}
        onDomainsFiltersChange={onDomainsFiltersChange}
      />

      {hasSearched.current && domains.length === 0 ? (
        <NoSearchResult />
      ) : (
        <DomainsTable
          domains={domains}
          domainsFilters={domainsFilters}
          onDomainsFiltersChange={onDomainsFiltersChange}
          onAction={onAction}
          isLoading={isLoading}
          onAddMoreEmailAccountClicked={onAddMoreEmailAccountClicked}
        />
      )}

      <DeleteDomainModal
        show={showDeleteDomainModal}
        onClose={onCloseDeleteDomainModal}
        isLoading={getIsRequestPending(deleteDomainRequestStatus)}
        onSubmit={() => {
          sendDeleteDomainRequest({
            id: actionRow.id,
            action: DomainAction.DELETE,
          });
        }}
      />

      <RevokeCancellationModal
        show={showRevokeCancellationModal}
        onClose={onCloseRevokeCancellationModal}
        isLoading={getIsRequestPending(deleteDomainRequestStatus)}
        onSubmit={() => {
          sendDeleteDomainRequest({
            id: actionRow.id,
            action: DomainAction.REVOKE,
          });
        }}
      />
    </div>
  );
};

export default DomainsContent;
