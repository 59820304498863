export type IProps = any;

export type LeadsFindRequestPayload = {
  start: number;
  take: number;
  name?: string;
  keyword?: string;
};

export const FilterComponentType = {
  SEARCH_INPUT: 'searchInput',
  DROP_DOWN: 'dropDown',
  MULTILEVEL_CHECKBOX: 'checkbox',
  TAG_CHECKBOX: 'savedTag',
  SAVED_LEAD_DATE: 'createdDate',
};

export const LeadFinderTabKey = {
  PEOPLE_TAB: 'people',
  SAVED_TAB: 'saved',
};

export enum LeadRevealType {
  EMAIL = 'email',
  EMAIL_PHONE = 'emailphone',
}

export const LeadRevealLimit = {
  VALID: 'valid',
  CREDIT_LIMIT_REACHED: 'credit-limit-reached',
  MORE_SELECTED: 'more-selected',
};

export enum BulkLeadsActionType {
  REVEAL_EMAIL = 'reveal_email',
  REVEAL_EMAIL_PHONE = 'reveal_email_phone',
  ADD_TO_PROSPECT = 'add_to_prospect',
  ADD_TO_SEQUENCE = 'add_tp_sequence',
  EXPORT = 'export',
}

export const BULK_ACTION_TIMEOUT = 4000;
export const MAX_POLLING_LIMIT = 20;

export const RR_PAGINATION_LIMIT = 10000;

export const LEAD_FINDER_REVEAL_LIMIT = 100;

export type SavedTabFilters = {
  tags: number[];
  createdDate: Date[];
  search: string;
};

export type AddTagsToLeadsPayload = {
  tagIds: number[];
  leads: number[];
  newTags: string[];
};

export type RemoveTagsToLeadsPayload = {
  tagIds: number[];
  leads: number[];
};

export type BulkActionPayload = {
  deSelectedLeadIds?: number[];
  leadsFilter?: {
    tags?: number[];
    search?: string;
    createdDate?: any[];
  };
  newTags?: string[];
  tagIds?: number[];
};

export type BulkActionRemoveTagsPayload = {
  deSelectedLeadIds?: number[];
  leadsFilter?: {
    tags?: number[];
    search?: string;
    createdDate?: any[];
  };
  tagIds?: number[];
};

export interface BulkAddToProspectSavedDto {
  deSelectedLeadIds?: number[];
  leadsFilter: {
    tags?: number[];
    search?: string;
    createdDate?: any[];
  };
}

export interface BulkAddToSequenceSavedDto {
  sequenceId: number;
  stepId: number;
  deSelectedLeadIds?: number[];
  leadsFilter: {
    tags?: number[];
    search?: string;
    createdDate?: any[];
  };
}

export interface BulkLeadRevealPeopleDto {
  leadsFilter: any;
  count: number;
  leadIds: number[];
  newTags?: string[];
  tagIds?: number[];
  revealType: LeadRevealType;
}

export interface BulkLeadExportSavedDto {
  deSelectedLeadIds?: number[];
  leadsFilter: {
    tags?: number[];
    search?: string;
    createdDate?: any[];
  };
}

export interface RevealSingleLeadPayload {
  revealLeadId: number;
  leadsFilter: any;
  revealType: LeadRevealType;
}
