import {
  getParsedPlanCode,
  calculatePlanPriority,
} from '../../../../../components/settings/components/billing-subscription/components/lead-finder-plans/components/plans-section/utils/get-lead-finder-plans-priority';
import { getPlanId } from '../../../../../components/settings/components/billing-subscription/components/upgrade-plan/utils/helper';
import { BillingOption } from '../../../../utils/billing-options';
import {
  SubscriptionPlanModes,
  SubscriptionPlans,
} from '../../../../utils/subscription-plans';
import { GetButtonLabel, SelectPlanCTAText } from './types';

const getButtonLabelForNormalPlan = (
  plan,
  planCycle,
  sendGetAccountSubscriptionResponse,
  sendGetPlansResponse,
) => {
  const {
    planName,
    planMode,
    planType,
    planCode,
  } = sendGetAccountSubscriptionResponse;

  const subscriptionId = getPlanId(
    sendGetPlansResponse,
    planName,
    planCode,
    planType,
  );

  if (
    planMode === SubscriptionPlanModes.MonthlyMode &&
    planCycle === BillingOption.ANNUALLY
  ) {
    return SelectPlanCTAText.UpgradePlan;
  }
  if (subscriptionId) {
    if (subscriptionId > plan.id) {
      return SelectPlanCTAText.DowngradePlan;
    }
    if (subscriptionId === plan.id) {
      return SelectPlanCTAText.CurrentPlan;
    }
  }

  return SelectPlanCTAText.UpgradePlan;
};

export const getButtonLabel = ({
  plan,
  planCycle,
  sendGetAccountSubscriptionResponse,
  sendGetPlansResponse,
}: GetButtonLabel) => {
  if (planCycle === BillingOption.LIFETIME) {
    if (plan?.planCode === sendGetAccountSubscriptionResponse?.planCode) {
      return SelectPlanCTAText.CurrentPlan;
    }
    if (plan?.planCode === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME) {
      return SelectPlanCTAText.DowngradePlan;
    }
    return SelectPlanCTAText.UpgradePlan;
  }

  return getButtonLabelForNormalPlan(
    plan,
    planCycle,
    sendGetAccountSubscriptionResponse,
    sendGetPlansResponse,
  );
};

// Function to determine the appropriate CTA label for the plan button
export const getLeadFinderButtonLabel = ({
  plan,
  sendGetLeadFinderSubscriptionResponse,
}: GetButtonLabel) => {
  const { planCode: currentPlanCode } = sendGetLeadFinderSubscriptionResponse;

  // Free or Trial plan always leads to upgrade
  if (
    currentPlanCode === SubscriptionPlans.LEAD_FINDER_FREE ||
    currentPlanCode === SubscriptionPlans.LEAD_FINDER_TRIAL
  ) {
    return SelectPlanCTAText.UpgradePlan;
  }

  const currentPlan = getParsedPlanCode(currentPlanCode);
  const newPlan = getParsedPlanCode(plan?.planCode);

  const currentPlanPriority = calculatePlanPriority(currentPlanCode);
  const newPlanPriority = calculatePlanPriority(plan?.planCode);

  // Handle type change (email-only to email-phone or vice versa)
  if (currentPlan.type === 'email' && newPlan.type === 'email-phone') {
    return SelectPlanCTAText.UpgradePlan;
  }

  if (currentPlan.type === 'email-phone' && newPlan.type === 'email') {
    return SelectPlanCTAText.DowngradePlan;
  }

  // Compare plan priorities
  if (newPlanPriority === currentPlanPriority) {
    return SelectPlanCTAText.CurrentPlan;
  }
  if (newPlanPriority > currentPlanPriority) {
    return SelectPlanCTAText.UpgradePlan;
  }
  return SelectPlanCTAText.DowngradePlan;
};
