/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import React from 'react';
import produce from 'immer';
import { Link } from 'react-router-dom';
import { SkeletonLoading } from '@saleshandy/design-system';

import { Spinner } from 'reactstrap';
import { ChevronLeft } from '@saleshandy/icons';
import type { IProps, IState } from './types';

import Input from '../../../../shared/design-system/components/input';
import Button from '../../../../shared/design-system/ui/button';
import { SHLogoDark } from '../../../../shared/svg';

import {
  executeOnRequestStatusWithPrevStatusCheck,
  getIsRequestPending,
} from '../../../../shared/utils';
import { RequestStatus } from '../../../../shared/enums/request-status';
import toaster, { Theme } from '../../../../shared/toaster';
import { openChatSupport } from '../../../../shared/utils/open-chat-support';
import { isInternalUrl } from '../../../../shared/utils/is-internal-url';
import { saveToLocalStorage } from '../../../email-accounts/helpers/email-accounts-infra-local-storage';
import classNames from 'classnames';

class SSOLoginForm extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      values: {
        email: '',
      },
      errors: {
        email: '',
      },
      dirty: {
        email: false,
      },
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    const { values } = this.state;

    const { search } = history.location;
    const userEmail = new URLSearchParams(search).get('userEmail');

    if (userEmail) {
      this.setState({ values: { ...values, email: userEmail } });
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      ssoLoginRequestStatus,
      ssoLoginRequestError,
      redirectUrl,
    } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: ssoLoginRequestStatus,
      prevStatus: prevProps.ssoLoginRequestStatus,
      onSuccess: () => {
        if (redirectUrl) {
          window.open(redirectUrl, '_self');
        }
      },
      onFailed: () => {
        if (ssoLoginRequestError?.message?.includes('support')) {
          toaster.error(
            <div>
              Your account is suspended, to revoke this suspension feel free to
              reach out to{' '}
              <button
                type="button"
                onClick={() => openChatSupport()}
                className="font-bold red-txt-13 underline bg-transparent border-0 p-0 cursor-pointer"
              >
                <u>support</u>
              </button>
            </div>,
            {
              theme: Theme.New,
              showCloseIcon: true,
              delay: 1000000,
            },
          );
        }
      },
    });
  }

  componentWillUnmount() {
    const { hideError } = this.props;
    hideError?.();
  }

  onInputChange(value: string, e: React.ChangeEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = this.validate(name, draft.values[name]);
        }
      }),
    );
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { dirty, errors, values } = this.state;
    const { sendSSOLoginRequest } = this.props;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = this.validate(key, values[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    const { email } = values;
    sendSSOLoginRequest(email);
  }

  truncateWord = (word, maxLength) => {
    if (word?.length > maxLength) {
      return `${word.substring(0, maxLength)}...`;
    }
    return word;
  };

  validate(name: string, value: string): string {
    switch (name) {
      case 'email': {
        if (!value) {
          return 'Email is required';
        }

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(value)) {
          return 'Please enter valid email address.';
        }

        return '';
      }
      default:
        return '';
    }
  };

  companyNameLoader(
    agencyLoading: boolean,
    isWhitelabel: boolean,
    companyName?: string,
  ) {
    if (agencyLoading) {
      return (
        <div className="container-fluid d-flex justify-content-center align-items-center">
          <SkeletonLoading width={350} height={40} />
        </div>
      );
    }

    if (isWhitelabel) {
      return (
        <div className="d-flex">
          <span className="mr-1">
            Login to {this.truncateWord(companyName || 'Saleshandy', 20)} with SSO
          </span>
        </div>
      );
    }

    return <span>Login to Saleshandy with SSO</span>;
  }

  clientLogoLoader(
    agencyLoading: boolean,
    isWhitelabel?: boolean,
    clientLogo?: string,
  ) {
    if (agencyLoading) {
      return (
        <div className="container-fluid d-flex justify-content-center align-items-center">
          <SkeletonLoading width={200} />
        </div>
      );
    }

    if (!isWhitelabel && !clientLogo) {
      return <SHLogoDark width={200} />;
    }

    if (isWhitelabel && clientLogo) {
      return <img src={clientLogo} alt="logo" />;
    }
  }

  hasErrors() {
    const { errors, dirty } = this.state;

    let isError = false;

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' || !dirty[key]) {
        isError = true;
      }
    });

    return isError;
  }

  render() {
    const {
      ssoLoginRequestStatus,
      ssoLoginRequestMessage,
      ssoLoginRequestError,
      ssoLoginRequestShowError,
      agencyConfigRequestStatus,
      isWhitelabel,
      companyName,
      clientLogo,
    } = this.props;

    const { values, errors } = this.state;

    const isLoginBtnLoading = getIsRequestPending(ssoLoginRequestStatus);
    const isLoginBtnDisabled = isLoginBtnLoading || this.hasErrors();
    const isAgencyLoading =
      getIsRequestPending(agencyConfigRequestStatus) && !isInternalUrl();

    if (isAgencyLoading && !isWhitelabel) {
      return <SkeletonLoading />;
    }

    const authWrapperClassName = classNames([
        'auth-wrapper',
        {
          'w-100 d-flex': isAgencyLoading || isWhitelabel,
        },
      ]); 

    return (
      <div
        className={authWrapperClassName}
      >
        {isLoginBtnLoading ? (
          <div className="w-100 h-100 text-center mt-5 sso-login-spinner">
            <Spinner className="spinner--blue" animation="border" />
            <span className="sso-login-spinner-text">Just few seconds more...</span>
          </div>
        ) : (
          <div className="auth-container">
            <div className="auth-form">
              <div className={`sh-logo ${isWhitelabel && 'd-block'}`}>
                {this.clientLogoLoader(isAgencyLoading, isWhitelabel, clientLogo)}
              </div>
              <h1 className="auth-container--title">
                {this.companyNameLoader(
                  isAgencyLoading,
                  isWhitelabel,
                  companyName,
                )}
              </h1>
              <form onSubmit={this.onFormSubmit} className="auth-container--form sso-login-form">
                <div className="auth-form-row">
                  <div className="auth-form-input email">
                    <Input
                      name="email"
                      label="Work Email"
                      type="email"
                      placeholder="Enter your work email address"
                      value={values.email}
                      variant={errors.email && Input.Variant.Error}
                      caption={errors.email || (ssoLoginRequestShowError ? ssoLoginRequestError?.message : '')}
                      onChange={this.onInputChange}
                      onBlur={this.onInputBlur}
                      tabIndex={0}
                      autoComplete="current-email"
                    />
                  </div>
                </div>

                <Button
                  type="submit"
                  isFullWidth
                  isLoading={isLoginBtnLoading}
                  disabled={isLoginBtnDisabled}
                  loadingText="Logging in with SSO, please wait..."
                >
                  Login
                </Button>

                {ssoLoginRequestShowError && (
                  <div className="auth-form-row">
                    <div className="error-message">
                      {ssoLoginRequestError?.message || ssoLoginRequestMessage}
                    </div>
                  </div>
                )}
              </form>
            </div>
            <div className="sso-setup-back-link">
              <p>
                <Link to="/login">
                  <ChevronLeft />
                  <span>Back</span>
                </Link>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SSOLoginForm;
