import { Images } from '../../../../../../../../../shared/app-constants';
import { SubscriptionPlanTitle } from '../../../../../../../../../shared/utils/subscription-plans';
import { GetPlansResponse } from '../../../../../types';
import { getPlanPeriod } from '../../../utils/helper';
import { LeadFinderSubscriptionPlansKey } from '../../plans-section/utils/helper';

const leadFinderEmailStarterMonthlyFeatures = [
  {
    label: '1,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];
const leadFinderEmailStarterYearlyFeatures = [
  {
    label: '1,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];

const leadFinderEmailProMonthlyFeatures = [
  {
    label: '2,500 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];
const leadFinderEmailProYearlyFeatures = [
  {
    label: '2,500 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];

const leadFinderEmailScaleMonthlyFeatures = [
  {
    label: '5,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];
const leadFinderEmailScaleYearlyFeatures = [
  {
    label: '5,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];

const leadFinderEmailScalePlus1MonthlyFeatures = [
  {
    label: '10,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];
const leadFinderEmailScalePlus1YearlyFeatures = [
  {
    label: '10,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];

const leadFinderEmailScalePlus2MonthlyFeatures = [
  {
    label: '20,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];
const leadFinderEmailScalePlus2YearlyFeatures = [
  {
    label: '20,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];

const leadFinderEmailScalePlus3MonthlyFeatures = [
  {
    label: '50,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];
const leadFinderEmailScalePlus3YearlyFeatures = [
  {
    label: '50,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];

const leadFinderEmailScalePlus4MonthlyFeatures = [
  {
    label: '100,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];
const leadFinderEmailScalePlus4YearlyFeatures = [
  {
    label: '100,000 lead finder credits',
    labelClass: 'font-semibold',
    highlight: true,
  },
  {
    label: 'Unused Credits Rollover',
  },
  {
    label: 'Real-time verified leads',
  },
  {
    label: 'Includes both personal and work email',
    labelClass: 'break-lines',
  },
  {
    label: 'Advanced search filters',
  },
  {
    label: 'Email and Phone information can be revealed',
    tooltipText:
      '1 credit per email and 2 credits for email + phone information will be charged',
  },
];

const planMetaData = {
  [SubscriptionPlanTitle.LeadFinderEmailStarterMonthly]: {
    displayName: 'Starter',
    features: leadFinderEmailStarterMonthlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailProMonthly]: {
    displayName: 'Pro',
    features: leadFinderEmailProMonthlyFeatures,
    isBestValuePlan: true,
  },
  [SubscriptionPlanTitle.LeadFinderEmailScaleMonthly]: {
    displayName: 'Scale',
    features: leadFinderEmailScaleMonthlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailScalePlus1Monthly]: {
    displayName: 'Plus 10k',
    features: leadFinderEmailScalePlus1MonthlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailScalePlus2Monthly]: {
    displayName: 'Plus 20k',
    features: leadFinderEmailScalePlus2MonthlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailScalePlus3Monthly]: {
    displayName: 'Plus 50k',
    features: leadFinderEmailScalePlus3MonthlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailScalePlus4Monthly]: {
    displayName: 'Plus 100k',
    features: leadFinderEmailScalePlus4MonthlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailStarterYearly]: {
    displayName: 'Starter',
    features: leadFinderEmailStarterYearlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailProYearly]: {
    displayName: 'Pro',
    features: leadFinderEmailProYearlyFeatures,
    isBestValuePlan: true,
  },
  [SubscriptionPlanTitle.LeadFinderEmailScaleYearly]: {
    displayName: 'Scale',
    features: leadFinderEmailScaleYearlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailScalePlus1Yearly]: {
    displayName: 'Plus 10k',
    features: leadFinderEmailScalePlus1YearlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailScalePlus2Yearly]: {
    displayName: 'Plus 20k',
    features: leadFinderEmailScalePlus2YearlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailScalePlus3Yearly]: {
    displayName: 'Plus 50k',
    features: leadFinderEmailScalePlus3YearlyFeatures,
  },
  [SubscriptionPlanTitle.LeadFinderEmailScalePlus4Yearly]: {
    displayName: 'Plus 100k',
    features: leadFinderEmailScalePlus4YearlyFeatures,
  },
};

export const getLeadFinderPlanMetaData = (planName) => planMetaData[planName];

export const getPlanPrice = (planPrice, planMode) =>
  Number(planPrice) / Number(planMode);

export const getPlanDetailsObj = (plan) => ({
  id: plan.id || null,
  planName: plan.planName,
  displayName: getLeadFinderPlanMetaData(plan.planName)?.displayName,
  isBestValuePlan: getLeadFinderPlanMetaData(plan.planName)?.isBestValuePlan,
  features: getLeadFinderPlanMetaData(plan.planName)?.features,
  price: getPlanPrice(plan.amount, plan.planMode) || null,
  ...plan,
});

export const getLeadFinderPlanMonthlyPrice = (
  planCode: string,
  plans: GetPlansResponse,
) => {
  const correspondingMonthlyPlanCode =
    planCode?.replace('yearly', 'monthly') || '';

  const correspondingMonthlyPlan = plans.find(
    (plan) => plan.planCode === correspondingMonthlyPlanCode,
  );

  if (correspondingMonthlyPlan) {
    return parseInt(correspondingMonthlyPlan?.amount || '0', 10);
  }

  return 0;
};

export const getLeadFinderPlanYearlyPrice = (
  planCode: string,
  plans: GetPlansResponse,
) => {
  const correspondingYearlyPlanCode =
    planCode?.replace('monthly', 'yearly') || '';

  const correspondingYearlyPlan = plans.find(
    (plan) => plan.planCode === correspondingYearlyPlanCode,
  );

  if (correspondingYearlyPlan) {
    return parseInt(correspondingYearlyPlan?.amount || '0', 10);
  }

  return 0;
};

export const stepWisePlanProspects = {
  step1: '10,000',
  step2: '20,000',
  step3: '50,000',
  step4: '100,000',
};

export const getSelectedPlanProspects = (step) =>
  stepWisePlanProspects[`step${step}`];

export const getInitialStepValue = ({
  planMode,
  planCode,
  planCycle,
  isEmailPhonePlan,
}) => {
  const planCyclePeriod = Number(getPlanPeriod(planCycle));

  const planList =
    LeadFinderSubscriptionPlansKey.leadScale[planCyclePeriod][
      isEmailPhonePlan ? 'emailPhone' : 'email'
    ];

  if (planList.includes(planCode)) {
    if (planCyclePeriod === planMode) {
      const planIndex = planList.findIndex((p) => p === planCode);
      return planIndex + 1;
    }
  }
  return 1;
};

export const getCustomScalePlusDiscountPer = (step) => {
  const stepWiseDiscount = {
    step1: '20',
    step2: '20',
    step3: '20',
    step4: '20',
  };

  return stepWiseDiscount[`step${step}`];
};

export const getPlanIcon = (planName) => {
  const planIconMap = {
    [SubscriptionPlanTitle.LeadFinderEmailStarterMonthly]: Images.RocketColor,
    [SubscriptionPlanTitle.LeadFinderEmailProMonthly]: Images.HeartColor,
    [SubscriptionPlanTitle.LeadFinderEmailScaleMonthly]: Images.StarColor,
    [SubscriptionPlanTitle.LeadFinderEmailScalePlus1Monthly]:
      Images.BuildingColor,
    [SubscriptionPlanTitle.LeadFinderEmailScalePlus2Monthly]:
      Images.BuildingColor,
    [SubscriptionPlanTitle.LeadFinderEmailScalePlus3Monthly]:
      Images.BuildingColor,
    [SubscriptionPlanTitle.LeadFinderEmailScalePlus4Monthly]:
      Images.BuildingColor,
    [SubscriptionPlanTitle.LeadFinderEmailStarterYearly]: Images.RocketColor,
    [SubscriptionPlanTitle.LeadFinderEmailProYearly]: Images.HeartColor,
    [SubscriptionPlanTitle.LeadFinderEmailScaleYearly]: Images.StarColor,
    [SubscriptionPlanTitle.LeadFinderEmailScalePlus1Yearly]:
      Images.BuildingColor,
    [SubscriptionPlanTitle.LeadFinderEmailScalePlus2Yearly]:
      Images.BuildingColor,
    [SubscriptionPlanTitle.LeadFinderEmailScalePlus3Yearly]:
      Images.BuildingColor,
    [SubscriptionPlanTitle.LeadFinderEmailScalePlus4Yearly]:
      Images.BuildingColor,
  };

  return planIconMap[planName];
};
