import { RequestStatus } from '../../../../shared/enums/request-status';
import { ActionForAuthentication, OAuthPayload } from '../../types';

export enum OAuthMethod {
  Google = 'google',
  Microsoft = 'microsoft',
}

export interface OAuthButtonsProps {
  type?: ActionForAuthentication;
  className?: string;
  getOAuthRedirectUrlRequestStatus?: RequestStatus;
  getOAuthRedirectUrlRequestError?: string;
  authUrl?: string;
  email?: string;
  userId?: number;
  getOAuthRedirectUrl?: (method: OAuthMethod, payload: OAuthPayload) => void;
}

export interface OAuthRedirectResponse {
  authUrl: string;
}
