import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import LoginForm from './login-form';
import {
  hideError,
  loginRequest,
  setIsRedirectedFromAgency,
} from '../login/login-slice';

const mapStateToProps = (state: RootState) => ({
  loginRequestStatus: state.login.loginRequest.status,
  loginRequestMessage: state.login.loginRequest.message,
  loginRequestError: state.login.loginRequest.error,
  loginRequestShowError: state.login.loginRequest.showError,

  token: state.login.token,

  trackingId: state.login.trackingId,
  firstName: state.login.firstName,
  lastName: state.login.lastName,
  email: state.login.email,
  mfaChannel: state.login.mfaChannel,

  agencyConfig: state.home.agencyConfig,

  isWhitelabel: !!state.home.agencyConfig?.clientBaseUrl,
  companyName: state.home.agencyConfig?.companyName,
  clientLogo: state.home.agencyConfig?.clientLogo,

  agencyConfigRequestStatus: state.home.getAgencyConfigRequest.status,
});

const mapDispatchToProps = {
  hideError: () => hideError(),
  sendLoginRequest: (email: string, password: string) =>
    loginRequest({ email, password }),
  setIsRedirectedFromAgency: (value: boolean) =>
    setIsRedirectedFromAgency(value),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(LoginForm));
