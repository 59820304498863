/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IProps } from './types';
import Modal from '../../../../../../../shared/design-system/components/atoms/modal';
import Button from '../../../../../../../shared/design-system/components/atoms/button';

const GetContactDetail: React.FC<IProps> = ({
  show,
  onHide,
  onDelete,
  isLoading,
  title,
  icon,
  content,
  submitButtonText,
  showCloseIcon,
  disableSubmit = false,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      titleContent={
        <div className="d-flex align-items-center">
          <div className="orange-txt-14">{icon}</div>
          <div>{title}</div>
        </div>
      }
      showCloseIcon={showCloseIcon}
      onClose={onHide}
      cancelButtonText={t('labels.cancel')}
      submitButtonText={submitButtonText}
      onSubmit={onDelete}
      cancelButtonVarient={Button.Variant.Outlined}
      onHide={onHide}
      isSubmitLoading={isLoading}
      isCancelDisabled={false}
      className="get-contact-info-modal"
      isSubmitDisabled={disableSubmit}
    >
      <div className="mt-2">{content}</div>
    </Modal>
  );
};

export default GetContactDetail;
