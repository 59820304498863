import React, { useEffect, useState, useRef } from 'react';
import { InfoCircle, UserList } from '@saleshandy/icons';
import { Pills } from '@saleshandy/design-system';
import ContentEditable from 'react-contenteditable';
import { TeamsList, User } from '../../../../../types/users-and-teams';
import Select from '../../../../../../../shared/design-system/components/select';
import ConfirmationModalV3 from '../../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { RequestStatus } from '../../../../../../../shared/enums/request-status';
import { getIsRequestPending } from '../../../../../../../shared/utils';
import Checkbox from '../../../../../../../shared/design-system/components/atoms/checkbox';
import { OverlayTooltip } from '../../../../../../../shared/design-system/components/overlay';

type IProps = {
  show: boolean;
  teamsList: TeamsList[];
  onSubmit: (teamIds: number[], isDefaultTeamMember: boolean) => void;
  onClose: () => void;
  isLoading: boolean;
  getTeamsListRequestStatus: RequestStatus;
  actionUser: User;
  keepAllTeamsAssigned: boolean;
  sendGetAssignTeamSettingRequest: (id: number) => void;
};

type AssignTeamType = TeamsList & {
  key: string;
};

const AssignTeamModal: React.FC<IProps> = ({
  show,
  teamsList = [],
  onSubmit,
  onClose,
  isLoading,
  getTeamsListRequestStatus,
  actionUser,
  keepAllTeamsAssigned: keepAllTeamsAssignedDefault,
  sendGetAssignTeamSettingRequest,
}) => {
  const searchInput = useRef<HTMLDivElement>(null);
  const [assignTeamDropdown, setAssignTeamDropdown] = useState(false);
  const [selectedTeamsKeys, setSelectedTeamsKeys] = useState<string[]>([]);
  const [keepAllTeamsAssigned, setKeepAllTeamsAssigned] = useState<boolean>(
    false,
  );
  const [searchText, setSearchText] = useState('');
  const [isEditableDivFocused, setIsEditableDivFocused] = useState(false);

  const mappedTeamsForFilter = teamsList.map((team) => ({
    key: team.id.toString(),
    ...team,
  }));

  const onToggle = (value: boolean) => {
    setAssignTeamDropdown(value);
    if (value && searchInput?.current) {
      searchInput.current.focus();
    }
    if (isEditableDivFocused && !value) {
      setIsEditableDivFocused(false);
      setSearchText('');
    }
  };

  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    if (!assignTeamDropdown) {
      setAssignTeamDropdown(true);
    }
  };

  const onAssignTeam = () => {
    onSubmit(
      selectedTeamsKeys.map((key) => Number(key)),
      keepAllTeamsAssigned,
    );
  };

  const onTeamRemove = (key: string) => {
    setSelectedTeamsKeys(
      selectedTeamsKeys.filter((teamKey) => teamKey !== key),
    );
  };

  const onKeepAllTeamsAssignedChange = (checked: boolean) => {
    setKeepAllTeamsAssigned(checked);
  };

  useEffect(() => {
    if (keepAllTeamsAssigned !== keepAllTeamsAssignedDefault) {
      setKeepAllTeamsAssigned(keepAllTeamsAssignedDefault);
    }
  }, [keepAllTeamsAssignedDefault]);

  useEffect(() => {
    if (keepAllTeamsAssigned) {
      setSelectedTeamsKeys([]);
    } else if (actionUser) {
      const selectedTeams =
        actionUser.teams?.map((team) => team.id?.toString()) || [];
      setSelectedTeamsKeys(selectedTeams);
    }
  }, [keepAllTeamsAssigned]);

  useEffect(() => {
    sendGetAssignTeamSettingRequest(actionUser?.id);
  }, []);

  return (
    <ConfirmationModalV3
      show={show}
      hideTitleIcon
      icon={<UserList className="blue-txt-11" />}
      title="Assign Team"
      showCloseIcon={false}
      className="action-modal"
      customJSX={
        <div className="user-action">
          <p className="user-action-label">Assign Team</p>
          <Select<AssignTeamType>
            className="users-change-role-select"
            show={assignTeamDropdown}
            onToggle={onToggle}
            options={mappedTeamsForFilter}
            multiSelect
            selectedOptionKey={selectedTeamsKeys.map((key) => key)}
            optionFilterProp="name"
            placeholder={
              keepAllTeamsAssigned ? (
                'All teams selected'
              ) : (
                <ContentEditable
                  html={isEditableDivFocused ? searchText : 'Select'}
                  disabled={false}
                  onChange={onSearchChange}
                  className="select-search"
                  placeholder="Select"
                  innerRef={searchInput}
                  onFocus={() => {
                    if (!isEditableDivFocused) {
                      setIsEditableDivFocused(true);
                    }
                  }}
                />
              )
            }
            filterOption={(_, option) =>
              option?.name?.toLowerCase().includes(searchText.toLowerCase())
            }
            selectedOptionRenderer={(option) => (
              <div className="email-input--pills">
                {option?.map(
                  (team) =>
                    team && (
                      <Pills
                        theme="outline"
                        size="md"
                        label={`${team.name}`.trim()}
                        showCloseIcon={true}
                        onClose={() => onTeamRemove(team.key)}
                        key={team.id}
                      />
                    ),
                )}
                <ContentEditable
                  innerRef={searchInput}
                  html={searchText}
                  disabled={false}
                  onChange={onSearchChange}
                  className="select-search"
                  onBlur={() => {
                    if (isEditableDivFocused) {
                      setIsEditableDivFocused(false);
                    }
                    setSearchText('');
                  }}
                />
              </div>
            )}
            onChange={(selectedTeams) => {
              if (searchText) {
                setSearchText('');
              }
              searchInput?.current?.focus();
              setSelectedTeamsKeys(selectedTeams.map((option) => option.key));
            }}
            optionRenderer={(option) => (
              <span className="blue-txt-15">{option?.name}</span>
            )}
            disabled={keepAllTeamsAssigned}
            isLoading={getIsRequestPending(getTeamsListRequestStatus)}
          />
          <div className="d-flex align-items-center bs-mt-12 assign-team-checkbox">
            <Checkbox
              checked={keepAllTeamsAssigned}
              onChange={onKeepAllTeamsAssignedChange}
              label="Keep all teams assigned"
            />
            <OverlayTooltip
              className="assign-team-tooltip"
              text="Selecting this option will automatically keep member assigned to all teams. Member will remain assigned even if new teams are added in the future. Uncheck this option if you prefer manual assignment."
            >
              <InfoCircle width={16} height={16} className="gray-txt-11" />
            </OverlayTooltip>
          </div>
        </div>
      }
      cancelButtonText="Cancel"
      submitButtonText="Assign"
      onSubmit={onAssignTeam}
      onClose={onClose}
      isSubmitDisabled={isLoading}
      isSubmitLoading={isLoading}
      cancelButtonClassName="cancel-btn"
    />
  );
};

export default AssignTeamModal;
