import React, { useCallback, useMemo } from 'react';
import { OverlayTooltip } from '../../overlay';
import { NotificationTextAsyncProps } from './type';

const NotificationTextAsync: React.FC<NotificationTextAsyncProps> = ({
  notificationText = '',
  notification = null,
}) => {
  const importFileName = useMemo(
    () => notification?.metaData?.importFile?.name || '',
    [notification?.metaData?.importFile?.name],
  );
  const sequenceName = useMemo(() => notification?.sequenceName || '', [
    notification?.sequenceName,
  ]);
  const totalCountNumber = useMemo(
    () => notification?.metaData?.totalCount || 0,
    [notification?.metaData?.totalCount],
  );
  const completedCountNumber = useMemo(
    () => notification?.metaData?.completedCount || 0,
    [notification?.metaData?.completedCount],
  );

  const logList = useMemo(() => {
    if (notificationText) {
      // eslint-disable-next-line no-useless-escape
      const regex = /([^\{\}]+|\{\{[a-zA-Z0-9_]+\}\})/g;

      // Use the regex to match and split the string
      const result = notificationText.match(regex);
      return result;
    }
    return null;
  }, [notificationText, notification?.metaData]);

  const getTextByVariableDetail = useCallback(
    (variable) => {
      switch (variable) {
        case '{{import_file_name}}':
          return {
            text: importFileName,
            displayName:
              importFileName?.length > 15
                ? `${importFileName?.slice(0, 15)}..`
                : importFileName,
          };
        case '{{sequence_name}}':
          return {
            text: sequenceName,
            displayName:
              sequenceName?.length > 25
                ? `${sequenceName?.slice(0, 25)}..`
                : sequenceName,
          };
        case '{{total_count}}':
          return {
            text: totalCountNumber,
            displayName: totalCountNumber.toString(),
          };
        case '{{completed_count}}':
          return {
            text: completedCountNumber,
            displayName: completedCountNumber.toString(),
          };

        default:
          return variable || '';
      }
    },
    [importFileName, sequenceName, totalCountNumber, completedCountNumber],
  );

  if (logList?.length > 0) {
    return (
      <span className="notification-data">
        {logList.map((item, index) => {
          if (item === '{{total_count}}' || item === '{{completed_count}}') {
            const countData = getTextByVariableDetail(item);
            return (
              <span
                className="notification-data"
                key={`notification-data-${index + 1}`}
              >
                {countData?.displayName || 0}
              </span>
            );
          }
          const isVariable = item.includes('{{') && item.includes('}}');

          if (isVariable) {
            const variableData = getTextByVariableDetail(item);
            return (
              <OverlayTooltip
                text={variableData?.text}
                key={`notification-data-bold-${index + 1}`}
              >
                <span className="notification-data-bold">
                  {variableData?.displayName || ''}
                </span>
              </OverlayTooltip>
            );
          }

          return (
            <span
              className="notification-data"
              key={`'notification-data-${index + 1}`}
            >
              {item}
            </span>
          );
        })}
      </span>
    );
  }

  return <></>;
};

export default NotificationTextAsync;
