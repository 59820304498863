import { createSlice } from '@reduxjs/toolkit';
import { getOAuthRedirectUrlRequest, oauthAuthenticateRequest } from './extra-actions';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { RequestState } from '../../../../shared/types/request-state';
import { ActionForAuthentication } from '../../types';

interface OAuthState {
  getOAuthRedirectUrlRequest: RequestState;
  oauthAuthenticateRequest: RequestState;
  authUrl: string | null;

  token: string;
  trackingId: string;
  firstName: string;
  lastName: string;
  email: string;
  action: ActionForAuthentication;
}

const initialState: OAuthState = {
  getOAuthRedirectUrlRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  oauthAuthenticateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  authUrl: '',
  token: '',
  trackingId: null,
  firstName: null,
  lastName: null,
  email: null,
  action: null,
};

const oauthSlice = createSlice({
  name: 'oauth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOAuthRedirectUrlRequest.pending, (state) => {
      state.getOAuthRedirectUrlRequest.status = RequestStatus.Pending;
      state.getOAuthRedirectUrlRequest.error = null;
    });
    builder.addCase(getOAuthRedirectUrlRequest.fulfilled, (state, action) => {
      state.getOAuthRedirectUrlRequest.status = RequestStatus.Succeeded;
      state.getOAuthRedirectUrlRequest.message = action.payload.message;
      state.authUrl = action.payload.payload?.authUrl;
    });
    builder.addCase(getOAuthRedirectUrlRequest.rejected, (state, action) => {
      state.getOAuthRedirectUrlRequest.status = RequestStatus.Failed;
      state.getOAuthRedirectUrlRequest.error =
        !action.payload.isHandled && action.payload;
    });

    builder.addCase(oauthAuthenticateRequest.pending, (state) => {
      state.oauthAuthenticateRequest.status = RequestStatus.Pending;
      state.oauthAuthenticateRequest.error = null;
    });
    builder.addCase(oauthAuthenticateRequest.fulfilled, (state, action) => {
      state.oauthAuthenticateRequest.status = RequestStatus.Succeeded;
      state.oauthAuthenticateRequest.message = action.payload.message;
      state.email = action.payload.payload?.email;
      state.token = action.payload.payload?.accessToken;
      state.trackingId = action.payload.payload?.trackingId;
      state.firstName = action.payload.payload?.firstName;
      state.lastName = action.payload.payload?.lastName;
      state.action = action.payload.payload?.action;
    });
    builder.addCase(oauthAuthenticateRequest.rejected, (state, action) => {
      state.oauthAuthenticateRequest.status = RequestStatus.Failed;
      state.oauthAuthenticateRequest.error = !action.payload.isHandled && action.payload;
    });
  },
});

export default oauthSlice.reducer; 