import React, { useState, useMemo, useEffect } from 'react';
import { DotsVertical, Pencil, Trash } from '@saleshandy/icons';
import { SkeletonLoading } from '@saleshandy/design-system';
import { Dropdown } from 'react-bootstrap';
import type { IProps } from './teams-container';
import Table from '../../../../../../shared/design-system/components/organisms/table';
import {
  Action,
  Column,
  PaginationShowHide,
} from '../../../../../../shared/design-system/components/organisms/table/types';
import {
  DeleteUserFromTeamPayload,
  Team,
  TeamUser,
  TeamsFilter,
} from '../../../../types/users-and-teams';
import { getFormattedDate } from '../../utils/get-formatted-date';
import AddModifyTeamModal from './modals/add-modify-team-modal';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import toaster, { Theme } from '../../../../../../shared/toaster';
import TeamDetailsModal from './modals/team-details-modal';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import {
  TeamSortBy,
  UserAndTeamsTabs,
  UserRole,
} from '../../../../enums/users-and-teams';
import { Order } from '../../../../../../shared/enums/order';
import { Placement } from '../../../../../../shared/design-system/components/overlay';
import DeleteTeamModal from './modals/delete-team-modal';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { getActionPermissions } from './utils/get-action-permissions';
import TruncatedTextWithTooltip from '../../../../../../shared/components/truncated-text-with-tooltip/truncated-text-with-tooltip';

const Teams: React.FC<IProps> = ({
  activeTab,
  userId,
  atmRole,
  tableRef,

  isTeamsFilterDirty,
  setIsTeamsFilterDirty,

  teams,
  teamsPaginationOptions,
  userTimezone,
  addTeamModal,
  showAddTeamModal,
  hideAddTeamModal,
  membersList,
  sendGetTeamsRequest,
  sendGetMembersListRequest,
  resetGetMembersListRequest,
  getMembersListRequestStatus,
  getMembersListRequestError,
  getTeamsRequestStatus,

  sendAddTeamRequest,
  resetAddTeamRequest,
  addTeamRequestStatus,
  addTeamRequestError,

  sendModifyTeamRequest,
  resetModifyTeamRequest,
  modifyTeamRequestStatus,
  modifyTeamRequestError,

  sendDeleteTeamRequest,
  resetDeleteTeamRequest,
  deleteTeamRequestStatus,
  deleteTeamRequestError,

  sendDeleteUserFromTeamRequest,
  resetDeleteUserFromTeamRequest,
  deleteUserFromTeamRequestStatus,
  deleteUserFromTeamRequestError,
}) => {
  const [actionRow, setActionRow] = useState<Team>(null);
  const [teamDetailsModal, setTeamDetailsModal] = useState(false);
  const [deleteTeamModal, setDeleteTeamModal] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState<number | null>(null);

  const [filters, setFilters] = useState<TeamsFilter>({
    pageNum: 1,
    pageSize: 50,
    sortOrder: Order.Desc,
    sortBy: TeamSortBy.CreatedAt,
  });

  const onFilterChange = (payload: TeamsFilter) => {
    if (!isTeamsFilterDirty) {
      setIsTeamsFilterDirty(true);
    }

    const updatedFilters = {
      ...filters,
      ...payload,
    };
    setFilters(updatedFilters);
    sendGetTeamsRequest(updatedFilters);
  };

  const hideDeleteTeamModal = () => {
    setDeleteTeamModal(false);
  };

  const onHideAddTeamModal = () => {
    hideAddTeamModal();
    setActionRow(null);

    resetAddTeamRequest();
    resetModifyTeamRequest();
  };

  const onAddOrModifyTeam = (payload) => {
    if (actionRow) {
      sendModifyTeamRequest({
        teamId: actionRow.id,
        ...payload,
      });
    } else {
      sendAddTeamRequest(payload);
    }
  };

  const onDeleteTeam = () => {
    sendDeleteTeamRequest(Number(actionRow.id));
  };

  const onTeamMemberRemove = (payload: DeleteUserFromTeamPayload) => {
    if (
      !getIsRequestPending(deleteUserFromTeamRequestStatus) &&
      !getIsRequestPending(getTeamsRequestStatus)
    ) {
      sendDeleteUserFromTeamRequest(payload);
    }
  };

  const onSortChange = (_sortBy, order) => {
    onFilterChange({
      sortOrder: order === 'asc' ? 1 : -1,
    });
  };

  const onAction = (key: string, row: Team) => {
    if (key === 'modifyTeam') {
      showAddTeamModal(true);
      setActionRow(row);
      return;
    }
    if (key === 'delete') {
      setDeleteTeamModal(true);
      setActionRow(row);
    }
  };

  const onViewTeamDetailsModal = (row: Team) => {
    setActionRow(row);
    setTeamDetailsModal(true);
  };

  const actions: Action[] = useMemo(
    () => [
      {
        key: 'modifyTeam',
        customIcon: true,
        iconElement: <Pencil />,
        displayName: 'Modify ',
        position: 'out',
      },
      {
        key: 'delete',
        customIcon: true,
        iconElement: <Trash />,
        displayName: 'Delete',
        position: 'out',
      },
    ],
    [],
  );

  const columns: Column[] = useMemo(
    () =>
      [
        {
          dataField: 'name',
          text: 'Name',
          align: 'left',
          component: (cell, row, rowIndex) => (
            <span
              {...accessibleOnClick(() => {
                onViewTeamDetailsModal(row);
              })}
              className="team-cell"
            >
              <TruncatedTextWithTooltip
                string={cell || ''}
                length={30}
                tooltipClassName="tooltip-xxl"
                placement={
                  rowIndex + 1 === teams.length
                    ? Placement.Top
                    : Placement.Bottom
                }
              />
            </span>
          ),
          headerLoadingSkeleton: <SkeletonLoading width={34} height={14} />,
          cellLoadingSkeleton: <SkeletonLoading width={172} height={14} />,
          headerClasses: 'teams-name-cell',
          cellClasses: 'teams-name-cell',
        },
        {
          dataField: 'users',
          text: 'Users',
          align: 'left',
          component: (cell: TeamUser[], row) => {
            const { length: totalUsers } = cell || { length: 0 };

            return (
              <span
                className="team-cell"
                {...accessibleOnClick(() => {
                  onViewTeamDetailsModal(row);
                })}
              >
                {totalUsers}
              </span>
            );
          },
          headerLoadingSkeleton: <SkeletonLoading width={32} height={14} />,
          cellLoadingSkeleton: <SkeletonLoading width={32} height={14} />,
          headerClasses: 'users-team-cell',
          cellClasses: 'users-team-cell',
        },
        {
          dataField: 'managers',
          text: 'Managers',
          align: 'left',
          component: (_cell, row) => {
            let managers = '';
            let users = [];

            if (row.users) {
              users =
                row.users?.filter((user) =>
                  user.role.includes(UserRole.TeamManager),
                ) || [];
            }

            if (users?.length > 0) {
              users.forEach((manager, index) => {
                if (manager.role.includes(UserRole.TeamManager)) {
                  if (index === 0) {
                    managers = manager.name.trim() || manager.email;
                  } else {
                    managers = `${managers}, ${
                      manager.name.trim() || manager.email
                    }`;
                  }
                }
              });
            }

            return (
              <span
                className="team-cell team-managers"
                {...accessibleOnClick(() => onViewTeamDetailsModal(row))}
              >
                {managers || '-'}
              </span>
            );
          },
          headerLoadingSkeleton: <SkeletonLoading width={68} height={14} />,
          cellLoadingSkeleton: <SkeletonLoading width={99} height={14} />,
          headerClasses: 'manager-cell',
          cellClasses: 'manager-cell',
        },
        {
          dataField: 'createdAt',
          text: 'Created On',
          align: 'left',
          sort: true,
          onSort: onSortChange,
          component: (cell, row) => (
            <span
              className={`team-cell ${
                !(
                  hasPermission(Permissions.ACCOUNT_TEAM_UPDATE) &&
                  hasPermission(Permissions.ACCOUNT_TEAM_DELETE)
                )
                  ? 'teams-four-col'
                  : ''
              }`}
              {...accessibleOnClick(() => onViewTeamDetailsModal(row))}
            >
              {getFormattedDate(cell, userTimezone, false)}
            </span>
          ),
          headerLoadingSkeleton: <SkeletonLoading width={70} height={14} />,
          cellLoadingSkeleton: <SkeletonLoading width={99} height={14} />,
          headerClasses: `created-on-team-cell ${
            !(
              hasPermission(Permissions.ACCOUNT_TEAM_UPDATE) &&
              hasPermission(Permissions.ACCOUNT_TEAM_DELETE)
            )
              ? 'teams-created-by-last-col'
              : ''
          }`,
          cellClasses: `created-on-team-cell ${
            !(
              hasPermission(Permissions.ACCOUNT_TEAM_UPDATE) &&
              hasPermission(Permissions.ACCOUNT_TEAM_DELETE)
            )
              ? 'teams-created-by-last-col'
              : ''
          }`,
        },
        hasPermission(Permissions.ACCOUNT_TEAM_UPDATE) &&
        hasPermission(Permissions.ACCOUNT_TEAM_DELETE)
          ? {
              dataField: 'actions',
              text: '',
              align: 'right',
              component: (cell, row) => (
                <div className="actionables">
                  <Dropdown
                    onToggle={(val) =>
                      setIsDropdownOpen((prev) => {
                        if (val) {
                          return row.id;
                        }
                        return prev === row.id ? null : prev;
                      })
                    }
                    bsPrefix="dropdown-custom"
                    drop="down"
                  >
                    <Dropdown.Toggle
                      bsPrefix="dropdown-toggle-custom"
                      variant="dropdown-custom"
                      id="dropdown-basic"
                    >
                      <DotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu bsPrefix="dropdown-menu-custom">
                      {actions.map(
                        (action) =>
                          getActionPermissions(action) && (
                            <Dropdown.Item
                              key={action.key}
                              {...accessibleOnClick(() =>
                                onAction(action.key, row),
                              )}
                            >
                              {action.iconElement} {action.displayName}
                            </Dropdown.Item>
                          ),
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ),
              cellClasses: (_, row) =>
                `${isDropdownOpen === row.id ? 'action-btn' : ''}`,
            }
          : null,
      ].filter((col) => col !== null) as Column[],
    [teams, isDropdownOpen],
  );

  const onPaginationOptionsChangeHandler = ({ page, limit }) => {
    onFilterChange({
      pageNum: filters.pageSize !== limit ? 1 : page,
      pageSize: limit,
    });
  };

  const hideTeamDetailsModal = () => {
    setTeamDetailsModal(false);
    setActionRow(null);
  };

  useEffect(() => {
    if (activeTab === UserAndTeamsTabs.Teams && isTeamsFilterDirty) {
      sendGetTeamsRequest(filters);
    }
  }, [activeTab]);

  useEffect(() => {
    if (addTeamModal) {
      sendGetMembersListRequest();
    }
  }, [addTeamModal]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getTeamsRequestStatus,
      onSuccess: () => {
        if (teamDetailsModal && actionRow) {
          const selectedTeam = teams.find((team) => team.id === actionRow.id);
          if (selectedTeam) {
            setActionRow(selectedTeam);
          }
        }
        resetGetMembersListRequest();
      },
    });
  }, [getTeamsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getMembersListRequestStatus,
      onSuccess: () => {
        resetGetMembersListRequest();
      },
      onFailed: () => {
        resetGetMembersListRequest();
        executeOnErrorWithErrorCheck({
          error: getMembersListRequestError,
          onError: () => {
            toaster.error(getMembersListRequestError?.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [getMembersListRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: addTeamRequestStatus,
      onSuccess: () => {
        toaster.success('Team Successfully Created', { theme: Theme.New });
        sendGetTeamsRequest(filters);
        onHideAddTeamModal();
        resetAddTeamRequest();
      },
      onFailed: () => {
        resetGetMembersListRequest();
        executeOnErrorWithErrorCheck({
          error: addTeamRequestError,
          onError: () => {
            if (addTeamRequestError.code !== 1001) {
              toaster.error(addTeamRequestError.message, {
                theme: Theme.New,
              });
              resetAddTeamRequest();
            }
          },
        });
      },
    });
  }, [addTeamRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: modifyTeamRequestStatus,
      onSuccess: () => {
        toaster.success('Team Successfully Modified', { theme: Theme.New });
        sendGetTeamsRequest(filters);
        onHideAddTeamModal();
        resetModifyTeamRequest();
      },
      onFailed: () => {
        resetGetMembersListRequest();
        executeOnErrorWithErrorCheck({
          error: modifyTeamRequestError,
          onError: () => {
            if (modifyTeamRequestError.code !== 1001) {
              toaster.error(modifyTeamRequestError.message, {
                theme: Theme.New,
              });
              resetModifyTeamRequest();
            }
          },
        });
      },
    });
  }, [modifyTeamRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteTeamRequestStatus,
      onSuccess: () => {
        toaster.success('Team Successfully Deleted', { theme: Theme.New });
        sendGetTeamsRequest(filters);
        setActionRow(null);
        hideDeleteTeamModal();
        resetDeleteTeamRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: deleteTeamRequestError,
          onError: () => {
            toaster.error(deleteTeamRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        setActionRow(null);
        hideDeleteTeamModal();
        resetDeleteTeamRequest();
      },
    });
  }, [deleteTeamRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteUserFromTeamRequestStatus,
      onSuccess: () => {
        toaster.success('Team Successfully Modified', { theme: Theme.New });
        sendGetTeamsRequest(filters);
        resetDeleteUserFromTeamRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: deleteUserFromTeamRequestError,
          onError: () => {
            toaster.error(deleteUserFromTeamRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        resetDeleteUserFromTeamRequest();
      },
    });
  }, [deleteUserFromTeamRequestStatus]);

  const isLoading = getIsRequestPending(getTeamsRequestStatus);

  return (
    <div className="users-and-teams--teams">
      <Table
        paginationOptions={{
          options: {
            totalElements: teamsPaginationOptions?.totalItems,
            page: filters.pageNum,
            limit: filters.pageSize,
          },
        }}
        onPaginationOptionsChange={onPaginationOptionsChangeHandler}
        columns={columns}
        data={teams}
        sort={{
          dataField: filters.sortBy,
          order: filters.sortOrder === 1 ? 'asc' : 'desc',
        }}
        headerVisibleForGenerateColumn={true}
        borderOverActions={false}
        pagination={PaginationShowHide.SHOW}
        isNewPagination={true}
        tableWrapperClasses="cursor-on-row four-cols-teams-table teams-table"
        bodyWrapperClasses="users-and-teams--table-body-wrapper"
        isLoading={isLoading}
        onRowClickHandler={onViewTeamDetailsModal}
        tableRef={tableRef}
      />

      <AddModifyTeamModal
        show={addTeamModal}
        title={actionRow ? 'Modify Team' : 'Create Team'}
        defaultValues={actionRow}
        onClose={onHideAddTeamModal}
        membersList={membersList}
        onSubmit={onAddOrModifyTeam}
        isLoading={
          getIsRequestPending(addTeamRequestStatus) ||
          getIsRequestPending(modifyTeamRequestStatus)
        }
        buttonLabel={actionRow ? 'Modify Team' : 'Create Team'}
        buttonLoadingText={actionRow ? 'Modifying Team...' : 'Creating Team...'}
        getMembersListRequestStatus={getMembersListRequestStatus}
        error={addTeamRequestError || modifyTeamRequestError}
        resetAddTeamRequest={resetAddTeamRequest}
        resetModifyTeamRequest={resetModifyTeamRequest}
      />

      <DeleteTeamModal
        show={deleteTeamModal}
        onSubmit={onDeleteTeam}
        onClose={hideDeleteTeamModal}
        isLoading={getIsRequestPending(deleteTeamRequestStatus)}
      />

      <TeamDetailsModal
        show={teamDetailsModal}
        onClose={hideTeamDetailsModal}
        userTimezone={userTimezone}
        team={actionRow}
        userId={userId}
        onTeamMemberRemove={onTeamMemberRemove}
        atmRole={atmRole}
        getTeamsRequestStatus={getTeamsRequestStatus}
        deleteUserFromTeamRequestStatus={deleteUserFromTeamRequestStatus}
      />
    </div>
  );
};

export default Teams;
