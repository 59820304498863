// useCreditsSelectors.ts
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import FeatureCodes from '../../../../../enums/feature-codes';
import { LeadRevealType } from '../../../../../../components/leads/type';

export function useCreditsSelectors() {
  const planCode = useSelector(
    (state: RootState) => state.home?.subscription?.planCode,
  );
  const planType = useSelector(
    (state: RootState) => state.home?.subscription?.planType,
  );
  const planName = useSelector(
    (state: RootState) => state.home?.subscription?.planName,
  );

  const leadFinderCredits = useSelector((state: RootState) =>
    state.home?.credits?.find(
      (credit) => credit.featureCode === FeatureCodes.LeadReveal,
    ),
  );

  const leadFinderRevealTypeValue = useSelector((state: RootState) =>
    state.home?.credits?.find(
      (credit) => credit?.featureCode === FeatureCodes.LeadRevealPhone,
    ),
  );

  const emailSendCredits = useSelector((state: RootState) =>
    state.home?.credits?.find(
      (credit) => credit.featureCode === FeatureCodes.EmailSending,
    ),
  );

  const prospectAddCredits = useSelector((state: RootState) =>
    state.home?.credits?.find(
      (credit) => credit.featureCode === FeatureCodes.ProspectAdd,
    ),
  );

  const emailVerificationCredits = useSelector((state: RootState) =>
    state.home?.credits?.find(
      (credit) => credit.featureCode === FeatureCodes.EmailVerification,
    ),
  );

  // Declare variables for each feature's `available` and `total` values
  const leadFinderCreditsAvailable =
    leadFinderCredits?.accountUsageQuotaRemaining;
  const leadFinderRevealType =
    leadFinderRevealTypeValue?.accountUsageQuotaRemaining === '1'
      ? LeadRevealType.EMAIL_PHONE
      : LeadRevealType.EMAIL;
  const emailSendAvailable = emailSendCredits?.accountUsageQuotaRemaining;
  const emailSendTotal = emailSendCredits?.featureLimits;
  const prospectAddAvailable = prospectAddCredits?.accountUsageQuotaRemaining;
  const prospectAddTotal = prospectAddCredits?.featureLimits;
  const emailVerificationAvailable =
    emailVerificationCredits?.accountUsageQuotaRemaining;
  const getCoreFeaturesCreditsRequestStatus = useSelector(
    (state: RootState) => state.home.getCoreFeaturesCreditsRequest.status,
  );

  return {
    planName,
    planCode,
    planType,
    leadFinderCreditsAvailable,
    leadFinderRevealType,
    emailSendAvailable,
    emailSendTotal,
    prospectAddAvailable,
    prospectAddTotal,
    emailVerificationAvailable,
    getCoreFeaturesCreditsRequestStatus,
  };
}
