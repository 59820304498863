/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import React from 'react';
import produce from 'immer';
import { Link } from 'react-router-dom';
import { SkeletonLoading } from '@saleshandy/design-system';

import { validate } from './validator';
import type { IProps, IState } from './types';

import Input from '../../../../shared/design-system/components/input';
import Button from '../../../../shared/design-system/ui/button';
import { SHLogoDark } from '../../../../shared/svg';

import {
  getIsRequestPending,
  initializeThirdPartyIntegrations,
  executeOnRequestStatusWithPrevStatusCheck,
  AuthHelper,
  initializeSentryIntegrations,
  redirectWithoutRefresh,
} from '../../../../shared/utils';
import toaster, { Theme } from '../../../../shared/toaster';
import { openChatSupport } from '../../../../shared/utils/open-chat-support';
import { isInternalUrl } from '../../../../shared/utils/is-internal-url';
import OAuthButtons from '../oauth-buttons';
import { ActionForAuthentication } from '../../types';

class LoginForm extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      values: {
        email: '',
        password: '',
      },
      errors: {
        email: '',
        password: '',
      },
      dirty: {
        email: false,
        password: false,
      },
      showPassword: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onPasswordVisibilityChange = this.onPasswordVisibilityChange.bind(
      this,
    );
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
  }

  componentDidMount() {
    const { history, setIsRedirectedFromAgency } = this.props;
    const { values } = this.state;

    const { search } = history.location;
    const userEmail = new URLSearchParams(search).get('userEmail');

    if (userEmail !== '') {
      this.setState({ values: { ...values, email: userEmail } });
    }

    setIsRedirectedFromAgency(false);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      loginRequestStatus,
      token,
      trackingId,
      firstName,
      lastName,
      email,
      agencyConfig,
      loginRequestError,
      mfaChannel,
    } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: loginRequestStatus,
      prevStatus: prevProps.loginRequestStatus,
      onSuccess: () => {
        if (mfaChannel) {
          // Redirect to otp screen
          redirectWithoutRefresh('/mfa-verify');
          return;
        }
        if (token) {
          AuthHelper.login({ token });

          initializeThirdPartyIntegrations({
            trackingId,
            showChatSupport: agencyConfig?.showChatSupport,
            firstName,
            lastName,
            email,
          });
        }
        initializeSentryIntegrations(email);
      },
      onFailed: () => {
        if (loginRequestError?.message?.includes('support')) {
          toaster.error(
            <div>
              Your account is suspended, to revoke this suspension feel free to
              reach out to{' '}
              <button
                type="button"
                className="link-button"
                onClick={(e) => {
                  e.preventDefault();
                  openChatSupport();
                }}
              >
                <span className="font-bold red-txt-13">
                  <u>support</u>
                </span>
              </button>
            </div>,
            {
              theme: Theme.New,
              showCloseIcon: true,
              delay: 1000000,
            },
          );
        }
      },
    });
  }

  componentWillUnmount() {
    const { hideError } = this.props;
    hideError?.();
  }

  onInputChange(value: string, e: React.ChangeEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onPasswordVisibilityChange() {
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { dirty, errors, values } = this.state;
    const { sendLoginRequest } = this.props;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    const { email, password } = values;
    sendLoginRequest(email, password);
  }

  // eslint-disable-next-line react/sort-comp
  hasErrors() {
    const { errors, dirty } = this.state;

    let isError = false;

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' || !dirty[key]) {
        isError = true;
      }
    });

    return isError;
  }

  truncateWord = (word, maxLength) => {
    if (word?.length > maxLength) {
      return `${word.substring(0, maxLength)}...`;
    }
    return word;
  };

  companyNameLoader(
    agencyLoading: boolean,
    isWhitelabel: boolean,
    companyName?: string,
  ) {
    if (agencyLoading) {
      return (
        <div className="container-fluid d-flex justify-content-center align-items-center">
          <SkeletonLoading width={350} height={40} />
        </div>
      );
    }

    if (isWhitelabel) {
      return (
        <div className="d-flex">
          <span className="mr-1">
            Welcome Back to {this.truncateWord(companyName || 'Saleshandy', 20)}
          </span>
        </div>
      );
    }

    return <span>Welcome Back to Saleshandy</span>;
  }

  clientLogoLoader(
    agencyLoading: boolean,
    isWhitelabel?: boolean,
    clientLogo?: string,
  ) {
    if (agencyLoading) {
      return (
        <div className="container-fluid d-flex justify-content-center align-items-center">
          <SkeletonLoading width={200} />
        </div>
      );
    }

    if (!isWhitelabel && !clientLogo) {
      return <SHLogoDark width={200} />;
    }

    if (isWhitelabel && clientLogo) {
      return <img src={clientLogo} alt="logo" />;
    }
  }

  render() {
    const { values, errors, showPassword } = this.state;
    const {
      loginRequestStatus,
      loginRequestError,
      loginRequestShowError,
      isWhitelabel,
      companyName,
      agencyConfigRequestStatus,
      clientLogo,
    } = this.props;

    const isLoginBtnLoading = getIsRequestPending(loginRequestStatus);
    const isLoginBtnDisabled = isLoginBtnLoading || this.hasErrors();
    const isAgencyLoading =
      getIsRequestPending(agencyConfigRequestStatus) && !isInternalUrl();

    return (
      <div
        className={
          isAgencyLoading || isWhitelabel
            ? 'auth-wrapper w-100 d-flex'
            : 'auth-wrapper'
        }
      >
        <div className="auth-container">
          <div className="auth-form">
            <div className={`sh-logo ${isWhitelabel && 'd-block'}`}>
              {this.clientLogoLoader(isAgencyLoading, isWhitelabel, clientLogo)}
            </div>
            <h1 className="auth-container--title">
              {this.companyNameLoader(
                isAgencyLoading,
                isWhitelabel,
                companyName,
              )}
            </h1>

            <OAuthButtons type={ActionForAuthentication.Login} />

            <form onSubmit={this.onFormSubmit} className="auth-container--form">
              <div className="auth-form-row">
                <div className="auth-form-input email">
                  <Input
                    name="email"
                    label="Work Email"
                    type="email"
                    placeholder="Enter your work email address"
                    value={values.email}
                    variant={errors.email && Input.Variant.Error}
                    caption={
                      errors.email ||
                      (loginRequestShowError ? loginRequestError.message : '')
                    }
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    tabIndex={0}
                    autoComplete="current-email"
                  />
                </div>
              </div>

              <div className="auth-form-row">
                <div className="auth-form-input password">
                  <Input
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter your password"
                    value={values.password}
                    variant={errors.password && Input.Variant.Error}
                    caption={errors.password}
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    className="auth-form-input"
                    autoComplete="current-password"
                    icons={[
                      {
                        place: Input.IconPlace.Right,
                        identifier: showPassword ? 'eye-alt' : 'eye',
                        className: 'pointer',
                        onClick: this.onPasswordVisibilityChange,
                      },
                    ]}
                  />
                  <div className="forgot-password">
                    <Link to="/reset-password">Forgot Password?</Link>
                  </div>
                </div>
              </div>

              <Button
                type="submit"
                isFullWidth
                isLoading={isLoginBtnLoading}
                disabled={isLoginBtnDisabled}
                loadingText="Logging in, please wait..."
              >
                Login
              </Button>
            </form>
          </div>

          <div className="d-flex flex-column align-items-center text-center">
            <div className="bottom-navigation">
              <p>
                <Link to="/sso-login">Login with SSO</Link>
              </p>
            </div>
          </div>

          {!isWhitelabel && (
            <div className="d-flex flex-column align-items-center text-center">
              <div className="bottom-navigation">
                <p>
                  Don't have an account?{' '}
                  <Link to="/signup" tabIndex={0}>
                    Sign up!
                  </Link>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LoginForm;
