import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@saleshandy/design-system';
import { SequencePrioritySettingCode } from '../../../../../sequence/enums';
import RadioButtonGroupWithShow from '../../../../../sequence/components/sequence-single-content/components/sequence-single-settings/components/sequence-single-settings-content/components/sequence-sending-priority/radio-button-show';
import type { IProps } from './admin-settings-sequence-container';
import { createOption } from '../../../../../sequence/components/sequence-single-content/components/sequence-single-settings/components/sequence-single-settings-content/components/sequence-sending-priority/sequence-sending-priority';

export const options = [
  createOption(
    1,
    'Prioritise Follow-Ups',
    'More emails will be sent from follow-up steps',
    SequencePrioritySettingCode.LAST_TO_FIRST,
    true,
  ),
  createOption(
    2,
    'Prioritise New Prospects',
    'More emails will be sent from the first step',
    SequencePrioritySettingCode.FIRST_TO_LAST,
    true,
  ),
  createOption(
    3,
    'Balanced Sending',
    'Equal number of emails will be sent from each step',
    SequencePrioritySettingCode.EQUAL,
    true,
  ),
  createOption(
    4,
    'Aggressively Prioritise New Prospects',
    'Up to 80% of total emails will be sent from the first Step',
    SequencePrioritySettingCode.AGGRESSIVE_FISRT,
    true,
  ),
];
const SequenceSetting: React.FC<IProps> = ({
  priorityAndDistributionData = [],
  setToastMessage,
  sendUpdateAdminDetailsRequest,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [selectedPriorityCode, setSelectedPriorityCode] = useState<string>('');
  const [initialPriorityCode, setInitialPriorityCode] = useState<string>('');

  const handlePriorityChange = (val: string) => {
    setSelectedPriorityCode(val);
    setIsDisabled(initialPriorityCode === val);
  };

  useEffect(() => {
    if (priorityAndDistributionData.length > 0) {
      const selected = priorityAndDistributionData.find(
        (setting) => setting.value === '1',
      );
      if (selected) {
        setSelectedPriorityCode(selected.code);
        setInitialPriorityCode(selected.code);
        setIsDisabled(true);
      }
    }
  }, [priorityAndDistributionData]);

  const handleSave = async () => {
    setIsLoading(true);
    setToastMessage('Default sending priority is updated successfully');
    try {
      const selectedOption = options.find(
        (option) => option.value === selectedPriorityCode,
      );
      if (selectedOption) {
        await sendUpdateAdminDetailsRequest({
          priorityAndDistributionType: [
            {
              newPriorityDistributionId: selectedOption.id,
            },
          ],
        });
        setIsLoading(false);
        setIsDisabled(true);
      }
    } catch (err) {
      setIsLoading(false);
      setToastMessage('');
    }
  };

  return (
    <>
      <div className="sequence-setting-radio-buttons-container admin-settings-sequence-sending-priority">
        <span className="sequence-setting-description text-label">
          Choose your default sending priority. It will apply to all the
          sequences created from now
        </span>
        <RadioButtonGroupWithShow
          name="priority-set"
          shouldDisplayHorizontal={true}
          options={options}
          value={selectedPriorityCode} // Set the prepopulated value based on prioritySetting array
          onChange={(val) => handlePriorityChange(val)} // Call API on value change
        />
        <Button
          variant="primary"
          className="mt-1 sending-priority-save-btn"
          onClick={() => handleSave()}
          isLoading={isLoading}
          disabled={isDisabled}
          loadingText="Save"
        >
          {t('labels.save')}
        </Button>
      </div>
    </>
  );
};

export default SequenceSetting;
