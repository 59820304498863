import React from 'react';

import { AddContactsFromCSVRequestData, IProps, IState } from './types';
import { SHModules } from '../../../../../../../../shared/enums/module';
import { RequestStatus } from '../../../../../../../../shared/enums/request-status';
import { ShAccountSettingsCode } from '../../../../../../../settings/enums/admin-settings';

import toaster from '../../../../../../../../shared/toaster';
import Modal from '../../../../../../../../shared/design-system/ui/modal';

import ImportFlow from '../import-flow';
import Report from '../report';
import PurchaseCreditsModal from '../../../../../../../settings/components/billing-subscription/components/purchase-credits-modal';
import RestrictionErrorModal from '../../../../../../../prospect/components/prospect-list/components/modals/restriction-error-modal';

import { getImportProspectRestrictionMsg } from './utils/helper';
import { ProfileProgressSteps } from '../../../../../../../../shared/types/user-setting';
import { showGeneralErrorNotification } from '../../../../../../../../shared/utils/errors';
import { getRestrictionPlanWarningEmailBody } from '../../../../../../../prospect/components/prospect-list/utils/helper';
import { handle3dSecureCardPayment } from '../../../../../../../../shared/components/handle-3d-secure-card-payment/handle3dSecureCardPayment';
import { executeOnRequestStatusWithPrevStatusCheck } from '../../../../../../../../shared/utils';
import { isWhitelabelWithClientView } from '../../../../../../../../shared/utils/user-details';

class ImportContactsModal extends React.Component<IProps, IState> {
  childRef: React.RefObject<any>;

  constructor(props) {
    super(props);

    this.childRef = React.createRef();

    this.state = {
      showReport: false,
      show: true,
      emailVerification: false,
      showVerificationReport: false,
      showPurchaseCreditsModel: false,
      is3dSecureResponseStatePending: false,
      remainingCredit: 0,
      isRestrictionModalVisible: false,
      csvRowCount: 0,
    };
    this.importFlowFinishHandler = this.importFlowFinishHandler.bind(this);
    this.showReport = this.showReport.bind(this);
    this.showPurchaseEvCreditsModal = this.showPurchaseEvCreditsModal.bind(
      this,
    );
    this.handleCloseModel = this.handleCloseModel.bind(this);
    this.hidePurchaseCreditsModal = this.hidePurchaseCreditsModal.bind(this);
    this.handle3dSecureCard = this.handle3dSecureCard.bind(this);
    this.showRestrictionErrorModal = this.showRestrictionErrorModal.bind(this);
    this.hideRestrictionErrorModal = this.hideRestrictionErrorModal.bind(this);
    this.updateEmailVerificationValue = this.updateEmailVerificationValue.bind(
      this,
    );
  }

  componentDidMount() {
    const {
      sendGetContactFieldsRequest,
      sendGetSequenceStepsRequest,
      sequenceId,
      fromModule,
      adminSettings,
      sendGetAdminSettingsRequest,
    } = this.props;
    sendGetContactFieldsRequest();
    if (fromModule !== SHModules.Prospect) {
      sendGetSequenceStepsRequest(sequenceId);
    }

    if (!adminSettings || adminSettings?.length === 0) {
      sendGetAdminSettingsRequest();
    } else {
      this.updateEmailVerificationValue();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      addContactsFromCSVRequestStatus,
      addContactsFromCSVRequestError,
      sendPurchaseEmailVerificationCreditsRequestStatus,
      sendPurchaseEmailVerificationCreditsRequestMessage,
      getPurchaseEmailVerificationCreditsResponse,
      sendGetEmailVerificationCreditsRequest,
      resetPurchaseEmailVerificationCreditsResponse,
      profileProgress,
      sendGetUserSettingsRequest,
      updateProfileProgressRequest,
      updateProfileProgressRequestStatus,
      getAdminSettingsRequestStatus,
      addContactsFromCSVRequestMessage,
      onClose,
    } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: getAdminSettingsRequestStatus,
      prevStatus: prevProps.getAdminSettingsRequestStatus,
      onSuccess: () => {
        this.updateEmailVerificationValue();
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: addContactsFromCSVRequestStatus,
      prevStatus: prevProps.addContactsFromCSVRequestStatus,

      onSuccess: () => {
        const { fromModule } = this.props;

        if (profileProgress) {
          const addProspectStep = profileProgress.find(
            (step) =>
              step.profileProgressStep.stepName ===
              ProfileProgressSteps.AddProspects,
          );

          if (
            !addProspectStep.isCompleted &&
            fromModule === SHModules.Sequence
          ) {
            updateProfileProgressRequest({
              step: ProfileProgressSteps.AddProspects,
              isCompleted: true,
            });
          }
        }

        const message = isWhitelabelWithClientView()
          ? addContactsFromCSVRequestMessage?.replace('an email', 'a')
          : addContactsFromCSVRequestMessage;

        toaster.success(message);
        onClose();
      },
      onFailed: () => {
        showGeneralErrorNotification(addContactsFromCSVRequestError.message);
        sendGetEmailVerificationCreditsRequest();
      },
    });

    // Purchase Ev Credits
    if (
      sendPurchaseEmailVerificationCreditsRequestStatus !==
      prevProps.sendPurchaseEmailVerificationCreditsRequestStatus
    ) {
      if (
        sendPurchaseEmailVerificationCreditsRequestStatus ===
          RequestStatus.Succeeded &&
        sendPurchaseEmailVerificationCreditsRequestMessage
      ) {
        if (getPurchaseEmailVerificationCreditsResponse.requires_action) {
          this.handle3dSecureCard(getPurchaseEmailVerificationCreditsResponse);
        } else {
          toaster.success(sendPurchaseEmailVerificationCreditsRequestMessage);
          this.hidePurchaseCreditsModal();
          sendGetEmailVerificationCreditsRequest();
          resetPurchaseEmailVerificationCreditsResponse();
        }
      } else if (
        sendPurchaseEmailVerificationCreditsRequestStatus ===
          RequestStatus.Failed &&
        sendPurchaseEmailVerificationCreditsRequestMessage
      ) {
        toaster.error(sendPurchaseEmailVerificationCreditsRequestMessage);
        resetPurchaseEmailVerificationCreditsResponse();
      }
    }

    if (
      updateProfileProgressRequestStatus !==
      prevProps.updateProfileProgressRequestStatus
    ) {
      if (updateProfileProgressRequestStatus === RequestStatus.Succeeded) {
        sendGetUserSettingsRequest();
      }
    }
  }

  updateEmailVerificationValue = () => {
    const { adminSettings } = this.props;
    if (adminSettings?.length > 0) {
      const emailVerificationOption =
        adminSettings?.length > 0
          ? adminSettings.find(
              (setting) =>
                setting.code === ShAccountSettingsCode.IsVerificationActive,
            )?.value || '0'
          : '0';

      this.setState({ emailVerification: emailVerificationOption === '1' });
    }
  };

  //* Show Hide Restriction Error Modal
  showRestrictionErrorModal = () =>
    this.setState({ isRestrictionModalVisible: true });

  hideRestrictionErrorModal = () => {
    const child = this.childRef?.current;

    // Call the child component's function
    child.importAnyWay();

    this.setState({ isRestrictionModalVisible: false });
  };

  showPurchaseEvCreditsModal(remainingCredit: number) {
    this.setState({ showPurchaseCreditsModel: true, remainingCredit });
  }

  hidePurchaseCreditsModal() {
    this.setState({ showPurchaseCreditsModel: false });
  }

  handleCloseModel() {
    const { showPurchaseCreditsModel } = this.state;

    this.setState({
      showPurchaseCreditsModel: !showPurchaseCreditsModel,
    });
  }

  async handle3dSecureCard(response) {
    const {
      resetPurchaseEmailVerificationCreditsResponse,
      sendGetEmailVerificationCreditsRequest,
    } = this.props;

    this.setState({ is3dSecureResponseStatePending: true });

    const apiEndPoint = '/email-verification-credits/confirm-payment-intent';

    const { isError, serverResponse } = await handle3dSecureCardPayment({
      response,
      apiEndPoint,
    });

    if (isError) {
      toaster.error(
        'Payment failed! Please retry again after some time from billing section.',
      );
    } else {
      toaster.success(serverResponse.payload.message);
      this.hidePurchaseCreditsModal();
      sendGetEmailVerificationCreditsRequest();
      resetPurchaseEmailVerificationCreditsResponse();
    }
    this.setState({ is3dSecureResponseStatePending: true });
  }

  handlePurchaseEvCredits(stripeCardToken, planId) {
    const { sendPurchaseEmailVerificationCreditsRequest } = this.props;

    this.hidePurchaseCreditsModal();
    sendPurchaseEmailVerificationCreditsRequest({
      stripeCardToken,
      planId,
    });
  }

  async importFlowFinishHandler({
    stepId,
    includeVerification,
    ...rest
  }: AddContactsFromCSVRequestData) {
    const {
      sendAddContactsFromCSVRequest,
      sendAddContactsFromCSVRequestWithoutStep,
      fromModule,
      enableBulkActionRequestPolling,
    } = this.props;

    const { csvRowCount } = this.state;

    if (stepId === null && stepId === undefined) {
      return;
    }

    if (fromModule !== SHModules.Prospect) {
      const response = await sendAddContactsFromCSVRequest({
        includeVerification,
        stepId,
        totalRecords: csvRowCount,
        ...rest,
      });
      if (response?.payload?.payload?.shouldPoll) {
        enableBulkActionRequestPolling();
      }
      this.setState({
        showVerificationReport: includeVerification,
      });
    } else {
      const response = await sendAddContactsFromCSVRequestWithoutStep({
        includeVerification,
        stepId,
        totalRecords: csvRowCount,
        ...rest,
      });
      if (response?.payload?.payload?.shouldPoll) {
        enableBulkActionRequestPolling();
      }
      this.setState({
        showVerificationReport: includeVerification,
      });
    }
  }

  showReport() {
    this.setState({ showReport: true });
  }

  handelCSVImport(importCount: number) {
    const { remainingProspectLimit } = this.props;

    this.setState({ csvRowCount: importCount });

    if (importCount > remainingProspectLimit) {
      this.showRestrictionErrorModal();
    }
  }

  render() {
    const {
      show: showState,
      showReport,
      emailVerification,
      showVerificationReport,
      showPurchaseCreditsModel,
      is3dSecureResponseStatePending,
      remainingCredit,
      isRestrictionModalVisible,
      csvRowCount,
    } = this.state;
    const {
      show,
      onClose,
      contactFields,
      steps,
      addContactsFromCSVRequestStatus,
      importCSVReport,
      fromModule,
      warning,
      sendPurchaseEmailVerificationCreditsRequestStatus,
      remainingProspectLimit,
      currentPlanName,
      getEmailVerificationPlanResponse,
    } = this.props;

    const isRequestPending =
      addContactsFromCSVRequestStatus === RequestStatus.Pending;

    const importFlow = showState && (
      <ImportFlow
        // eslint-disable-next-line no-return-assign
        warning={warning}
        ref={this.childRef}
        isRequestPending={isRequestPending}
        onFinish={this.importFlowFinishHandler}
        fields={contactFields}
        steps={steps}
        isEmailVerification={emailVerification}
        fromModule={fromModule}
        onClose={onClose}
        showPurchaseEvCreditsModal={(remainingCredits) =>
          this.showPurchaseEvCreditsModal(remainingCredits)
        }
        isPurchaseEmailVerificationCreditsLoading={
          sendPurchaseEmailVerificationCreditsRequestStatus ===
            RequestStatus.Pending || is3dSecureResponseStatePending
        }
        handleCSVImport={(importCount) => this.handelCSVImport(importCount)}
      />
    );

    return (
      <>
        <Modal
          show={show}
          className="import-contacts-csv-modal"
          variant="border"
          title={!showReport ? 'Import Prospects' : 'Import Report'}
          onHide={onClose}
          showCloseButton={!showReport}
          hideFooter={true}
        >
          <div className="import-contacts-content import-contacts-csv-modal--content">
            {!showReport ? (
              importFlow
            ) : (
              <Report
                importCSVReport={importCSVReport}
                onFinish={onClose}
                fromModule={fromModule}
                isEmailVerification={showVerificationReport}
              />
            )}
          </div>
        </Modal>
        {showPurchaseCreditsModel && (
          <PurchaseCreditsModal
            show={showPurchaseCreditsModel}
            evCreditPlans={getEmailVerificationPlanResponse}
            remainingCredit={remainingCredit}
            onClose={this.handleCloseModel}
            onBuyCredits={(stripeCardToken, planId) =>
              this.handlePurchaseEvCredits(stripeCardToken, planId)
            }
            currentPlan={currentPlanName}
            type="email-verification"
          />
        )}
        {isRestrictionModalVisible && (
          <RestrictionErrorModal
            show={isRestrictionModalVisible}
            bodyContent={getImportProspectRestrictionMsg(
              csvRowCount,
              remainingProspectLimit,
            )}
            emailBody={getRestrictionPlanWarningEmailBody()}
            onClose={this.hideRestrictionErrorModal}
            onHide={onClose}
            modalTitle="Prospects limit exceeded"
            cancelButtonText="Import Anyway"
          />
        )}
      </>
    );
  }
}

export default ImportContactsModal;
