import React, { useEffect, useState } from 'react';

import type { IProps } from './change-email-modal-container';
import { ChangeEmailModalFlow } from './enums';

import Modal from '../../../../../../shared/design-system/components/atoms/modal';

import ValidateCurrentPassword from './change-email-modal-flow/validate-current-password';
import ResetPassword from './change-email-modal-flow/reset-password';
import NewEmail from './change-email-modal-flow/new-email';
import VerifyOTP from './change-email-modal-flow/verify-otp';
import EmailChangedSuccessfully from './change-email-modal-flow/email-changed-successfully';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
} from '../../../../../../shared/utils';
import toaster, { Theme } from '../../../../../../shared/toaster';

const ChangeEmailModal: React.FC<IProps> = ({
  show,
  onClose,
  email,
  isCurrentPasswordValid,
  canResendChangeEmailVerificationCode,

  verifyCurrentPasswordRequestStatus,
  resetPasswordRequestStatus,
  sendOtpForChangeEmailRequestStatus,
  sendOtpForChangeEmailRequestError,
  resendOtpForChangeEmailRequestStatus,
  verifyOtpForChangeEmailRequestStatus,
  verifyOtpForChangeEmailRequestError,
  resetIsCurrentPasswordValid,
  resetVerifyOtpForChangeEmailRequest,
  resetSendOtpForChangeEmailRequest,
  ...props
}) => {
  const [modalState, setModalState] = useState<ChangeEmailModalFlow>(ChangeEmailModalFlow.ValidateCurrentPassword);
  const [newEmail, setNewEmail] = useState<string>('');

  const onModalStateChange = (state: ChangeEmailModalFlow) => {
    setModalState(state);
  };

  const handleCurrentPasswordSubmit = (password: string) => {
    const { verifyCurrentPasswordRequest } = props;
    verifyCurrentPasswordRequest(password);
  };

  const handleResetPasswordSubmit = (value: string) => {
    const { sendResetPasswordRequest } = props;
    sendResetPasswordRequest(value);
  };

  const handleNewEmailSubmit = (value: string) => {
    const { sendOtpForChangeEmailRequest } = props;
    sendOtpForChangeEmailRequest(value);

    setNewEmail(value);
  };

  const handleResendOtp = () => {
    const { resendOtpForChangeEmailRequest } = props;
    resendOtpForChangeEmailRequest();
  };

  const handleVerifyOtpSubmit = (code: number) => {
    const { verifyOtpForChangeEmailRequest } = props;
    verifyOtpForChangeEmailRequest(code);
  };

  const handleOnCancel = () => {
    onClose();
    setNewEmail('');
    setModalState(ChangeEmailModalFlow.ValidateCurrentPassword);
  };

  useEffect(() => {
    const {
      verifyCurrentPasswordRequestError,
      resetVerifyCurrentPasswordRequest,
    } = props;

    executeOnRequestStatus({
      status: verifyCurrentPasswordRequestStatus,
      onSuccess: () => {
        if (isCurrentPasswordValid) {
          setModalState(ChangeEmailModalFlow.NewEmail);
          resetVerifyCurrentPasswordRequest();
        }
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: verifyCurrentPasswordRequestError,
          onError: () => {
            toaster.error(verifyCurrentPasswordRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        resetVerifyCurrentPasswordRequest();
      },
    });
  }, [verifyCurrentPasswordRequestStatus]);

  useEffect(() => {
    const { resetPasswordRequestError, resetPasswordRequestState } = props;

    executeOnRequestStatus({
      status: resetPasswordRequestStatus,
      onSuccess: () => {
        toaster.success('Email sent successfully.', {
          theme: Theme.New,
        });
        handleOnCancel();
        resetPasswordRequestState();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: resetPasswordRequestError,
          onError: () => {
            toaster.error(resetPasswordRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        resetPasswordRequestState();
      },
    });
  }, [resetPasswordRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: sendOtpForChangeEmailRequestStatus,
      onSuccess: () => {
        toaster.success('OTP has been sent successfully', {
          theme: Theme.New,
        });
        setModalState(ChangeEmailModalFlow.VerifyOTP);
        resetSendOtpForChangeEmailRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: sendOtpForChangeEmailRequestError,
          onError: () => {
            if (sendOtpForChangeEmailRequestError.code !== 1001) {
              toaster.error(sendOtpForChangeEmailRequestError.message, {
                theme: Theme.New,
              });
              resetSendOtpForChangeEmailRequest();
            }
          },
        });
      },
    });
  }, [sendOtpForChangeEmailRequestStatus]);

  useEffect(() => {
    const {
      resendOtpForChangeEmailRequestError,
      resetResendOtpForChangeEmailRequest,
    } = props;

    executeOnRequestStatus({
      status: resendOtpForChangeEmailRequestStatus,
      onSuccess: () => {
        toaster.success('OTP has been resent successfully', {
          theme: Theme.New,
        });
        resetResendOtpForChangeEmailRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: resendOtpForChangeEmailRequestError,
          onError: () => {
            toaster.error(resendOtpForChangeEmailRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        resetResendOtpForChangeEmailRequest();
      },
    });
  }, [resendOtpForChangeEmailRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: verifyOtpForChangeEmailRequestStatus,
      onSuccess: () => {
        setModalState(ChangeEmailModalFlow.EmailChangedSuccessfully);
        resetVerifyOtpForChangeEmailRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: verifyOtpForChangeEmailRequestError,
          onError: () => {
            if (verifyOtpForChangeEmailRequestError.code !== 1001) {
              toaster.error(verifyOtpForChangeEmailRequestError.message, {
                theme: Theme.New,
              });
              resetVerifyOtpForChangeEmailRequest();
            }
          },
        });
      },
    });
  }, [verifyOtpForChangeEmailRequestStatus]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      hideHeader
      hideFooter
      backdrop="static"
      className="change-email-modal"
    >
      {modalState === ChangeEmailModalFlow.ValidateCurrentPassword && (
        <ValidateCurrentPassword
          email={email}
          onCancel={handleOnCancel}
          onSubmit={handleCurrentPasswordSubmit}
          isCurrentPasswordValid={isCurrentPasswordValid}
          onModalStateChange={onModalStateChange}
          verifyCurrentPasswordRequestStatus={
            verifyCurrentPasswordRequestStatus
          }
          resetIsCurrentPasswordValid={resetIsCurrentPasswordValid}
        />
      )}

      {modalState === ChangeEmailModalFlow.ResetPassword && (
        <ResetPassword
          onCancel={handleOnCancel}
          onSubmit={handleResetPasswordSubmit}
          resetPasswordRequestStatus={resetPasswordRequestStatus}
        />
      )}

      {modalState === ChangeEmailModalFlow.NewEmail && (
        <NewEmail
          email={email}
          newEmail={newEmail}
          onCancel={handleOnCancel}
          onSubmit={handleNewEmailSubmit}
          onModalStateChange={onModalStateChange}
          sendOtpForChangeEmailRequestStatus={
            sendOtpForChangeEmailRequestStatus
          }
          error={sendOtpForChangeEmailRequestError}
          resetSendOtpForChangeEmailRequest={resetSendOtpForChangeEmailRequest}
        />
      )}

      {modalState === ChangeEmailModalFlow.VerifyOTP && (
        <VerifyOTP
          email={email}
          newEmail={newEmail}
          onCancel={handleOnCancel}
          onSubmit={handleVerifyOtpSubmit}
          onModalStateChange={onModalStateChange}
          handleResendOtp={handleResendOtp}
          resendOtpForChangeEmailRequestStatus={
            resendOtpForChangeEmailRequestStatus
          }
          verifyOtpForChangeEmailRequestStatus={
            verifyOtpForChangeEmailRequestStatus
          }
          canResendChangeEmailVerificationCode={
            canResendChangeEmailVerificationCode
          }
          error={verifyOtpForChangeEmailRequestError}
          resetVerifyOtpForChangeEmailRequest={
            resetVerifyOtpForChangeEmailRequest
          }
        />
      )}

      {modalState === ChangeEmailModalFlow.EmailChangedSuccessfully && (
        <EmailChangedSuccessfully newEmail={newEmail} />
      )}
    </Modal>
  );
};

export default ChangeEmailModal;
