import React, { useEffect, useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import type { IProps } from './upgrade-plan-billing-section-container';
import { Images } from '../../../../app-constants';
import Button from '../../atoms/button';
import Input from '../../input/input';
import { validate } from './validator';
import ImageIcon from '../../../../components/images/image-icon';
import { RequestStatus } from '../../../../enums/request-status';
import { getTotalAmount } from './helper/get-total-amount';
import { handle3dSecureCardPayment } from '../../../../components/handle-3d-secure-card-payment/handle3dSecureCardPayment';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import {
  checkShouldShowTotalAmoutToPayText,
  getFinalSubTotal,
  getSubtotalDiscountAmount,
  getTotalAmountMinusDiscount,
  showErrorNotification,
  showToastMessage,
} from './helper/helper';
import { checkIsLtdPlan } from '../subscription-plan-details/helper/helper';
import { executeOnRequestStatus } from '../../../../utils/execute-on-request-status';
import { showGeneralErrorNotification } from '../../../../utils/errors';
import { getIsRequestPending } from '../../../../utils/get-request-status';
import { OverlayTooltip } from '../../overlay/tooltip/overlay-tooltip';
import { supportUrls } from '../../../../utils/urls';
import { ModifyPlanAction } from '../../../../utils/subscription-plans';
import DowngradeWarningModal from '../../../../../components/settings/components/billing-subscription/components/upgrade-plan/components/downgrade-warning-modal';
import { BillingOption } from '../../../../utils/billing-options';
import { isRequestPending } from '../../../../../components/settings/components/billing-subscription/utils/helper';
import { AnalyticsEvents } from '../../../../enums/analytics';

const UpgradePlanBillingSection: React.FC<IProps> = ({
  planId,
  planName,
  planPrice,
  planCode,
  planType,
  numberOfSlotsUserWant,
  minimumNumberOfSlotsUserCanHave,
  billingOption,
  onPurchase,
  couponCodeDiscountType,
  couponCodeDiscount,
  couponCodeResponseMessage,
  isSendApplyCouponCodeRequestError,
  couponCode,
  havePromoCode,
  sendApplyCouponCodeRequest,
  onDeleteAppliedCouponCode,
  onCouponCodeChange,
  userHasPromoCode,
  isUserModifyingSubscription,
  sendGetModifySubscriptionRequestStatus,
  sendGetModifySubscriptionRequestError,
  sendGetModifySubscriptionResponse,
  sendGetCalculatePayRequestStatus,
  sendGetCalculatePayResponse,
  sendPurchaseSubscriptionRequestStatus,
  sendPurchaseLeadFinderSubscriptionRequestStatus,
  sendPurchaseSubscriptionRequestMessage,
  sendPurchaseLeadFinderSubscriptionRequestMessage,
  sendPurchaseLeadFinderModifySubscriptionRequestMessage,
  sendApplyCouponCodeRequestStatus,
  sendGetAccountSubscriptionRequest,
  sendGetLeadFinderSubscriptionRequest,
  sendGetUserSettingsRequest,
  sendGetPostLoadMetaRequest,
  sendGetPurchaseModifySubscriptionRequestStatus,
  sendPurchaseLeadFinderModifySubscriptionRequestStatus,
  sendPurchaseModifySubscriptionResponse,
  selectedPlanDetails,
  resetSubscription,
  resetPurchaseModifySubscriptionResponse,
  resetPurchaseLeadFinderModifySubscriptionResponse,
  showLoading,
  hideLoading,
  sendGetAccountSubscriptionResponse: {
    planAmount: existingPlanPrice,
    slots: existingPlanSlots,
    planCode: existingPlanCode,
  },
  upgradeLtdPlanRequestStatus,
  upgradeLtdPlanRequestError,
  upgradeLtdPlanResponse,
  resetUpgradeLtdPlan,
  modifyLtdPlanRequestStatus,
  modifyLtdPlanRequestError,
  modifyLtdPlanResponse,
  resetModifyLtdPlan,
  isBillingSummaryPending,
  currentPlanName,
  isLeadFinderUpgrade = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isPurchaseInProgress: boolean =
    isRequestPending(
      isLeadFinderUpgrade
        ? sendPurchaseLeadFinderSubscriptionRequestStatus
        : sendPurchaseSubscriptionRequestStatus,
    ) ||
    isRequestPending(
      isLeadFinderUpgrade
        ? sendPurchaseLeadFinderModifySubscriptionRequestStatus
        : sendGetPurchaseModifySubscriptionRequestStatus,
    );
  const isApplyCouponCodeInProgress = isRequestPending(
    sendApplyCouponCodeRequestStatus,
  );

  const [userSelectedPlanDetails, setUserSelectedPlanDetails] = useState([]);
  const [subTotal, setSubTotal] = useState<number>(
    numberOfSlotsUserWant * planPrice,
  );
  const [error, setError] = useState<string>('');
  const [
    is3dSecureResponseStatePending,
    setIs3dSecureResponseStatePending,
  ] = useState<boolean>(false);
  const [
    isDowngradeWarningModalShow,
    setIsDowngradeWarningModalShow,
  ] = useState<boolean>(false);

  useEffect(() => {
    setUserSelectedPlanDetails([
      {
        planName: t('labels.userAccounts'),
        quota: numberOfSlotsUserWant,
        price:
          billingOption === BillingOption.ANNUALLY ? 12 * planPrice : planPrice,
        isNewEntry: !isUserModifyingSubscription,
      },
    ]);

    const { subTotalPrice } = getFinalSubTotal({
      isUserModifyingSubscription,
      sendGetCalculatePayResponse,
      numberOfSlotsUserWant,
      planPrice,
      billingOption,
      couponCodeDiscountType,
      couponCodeDiscount,
      action: selectedPlanDetails.action,
    });

    setSubTotal(subTotalPrice);
  }, [
    numberOfSlotsUserWant,
    planPrice,
    couponCodeDiscount,
    couponCodeDiscountType,
    sendGetCalculatePayRequestStatus,
  ]);

  const totalAmount = getTotalAmount(
    billingOption,
    isUserModifyingSubscription,
    subTotal,
    sendGetCalculatePayResponse,
    selectedPlanDetails.action,
    checkIsLtdPlan(planType)
      ? Number(existingPlanPrice) * existingPlanSlots
      : sendGetModifySubscriptionResponse?.alreadyPaid,
  );

  const showDowngradeWarningModal = () => setIsDowngradeWarningModalShow(true);

  const hideDowngradeWarningModal = () => setIsDowngradeWarningModalShow(false);

  const onApplyCouponCode = (e) => {
    e.preventDefault();

    let isError = false;

    const checkError = validate('couponCode', couponCode);
    isError = isError || !!checkError;
    setError(checkError);

    if (isError) {
      return;
    }

    sendApplyCouponCodeRequest({
      couponCode,
      amount: totalAmount,
      planId,
    });
  };

  const handleDeleteAppliedCouponCode = () => {
    onCouponCodeChange('');
    onDeleteAppliedCouponCode();
    userHasPromoCode(false);
  };

  const onPurchaseHandler = (token) => {
    hideDowngradeWarningModal();
    onPurchase(token, subTotal);
  };

  const onSave = (token) => {
    if (selectedPlanDetails?.action === ModifyPlanAction.Downgrade) {
      showDowngradeWarningModal();
    } else {
      onPurchaseHandler(token);
    }
  };

  const handleUserHasPromoCode = () => {
    userHasPromoCode(true);
    // setHavePromoCode(true);
  };

  const sendPurchaseSubscriptionRequestSuccess = (message) => {
    showToastMessage(message);

    if (isLeadFinderUpgrade) {
      sendGetLeadFinderSubscriptionRequest();
    } else {
      sendGetAccountSubscriptionRequest();
    }
    hideLoading();
    history.push('/settings/billing/subscriptions');
    resetSubscription();
    sendGetPostLoadMetaRequest();

    setTimeout(() => {
      // Stripe is taking time to update payment is pending or not data
      sendGetUserSettingsRequest();
    }, 500);
  };

  // Handle 3d secure card
  const handle3dSecureCard = async (
    response,
    baseURL = 'subscription',
    resetHandler = null,
  ) => {
    const apiEndPoint = `/${baseURL}/confirm-payment-intent`;
    setIs3dSecureResponseStatePending(true);

    const { isError, serverResponse } = await handle3dSecureCardPayment({
      response,
      apiEndPoint,
    });

    setIs3dSecureResponseStatePending(false);

    if (isError) {
      hideLoading();
      if (isLeadFinderUpgrade) {
        resetPurchaseLeadFinderModifySubscriptionResponse();
      } else {
        resetPurchaseModifySubscriptionResponse();
      }
      if (resetHandler) {
        resetHandler();
      }
    } else {
      sendPurchaseSubscriptionRequestSuccess(serverResponse.payload.message);
    }
  };

  const handlePayNowAnalyticsEvent = () => {
    window.analytics?.track?.({
      event: AnalyticsEvents.ClickedOnPayNow,
      properties: {
        Source: 'Subscription',
        current_plan: currentPlanName,
        'Clicked Plan': planName,
      },
    });
  };

  // Get modify subscription account quota & price status failed
  useEffect(() => {
    showErrorNotification(
      sendGetModifySubscriptionRequestStatus,
      sendGetModifySubscriptionRequestError,
    );
  }, [sendGetCalculatePayRequestStatus]);

  // Purchase subscription
  useEffect(() => {
    if (isLeadFinderUpgrade) {
      sendPurchaseLeadFinderSubscriptionRequestStatus ===
        RequestStatus.Pending && showLoading();

      sendPurchaseLeadFinderSubscriptionRequestStatus ===
        RequestStatus.Succeeded &&
        sendPurchaseSubscriptionRequestSuccess(
          sendPurchaseLeadFinderSubscriptionRequestMessage,
        );

      if (
        sendPurchaseLeadFinderSubscriptionRequestStatus === RequestStatus.Failed
      ) {
        handleDeleteAppliedCouponCode();
      }
    } else {
      sendPurchaseSubscriptionRequestStatus === RequestStatus.Pending &&
        showLoading();

      sendPurchaseSubscriptionRequestStatus === RequestStatus.Succeeded &&
        sendPurchaseSubscriptionRequestSuccess(
          sendPurchaseSubscriptionRequestMessage,
        );

      if (sendPurchaseSubscriptionRequestStatus === RequestStatus.Failed) {
        handleDeleteAppliedCouponCode();
      }
    }
  }, [
    sendPurchaseSubscriptionRequestStatus,
    sendPurchaseLeadFinderSubscriptionRequestStatus,
  ]);

  // Modify subscription
  useEffect(() => {
    if (isLeadFinderUpgrade) {
      sendPurchaseLeadFinderModifySubscriptionRequestStatus ===
        RequestStatus.Pending && showLoading();

      if (
        sendPurchaseLeadFinderModifySubscriptionRequestStatus ===
        RequestStatus.Succeeded
      ) {
        sendPurchaseSubscriptionRequestSuccess(
          sendPurchaseLeadFinderModifySubscriptionRequestMessage,
        );
      }

      if (
        sendPurchaseLeadFinderModifySubscriptionRequestStatus ===
        RequestStatus.Failed
      ) {
        handleDeleteAppliedCouponCode();
      }
    } else {
      sendGetPurchaseModifySubscriptionRequestStatus ===
        RequestStatus.Pending && showLoading();

      if (
        sendGetPurchaseModifySubscriptionRequestStatus ===
        RequestStatus.Succeeded
      ) {
        sendPurchaseSubscriptionRequestSuccess(
          sendPurchaseModifySubscriptionResponse.message,
        );
      }

      if (
        sendGetPurchaseModifySubscriptionRequestStatus === RequestStatus.Failed
      ) {
        handleDeleteAppliedCouponCode();
      }
    }
  }, [
    sendGetPurchaseModifySubscriptionRequestStatus,
    sendPurchaseLeadFinderModifySubscriptionRequestStatus,
  ]);

  useEffect(() => {
    executeOnRequestStatus({
      status: upgradeLtdPlanRequestStatus,
      onSuccess: () => {
        if (upgradeLtdPlanResponse.requires_action) {
          handle3dSecureCard(
            upgradeLtdPlanResponse,
            'ltd',
            resetUpgradeLtdPlan,
          );
        } else {
          sendPurchaseSubscriptionRequestSuccess(
            upgradeLtdPlanResponse.message,
          );
        }
      },
      onFailed: () => {
        showGeneralErrorNotification(upgradeLtdPlanRequestError.message);
      },
    });
  }, [upgradeLtdPlanRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: modifyLtdPlanRequestStatus,
      onSuccess: () => {
        if (modifyLtdPlanResponse.requires_action) {
          handle3dSecureCard(modifyLtdPlanResponse, 'ltd', resetModifyLtdPlan);
        } else {
          sendPurchaseSubscriptionRequestSuccess(modifyLtdPlanResponse.message);
        }
      },
      onFailed: () => {
        showGeneralErrorNotification(modifyLtdPlanRequestError.message);
      },
    });
  }, [modifyLtdPlanRequestStatus]);

  useEffect(() => {
    handleDeleteAppliedCouponCode();
  }, [billingOption]);

  const renderSaveButton = () => (
    <Button
      className="header-btn"
      variant={Button.Variant.Primary}
      isLoading={
        isPurchaseInProgress ||
        is3dSecureResponseStatePending ||
        getIsRequestPending(upgradeLtdPlanRequestStatus) ||
        getIsRequestPending(modifyLtdPlanRequestStatus)
      }
      disabled={
        isPurchaseInProgress ||
        is3dSecureResponseStatePending ||
        minimumNumberOfSlotsUserCanHave > numberOfSlotsUserWant ||
        (checkIsLtdPlan(planType) &&
          Number(existingPlanSlots) === Number(numberOfSlotsUserWant)) ||
        getIsRequestPending(upgradeLtdPlanRequestStatus) ||
        getIsRequestPending(modifyLtdPlanRequestStatus) ||
        (!sendGetCalculatePayResponse && isUserModifyingSubscription) ||
        !sendGetCalculatePayResponse?.isSubscriptionModified
      }
      onClick={onSave}
    >
      {t('labels.save')}
    </Button>
  );

  const renderStripeButton = () => (
    <StripeCheckout
      token={onPurchaseHandler}
      name="Saleshandy"
      image={Images.SHIcon70}
      stripeKey={process.env.REACT_APP_STRIPE_KEY}
      amount={
        getTotalAmountMinusDiscount({
          totalAmount,
          discountType: couponCodeDiscountType,
          discount: couponCodeDiscount,
        }) * 100
      }
      zipCode
      billingAddress
      ComponentClass="div"
    >
      <Button
        onClick={() => handlePayNowAnalyticsEvent()}
        variant={Button.Variant.Primary}
        isLoading={
          isPurchaseInProgress ||
          is3dSecureResponseStatePending ||
          getIsRequestPending(upgradeLtdPlanRequestStatus) ||
          getIsRequestPending(modifyLtdPlanRequestStatus)
        }
        disabled={
          isPurchaseInProgress ||
          is3dSecureResponseStatePending ||
          getIsRequestPending(upgradeLtdPlanRequestStatus) ||
          getIsRequestPending(modifyLtdPlanRequestStatus)
        }
      >
        {t('labels.pay_now')}
      </Button>
    </StripeCheckout>
  );

  const renderButton = () =>
    totalAmount === 0 ? renderSaveButton() : renderStripeButton();

  useEffect(() => {
    if (havePromoCode) {
      handleDeleteAppliedCouponCode();
    }
  }, [numberOfSlotsUserWant]);

  return (
    <>
      <div className="upgrade-plan-billing-section">
        <div className="card">
          <div className="card-body">
            <div className="upgrade-plan-billing-section__header">
              <span className="semibold-1 gray-txt-12">
                {t('labels.bill_summary')}
              </span>
            </div>

            {isBillingSummaryPending ? (
              <div className="upgrade-plan-billing-section__loader-container d-flex justify-content-center align-items-center">
                <ImageIcon src={Images.Loader} />
              </div>
            ) : (
              <>
                <div className="upgrade-plan-billing-section__price-details-wrap">
                  <div className="price-details">
                    <div className="d-flex justify-content-between">
                      <span className="regular-1">{t('labels.plan_name')}</span>
                      <span className="semibold-2">{planName}</span>
                    </div>
                    {userSelectedPlanDetails.map((plan) => (
                      <div
                        key={plan.planName}
                        className="d-flex justify-content-between mt-2"
                      >
                        <div className="d-flex flex-column days-of-bill-text">
                          <span className="regular-1 gray-txt-12">{`${plan.quota} x Slots`}</span>
                        </div>
                        <span className="semibold-2">
                          ${plan.quota * plan.price}
                        </span>
                      </div>
                    ))}

                    <div className="upgrade-plan-billing-section__divider mt-3" />

                    <div className="d-flex justify-content-between subtotal-discount-amount">
                      <span className="semibold-1 gray-txt-12">
                        {t('labels.total_amount')}
                      </span>
                      <span className="semibold-2">{`$${Number(
                        subTotal,
                      ).toFixed(2)}`}</span>
                    </div>

                    {checkShouldShowTotalAmoutToPayText({
                      planType,
                      planCode,
                      numberOfSlotsUserWant,
                      existingPlanSlots,
                      existingPlanCode,
                    }) && (
                      <div className="d-flex justify-content-between subtotal-discount-amount">
                        <span className="semibold-1 gray-txt-12">
                          Amount already paid
                        </span>
                        <span className="semibold-2">
                          $
                          {Math.abs(
                            Number(existingPlanPrice) * existingPlanSlots,
                          )}
                        </span>
                      </div>
                    )}

                    {selectedPlanDetails.action !==
                      ModifyPlanAction.AddSlots && (
                      <>
                        {sendGetCalculatePayResponse?.creditBeforeModification >
                          0 &&
                          !checkIsLtdPlan(planType) && (
                            <div className="d-flex justify-content-between already_paid_amount">
                              <div>
                                <span className="semibold-1 gray-txt-12">
                                  {t('labels.credits')}
                                </span>
                                <OverlayTooltip text="It's the credit balance available in your account.">
                                  <ImageIcon
                                    src={Images.Help}
                                    className="ml-1"
                                  />
                                </OverlayTooltip>
                              </div>
                              <span className="semibold-2">{`$${sendGetCalculatePayResponse?.creditBeforeModification.toFixed(
                                2,
                              )}`}</span>
                            </div>
                          )}

                        {sendGetCalculatePayResponse?.unusedBalance > 0 &&
                          !checkIsLtdPlan(planType) && (
                            <div className="d-flex justify-content-between already_paid_amount">
                              <div>
                                <span className="semibold-1 gray-txt-12">
                                  {t('labels.unused_balance')}
                                </span>
                                <OverlayTooltip text="It's calculated on a pro-rate basis of unused subscription.">
                                  <ImageIcon
                                    src={Images.Help}
                                    className="ml-1"
                                  />
                                </OverlayTooltip>
                              </div>
                              <span className="semibold-2">{`$${sendGetCalculatePayResponse?.unusedBalance.toFixed(
                                2,
                              )}`}</span>
                            </div>
                          )}
                      </>
                    )}
                  </div>

                  <div className="promo-code-container">
                    {havePromoCode && (
                      <div className="coupon-code-input-wrapper mt-3">
                        <Input
                          name="couponCode"
                          placeholder="Enter Coupon"
                          value={couponCode}
                          variant={
                            ((error || isSendApplyCouponCodeRequestError) &&
                              Input.Variant.Error) ||
                            (couponCodeDiscount && Input.Variant.Succeeded)
                          }
                          caption={error || couponCodeResponseMessage}
                          onChange={onCouponCodeChange}
                          className="coupon-code-input"
                          button={
                            !isApplyCouponCodeInProgress &&
                            !couponCodeDiscount && {
                              buttonTitle: 'Apply',
                              onClick: (e) => onApplyCouponCode(e),
                            }
                          }
                          icons={[
                            isApplyCouponCodeInProgress && {
                              place: Input.IconPlace.Right,
                              identifier: 'spinner-alt',
                              colorDefault: true,
                              className: 'icon-loading',
                            },
                            ...(couponCodeDiscount
                              ? [
                                  {
                                    place: Input.IconPlace.Right,
                                    identifier: 'trash',
                                    colorDefault: true,
                                    className: 'icon-1 icon-2',
                                    onClick: () =>
                                      handleDeleteAppliedCouponCode(),
                                  },
                                ]
                              : []),
                          ]}
                        />
                      </div>
                    )}

                    {couponCodeDiscount && (
                      <div className="d-flex justify-content-between subtotal-discount-amount mt-4">
                        <span className="semibold-1 gray-txt-12">
                          {t('labels.discount')}
                        </span>
                        <span className="semibold-2">{`$${getSubtotalDiscountAmount(
                          couponCodeDiscountType,
                          totalAmount,
                          couponCodeDiscount,
                        ).toFixed(2)}`}</span>
                      </div>
                    )}

                    {!havePromoCode &&
                      (selectedPlanDetails.action === ModifyPlanAction.Update ||
                        checkIsLtdPlan(planType)) && (
                        <span
                          role="button"
                          className="have-promo-code-btn font-10 font-weight-medium blue-txt-11 d-flex justify-content-end"
                          {...accessibleOnClick(handleUserHasPromoCode)}
                        >
                          {t('labels.have_a_promo_code')}
                        </span>
                      )}
                  </div>
                </div>

                <div className="upgrade-plan-billing-section__divider" />

                <div className="mt-4">
                  <div className="d-flex justify-content-between">
                    <span className="semibold-1">
                      {t('labels.total_amount_to_pay_today')}
                      {!checkIsLtdPlan(planType) &&
                        isUserModifyingSubscription && (
                          <OverlayTooltip text=" This amount has been calculated on a pro-rata basis.">
                            <ImageIcon src={Images.Help} className="ml-1" />
                          </OverlayTooltip>
                        )}
                    </span>

                    <span className="semibold-2">
                      $
                      {getTotalAmountMinusDiscount({
                        totalAmount,
                        discountType: couponCodeDiscountType,
                        discount: couponCodeDiscount,
                      })}
                    </span>
                  </div>
                  {!checkIsLtdPlan(planType) && (
                    <p className="regular-1 font-10 gray-txt-15 line-height-14 text-center mt-4">
                      Your subscription will auto-renew until you cancel. By
                      upgrading, you agree to our{' '}
                      <a
                        href={supportUrls.refundPolicy}
                        target="_blank"
                        className="link"
                        rel="noreferrer"
                      >
                        refund policy.
                      </a>
                    </p>
                  )}
                </div>

                <div className="d-flex justify-content-center mt-4">
                  {renderButton()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <DowngradeWarningModal
        show={isDowngradeWarningModalShow}
        onClose={hideDowngradeWarningModal}
        onSubmit={onPurchaseHandler}
      />
    </>
  );
};

export default UpgradePlanBillingSection;
