import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import {
  AuthHelper,
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  initializeSentryIntegrations,
  initializeThirdPartyIntegrations,
  redirectWithoutRefresh,
  setToken,
} from '../../../../shared/utils';
import toaster, { Theme } from '../../../../shared/toaster';
import { ActionForAuthentication } from '../../types';
import { AnalyticsEvents } from '../../../../shared/enums/analytics';
import { IProps } from './types';

const OAuthWhitelabelCallback: React.FC<IProps> = (props) => {
  const location = useLocation();
  const history = useHistory();

  const {
    deleteMyProfileRequestStatus,
    deleteMyProfileRequestMessage,
    deleteMyProfileRequestError,
    sendDeleteMyProfileRequest,
    resetDeleteMyProfileRequest,
  } = props;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const action = searchParams.get('action');
    const trackingId = searchParams.get('trackingId');
    const firstName = searchParams.get('firstName');
    const lastName = searchParams.get('lastName');
    const email = searchParams.get('email');
    const queryMessage = searchParams.get('message');

    if (token) {
      try {
        switch (action) {
          case ActionForAuthentication.Invitation:
          case ActionForAuthentication.Signup:
            setToken(token);
            if (trackingId) {
              window.analytics?.track?.({
                userId: trackingId,
                event: AnalyticsEvents.SignupCompleted,
                properties: {
                  firstName,
                  lastName,
                  email,
                  id: trackingId,
                  source: 'v3',
                },
              });
            }

            redirectWithoutRefresh({
              pathname: '/sequence',
              search: 'signup=completed',
            });
            break;
          case ActionForAuthentication.ValidationChangeEmail:
            setToken(token);

            redirectWithoutRefresh({
              pathname: '/settings/profile',
              state: {
                oAuthChangeEmail: true,
              },
            });
            break;
          case ActionForAuthentication.DeleteAccount:
            sendDeleteMyProfileRequest('null');
            break;
          case ActionForAuthentication.Login:
          default:
            AuthHelper.login({ token });
            initializeThirdPartyIntegrations({
              trackingId,
              firstName,
              lastName,
              email,
            });
            initializeSentryIntegrations(email);
            history.push('/');
            toaster.success('Logged in successfully', { theme: Theme.New });
        }
      } catch (error) {
        toaster.error('Authentication failed.', {
          theme: Theme.New,
          showCloseIcon: true,
        });
        history.push('/login');
      }
    } else if (queryMessage) {
      if (queryMessage === 'email-changed-successfully') {
        toaster.success('Email changed successfully', { theme: Theme.New });
        history.push('/');
      } else if (queryMessage === 'authentication-failed') {
        toaster.error('Authentication failed.', {
          theme: Theme.New,
          showCloseIcon: true,
        });
        history.push('/login');
      }
    } else {
      toaster.error('Invalid authentication token', {
        theme: Theme.New,
        showCloseIcon: true,
      });
      history.push('/login');
    }
  }, [location.search, history]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteMyProfileRequestStatus,
      onSuccess: () => {
        toaster.success(deleteMyProfileRequestMessage, { theme: Theme.New });
        AuthHelper.logout({ redirect: true, pathName: '/signup' });
        resetDeleteMyProfileRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: deleteMyProfileRequestError,
          onError: () => {
            if (deleteMyProfileRequestError.code !== 1001) {
              toaster.error(deleteMyProfileRequestError.message, {
                theme: Theme.New,
              });
              resetDeleteMyProfileRequest();
            }
          },
        });
      },
    });
  }, [deleteMyProfileRequestStatus]);

  return null;
};

export default OAuthWhitelabelCallback;
