import React from 'react';

const FeaturesHeader = () => (
  <div className="features-header d-flex">
    <div className="font-14 font-semibold blue-txt-15 feature-title">
      FEATURE
    </div>
    <div className="font-14 font-bold blue-txt-15 plan-name email-warmup-bg">
      Starter
    </div>
    <div className="font-14 font-bold blue-txt-15 plan-name email-outreach-bg">
      Outreach
    </div>
  </div>
);

export default FeaturesHeader;
