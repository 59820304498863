import { useLocation } from 'react-router';
import store from '../../store';
import { checkIsLtdPlan } from '../design-system/components/molecules/subscription-plan-details/helper/helper';
import { Permissions } from './access-control/enums/permissions';
import hasPermission from './access-control/has-permission';
import { isAgencyUser, getAgencyUserRole } from './user-details';
import { UserRole } from '../enums/user-roles';
import { isFreePlan } from './plans';
import { Routes } from '../../components/home/components/header/header';
import { isPlanDowngradedFromTrial } from '../../components/home/utils/plan-transition';

export const getUpgradePlanCTA = (isCTAForModal = false) => {
  const state = store.getState();
  const planType = state.home?.subscription?.planType;
  const planTransitionDetails = state.home.subscription?.planTransitionDetails;
  const location = useLocation();
  const path = location.pathname.split('/')?.[1];

  if (isAgencyUser()) {
    const agencyUserRole = getAgencyUserRole();
    if (agencyUserRole && agencyUserRole === UserRole.AGENCYOWNER) {
      return 'Contact_Support';
    }
    return 'Contact_Owner';
  }

  if (checkIsLtdPlan(planType)) {
    return 'Contact_Support';
  }

  if (!hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
    return 'Contact_Owner';
  }

  if(isFreePlan()){
    if (path === Routes.LEADS || isPlanDowngradedFromTrial(planTransitionDetails)) {
      return 'upgrade_now';
    }
    return 'resume_email_sending';
  }


  return isCTAForModal ? 'upgrade' : 'upgrade_now';
};
