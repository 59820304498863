import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import SelectPlan from '../select-plan';
import ModifySubscriptionProgressBar from './components/modify-subscription-progress-bar';
import type { IProps } from './modify-lead-finder-subscription-container';
import { BillingOption } from '../../../../../../shared/utils/billing-options';
import { ModifySubscriptionSteps, SetPlanDetails } from './types';
import RadioButtonTransfer from '../../../../../../shared/design-system/components/atoms/radio-button-transfer';
import {
  billingOptions,
  gePlanCycleBasedOnPlanType,
  getHighlightMessage,
  getDisabledLabel,
  getDisabledTab,
  isBillingAndSubscriptionPageReady,
  isLeadFinderValueBasedPlanVisible,
  isLeadFinderColdEmailPlanBannerShow,
} from './utils/helper';
import {
  ModifyPlanAction,
  PlanType,
  SubscriptionPlanModes,
  SubscriptionPlans,
} from '../../../../../../shared/utils/subscription-plans';
import ImageIcon from '../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../shared/app-constants';
import Spinner from '../../../../../../shared/design-system/components/atoms/custom-spinner';
import { getPlanMetaData } from '../select-plan/utils/get-plan-meta-data';
import {
  getPlanId,
  getPlanNameByPlanCycle,
  getPlanNameByPlanMode,
} from '../upgrade-plan/utils/helper';
import { getPlanCode } from '../select-plan/utils/helper';
import PaymentFailed from '../upgrade-plan/payment-failed';
import { executeOnRequestStatus } from '../../../../../../shared/utils/execute-on-request-status';
import ColdEmailPlanExploreBanner from './components/cold-email-plan-explore-banner';
import { showGeneralErrorNotification } from '../../../../../../shared/utils/errors';
import LeadFinderPlans from '../lead-finder-plans';
import LeadFinderUpgradePlan from '../lead-finder-upgrade-plan';

const ModifyLeadFinderSubscription: React.FC<IProps> = (containerProps) => {
  const { t } = useTranslation();
  const location = useLocation<{
    modifySubscriptionStep: ModifySubscriptionSteps;
    isRedirectedFromBillingOptions: boolean;
    isRedirectedFromEmailAccounts?: boolean;
  }>();

  // Select Plan
  const {
    selectPlan,
    selectedPlanDetails,
    sendGetCalculatePayRequest,
    sendGetLeadFinderSubscriptionResponse,
    sendGetLeadFinderPlansResponse,
    userCurrentPlan,
    sendPurchaseLeadFinderSubscriptionRequestStatus,
    sendPurchaseLeadFinderModifySubscriptionRequestStatus,
    resetPurchaseLeadFinderModifySubscriptionResponse,
    resetCalculatePayState,
    sendGetLeadFinderSubscriptionRequestStatus,
    sendGetLeadFinderPlansRequestStatus,
    leadFinderSubscriptionPlanStatus,
  } = containerProps;

  // States
  const [
    modifySubscriptionStep,
    setModifySubscriptionStep,
  ] = useState<ModifySubscriptionSteps>(ModifySubscriptionSteps.SelectPlan);
  const [planCycle, setPlanCycle] = useState<BillingOption>(
    gePlanCycleBasedOnPlanType(
      sendGetLeadFinderSubscriptionResponse?.planCode,
      sendGetLeadFinderSubscriptionResponse?.planMode,
    ),
  );
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);

  const setPlanDetails = ({
    planCode,
    planType,
    planMode,
    planName,
    action,
  }: SetPlanDetails) => {
    const {
      planName: currentPlanName,
      planMode: currentPlanMode,
      planAmount,
      planId,
    } = sendGetLeadFinderSubscriptionResponse || {};

    const getPlanName = getPlanNameByPlanMode(
      sendGetLeadFinderPlansResponse,
      planCode,
      planMode,
    );

    let modifyAction = action;

    if (
      currentPlanMode === SubscriptionPlanModes.MonthlyMode &&
      planCycle === BillingOption.ANNUALLY
    ) {
      modifyAction = ModifyPlanAction.Update;
    } else if (currentPlanName === planName) {
      modifyAction = ModifyPlanAction.AddSlots;
      resetCalculatePayState();
    }

    const { displayName, price } =
      getPlanMetaData(planCode, planCycle, planName || getPlanName) || {};

    selectPlan({
      displayName: displayName || planName,
      planId:
        getPlanId(
          sendGetLeadFinderPlansResponse,
          planName || getPlanName,
          planCode,
          planType,
        ) || planId,
      planPrice: price || Number(planAmount),
      planCode,
      planType,
      action: modifyAction,
    });
  };

  const handleModifySubscriptionStep = (step) =>
    setModifySubscriptionStep(step);

  const handelBillingOption = (value) => setPlanCycle(value);

  const handelSelectPlan = (
    planCode: SubscriptionPlans,
    planType: PlanType,
    planName,
    planMode: number,
    action,
  ) => {
    const planId = getPlanId(
      sendGetLeadFinderPlansResponse,
      planName,
      planCode,
      planType,
    );

    setPlanDetails({ planCode, planType, planMode, planName, action });
    handleModifySubscriptionStep(ModifySubscriptionSteps.SelectSlots);

    if (action === ModifyPlanAction.AddSlots) {
      resetCalculatePayState();
    }

    if (
      action !== ModifyPlanAction.AddSlots &&
      planCycle !== BillingOption.LIFETIME &&
      userCurrentPlan !== SubscriptionPlans.FREE &&
      userCurrentPlan !== SubscriptionPlans.TRIAL
    ) {
      sendGetCalculatePayRequest({
        accountSubscriptionPlanId: planId,
        slots: sendGetLeadFinderSubscriptionResponse?.slots,
      });
    }
  };

  const onPaymentFailed = () => {
    setIsPaymentFailed(true);
  };

  const onPaymentRetrieve = () => {
    setIsPaymentFailed(false);
    setModifySubscriptionStep(ModifySubscriptionSteps.SelectPlan);
  };

  useEffect(() => {
    const { modifySubscriptionStep: step } = location.state || {
      modifySubscriptionStep: null,
    };

    if (step) {
      resetCalculatePayState();
      setModifySubscriptionStep(step);
    }
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: sendGetLeadFinderSubscriptionRequestStatus,
      onPending: () => {
        setIsPaymentFailed(false);
      },
      onSuccess: () => {
        if (sendGetLeadFinderSubscriptionResponse) {
          const { planCode, planName } = sendGetLeadFinderSubscriptionResponse;
          setPlanCycle(
            gePlanCycleBasedOnPlanType(
              sendGetLeadFinderSubscriptionResponse?.planCode,
              sendGetLeadFinderSubscriptionResponse?.planMode,
            ),
          );
          setPlanDetails({
            planCode,
            planName,
            action: ModifyPlanAction.AddSlots,
          });
        }
      },
    });
  }, [
    sendGetLeadFinderSubscriptionRequestStatus,
    sendGetLeadFinderPlansRequestStatus,
  ]);

  useEffect(() => {
    if (selectedPlanDetails && sendGetLeadFinderSubscriptionResponse) {
      const { planName, planCode } = sendGetLeadFinderSubscriptionResponse;
      setPlanDetails({
        planCode:
          planCode === SubscriptionPlans.PAID_MONTHLY ||
          planCode === SubscriptionPlans.PAID_YEARLY
            ? getPlanCode(planCycle)
            : planCode,
        planName: getPlanNameByPlanCycle(
          selectedPlanDetails.displayName || planName,
          planCycle,
        ),
        action: selectedPlanDetails.action,
      });
    }
  }, [planCycle]);

  useEffect(() => {
    executeOnRequestStatus({
      status: sendPurchaseLeadFinderSubscriptionRequestStatus,
      onFailed: () => {
        containerProps.hideLoading();
        onPaymentFailed();
      },
    });
  }, [sendPurchaseLeadFinderSubscriptionRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: sendPurchaseLeadFinderModifySubscriptionRequestStatus,
      onFailed: () => {
        containerProps.hideLoading();
        onPaymentFailed();
        resetPurchaseLeadFinderModifySubscriptionResponse();
      },
    });
  }, [sendPurchaseLeadFinderModifySubscriptionRequestStatus]);

  useEffect(() => {
    const {
      sendGetLeadFinderSubscriptionRequest,
      sendGetLeadFinderPlansRequest,
      sendGetConnectedUsersAndEmailAccountsRequest,
    } = containerProps;
    sendGetLeadFinderSubscriptionRequest();
    sendGetLeadFinderPlansRequest();
    sendGetConnectedUsersAndEmailAccountsRequest();
  }, []);

  const {
    sendGetConnectedUsersAndEmailAccountsRequestError,
    sendGetConnectedUsersAndEmailAccountsRequestStatus,
    resetConnectedUsersAndEmailAccountsRequest,
  } = containerProps;

  useEffect(() => {
    executeOnRequestStatus({
      status: sendGetConnectedUsersAndEmailAccountsRequestStatus,
      onSuccess: () => {
        resetConnectedUsersAndEmailAccountsRequest();
      },
      onFailed: () => {
        showGeneralErrorNotification(
          sendGetConnectedUsersAndEmailAccountsRequestError?.message,
        );
        resetConnectedUsersAndEmailAccountsRequest();
      },
    });
  }, [sendGetConnectedUsersAndEmailAccountsRequestStatus]);

  // Check it payment failed or not
  if (isPaymentFailed) {
    return <PaymentFailed onRetryPayment={() => onPaymentRetrieve()} />;
  }

  const isRequestPending = isBillingAndSubscriptionPageReady({
    sendGetLeadFinderSubscriptionRequestStatus,
    sendGetLeadFinderPlansRequestStatus,
    sendGetLeadFinderSubscriptionResponse,
    selectedPlanDetails,
  });

  const subscriptionStep = () =>
    modifySubscriptionStep === ModifySubscriptionSteps.SelectPlan ? (
      <div className="d-flex justify-content-center mt-4">
        <SelectPlan
          planCycle={planCycle}
          onSelectPlan={(planCode, planType, planName, planMode, action) =>
            handelSelectPlan(planCode, planType, planName, planMode, action)
          }
          sendGetLeadFinderSubscriptionResponse={
            sendGetLeadFinderSubscriptionResponse
          }
          isLeadFinderPlans={true}
          sendGetLeadFinderPlansResponse={sendGetLeadFinderPlansResponse}
        />
      </div>
    ) : (
      <LeadFinderUpgradePlan
        containerProps={containerProps}
        planCycle={planCycle}
        isRedirectedFromEmailAccounts={
          location.state?.isRedirectedFromEmailAccounts
        }
      />
    );

  if (isRequestPending) {
    return <Spinner />;
  }

  if (
    isLeadFinderValueBasedPlanVisible(
      sendGetLeadFinderSubscriptionResponse?.planCode,
      sendGetLeadFinderSubscriptionResponse?.planType,
      leadFinderSubscriptionPlanStatus,
    )
  ) {
    return <LeadFinderPlans />;
  }

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        {isLeadFinderColdEmailPlanBannerShow(
          sendGetLeadFinderSubscriptionResponse?.planCode,
        ) && <ColdEmailPlanExploreBanner />}
        <ModifySubscriptionProgressBar
          modifySubscriptionStep={modifySubscriptionStep}
          onStepChange={(step) => handleModifySubscriptionStep(step)}
          disabledStep={getDisabledTab(
            sendGetLeadFinderSubscriptionResponse?.planCode,
            sendGetLeadFinderSubscriptionResponse?.planType,
          )}
        />
        {planCycle !== BillingOption.LIFETIME && (
          <div className="d-flex flex-column align-items-center mt-4">
            <div className="modify-subscription__highlight-container d-flex justify-content-center align-items-center position-relative font-italic">
              <span className="semibold-1 font-8 orange-txt-16">
                {t(`messages.${getHighlightMessage(planCycle)}`)}
              </span>
              <div className="modify-subscription__circle">
                <ImageIcon src={Images.HalfCircle} />
              </div>
            </div>
            <RadioButtonTransfer
              options={billingOptions}
              onChange={handelBillingOption}
              value={planCycle}
              disabledLabel={getDisabledLabel(
                sendGetLeadFinderSubscriptionResponse,
              )}
            />
          </div>
        )}
      </div>
      {subscriptionStep()}
    </>
  );
};

export default ModifyLeadFinderSubscription;
