import React from 'react';
import { Images } from '../../../../app-constants';
import ImageIcon from '../../../../components/images/image-icon';
import Button from '../button';
import Modal from '../modal';
import { ConfirmationModalIconType } from './enum';
import { ConfirmationModalProps } from './type';

const ConfirmationModalV3: React.FC<ConfirmationModalProps> = ({
  show,
  title,
  onClose,
  onSubmit,
  className = '',
  content = '',
  contents = [],
  customJSX = null,
  iconSrc,
  iconType,
  hideTitleIcon = false,
  showCloseIcon = true,
  hideCancelButton = false,
  hideSubmitButton = false,
  submitButtonClassName = '',
  cancelButtonClassName = '',
  isSubmitLoading = false,
  isCancelLoading = false,
  isSubmitDisabled = false,
  isCancelDisabled = false,
  submitButtonText = 'Yes',
  cancelButtonText = 'No',
  icon = null,
  submitButtonVariant = Button.Variant.Primary,
  disableEnforceFocus,
  hideFooter = false,
}) => {
  const getImageIconSource = () => {
    if (iconType === ConfirmationModalIconType.ALERT_TRIANGLE) {
      return Images.AlertTriangleLarge;
    }
    if (iconType === ConfirmationModalIconType.INFO_CIRCLE_BLUE) {
      return Images.AlertCircleBlue;
    }
    if (iconType === ConfirmationModalIconType.INFO_CIRCLE_RED) {
      return Images.AlertCircleRed;
    }
    if (iconType === ConfirmationModalIconType.INFO_CIRCLE_YELLOW) {
      return Images.InfoCircleYellow;
    }
    if (iconType === ConfirmationModalIconType.TRASH_RED) {
      return Images.TrashDanger;
    }
    if (iconType === ConfirmationModalIconType.ALERT_CIRCLE_ORANGE) {
      return Images.AlertCircleOrange;
    }
    if (iconSrc) {
      return iconSrc;
    }
    return Images.InfoCircle;
  };

  const generateModalTitleContent = () => (
    <div
      className={`confirmation-modal-v3--title d-flex align-items-center icon-${iconType}`}
    >
      {!hideTitleIcon && (
        <ImageIcon src={getImageIconSource()} alt="empty-data/" />
      )}
      { icon &&
        <div className="orange-txt-14 d-flex align-items-center">
          {icon}
        </div>
      }
      <h2>{title}</h2>
    </div>
  );

  return (
    <Modal
      show={show}
      titleContent={generateModalTitleContent()}
      className={`confirmation-modal-v3 ${className}`}
      onClose={onClose}
      onSubmit={onSubmit}
      showCloseIcon={showCloseIcon}
      hideCancelButton={hideCancelButton}
      hideSubmitButton={hideSubmitButton}
      submitButtonClassName={submitButtonClassName}
      cancelButtonClassName={cancelButtonClassName}
      isSubmitLoading={isSubmitLoading}
      isCancelLoading={isCancelLoading}
      isSubmitDisabled={isSubmitDisabled}
      isCancelDisabled={isCancelDisabled}
      submitButtonText={submitButtonText}
      cancelButtonText={cancelButtonText}
      submitButtonVariant={submitButtonVariant}
      cancelButtonVarient={Button.Variant.Outlined}
      backdrop="static"
      extraModalProps={{
        centered: true,
        'aria-labelledby': 'contained-modal-title-vcenter',
        ...disableEnforceFocus && {enforceFocus: false},
      }}
      hideFooter={hideFooter}
    >
      <div className="confirmation-modal-v3--body">
        {contents.length > 0 && (
          <div className="confirmation-modal-v3--body__contents">
            {contents.map((contentItem) => (
              <p key={contentItem} className="">
                {contentItem}
              </p>
            ))}
          </div>
        )}
        {content && (
          <div className="confirmation-modal-v3--body__content">
            <div className="">{content}</div>
          </div>
        )}
        {customJSX && <>{customJSX}</>}
      </div>
    </Modal>
  );
};
export default ConfirmationModalV3;
