import React, { useEffect, useState } from 'react';
import { Wallet } from '@saleshandy/icons';
import { Button } from '@saleshandy/design-system';
import { useTranslation } from 'react-i18next';
import StripeCheckout from 'react-stripe-checkout';

import type { IProps } from './purchase-credits-modal-container';

import Modal from '../../../../../../shared/design-system/ui/modal';
import EvCreditPlans from '../ev-credits/components/ev-credit-plans';
import Checkbox from '../../../../../../shared/design-system/components/atoms/checkbox';

import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Images } from '../../../../../../shared/app-constants';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { AnalyticsEvents } from '../../../../../../shared/enums/analytics';
import { contactAdmin } from '../../../../../../shared/utils/contact-admin';
import {
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';

const PurchaseCreditsModal: React.FC<IProps> = ({
  show,
  evCreditPlans = [],
  onBuyCredits,
  onClose,
  isPurchaseEmailVerificationCreditsLoading,
  remainingCredit,
  sequenceName,
  currentPlan,
  getEmailVerificationPlanResponse,
  sendGetEmailVerificationPlanRequest,
  getEmailVerificationPlanRequestStatus,
  type,
  sendGetLeadFinderOneTimePlansRequest,
  getLeadFinderOneTimePlansRequestStatus,
  getLeadFinderOneTimePlansResponse,
  openTalkToSalesChat,
}) => {
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState(
    () =>
      (evCreditPlans?.length > 0 &&
        evCreditPlans.find((plan) => plan.isBestValue === 1)) ||
      null,
  );
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckBox = () => {
    setIsChecked((preState) => !preState);
  };

  const onSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const onPurchaseHandler = (token) => {
    onBuyCredits(token.id, +selectedPlan.id);
  };

  const handlePayNowAnalyticsEvent = () => {
    window.analytics?.track?.({
      event: AnalyticsEvents.ClickedOnPayNow,
      properties: {
        Source: 'Verification Credits',
        current_plan: currentPlan,
      },
    });
  };
  const contactAdminHandler = () => {
    const generalEmailSub = t('messages.general_email_sub_for_purchase_ev');
    const emailSubForSequenceProspectVerify = t(
      'messages.email_sub_for_purchase_ev_from_sequence_settings',
    );
    const generalEmailBody =
      `Hey, %0D%0A %0D%0A I wish to buy ${selectedPlan.name} for Email Verification from SalesHandy.` +
      `%0D%0A Please make arrangements for the same by given URL.` +
      `%0D%0A ${process.env.REACT_APP_V3_PURCHASE_EV_CREDIT_URL} %0D%0A %0D%0AThanks in advance`;
    const sequenceProspectEmailBody =
      `To verify the prospects of our lates sequence "${sequenceName}" we need to purchase credits.` +
      `%0D%0A %0D%0APlease purchase credits to ensure that the sequence "${sequenceName}" continues smoothly.`;

    contactAdmin(
      sequenceName ? emailSubForSequenceProspectVerify : generalEmailSub,
      sequenceName ? sequenceProspectEmailBody : generalEmailBody,
    );
  };

  useEffect(() => {
    if (show && (!evCreditPlans || evCreditPlans?.length === 0)) {
      if (type === 'email-verification') {
        sendGetEmailVerificationPlanRequest();
      } else if (type === 'lead-finder') {
        sendGetLeadFinderOneTimePlansRequest();
      }
    }
  }, [show]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getEmailVerificationPlanRequestStatus,
      onSuccess: () => {
        setSelectedPlan(
          () =>
            (getEmailVerificationPlanResponse?.length > 0 &&
              getEmailVerificationPlanResponse.find(
                (plan) => plan.isBestValue === 1,
              )) ||
            null,
        );
      },
    });
  }, [getEmailVerificationPlanRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getLeadFinderOneTimePlansRequestStatus,
      onSuccess: () => {
        setSelectedPlan(
          () =>
            (getLeadFinderOneTimePlansResponse?.length > 0 &&
              getLeadFinderOneTimePlansResponse.find(
                (plan) => plan.isBestValue === 1,
              )) ||
            null,
        );
      },
    });
  }, [getLeadFinderOneTimePlansRequestStatus]);

  const creditsToUse = () => {
    if (evCreditPlans?.length > 0) {
      return evCreditPlans;
    }
    if (type === 'email-verification') {
      return getEmailVerificationPlanResponse;
    }
    return getLeadFinderOneTimePlansResponse;
  };

  return (
    <Modal
      show={show}
      title={
        <div className="purchase-credits-modal--custom-header">
          <span>
            <Wallet />
          </span>
          <span>{t('labels.purchase_credits')}</span>
        </div>
      }
      className="purchase-credits-modal"
      variant="border"
      showCloseButton
      onHide={onClose}
      hideFooter
    >
      <div className="purchase-credits-modal--plans">
        <EvCreditPlans
          credits={creditsToUse()}
          onSelectPlan={(plan) => onSelectPlan(plan)}
          selectedPlan={selectedPlan}
          remainingCredit={remainingCredit}
          isPlansLoading={
            (type === 'email-verification' &&
              getIsRequestPending(getEmailVerificationPlanRequestStatus)) ||
            (type === 'lead-finder' &&
              getIsRequestPending(getLeadFinderOneTimePlansRequestStatus))
          }
        />

        {hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) && (
          <Checkbox
            disabled={false}
            label={
              type === 'email-verification'
                ? t('messages.purchase_ev_credits_agreement')
                : t('messages.purchase_lead_finder_credits_agreement')
            }
            intermediate={false}
            checked={isChecked}
            onChange={handleCheckBox}
            className="purchase-ev-credits-agreement"
          />
        )}
      </div>

      <div className="purchase-credits-modal--custom-footer">
        <p className="selected-plan-amount">
          {selectedPlan ? `$${selectedPlan?.amount}` : ''}
        </p>

        {!hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) && (
          <Button className="contact-admin-btn" onClick={contactAdminHandler}>
            {t('labels.contact_admin')}
          </Button>
        )}
        {hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) &&
          type === 'lead-finder' &&
          selectedPlan?.amount > 1000 && (
            <Button
              className="contact-admin-btn"
              onClick={openTalkToSalesChat}
              disabled={!isChecked}
            >
              Talk to Sales
            </Button>
          )}
        {hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) &&
          (type !== 'lead-finder' || selectedPlan?.amount <= 1000) && (
            <StripeCheckout
              token={onPurchaseHandler}
              name="Saleshandy"
              image={Images.SHIcon70}
              stripeKey={process.env.REACT_APP_STRIPE_KEY}
              amount={selectedPlan?.amount * 100}
              zipCode
              billingAddress
              ComponentClass="div"
            >
              <Button
                onClick={() => handlePayNowAnalyticsEvent()}
                isLoading={isPurchaseEmailVerificationCreditsLoading}
                disabled={
                  (type === 'email-verification' &&
                    getIsRequestPending(
                      getEmailVerificationPlanRequestStatus,
                    )) ||
                  (type === 'lead-finder' &&
                    getIsRequestPending(
                      getLeadFinderOneTimePlansRequestStatus,
                    )) ||
                  !selectedPlan ||
                  isPurchaseEmailVerificationCreditsLoading ||
                  !isChecked
                }
                className="buy-now-btn"
                loadingText="Buying..."
              >
                {t('labels.buy_now')}
              </Button>
            </StripeCheckout>
          )}
      </div>
    </Modal>
  );
};

export default PurchaseCreditsModal;
