import React, { useCallback, useEffect } from 'react';
import { Cross } from '@saleshandy/icons';
import CircularProgressBar from '../../design-system/components/atoms/circular-progressbar';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import { IProps } from './type';

const OnScreenAsyncProgressBar: React.FC<IProps> = ({
  show,
  percentage,
  textPercentage,
  text,
  completionText,
  ctaText,
  ctaRedirectLink,
  onClose,
  isComplete,
  onCompletion,
  classNameSuffix = '',
}) => {
  const handleCtaRedirectLink = useCallback(() => {
    if (ctaRedirectLink) {
      window.open(ctaRedirectLink, '_blank', 'noopener,noreferrer');
    }
  }, [ctaRedirectLink]);

  useEffect(() => {
    if (isComplete) {
      onCompletion();
    }
  }, [isComplete]);

  if (!show) {
    return <></>;
  }
  return (
    <div className={`async-progress-bar ${classNameSuffix}`}>
      <div
        className={`async-card-body d-flex ${
          !isComplete ? 'align-items-center' : ''
        }`}
      >
        {!isComplete ? (
          <div className="d-flex align-items-center card-body-container">
            <CircularProgressBar
              percentage={percentage}
              text={textPercentage.toString()}
              pathColor="#059669"
              trailColor="#e5e7eb"
              textColor="#059669"
              width="34px"
              height="34px"
              textFontSize="29px"
              textFontWeight="600"
            />
            <div className="d-flex flex-column align-items-flex-start in-progress">
              <span className="text">{text || ''}</span>
              <span
                className={`view-button ${
                  textPercentage > '0%' ? '' : 'disabled'
                }`}
                {...(textPercentage > '0%'
                  ? accessibleOnClick(handleCtaRedirectLink)
                  : accessibleOnClick(() => {}))}
              >
                {ctaText || ''}
              </span>
            </div>
          </div>
        ) : (
          <div
            className={`d-flex flex-column align-items-flex-start card-body-container ${
              isComplete ? 'completed' : ''
            }`}
          >
            <span className="text">{completionText || ''}</span>
            <span
              className="view-button"
              {...accessibleOnClick(handleCtaRedirectLink)}
            >
              {ctaText || ''}
            </span>
          </div>
        )}
        <div
          {...accessibleOnClick(onClose)}
          className="d-flex align-items-flex-start"
        >
          <Cross
            className={`async-cross-icon ${
              isComplete
                ? 'async-completed-process'
                : 'async-inprogress-process'
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default OnScreenAsyncProgressBar;
