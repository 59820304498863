import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../../shared/design-system/components/atoms/button';
import Close from '../../../../../../shared/components/images/close';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import { IntercomEvents } from '../../../../../../shared/enums/intercom-events';

type IProps = {
  onRetryPayment: () => void;
};

const PaymentFailed: React.FC<IProps> = ({ onRetryPayment }) => {
  const { t } = useTranslation();

  return (
    <div className="billing-subscription__payment-failed-wrap">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div>
          <Close />
        </div>
        <h3 className="red-txt-12 semibold-3">{t('labels.payment_failed')}</h3>

        <div className="payment-failed-message">
          <span>{t('messages.payment_failed_message')}</span>
          <span
            role="button"
            className="ml-1 link"
            {...accessibleOnClick(() => window.Intercom(IntercomEvents.SHOW))}
          >
            {t('labels.contact_support')}
          </span>
        </div>

        <div className="mt-5">
          <Button
            variant={Button.Variant.Light}
            className="btn-retry-payment"
            onClick={onRetryPayment}
          >
            {t('labels.retry_payment')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
