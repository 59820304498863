
import React from "react";
import { ChevronLeft } from "@saleshandy/icons"
import history from "../../../../../../shared/history";
import { accessibleOnClick } from "../../../../../../shared/utils/accessible-on-click";

const UpgradePlanHeader = () => {
  const goBack = () => {
    history.push({
      pathname: `/settings/billing/subscriptions/lead-finder`,
    });
  };

  return (
    <div className="upgrade-plan-header" {...accessibleOnClick(goBack)}>
      <ChevronLeft />
      <span>Back</span>
    </div>
  )
}

export default UpgradePlanHeader;