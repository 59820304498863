import { Images } from '../../../../../../../shared/app-constants';
import { BillingOption } from '../../../../../../../shared/utils/billing-options';
import {
  SubscriptionPlanPrice,
  SubscriptionPlans,
  SubscriptionPlanTitle,
} from '../../../../../../../shared/utils/subscription-plans';

const starterPlanMeta = {
  description:
    'Get email tracking and email warm up to kickstart your journey in cold email space.',
  features: [
    'Unlimited email tracking',
    'Fully automated email warm-up',
    'Customized warm-up settings',
    'SMTP/IMAP Supported',
  ],
  headerImage: Images.Ellipse3,
  isBestValuePlan: false,
};

const outreachPlanMeta = {
  description:
    'Accelerate your cold email outreach journey with fully automated sequences.',
  features: [
    'Everything in Starter',
    'Unlimited prospects',
    'Unlimited Email Sequences',
    'A/B Test',
  ],
  headerImage: Images.Ellipse2,
  isBestValuePlan: true,
};

const ltdEmailOutreachPlanMeta = {
  description:
    'Automate your cold email outreach through fully automated sequences.',
  features: [
    'Limited to 1 user account',
    'Unlimited email sequences',
    '100k prospects',
    'Unlimited shared templates',
  ],
  headerImage: Images.Ellipse1,
  isBestValuePlan: false,
};

const ltdEmailOutreachProPlanMeta = {
  description:
    'Collaborate with your team with advanced team management & reports.',
  features: [
    'Multiple user accounts',
    'Can stack upto 20 email accounts',
    'Unlimited email sequences',
    'Unlimited prospects',
    'Unlimited shared templates',
  ],
  headerImage: Images.Ellipse3,
  isBestValuePlan: true,
};

const planMetaData = {
  [BillingOption.MONTHLY]: {
    [SubscriptionPlanTitle.StarterMonthly]: {
      ...starterPlanMeta,
      displayName: SubscriptionPlanTitle.StarterMonthly,
      price: SubscriptionPlanPrice.StarterMonthly,
      planCode: SubscriptionPlans.EMAIL_STARTER_MONTHLY,
    },
    [SubscriptionPlanTitle.OutreachMonthly]: {
      ...outreachPlanMeta,
      displayName: SubscriptionPlanTitle.OutreachMonthly,
      price: SubscriptionPlanPrice.OutreachMonthly,
      planCode: SubscriptionPlans.EMAIL_OUTREACH_MONTHLY,
    },
  },
  [BillingOption.ANNUALLY]: {
    [SubscriptionPlanTitle.StarterYearly]: {
      ...starterPlanMeta,
      displayName: SubscriptionPlanTitle.StarterYearly,
      price: SubscriptionPlanPrice.StarterYearly,
      planCode: SubscriptionPlans.EMAIL_STARTER_YEARLY,
    },
    [SubscriptionPlanTitle.OutreachYearly]: {
      ...outreachPlanMeta,
      displayName: SubscriptionPlanTitle.OutreachYearly,
      price: SubscriptionPlanPrice.OutreachYearly,
      planCode: SubscriptionPlans.EMAIL_OUTREACH_MONTHLY,
    },
  },
  [SubscriptionPlans.EMAIL_OUTREACH_LIFETIME]: {
    ...ltdEmailOutreachPlanMeta,
    displayName: SubscriptionPlanTitle.EmailOutreach,
    price: SubscriptionPlanPrice.EmailOutreachLifetime,
    planCode: SubscriptionPlans.EMAIL_OUTREACH_LIFETIME,
  },
  [SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME]: {
    ...ltdEmailOutreachProPlanMeta,
    displayName: SubscriptionPlanTitle.EmailOutreachPro,
    price: SubscriptionPlanPrice.EmailOutreachProLifetime,
    planCode: SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME,
  },
};

export const getOldPlanPrice = (planCode) => {
  if (planCode === SubscriptionPlans.PAID_MONTHLY) {
    return SubscriptionPlanPrice.OldOureachMonthly;
  }
  return SubscriptionPlanPrice.OldOureachYearly;
};

export const getPlanMetaData = (
  planCode: SubscriptionPlans,
  planCycle: BillingOption,
  planName?: string,
): {
  displayName: SubscriptionPlanTitle;
  description: string;
  features: string[];
  headerImage: string;
  isBestValuePlan: boolean;
  price: number;
  planCode: SubscriptionPlans;
} => {
  if (
    planCode &&
    (planCode === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME ||
      planCode === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME)
  ) {
    return planMetaData[planCode];
  }

  if (
    planCode === SubscriptionPlans.PAID_MONTHLY ||
    planCode === SubscriptionPlans.PAID_YEARLY
  ) {
    return {
      ...planMetaData[planCycle][planName],
      planCode,
      price: getOldPlanPrice(planCode),
    };
  }

  return planMetaData[planCycle][planName];
};
