/* eslint-disable no-console */

export const LOCAL_STORAGE_KEYS = {
  EMAIL_ACCOUNT_INFRASTRUCTURE_CART: 'emailAccountInfrastructureCart',
  EMAIL_ACCOUNT_INFRASTRUCTURE_SERVICE_PROVIDER:
    'emailAccountInfrastructureServiceProvider',
  EMAIL_ACCOUNT_INFRASTRUCTURE_CONTACT_DETAILS:
    'emailAccountInfrastructureContactDetails',
  EMAIL_ACCOUNT_INFRASTRUCTURE_FORWARDING_DOMAIN:
    'emailAccountInfrastructureForwardingDomain',
  EMAIL_ACCOUNT_INFRASTRUCTURE_LAST_UPDATED_AT:
    'emailAccountInfrastructureLastUpdatedAt',
  EMAIL_CHANGE_FOR_SSO_USER_IN_PROGRESS:
  'email-change-for-sso-user-in-progress',
  NEW_SSO_EMAIL_VARIFICATION_IN_PROGRESS:
  'new-sso-email-varification-in-progress'
};

// Helper to save an item to local storage
export const saveToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error saving ${key} to local storage:`, error);
  }
};

// Helper to get an item from local storage
export const getFromLocalStorage = (key) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error(`Error getting ${key} from local storage:`, error);
    return null;
  }
};

// Helper to remove an item from local storage
export const removeFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing ${key} from local storage:`, error);
  }
};

// Helper to remove all keys from local storage
export const clearAllEmailAccountInfrastructureKeysFromLocalStorage = () => {
  Object.values(LOCAL_STORAGE_KEYS).forEach((key) => {
    removeFromLocalStorage(key);
  });
};

export const getEmailAccountInfrastructureServiceProvider = () =>
  getFromLocalStorage(
    LOCAL_STORAGE_KEYS.EMAIL_ACCOUNT_INFRASTRUCTURE_SERVICE_PROVIDER,
  );

export const getEmailAccountInfrastructureLastUpdatedAt = () =>
  getFromLocalStorage(
    LOCAL_STORAGE_KEYS.EMAIL_ACCOUNT_INFRASTRUCTURE_LAST_UPDATED_AT,
  );

export const getEmailAccountInfrastructureCart = () =>
  getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL_ACCOUNT_INFRASTRUCTURE_CART);

export const preloadDataFromLocalStorage = () => {
  const serviceProvider = getEmailAccountInfrastructureServiceProvider();

  const cart = getEmailAccountInfrastructureCart();

  const forwardingDomain = getFromLocalStorage(
    LOCAL_STORAGE_KEYS.EMAIL_ACCOUNT_INFRASTRUCTURE_FORWARDING_DOMAIN,
  );
  const contactDetails = getFromLocalStorage(
    LOCAL_STORAGE_KEYS.EMAIL_ACCOUNT_INFRASTRUCTURE_CONTACT_DETAILS,
  );

  return { serviceProvider, cart, forwardingDomain, contactDetails };
};
