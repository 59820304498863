/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Range, getTrackBackground } from 'react-range';
import type { RangeInputType } from './types';

const STEP = 1;
const MIN = 1;
const MAX = 4;

export const RangeInput: React.FC<RangeInputType> = ({ value, onChange }) => {
  const handleChange = (v: number[]) => {
    onChange(v);
  };

  return (
    <div
      className="range"
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: 'inherit',
      }}
    >
      <Range
        values={value}
        step={STEP}
        min={MIN}
        max={MAX}
        rtl={false}
        onChange={(v: number[]) => handleChange(v)}
        renderMark={({ props, index }) => (
          <div
            {...props}
            className="range__mark"
            style={{
              ...props.style,
              height: '2px',
              width: '2px',
              borderRadius: '50px',
              backgroundColor: index * STEP < value[0] ? '#FFF' : '#3B82F6',
              marginTop: '1.5px',
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className="range__track"
            style={{
              ...props.style,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '5px',
              background: getTrackBackground({
                values: value,
                colors: ['#1D4ED8', '#c0dbfe'],
                min: MIN,
                max: MAX,
                rtl: false,
              }),
              borderRadius: '4px',
              position: 'relative',
              paddingRight: '3px',
            }}
          >
            <div
              ref={props.ref}
              className="range__track-ch"
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className={
              (value[0] === MIN && 'range__thumb-first') ||
              (value[0] === MAX && 'range__thumb-last')
            }
            style={{
              ...props.style,
              height: '14px',
              width: '14px',
              borderRadius: '50px',
              outlineStyle: 'solid',
              outlineColor: '#1D4ED8',
              outlineWidth: '2px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
            }}
          />
        )}
      />
    </div>
  );
};
