import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../store/root-reducer";
import SSOCallback from "./sso-callback";
import { ssoCallbackRequest } from "./extra-actions";
import { RouteComponentProps } from "react-router";
import { SSOCallbackRequestPayload } from "../../../settings/types/request-payload";

const mapStateToProps = (state: RootState) => ({
    agencyConfig: state.home.agencyConfig,
    agencyConfigRequestStatus: state.home.getAgencyConfigRequest.status,

    ssoCallbackRequest: state.ssoCallback.ssoCallbackRequest,
    ssoCallbackRequestStatus: state.ssoCallback.ssoCallbackRequest.status,
    ssoCallbackRequestError: state.ssoCallback.ssoCallbackRequest.error,
    ssoCallbackRequestShowError: state.ssoCallback.ssoCallbackRequest.showError,

    token: state.ssoCallback.token,
    trackingId: state.ssoCallback.trackingId,
    firstName: state.ssoCallback.firstName,
    lastName: state.ssoCallback.lastName,
    email: state.ssoCallback.email,
    redirectUrl: state.ssoCallback.redirectUrl,
    ssoWhitelabelDomain: state.ssoCallback.ssoWhitelabelDomain,

    isWhitelabel: !!state.home.agencyConfig?.clientBaseUrl,
    companyName: state.home.agencyConfig?.companyName,
    clientLogo: state.home.agencyConfig?.clientLogo,
});

const mapDispatchToProps = {
    sendSSOCallbackRequest: (payload: SSOCallbackRequestPayload) => ssoCallbackRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(SSOCallback);