import { DateTime } from 'luxon';
import { DatePickerOption } from './types';

export const getCurrentTimeInUTC = () => DateTime.fromJSDate(new Date());

const durationCustomOptions: DatePickerOption[] = [
  {
    displayText: 'Today',
    label: 'today',
    startDate: getCurrentTimeInUTC(),
    endDate: getCurrentTimeInUTC(),
    value: 'today',
    key: 'today',
  },
  {
    displayText: 'Yesterday',
    label: 'yesterday',
    startDate: getCurrentTimeInUTC().minus({ days: 1 }),
    endDate: getCurrentTimeInUTC(),
    value: 'yesterday',
    key: 'yesterday',
  },
  {
    displayText: 'Last 7 Days',
    label: 'last_7_days',
    startDate: getCurrentTimeInUTC().minus({ days: 7 }),
    endDate: getCurrentTimeInUTC(),
    value: 'last_7_days',
    key: 'last_7_days',
  },
  {
    displayText: 'Last 14 Days',
    label: 'last_14_days',
    startDate: getCurrentTimeInUTC().minus({ days: 14 }),
    endDate: getCurrentTimeInUTC(),
    value: 'last_14_days',
    key: 'last_14_days',
  },
  {
    displayText: 'Last 30 Days',
    label: 'last_30_days',
    startDate: getCurrentTimeInUTC().minus({ days: 30 }),
    endDate: getCurrentTimeInUTC(),
    value: 'last_30_days',
    key: 'last_30_days',
  },
  {
    displayText: 'Last 90 Days',
    label: 'last_90_days',
    startDate: getCurrentTimeInUTC().minus({ days: 90 }),
    endDate: getCurrentTimeInUTC(),
    value: 'last_90_days',
    key: 'last_90_days',
  },
  {
    displayText: 'Custom',
    label: 'custom',
    startDate: getCurrentTimeInUTC().minus({ days: 30 }),
    endDate: getCurrentTimeInUTC(),
    value: 'custom',
    key: 'custom',
  },
];

export default durationCustomOptions;
