import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../../../../../shared/types';
import { Field } from '../../../../../settings/types/field';
import {
  getFieldsRequest,
  getPropsectsOverviewRequest,
  sendTestEmailRequest,
} from './extra-actions';
import { ProspectOverview } from './types/request-payload';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  sendTestEmailRequest: RequestState;
  getFieldsRequest: RequestState;
  getPropsectsOverviewRequest: RequestState;
  prospectsoverview: ProspectOverview[];
  fields: Field[];
};

const initialState: State = {
  sendTestEmailRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getFieldsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getPropsectsOverviewRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  prospectsoverview: [],
  fields: [],
};

const emailModal = createSlice({
  name: 'test-email',
  initialState,
  reducers: {
    resetSendTestEmailState: (state) => {
      state.sendTestEmailRequest = initialState.sendTestEmailRequest;
    },
    resetProspectsOverview: (state) => {
      state.prospectsoverview = initialState.prospectsoverview;
    },
  },
  extraReducers: (builder) => {
    // Send test email
    builder.addCase(sendTestEmailRequest.pending, (state: State) => {
      state.sendTestEmailRequest.status = RequestStatus.Pending;
      state.sendTestEmailRequest.error = null;
    });
    builder.addCase(sendTestEmailRequest.fulfilled, (state: State, action) => {
      state.sendTestEmailRequest.status = RequestStatus.Succeeded;
      state.sendTestEmailRequest.message = action.payload.message;
    });
    builder.addCase(sendTestEmailRequest.rejected, (state: State, action) => {
      state.sendTestEmailRequest.status = RequestStatus.Failed;
      state.sendTestEmailRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Merge Tags
    builder.addCase(getFieldsRequest.pending, (state) => {
      state.getFieldsRequest.status = RequestStatus.Pending;
      state.getFieldsRequest.error = null;
    });
    builder.addCase(getFieldsRequest.fulfilled, (state, action) => {
      state.getFieldsRequest.status = RequestStatus.Succeeded;
      state.getFieldsRequest.message = action.payload.message;
      state.fields = action.payload.payload;
    });
    builder.addCase(getFieldsRequest.rejected, (state, action) => {
      state.getFieldsRequest.status = RequestStatus.Failed;
      state.getFieldsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Prospects overview
    builder.addCase(getPropsectsOverviewRequest.pending, (state) => {
      state.getPropsectsOverviewRequest.status = RequestStatus.Pending;
      state.getPropsectsOverviewRequest.error = null;
    });
    builder.addCase(getPropsectsOverviewRequest.fulfilled, (state, action) => {
      state.getPropsectsOverviewRequest.status = RequestStatus.Succeeded;
      state.getPropsectsOverviewRequest.message = action.payload.message;
      state.prospectsoverview = action.payload.payload;
    });
    builder.addCase(getPropsectsOverviewRequest.rejected, (state, action) => {
      state.getPropsectsOverviewRequest.status = RequestStatus.Failed;
      state.getPropsectsOverviewRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetSendTestEmailState,
  resetProspectsOverview,
} = emailModal.actions;
export default emailModal.reducer;
