import {
  GetAccountSubscriptionResponse,
  GetPlansResponse,
  Plan,
} from '../../../../../components/settings/components/billing-subscription/types';
import { BillingOption } from '../../../../utils/billing-options';
import {
  ModifyPlanAction,
  PlanType,
  SubscriptionPlanModes,
  SubscriptionPlans,
} from '../../../../utils/subscription-plans';

export type IProps = {
  plan: Plan;
  planCycle: BillingOption;
  onSelectPlan: (
    planCode: SubscriptionPlans,
    planType: PlanType,
    planName: string,
    planMode: SubscriptionPlanModes,
    action: ModifyPlanAction,
  ) => void;
  sendGetAccountSubscriptionResponse?: GetAccountSubscriptionResponse;
  sendGetLeadFinderSubscriptionResponse?: GetAccountSubscriptionResponse;
  sendGetPlansResponse?: GetPlansResponse;
  sendGetLeadFinderPlansResponse?: GetPlansResponse;
  isLeadFinderPlans?: boolean;
};

export enum SelectPlanCTAText {
  UpgradePlan = 'Upgrade',
  DowngradePlan = 'Downgrade',
  CurrentPlan = 'Current',
  CustomPlan = 'Contact Us',
}

export type GetButtonLabel = {
  plan: Plan;
  planCycle?: BillingOption;
  sendGetAccountSubscriptionResponse?: GetAccountSubscriptionResponse;
  sendGetLeadFinderSubscriptionResponse?: GetAccountSubscriptionResponse;
  sendGetPlansResponse?: GetPlansResponse;
  sendGetLeadFinderPlansResponse?: GetPlansResponse;
};
