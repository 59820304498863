import React from 'react';
import { useTranslation } from 'react-i18next';
import { Images } from '../../../../../../../shared/app-constants';
import ImageIcon from '../../../../../../../shared/components/images/image-icon';
import Modal from '../../../../../../../shared/design-system/components/atoms/modal';
import { IProps } from './types';

const DowngradeWarningModal: React.FC<IProps> = ({
  show,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      backdrop="static"
      className="unsubscribe-link-modal"
      titleContent={
        <div className="d-flex align-items-start">
          <ImageIcon src={Images.AlertTriangleLarge} />
          <div className="pl-3">Attention</div>
        </div>
      }
      onClose={onClose}
      onHide={onClose}
      onSubmit={onSubmit}
      showCloseIcon={false}
      submitButtonText={t('labels.yes')}
      cancelButtonText={t('labels.cancel')}
    >
      <div className="mt-2">
        <p>
          You are currently downgrading to the starter plan in which there is no
          sequence feature included.
        </p>
        <p>
          All your ongoing sequences will get paused once plan is downgraded.
        </p>
        <p>Are you sure you want to downgrade your current plan?</p>
      </div>
    </Modal>
  );
};

export default DowngradeWarningModal;
