import { DiscountType } from '../../../../../../components/settings/components/billing-subscription/types/apply-coupon-code';

export const getTotalAfterDiscount = (
  couponCodeDiscountType,
  totalAmountPrice,
  couponCodeDiscount,
) => {
  let totalAfterDiscount;

  if (couponCodeDiscountType === DiscountType.Percentage) {
    totalAfterDiscount =
      totalAmountPrice - (totalAmountPrice * couponCodeDiscount) / 100;
  } else if (couponCodeDiscountType === DiscountType.Amount) {
    totalAfterDiscount = totalAmountPrice - couponCodeDiscount;
  }

  return totalAfterDiscount;
};
