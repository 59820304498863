/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCircle } from '@saleshandy/icons';
import { IProps } from './type';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';

const SessionExpireModal: React.FC<IProps> = ({
  show,
  onHide,
  onSubmit,
  isLoading,
  submitButtonText,
  showCloseIcon,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      hideHeader={true}
      showCloseIcon={showCloseIcon}
      hideCancelButton={true}
      submitButtonText={submitButtonText}
      onSubmit={onSubmit}
      onHide={onHide}
      isSubmitLoading={isLoading}
      isCancelDisabled={false}
      className="session-expire-modal"
    >
      <div className="session-expire">
        <div className="d-flex align-items-center title">
          <AlertCircle width={24} height={24} className="orange-txt-14" />
          <span className="title-text">Session Expired </span>
        </div>
        <div>
          <span className="modal-content-text">
            Your session has expired. To continue using our services, please log
            in again.
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default SessionExpireModal;
