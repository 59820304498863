import React from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import {
  OverlayTooltip,
  Placement,
} from '../../../../shared/design-system/components/overlay';
import { ProductTour } from '../../../../shared/enums/product-tour';
import { getNavItemIcon } from './get-nav-item-icon';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';

const NavItem = ({
  eventKey,
  route,
  text,
  tooltipText,
  count,
  hasVisitedAgencyPortal,
  hasDomains = false,
}) => {
  const history = useHistory();

  const navItemClassName = classNames([
    'sidenav__item',
    {
      active:
        eventKey !== 'referral'
          ? history.location.pathname.startsWith(route)
          : false,
    },
  ]);

  const badgeClassName = classNames([
    'sidenav__badge',
    {
      sm: count < 10,
      md: count >= 10 && count < 100,
      lg: count >= 100,
    },
  ]);

  const dotBadgeClassName = classNames(['sidenav__dot-badge']);

  const badge = (
    <div className={badgeClassName}>
      <span>{count < 100 ? count : '99+'}</span>
    </div>
  );

  const getNavItemId = () => {
    if (eventKey === 'sequence') {
      return ProductTour.NavigateToSequences;
    }
    if (eventKey === 'leads') {
      return ProductTour.NavigateToLeadFinder;
    }

    return null;
  };

  const dotBadge = <div className={dotBadgeClassName} />;

  const shouldShowBadgeForUnifiedInbox =
    eventKey === 'unifiedInbox' && count > 0;

  const shouldShowBadgeForAgencyPortal =
    eventKey === 'agencyPortal' && !hasVisitedAgencyPortal;

  const navItem = (
    <li id={getNavItemId()} key={eventKey} className={navItemClassName}>
      {eventKey !== 'email-accounts' && (
        <div className="sidenav__icon">
          {getNavItemIcon(eventKey)}
          {shouldShowBadgeForUnifiedInbox && badge}
          {shouldShowBadgeForAgencyPortal && dotBadge}
        </div>
      )}

      <div className="sidenav__text">{text}</div>
      {eventKey === 'email-accounts' && (
        <div>
          <div className="sidenav__icon">
            {getNavItemIcon(eventKey)}
            {shouldShowBadgeForUnifiedInbox && badge}
            {shouldShowBadgeForAgencyPortal && dotBadge}
          </div>
          {hasPermission(Permissions.DOMAIN_READ) && hasDomains && (
            <div className="email-menu-wrapper position-absolute">
              <div className="more-menu-dropdown top-0">
                <Link to="/email-accounts" className="more-menu-dropdown-item">
                  Email Accounts
                </Link>
                <Link to="/domains" className="more-menu-dropdown-item">
                  Domains
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </li>
  );

  if (route === '') {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a className="sidenav__item-wrapper nav-item-container badge-referral">
        {!hasDomains && (
          <OverlayTooltip
            id={eventKey}
            text={tooltipText}
            placement={Placement.Right}
            className="sidenav__item-tooltip"
          >
            <li className="sidenav__item ">
              <div className="sidenav__icon celloClass ">{navItem}</div>
            </li>
          </OverlayTooltip>
        )}
        {hasDomains && <li className="sidenav__item">{navItem}</li>}
      </a>
    );
  }

  return (
    <Link
      className="sidenav__item-wrapper nav-item-container"
      to={route}
      key={eventKey}
    >
      {!hasDomains && (
        <OverlayTooltip
          id={eventKey}
          text={tooltipText}
          placement={Placement.Right}
          className="sidenav__item-tooltip"
        >
          {navItem}
        </OverlayTooltip>
      )}
      {hasDomains && navItem}
    </Link>
  );
};

export default NavItem;
