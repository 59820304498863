import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import Icon from '../../../../../../shared/design-system/components/atoms/icon';
import AllFeaturesListModal from '../../../../../../shared/design-system/components/molecules/all-features-list-modal';
import PricingCard from '../../../../../../shared/design-system/components/molecules/pricing-card';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import { BillingOption } from '../../../../../../shared/utils/billing-options';
import { IProps } from './types';
import { getPlanMode, getPlanData } from './utils/helper';
import { supportUrls } from '../../../../../../shared/utils/urls';
import { isColdEmailPlanBannerShow } from '../modify-subscription/utils/helper';
import { isLeadFinderColdEmailPlanBannerShow } from '../modify-lead-finder-subscription/utils/helper';

const SelectPlan: React.FC<IProps> = ({
  planCycle,
  onSelectPlan,
  sendGetAccountSubscriptionResponse,
  sendGetLeadFinderSubscriptionResponse,
  sendGetPlansResponse,
  sendGetLeadFinderPlansResponse,
  isLeadFinderPlans = false,
}) => {
  const { t } = useTranslation();

  const [
    isAllFeaturesTableVisible,
    setIsAllFeaturesTableVisible,
  ] = useState<boolean>(false);
  const [planData, setPlanData] = useState([]);

  const showAllFeaturesTable = () => setIsAllFeaturesTableVisible(true);

  const hideAllFeaturesTable = () => setIsAllFeaturesTableVisible(false);

  useEffect(() => {
    if (sendGetLeadFinderPlansResponse) {
      setPlanData(
        getPlanData(
          getPlanMode(planCycle),
          sendGetLeadFinderPlansResponse,
          sendGetLeadFinderSubscriptionResponse?.planCode,
        ),
      );
    }
    if (sendGetPlansResponse) {
      setPlanData(
        getPlanData(
          getPlanMode(planCycle),
          sendGetPlansResponse,
          sendGetAccountSubscriptionResponse?.planCode,
        ),
      );
    }
  }, [planCycle]);

  return (
    <>
      <div className="modify-subscription__select-plan">
        <div className="d-flex justify-content-between modify-subscription__select-plan-container">
          {planData?.map((plan) => (
            <PricingCard
              key={plan.id}
              plan={plan}
              planCycle={planCycle}
              onSelectPlan={onSelectPlan}
              sendGetAccountSubscriptionResponse={
                sendGetAccountSubscriptionResponse
              }
              sendGetLeadFinderSubscriptionResponse={
                sendGetLeadFinderSubscriptionResponse
              }
              sendGetLeadFinderPlansResponse={sendGetLeadFinderPlansResponse}
              isLeadFinderPlans={isLeadFinderPlans}
            />
          ))}
        </div>
        {planCycle !== BillingOption.LIFETIME && (
          <>
            <div className="d-flex justify-content-center align-items-center mt-4 pointer">
              <span
                className="regular-2 blue-txt-11"
                {...accessibleOnClick(showAllFeaturesTable)}
              >
                <Icon identifier="external" className="mr-1" />
                {t('labels.see_all_features')}
              </span>
            </div>
            {(!isColdEmailPlanBannerShow(
              sendGetAccountSubscriptionResponse?.planType,
            ) ||
              !isLeadFinderColdEmailPlanBannerShow(
                sendGetLeadFinderSubscriptionResponse?.planType,
              )) && (
              <div className="d-flex justify-content-between align-items-center mt-4 modify-subscription__select-plan-banner">
                <span className="regular-1 popover-arrow-color-txt">
                  <span role="img" aria-label="emoji" className="mr-1">
                    🤔
                  </span>
                  Want to discuss the custom pricing that suits you?
                </span>
                <Button
                  variant={Button.Variant.Primary}
                  theme={Button.Theme.Ghost}
                  className="ghost-outline-new"
                  onClick={() =>
                    window.open(supportUrls.scheduleCall, '_blank')
                  }
                >
                  Schedule a call with us
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <AllFeaturesListModal
        show={isAllFeaturesTableVisible}
        onClose={hideAllFeaturesTable}
      />
    </>
  );
};

export default SelectPlan;
