import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronRight } from '@saleshandy/icons';

import { RootState } from '../../../../../store/root-reducer';
import { accessibleOnClick } from '../../../../../shared/utils/accessible-on-click';

import GoingBackToEmailAccountsModal from '../../../modals/going-back-to-email-accounts-modal';
import EmailInfrastructureServiceProvider from '../../create-email-infrastructure/components/email-infrastructure-service-provider';

import { removeAllNewEmailAccount } from '../../../email-accounts-slice';

const AddEmailAccountsHeader: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showClearAllCartModal, setShowClearAllCartModal] = useState(false);

  const selectedDomain = useSelector(
    (state: RootState) => state.emailAccount.selectedDomain,
  );
  const newEmailAccountsToAdd = useSelector(
    (state: RootState) => state.emailAccount.newEmailAccountsToAdd,
  );

  const onShowClearAllCartModal = () => {
    setShowClearAllCartModal(true);
  };

  const onCloseClearAllCartModal = () => {
    setShowClearAllCartModal(false);
  };

  const handleOnSubmitClick = () => {
    dispatch(removeAllNewEmailAccount());
    history.push('/domains');
    onCloseClearAllCartModal();
  };

  const onTitleClick = () => {
    if (newEmailAccountsToAdd?.length > 0) {
      onShowClearAllCartModal();
    } else {
      handleOnSubmitClick();
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="create-email-infrastructure-header d-flex align-items-center">
          <p
            className="create-email-infrastructure-header--title"
            {...accessibleOnClick(onTitleClick)}
          >
            {selectedDomain?.name || 'Email Accounts'}
          </p>
          <div className="create-email-infrastructure-header--icon d-flex justify-content-center align-items-center">
            <ChevronRight />
          </div>
          <p className="create-email-infrastructure-header--title">
            Add Email Accounts
          </p>
          <div className="create-email-infrastructure-header--icon d-flex justify-content-center align-items-center">
            <EmailInfrastructureServiceProvider
              esp={selectedDomain?.order?.emailServiceProvider}
            />
          </div>
        </div>
      </div>

      <GoingBackToEmailAccountsModal
        show={showClearAllCartModal}
        onSubmit={handleOnSubmitClick}
        onClose={onCloseClearAllCartModal}
        onlyEmailAccounts={true}
      />
    </>
  );
};

export default AddEmailAccountsHeader;
