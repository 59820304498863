import {
  ContactVerificationStatus,
  ZeroBounceSubStatusSaleshandy,
} from '../../components/sequence/enums';

import { capitalize } from './string';

type VerificationSubStatusMapping = {
  verificationStatus: ContactVerificationStatus;
  verificationSubStatuses: ZeroBounceSubStatusSaleshandy[];
};

export const getVerificationSubStatusMapping = (): VerificationSubStatusMapping[] => [
  {
    verificationStatus: ContactVerificationStatus.Valid,
    verificationSubStatuses: [
      ZeroBounceSubStatusSaleshandy.ALIAS_EMAIL,
      ZeroBounceSubStatusSaleshandy.CORRECTLY_FORMATTED_EMAIL,
      ZeroBounceSubStatusSaleshandy.LIKELY_SECONDARY_EMAIL,
    ],
  },
  {
    verificationStatus: ContactVerificationStatus.Risky,
    verificationSubStatuses: [
      ZeroBounceSubStatusSaleshandy.CATCH_ALL,
      ZeroBounceSubStatusSaleshandy.UNKNOWN_NOT_VALIDATED,
      ZeroBounceSubStatusSaleshandy.UNKNOWN_WHILE_VALIDATING_ERROR,
      ZeroBounceSubStatusSaleshandy.UNKNOWN_FAILED_SMTP_CONNECTION,
      ZeroBounceSubStatusSaleshandy.UNKNOWN_SERVER_DISCONNECTED_WHILE_VALIDATING,
      ZeroBounceSubStatusSaleshandy.UNKNOWN_NOT_VALIDATED_TEMPORARILY,
      ZeroBounceSubStatusSaleshandy.UNKNOWN_SERVER_UNRESPONSIVE,
      ZeroBounceSubStatusSaleshandy.UNKNOWN_TEMPORARY_SERVER_ERROR,
      ZeroBounceSubStatusSaleshandy.UNKNOWN_TIMEOUT,
      ZeroBounceSubStatusSaleshandy.DO_NOT_MAIL_GLOBALLY_BLACKLISTED,
      ZeroBounceSubStatusSaleshandy.DO_NOT_MAIL_BOT_CREATED_EMAIL_ACCOUNTS,
      ZeroBounceSubStatusSaleshandy.DO_NOT_MAIL_POSSIBLE_SPAM_TRAP,
      ZeroBounceSubStatusSaleshandy.DO_NOT_MAIL_ROLE_BASED_EMAILS,
      ZeroBounceSubStatusSaleshandy.DO_NOT_MAIL_TEMPORARY_EMAIL_ACCOUNTS,
      ZeroBounceSubStatusSaleshandy.DO_NOT_MAIL_ROLE,
      ZeroBounceSubStatusSaleshandy.DO_NOT_MAIL_MX_FORWARDED,
    ],
  },
  {
    verificationStatus: ContactVerificationStatus.Bad,
    verificationSubStatuses: [
      ZeroBounceSubStatusSaleshandy.ABUSE,
      ZeroBounceSubStatusSaleshandy.INVALID_DOES_NOT_ACCEPT_EMAIL,
      ZeroBounceSubStatusSaleshandy.INVALID_FAILED_SYNTAX,
      ZeroBounceSubStatusSaleshandy.INVALID_POSSIBLE_TYPO,
      ZeroBounceSubStatusSaleshandy.INVALID_MAILBOX_NOT_FOUND,
      ZeroBounceSubStatusSaleshandy.INVALID_NO_DNS_RECORDS,
      ZeroBounceSubStatusSaleshandy.INVALID_MAILBOX_FULL,
      ZeroBounceSubStatusSaleshandy.INVALID_UNROUTABLE_IP_ADDRESS,
      ZeroBounceSubStatusSaleshandy.SPAMTRAP,
    ],
  },
];

/**
 * Converts the response from `getVerificationSubStatusMapping` to `MultiLevelSelectOption[]`.
 * @returns {MultiLevelSelectOption[]} - Array of MultiLevelSelectOption.
 */
export const generateMultiLevelSelectOptions = () => {
  const verificationSubStatusMapping = getVerificationSubStatusMapping();

  return verificationSubStatusMapping.map((mapping) => ({
    key: mapping.verificationStatus,
    value: capitalize(mapping.verificationStatus),
    items: mapping.verificationSubStatuses.map((subStatus) => ({
      key: subStatus,
      value: subStatus,
    })),
  }));
};

// Returns the verification status from the sub status
// params: subStatus: string
export const getVerificationStatusFromSubStatus = (
  subStatus: string,
): ContactVerificationStatus | undefined => {
  const verificationSubStatusMapping = getVerificationSubStatusMapping();

  return verificationSubStatusMapping.find((mapping) =>
    mapping.verificationSubStatuses.includes(
      subStatus as ZeroBounceSubStatusSaleshandy,
    ),
  )?.verificationStatus;
};
