import React, { useMemo } from 'react';
import { Avatar, SkeletonLoading } from '@saleshandy/design-system';
import { ArrowBackUp, Trash } from '@saleshandy/icons';
import moment from 'moment-timezone';

import { Domain, DomainsFilters } from '../types';
import { DomainAction, DomainStatus } from '../enums';

import DomainsMailboxesPopover from './domains-mailboxes-popover';

import Table from '../../../shared/design-system/components/organisms/table';
import {
  Action,
  Column,
  PaginationShowHide,
} from '../../../shared/design-system/components/organisms/table/types';
import EmailInfrastructureServiceProvider from '../../email-accounts/components/create-email-infrastructure/components/email-infrastructure-service-provider';

type DomainsTableProps = {
  domains: Domain[];
  domainsFilters: DomainsFilters;
  onDomainsFiltersChange: (filters: Partial<DomainsFilters>) => void;
  onAction: (key: string, row: Domain) => void;

  isLoading: boolean;
  onAddMoreEmailAccountClicked: (domain: Domain) => void;
};

const DomainsTable: React.FC<DomainsTableProps> = ({
  domains,
  domainsFilters,
  onDomainsFiltersChange,
  onAction,
  isLoading,
  onAddMoreEmailAccountClicked,
}) => {
  const columns: Column[] = useMemo(
    () => [
      {
        dataField: 'name',
        text: 'Domains',
        component: (_c, row: Domain) => (
          <span className="table-cell-text domain-name">
            <span className="domain-esp">
              <EmailInfrastructureServiceProvider
                esp={row.order?.emailServiceProvider}
              />
            </span>
            <span>{row.name}</span>
          </span>
        ),
        headerLoadingSkeleton: <SkeletonLoading width={80} height={16} />,
        cellLoadingSkeleton: <SkeletonLoading width={120} height={20} />,
      },
      {
        dataField: 'redirectDomain',
        text: 'Forwarded Domain',
        component: (_c, row: Domain) => (
          <span className="table-cell-text">{row.redirectDomain}</span>
        ),
        headerLoadingSkeleton: <SkeletonLoading width={120} height={16} />,
        cellLoadingSkeleton: <SkeletonLoading width={120} height={20} />,
      },

      {
        dataField: 'mailboxes',
        text: 'Email Accounts',
        component: (_c, row: Domain) => (
          <DomainsMailboxesPopover
            row={row}
            onAddMoreEmailAccountClicked={onAddMoreEmailAccountClicked}
          />
        ),
        headerLoadingSkeleton: <SkeletonLoading width={100} height={16} />,
        cellLoadingSkeleton: <SkeletonLoading width={120} height={20} />,
      },

      {
        dataField: 'createdAt',
        text: 'Created Date',
        component: (_c, row: Domain) => (
          <span className="table-cell-text">
            {moment(row.createdAt)?.format('DD MMM, YYYY') ?? '-'}
          </span>
        ),
        headerLoadingSkeleton: <SkeletonLoading width={100} height={16} />,
        cellLoadingSkeleton: <SkeletonLoading width={120} height={20} />,
      },

      {
        dataField: 'createdBy',
        text: 'Created by',
        component: (_c, row: Domain) => {
          const [firstName, lastName] = row.createdBy.split(' ') || ['', ''];

          return (
            <div className="d-flex align-items-center gap-8">
              <Avatar firstName={firstName} lastName={lastName} />
              <span className="table-cell-text">{row.createdBy}</span>
            </div>
          );
        },
        headerLoadingSkeleton: <SkeletonLoading width={100} height={16} />,
        cellLoadingSkeleton: <SkeletonLoading width={120} height={20} />,
      },
    ],
    [],
  );

  const generateActionsColumn = (row: Domain): Action[] => {
    if (row.status === DomainStatus.Deleted) {
      return [
        {
          key: DomainAction.REVOKE,
          displayName: 'Revoke Cancellation',
          iconElement: <ArrowBackUp />,
          position: 'out',
        },
      ];
    }

    return [
      {
        key: DomainAction.DELETE,
        displayName: 'Delete',
        iconElement: <Trash />,
        position: 'out',
        disabled: row.status !== DomainStatus.Active,
      },
    ];
  };

  const onPaginationOptionsChange = ({ page, limit }) => {
    if (limit && limit !== domainsFilters.pageSize) {
      onDomainsFiltersChange({ page: 1, pageSize: limit });
      return;
    }

    if (page && page !== domainsFilters.page) {
      onDomainsFiltersChange({ page });
    }
  };

  const revokeDomainClasses = (row) => {
    if (row.status === DomainStatus.Deleted) {
      return 'disable-row';
    }

    return '';
  };

  return (
    <div className="domains-table-container">
      <Table
        columns={columns}
        data={domains}
        actionColumnName="Action"
        actions={(_c, row) => generateActionsColumn(row)}
        onAction={onAction}
        paginationOptions={{
          options: {
            page: domainsFilters.page,
            limit: domainsFilters.pageSize,
            totalElements: domainsFilters.total,
          },
        }}
        onPaginationOptionsChange={onPaginationOptionsChange}
        pagination={PaginationShowHide.SHOW}
        isNewPagination={true}
        headerVisibleForGenerateColumn={true}
        isLoading={isLoading}
        rowClasses={revokeDomainClasses}
      />
    </div>
  );
};

export default DomainsTable;
