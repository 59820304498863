// Priority mappings for different plan attributes
const planTypePriority = {
  email: 1,
  'email-phone': 2,
};

const planLevelPriority = {
  starter: 1,
  pro: 2,
  scale: 3,
  'scale-plus': 4,
};

const creditCountPriority = {
  '10k': 1,
  '20k': 2,
  '50k': 3,
  '100k': 4,
};

const planDurationPriority = {
  monthly: 1,
  yearly: 2,
};

// Function to parse the plan code and extract its components
export const getParsedPlanCode = (planCode: string) => {
  const parts = planCode.split('-');

  const plan = {
    type: '',
    level: '',
    duration: '',
    subscriberCount: '',
    date: '',
  };

  // Determine the plan type (email-only or email-phone)
  if (parts.includes('email') && parts.includes('phone')) {
    plan.type = 'email-phone';
  } else if (parts.includes('email')) {
    plan.type = 'email';
  }

  // Identify the level (starter, pro, scale, scale-plus)
  if (parts.includes('starter')) {
    plan.level = 'starter';
  } else if (parts.includes('pro')) {
    plan.level = 'pro';
  } else if (parts.includes('scale')) {
    plan.level = 'scale';
    if (parts.includes('plus')) {
      plan.level = 'scale-plus';
      const subscriberIdx = parts.findIndex((part) => part === 'plus') + 1;
      plan.subscriberCount = parts[subscriberIdx];
    }
  }

  // Determine the plan duration (monthly or yearly)
  if (parts.includes('monthly')) {
    plan.duration = 'monthly';
  } else if (parts.includes('yearly')) {
    plan.duration = 'yearly';
  }

  // Capture any date information (e.g., '09-2024')
  const datePart = parts.find((part) => /^\d{2}-\d{4}$/.test(part));
  if (datePart) {
    plan.date = datePart;
  }

  return plan;
};

// Function to calculate the priority of a given plan
export const calculatePlanPriority = (planCode) => {
  const { type, level, subscriberCount, duration } = getParsedPlanCode(
    planCode,
  );

  let priority = 0;

  // Add type priority (email-only or email-phone)
  priority += planTypePriority[type] || 0;

  // Add level priority (starter, pro, scale, scale-plus)
  priority += (planLevelPriority[level] || 0) * 10;

  // If the level is 'scale-plus', add subscriber count priority
  if (level === 'scale-plus') {
    priority += (creditCountPriority[subscriberCount] || 0) * 100;
  }

  // Add duration priority (monthly or yearly)
  priority += (planDurationPriority[duration] || 0) * 1000;

  return priority;
};
