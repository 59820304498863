import React, { useEffect, useRef, useState } from 'react';
import { Button, SkeletonLoading } from '@saleshandy/design-system';
import { Download } from '@saleshandy/icons';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { UserAndTeamsTabs } from '../../../enums/users-and-teams';
import SearchInput from '../../../../../shared/design-system/ui/search-input';
import { UsersFilter } from '../../../types/users-and-teams';
import hasPermission from '../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../shared/utils/access-control/enums/permissions';
import PremiumFeatureIndicator from '../../../../../shared/components/premium-feature-indicator';
import {
  OverlayPopover,
  OverlayTooltip,
  Placement,
} from '../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../shared/utils/accessible-on-click';
import DateFilter from '../../../../../shared/components/date-filter';
import { DatePickerOption } from '../../../../../shared/components/date-filter/types';
import durationCustomOptions from '../../../../../shared/components/date-filter/date-filter-custom-option';
import InputLabel from '../../../../../shared/design-system/components/input/input-label';

type IProps = {
  activeTab: UserAndTeamsTabs;
  onSearch: VoidFunction;
  filters: UsersFilter;
  onSearchChange: (search: string) => void;
  onClearSearch: VoidFunction;
  showInviteUserModal: VoidFunction;
  showAddTeamModal: VoidFunction;
  isInviteUserModalBtnLoading: boolean;
  isLoading: boolean;
  exportUsers: VoidFunction;
  dateFilters: DatePickerOption;
  onDateFilterChange: (filter: DatePickerOption) => void;
  timeZone: string;
};

const UsersAndTeamsExtraContent: React.FC<IProps> = ({
  activeTab,
  filters,
  onSearch,
  onSearchChange,
  onClearSearch,
  showAddTeamModal,
  showInviteUserModal,
  isInviteUserModalBtnLoading,
  isLoading,
  exportUsers,
  dateFilters,
  onDateFilterChange,
  timeZone,
}) => {
  const [isDateFilterModal, setIsDateFilterModal] = useState<boolean>(false);

  const overlayRef = useRef(null);

  const handleClickOutside = (event) => {
    const { target } = event;

    if (overlayRef?.current?.contains(target)) return;

    const popoverEle = document.querySelector(
      '.download-usage-report__popover',
    );
    if (popoverEle?.contains(target)) return;

    const dateFilterDropdownEle = document.querySelector('.date-filter-select');
    if (dateFilterDropdownEle?.contains(target)) {
      return;
    }

    setIsDateFilterModal(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const onDateTimeSelectionChange = (
    label: string,
    startDate: DateTime,
    endDate: DateTime,
    displayText: string,
  ) => {
    const optionObject = {
      label: label || 'today',
      startDate: startDate.setZone(timeZone) || DateTime.now(),
      endDate: endDate.setZone(timeZone) || DateTime.now(),
      displayText: displayText || 'Today',
      value: label || 'Today',
      key: label || 'Today',
    };
    onDateFilterChange(optionObject);
  };
  const exportUsersAndCloseModal = () => {
    const defaultOption = {
      label: 'today',
      startDate: DateTime.now().setZone(timeZone),
      endDate: DateTime.now().setZone(timeZone),
      displayText: 'Today',
      value: 'today',
      key: 'today',
    };

    onDateFilterChange(defaultOption);
    exportUsers();
    setIsDateFilterModal(false);
  };

  return (
    <>
      <div className="users-and-teams--actions-wrapper">
        {isLoading && activeTab === UserAndTeamsTabs.Users && (
          <SkeletonLoading width={200} height={32} />
        )}

        {isLoading && <SkeletonLoading width={100} height={32} />}

        {!isLoading && activeTab === UserAndTeamsTabs.Users && (
          <>
            <div className="cursor-pointer" ref={overlayRef}>
              <OverlayPopover
                className="download-usage-report__popover"
                show={isDateFilterModal}
                placement={Placement.Bottom}
                content={
                  <div className="download-usage-report__content">
                    <div className="download-usage-report__date-selector">
                      <InputLabel
                        text="Select time period"
                        place={InputLabel.Place.Before}
                      />
                      <DateFilter
                        selectedDateRangeOption={dateFilters?.label}
                        startDate={dateFilters?.startDate}
                        endDate={dateFilters?.endDate}
                        displayText={dateFilters?.displayText}
                        shouldShowDateTooltip={true}
                        shouldHaveMaxValueLimit={true}
                        shouldHaveMinMaxValueLimit={false}
                        onDateTimeSelectionChange={onDateTimeSelectionChange}
                        durationOptions={durationCustomOptions}
                      />
                    </div>
                    <div className="download-usage-report__footer">
                      <Button {...accessibleOnClick(exportUsersAndCloseModal)}>
                        Export
                      </Button>
                    </div>
                  </div>
                }
              >
                <OverlayTooltip
                  text="Download Session Log"
                  placement={Placement.Bottom}
                >
                  <div
                    className={classNames('download-usage-report__trigger', {
                      'is-active': isDateFilterModal,
                    })}
                    {...accessibleOnClick(() =>
                      setIsDateFilterModal((prev) => !prev),
                    )}
                  >
                    <Download />
                  </div>
                </OverlayTooltip>
              </OverlayPopover>
            </div>

            <SearchInput
              onSearch={onSearch}
              searchValue={filters.search}
              setSearchValue={onSearchChange}
              onClearSearch={onClearSearch}
              searchIconPlace={IconPlace.Left}
              className="users-and-teams--search"
              placeholder="Search..."
              initialValue={filters.search}
            />

            <PremiumFeatureIndicator />

            <Button
              onClick={showInviteUserModal}
              isLoading={isInviteUserModalBtnLoading}
              disabled={isInviteUserModalBtnLoading}
            >
              Invite Users
            </Button>
          </>
        )}

        {!isLoading && activeTab === UserAndTeamsTabs.Teams && (
          <>
            <Button onClick={showAddTeamModal}>Create Team</Button>
          </>
        )}
      </div>
    </>
  );
};

export default UsersAndTeamsExtraContent;
