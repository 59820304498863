import React from 'react';
import { Button } from '@saleshandy/design-system';
import { Cross } from '@saleshandy/icons';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import AlertTriangle from '../../../../components/images/alert-triangle';
import { avoidBannerRoutes } from '../../../../utils/avoid-banner-alert';

export enum BannerVariant {
  Danger = 'danger',
  Warning = 'warning',
  Primary = 'primary',
}

export enum PaymentActionType {
  Initial = 'initial',
  Recurring = 'recurring',
}

type IProps = {
  pageHeaderBannerMessage?: React.ReactNode;
  ctaURL?: string;
  ctaText?: string;
  target?: string;
  style?: React.CSSProperties;
  className?: string;
  variant?: BannerVariant;
  Icon?: React.ReactNode;
  children?: React.ReactChild;
  bannerTextOne?: string;
  bannerTextTwo?: string;
  paymentActionType?: string;
  ctaBtnText?: string;
  ctaBtnClick?: VoidFunction;
  ctaSecondaryBtnText?: string;
  ctaSecondaryBtnClick?: VoidFunction;
  isIconShow?: boolean;
  onBannerClose?: VoidFunction;
  closeBtnClassName?: string;
};

const Banner: React.FC<IProps> = ({
  pageHeaderBannerMessage,
  style,
  className,
  ctaURL,
  ctaText,
  target,
  variant,
  Icon,
  children,
  bannerTextTwo,
  paymentActionType,
  ctaBtnText,
  isIconShow = true,
  ctaSecondaryBtnText,
  ctaSecondaryBtnClick,
  ctaBtnClick,
  onBannerClose,
  closeBtnClassName = '',
}) => {
  const location = useLocation();

  if (avoidBannerRoutes.indexOf(location.pathname) !== -1) {
    return null;
  }

  const bannerClasses = classNames([
    `fade danger-alert alert show ${className}`,
    {
      'alert-danger': variant === BannerVariant.Danger,
      'alert-warning-2': variant === BannerVariant.Warning,
      'alert-primary-2': variant === BannerVariant.Primary,
    },
  ]);

  if (!pageHeaderBannerMessage) {
    return <></>;
  }

  const renderBanner = () => {
    if (pageHeaderBannerMessage && !bannerTextTwo) {
      return (
        <span className="alert-message-text">{pageHeaderBannerMessage}</span>
      );
    }

    return (
      <>
        <span className="alert-message-text">{pageHeaderBannerMessage}</span>{' '}
        <a
          className="upgrade-link"
          href={ctaURL}
          target="_blank"
          rel="noreferrer"
        >
          {ctaText}
        </a>{' '}
        <span className="alert-message-text">{`${bannerTextTwo} ${
          paymentActionType === PaymentActionType.Recurring
            ? process.env.REACT_APP_PAY_BEFORE
            : ''
        }`}</span>
      </>
    );
  };

  return (
    <div role="alert" className={bannerClasses} style={style} data-banner>
      <div className="d-flex align-items-center">
        {isIconShow && <>{Icon || <AlertTriangle />}</>}

        {children || renderBanner()}

        {target && !bannerTextTwo && (
          <a className="upgrade-link" href={ctaURL} target={target}>
            {ctaText}
          </a>
        )}
        {!bannerTextTwo && (
          <Link className="upgrade-link" to={ctaURL}>
            {ctaText}
          </Link>
        )}
        {ctaBtnText && (
          <>
            <span className="mx-2">or</span>
            <Button
              variant="link"
              className="upgrade-link ml-0 p-0"
              onClick={ctaBtnClick}
            >
              {ctaBtnText}
            </Button>
          </>
        )}
        {ctaSecondaryBtnText && ctaSecondaryBtnClick && (
          <Button
            variant="primary"
            className="upgrade-btn ml-0 p-0"
            onClick={ctaSecondaryBtnClick}
          >
            {ctaSecondaryBtnText}
          </Button>
        )}
      </div>
      {onBannerClose && (
        <Cross
          role="button"
          className={`gray-txt-15 d-flex justify-content-end pointer ${closeBtnClassName}`}
          onClick={onBannerClose}
        />
      )}
    </div>
  );
};

Banner.defaultProps = {
  variant: BannerVariant.Danger,
};

export default Banner;
