import { FeaturesList } from '../types';

export const getAllFeaturesList = (): FeaturesList[] => [
  {
    id: 1,
    title: 'Sequence & Automation',
    features: [
      {
        featureId: 1,
        featureName: 'Unlimited Prospects & Steps',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
          helperText: '(Unlimited Prospects)',
        },
      },
      {
        featureId: 2,
        featureName: 'Email Delivery Schedules',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 3,
        featureName: 'Auto-reply Detection',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 4,
        featureName: 'Gmail, Outlook',
        emailWarmupFeatureMeta: {
          isAvailable: true,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 5,
        featureName: 'SMTP & IMAP',
        emailWarmupFeatureMeta: {
          isAvailable: true,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 6,
        featureName: 'Prospect Engagement tracking: Open, Click & Reply',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 7,
        featureName: 'Sequence step A/B Testing',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
    ],
  },
  {
    id: 2,
    title: 'Deliverability / Email Safety',
    features: [
      {
        featureId: 1,
        featureName: 'Real Inbox Email Deliverability Tests',
        emailWarmupFeatureMeta: {
          isComingSoon: true,
        },
        emailOutreachFeatureMeta: {
          isComingSoon: true,
        },
      },
      {
        featureId: 2,
        featureName: 'Ramp-up (Auto Increase Daily Email Sending)',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
    ],
  },
  {
    id: 3,
    title: 'Email Warmup',
    features: [
      {
        featureId: 1,
        featureName: 'Warmup with default settings (restricted usage)',
        emailWarmupFeatureMeta: {
          isAvailable: true,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 2,
        featureName: 'Warmup with customized settings',
        emailWarmupFeatureMeta: {
          isAvailable: true,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 3,
        featureName: 'Warmup reports',
        emailWarmupFeatureMeta: {
          isAvailable: true,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
    ],
  },
  {
    id: 4,
    title: 'Prospect Management',
    features: [
      {
        featureId: 1,
        featureName: 'Tags',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 2,
        featureName: 'Unlimited Custom Fields',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 3,
        featureName: 'CSV Import',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 3,
        featureName: 'Custom Tracking Domain',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 4,
        featureName: 'Blacklist Domains',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
    ],
  },
  {
    id: 5,
    title: 'Chrome Extension',
    features: [
      {
        featureId: 1,
        featureName: 'Track emails from Gmail/Outlook (Chrome)',
        emailWarmupFeatureMeta: {
          isAvailable: true,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 2,
        featureName: 'Find email address from linkedin',
        emailWarmupFeatureMeta: {
          isAvailable: true,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 3,
        featureName: 'Add prospect from anywhere',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
    ],
  },
  {
    id: 6,
    title: 'Reports',
    features: [
      {
        featureId: 1,
        featureName: 'Stepwise reports',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 2,
        featureName: 'A/B test Reports',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 3,
        featureName: 'Cadence Reports',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 4,
        featureName: 'Template Reports',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 5,
        featureName: 'Prospect Activity History',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 6,
        featureName: 'Team Reports',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 7,
        featureName: 'Warmup Reports',
        emailWarmupFeatureMeta: {
          isAvailable: true,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
    ],
  },
  {
    id: 7,
    title: 'Integrations',
    features: [
      {
        featureId: 1,
        featureName: 'Public API',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 2,
        featureName: 'Zapier Integration',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isComingSoon: true,
        },
      },
      {
        featureId: 3,
        featureName: 'CRM Integration',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isComingSoon: true,
        },
      },
    ],
  },
  {
    id: 8,
    title: 'Support',
    features: [
      {
        featureId: 1,
        featureName: 'Help center of 100+ articles',
        emailWarmupFeatureMeta: {
          isAvailable: true,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 2,
        featureName: 'Email support',
        emailWarmupFeatureMeta: {
          isAvailable: true,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 3,
        featureName: 'Real-time support through web conference',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 4,
        featureName: 'Demos & Onboarding',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 5,
        featureName: '1:1 User Success Call',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 6,
        featureName: 'Dedicated Success Manager',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
    ],
  },
  {
    id: 9,
    title: 'Teams',
    features: [
      {
        featureId: 1,
        featureName: 'Shared Templates & Cadences',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isAvailable: true,
        },
      },
      {
        featureId: 2,
        featureName: 'Admin Controls',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isComingSoon: true,
        },
      },
      {
        featureId: 3,
        featureName: 'IP-based Login Restrictions',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isComingSoon: true,
        },
      },
      {
        featureId: 4,
        featureName: 'Advanced Team Management',
        emailWarmupFeatureMeta: {
          isAvailable: false,
        },
        emailOutreachFeatureMeta: {
          isComingSoon: true,
        },
      },
    ],
  },
];
