import React from 'react';
import classnames from 'classnames';
import { IProps } from './types';
import { ModifySubscriptionSteps } from '../../types';
import Icon from '../../../../../../../../shared/design-system/components/atoms/icon';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';

const ModifySubscriptionProgressBar: React.FC<IProps> = ({
  modifySubscriptionStep,
  onStepChange,
  disabledStep,
}) => {
  const selectPlanStepClassName = classnames([
    {
      'active-step':
        modifySubscriptionStep === ModifySubscriptionSteps.SelectPlan,
    },
    {
      'done-step':
        modifySubscriptionStep === ModifySubscriptionSteps.SelectSlots,
    },
    {
      'cursor-not-allowed': disabledStep === ModifySubscriptionSteps.SelectPlan,
    },
  ]);
  const selectSlotsStepClassName = classnames([
    {
      'active-step':
        modifySubscriptionStep === ModifySubscriptionSteps.SelectSlots,
    },
    {
      'disable-step':
        modifySubscriptionStep === ModifySubscriptionSteps.SelectPlan ||
        disabledStep === ModifySubscriptionSteps.SelectSlots,
    },
    {
      'cursor-not-allowed':
        disabledStep === ModifySubscriptionSteps.SelectSlots,
    },
  ]);

  const handleStepChange = (step) => {
    if (
      step === ModifySubscriptionSteps.SelectPlan &&
      disabledStep !== ModifySubscriptionSteps.SelectPlan
    ) {
      onStepChange(step);
      return;
    }
    if (
      step === ModifySubscriptionSteps.SelectSlots &&
      disabledStep !== ModifySubscriptionSteps.SelectSlots
    ) {
      onStepChange(step);
    }
  };

  return (
    <div className="modify-subscription__progress-bar-container d-flex justify-content-between">
      <div
        className={`${selectPlanStepClassName} d-flex flex-column justify-content-center align-items-center`}
        {...accessibleOnClick(() =>
          handleStepChange(ModifySubscriptionSteps.SelectPlan),
        )}
      >
        <div className="step-icon d-flex justify-content-center align-items-center">
          <Icon identifier="file-invoice" />
        </div>
        <span className="semibold-1 font-10 popover-arrow-color-txt">
          Choose your plan
        </span>
      </div>
      <div className="modify-subscription__divider" />
      <div
        className={`${selectSlotsStepClassName} d-flex flex-column align-items-center`}
        {...accessibleOnClick(() =>
          handleStepChange(ModifySubscriptionSteps.SelectSlots),
        )}
      >
        <div className="step-icon d-flex justify-content-center align-items-center">
          <Icon identifier="wand" />
        </div>
        <span className="semibold-1 font-10 popover-arrow-color-txt">
          Select Slots
        </span>
      </div>
    </div>
  );
};

export default ModifySubscriptionProgressBar;
