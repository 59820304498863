import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import ImageIcon from '../../../../components/images/image-icon';
import Button from '../../atoms/button';
import Icon from '../../atoms/icon/icon';
import { IProps, SelectPlanCTAText } from './types';
import {
  ModifyPlanAction,
  SubscriptionPlans,
} from '../../../../utils/subscription-plans';
import { BillingOption } from '../../../../utils/billing-options';
import { getPlanMetaData } from '../../../../../components/settings/components/billing-subscription/components/select-plan/utils/get-plan-meta-data';
import { getButtonLabel, getLeadFinderButtonLabel } from './helpers';
import { getPlanName } from '../../../../../components/settings/components/billing-subscription/components/modify-subscription/utils/helper';

const PricingCard: React.FC<IProps> = ({
  plan,
  planCycle,
  onSelectPlan,
  sendGetAccountSubscriptionResponse,
  sendGetLeadFinderSubscriptionResponse,
  isLeadFinderPlans = false,
  sendGetPlansResponse,
}) => {
  const { t } = useTranslation();

  const [btnDisabled, setBtnDisabled] = useState(false);

  const {
    displayName,
    headerImage,
    isBestValuePlan,
    description,
    features,
    price,
  } = getPlanMetaData(plan?.planCode, planCycle, plan?.planName) || {};

  const btnLabel = isLeadFinderPlans
    ? getLeadFinderButtonLabel({
        plan,
        planCycle,
        sendGetLeadFinderSubscriptionResponse,
      })
    : getButtonLabel({
        plan,
        planCycle,
        sendGetAccountSubscriptionResponse,
        sendGetPlansResponse,
      });

  const setButtonDisabled = () => {
    if (plan?.planCode === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME) {
      setBtnDisabled(true);
    }
  };

  const getAction = () => {
    if (btnLabel === SelectPlanCTAText.UpgradePlan) {
      return ModifyPlanAction.Update;
    }
    if (btnLabel === SelectPlanCTAText.DowngradePlan) {
      return ModifyPlanAction.Downgrade;
    }

    return ModifyPlanAction.AddSlots;
  };

  const handlePlanSelect = () => {
    const action = getAction();

    onSelectPlan(
      plan?.planCode,
      plan?.planType,
      plan?.planName,
      plan?.planMode,
      action,
    );
  };

  useEffect(() => {
    setButtonDisabled();
  }, []);

  // Global Variable
  const btnClassName = classnames([
    {
      'ghost-primary-new': btnLabel !== SelectPlanCTAText.CurrentPlan,
      'ghost-secondary-new': btnLabel === SelectPlanCTAText.CurrentPlan,
    },
  ]);

  const cardClassName = classnames([
    'pricing-card',
    {
      'best-value-plan': isBestValuePlan,
      'pricing-card-lifetime-plan': planCycle === BillingOption.LIFETIME,
    },
  ]);

  return (
    <div className={cardClassName}>
      <div className="card">
        <div className="card-body">
          <div className="pricing-card__details mb-2">
            <div className="d-flex justify-content-between">
              <ImageIcon src={headerImage} />
              {isBestValuePlan && (
                <span className="semibold-1 bs-tag bs-tag-blue-3">
                  {t('labels.most_value')}
                </span>
              )}
            </div>
            <div className="mt-2">
              <span className="semibold-1 font-weight-bold">
                {getPlanName(planCycle, displayName)}
              </span>
              <p className="regular-1 gray-txt-15 font-10 pricing-card__details-description">
                {description}
              </p>
            </div>
            <div className="d-flex align-items-start mb-3">
              <span className="regular-1 popover-arrow-color-txt mt-1 pricing-card__details-currency">
                $
              </span>
              <span className="semibold-5 popover-arrow-color-txt">
                {price}
              </span>
              <div className="d-flex flex-column mt-1">
                <span className="font-8 font-weight-medium gray-txt-15 pricing-card__details-price-detail">
                  per slot
                </span>
                <span className="font-8 font-weight-medium gray-txt-15 pricing-card__details-price-detail">
                  {planCycle === BillingOption.LIFETIME
                    ? planCycle
                    : `per ${planCycle}`}
                </span>
              </div>
            </div>
            <div className="pricing-card__divider" />
            <div className="pricing-card__features mt-2">
              <span className="semibold-1 font-10 gray-txt-11">Includes:</span>
              <ul>
                {features?.map((feature) => (
                  <li key={feature} className="d-flex align-items-center mb-1">
                    <Icon
                      identifier="check"
                      className="green-txt-16 font-12 mr-2"
                    />
                    <span className="regular-1 font-10 popover-arrow-color-txt">
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <Button
          variant={Button.Variant.Primary}
          theme={Button.Theme.Ghost}
          onClick={handlePlanSelect}
          className={btnClassName}
          disabled={btnDisabled}
        >
          {btnLabel}
        </Button>
      </div>
    </div>
  );
};

export default PricingCard;
