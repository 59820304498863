import React from 'react';
import { Feature, FeaturesList } from '../types';
import {
  EmailOutreachFeatureMeta,
  EmailWarmupFeatureMeta,
} from './feature-meta';

const FeaturesSection: React.FC<FeaturesList> = ({ id, title, features }) => (
  <div key={id} className="features-section">
    <div className="features-section--header font-14 font-semibold blue-txt-15">
      {title}
    </div>
    {features.map((feature: Feature) => (
      <div className="feature d-flex" key={feature.featureId}>
        <div className="font-14 blue-txt-15 d-flex align-items-center feature-name">
          {feature.featureName}
        </div>
        <EmailWarmupFeatureMeta
          isAvailable={feature.emailWarmupFeatureMeta?.isAvailable}
          helperText={feature.emailWarmupFeatureMeta?.helperText}
          isComingSoon={feature.emailWarmupFeatureMeta?.isComingSoon}
        />
        <EmailOutreachFeatureMeta
          isAvailable={feature.emailOutreachFeatureMeta?.isAvailable}
          helperText={feature.emailOutreachFeatureMeta?.helperText}
          isComingSoon={feature.emailOutreachFeatureMeta?.isComingSoon}
        />
      </div>
    ))}
  </div>
);

export default FeaturesSection;
