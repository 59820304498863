import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../../../store/root-reducer';
// eslint-disable-next-line import/no-cycle
import BillingSummaryModal from './billing-summary-modal';
import {
  ApplyCouponCodeRequest,
  ModifySubscriptionRequest,
  Plan,
  PurchaseModifySubscriptionRequest,
  PurchaseSubscriptionRequest,
} from '../../../../types';
import {
  applyCouponCodeRequest,
  modifySubscriptionRequest,
  purchaseLeadFinderModifySubscriptionRequest,
  purchaseLeadFinderSubscriptionRequest,
} from '../../../../extra-actions';
import {
  deleteAppliedCouponCode,
  resetHandleLeadFinderSubscriptionChurnkeyResponse,
  resetSubscription,
} from '../../../../billing-subscription-slice';

const mapStateToProps = (state: RootState) => ({
  sendPurchaseLeadFinderSubscriptionRequestError:
    state.billingAndSubscription.purchaseLeadFinderSubscriptionRequest.error,
  sendPurchaseLeadFinderSubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderSubscriptionRequest.status,
  sendPurchaseLeadFinderSubscriptionRequestMessage:
    state.billingAndSubscription.purchaseLeadFinderSubscriptionRequest.message,

  sendGetConnectedUsersAndEmailAccountsRequestError:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest.error,
  sendGetConnectedUsersAndEmailAccountsRequestResponse:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsResponse,

  sendApplyCouponCodeRequestStatus:
    state.billingAndSubscription.applyCouponCodeRequest.status,
  sendApplyCouponCodeRequestMessage:
    state.billingAndSubscription.applyCouponCodeRequest.message,
  isSendApplyCouponCodeRequestError:
    state.billingAndSubscription.applyCouponCodeRequest.error?.error,
  getCouponCodeResponse: state.billingAndSubscription.applyCouponCodeResponse,

  sendGetModifySubscriptionRequestStatus:
    state.billingAndSubscription.modifySubscriptionRequest.status,
  sendGetModifySubscriptionRequestError:
    state.billingAndSubscription.modifySubscriptionRequest.error,
  sendGetModifySubscriptionResponse:
    state.billingAndSubscription.modifySubscriptionResponse,
  sendPurchaseLeadFinderModifySubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderModifySubscriptionRequest
      .status,
  sendPurchaseLeadFinderModifySubscriptionRequestMessage:
    state.billingAndSubscription.purchaseLeadFinderModifySubscriptionRequest
      .message,

  sendGetHandleSubscriptionResponse:
    state.billingAndSubscription.handleSubscriptionResponse,
  sendGetHandleLeadFinderChurnkeySubscriptionError:
    state.billingAndSubscription.handleLeadFinderChurnkeySubscriptionRequest
      .error,
  sendGetHandleLeadFinderChurnkeySubscriptionStatus:
    state.billingAndSubscription.handleLeadFinderChurnkeySubscriptionRequest
      .status,

  sendGetCalculatePayRequestStatus:
    state.billingAndSubscription.calculatePayRequest.status,
  sendGetCalculatePayResponse:
    state.billingAndSubscription.getCalculatePayResponse,

  couponCodeDiscount:
    state.billingAndSubscription.applyCouponCodeResponse?.discountValue,
  couponCodeDiscountType:
    state.billingAndSubscription.applyCouponCodeResponse?.discountType,
  sendGetAccountSubscriptionResponse:
    state.billingAndSubscription.getAccountSubscriptionResponse,
  currentPlanName: state.home?.subscription?.planName,
});

const mapDispatchToProps = {
  sendPurchaseLeadFinderSubscriptionRequest: (
    payload: PurchaseSubscriptionRequest,
  ) => purchaseLeadFinderSubscriptionRequest(payload),
  resetSubscription: () => resetSubscription(),
  resetHandleLeadFinderSubscriptionChurnkeyResponse: () =>
    resetHandleLeadFinderSubscriptionChurnkeyResponse(),
  sendApplyCouponCodeRequest: (payload: ApplyCouponCodeRequest) =>
    applyCouponCodeRequest(payload),
  deleteAppliedCouponCode: () => deleteAppliedCouponCode(),
  sendModifySubscriptionRequest: (payload: ModifySubscriptionRequest) =>
    modifySubscriptionRequest(payload),
  sendPurchaseLeadFinderModifySubscriptionRequest: (
    payload: PurchaseModifySubscriptionRequest,
  ) => purchaseLeadFinderModifySubscriptionRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onClose: VoidFunction;
  selectedPlanDetails: Plan | null;
  isUserModifyingSubscription: boolean;
  onPurchase: (token: string, couponCode: string) => void;
};

export default connector(BillingSummaryModal);
