import React from 'react';
import { produce } from 'immer';
import { Button } from '@saleshandy/design-system';
import { InfoCircle } from '@saleshandy/icons';
import Input from '../../../../../../shared/design-system/components/input';
import Select from '../../../../../../shared/design-system/components/select';
import ChangePasswordModal from '../change-password-modal';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { IState, IProps, TimezoneOption } from './types';
import toaster, { Theme } from '../../../../../../shared/toaster';
import { UpdatePasswordRequestPayload } from '../change-password-modal/types';
import { validate } from './validator';
import { UpdateUserProfilePayload } from '../../../../types/request-payload';
import {
  getCurrentTimeZone,
  getTimezoneList,
} from '../../../../../../shared/utils/date-time';
import TimezoneOptionRenderer from '../../../../../../shared/design-system/components/atoms/timezone-option-renderer';
import HeaderBanner from '../../../header-banner';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import ChangeEmailModal from '../change-email-modal';
import DeleteAccountModal from '../delete-account-modal';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../shared/design-system/components/overlay';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';
import { AuthenticationMethod } from '../../../../enums/authentication-method';
import { UserSettingCode } from '../../../../../../shared/types/user-setting';
import OAuthButtons, {
  OAuthMethod,
} from '../../../../../auth/components/oauth-buttons';
import { showToasterWithErrorCheck } from '../../../../../email-accounts/components/email-accounts-content/utils/helpers';
import { ActionForAuthentication } from '../../../../../auth/types';
import { isRequestPending } from '../../../billing-subscription/utils/helper';
import {
  AuthHelper,
  executeOnRequestStatusWithPrevStatusCheck,
  redirectWithRefresh,
} from '../../../../../../shared/utils';
import { SSOStatus } from '../../../../../../shared/enums/sso-status';
import ConfirmationModalV3 from '../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { openChatSupport } from '../../../../../../shared/utils/open-chat-support';
import {
  LOCAL_STORAGE_KEYS,
  removeFromLocalStorage,
  saveToLocalStorage,
} from '../../../../../email-accounts/helpers/email-accounts-infra-local-storage';

class MyProfileContent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { myProfile } = props;

    this.state = {
      values: {
        firstName: myProfile.firstName,
        lastName: myProfile.lastName,
      },
      errors: {
        firstName: '',
        lastName: '',
      },
      dirty: {
        firstName: false,
        lastName: false,
      },
      timeZoneKey: this.getTimeZoneOptions().find(
        (item) =>
          item?.name?.toLowerCase() === myProfile?.timeZone?.toLowerCase(),
      )?.name,
      changeEmailModal: false,
      showChangePasswordModal: false,
      deleteAccountModal: false,
      showOAuthChangeEmailWarningModal: false,
      showOAuthChangeEmailModal: false,
      showVerifySSOEmailModal: false,
      showOAuthDeleteModal: false,
      newSSOEmail: '',
      newSSOEmailError: '',
      showSSOEmailChangeModal: false,
      newSSOEmailVerificationInProgress: false,
      showSSOEmailChangeSuccessModal: false,
    };

    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.getTimeZoneOptions = this.getTimeZoneOptions.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.showChangePasswordModal = this.showChangePasswordModal.bind(this);
    this.hideChangePasswordModal = this.hideChangePasswordModal.bind(this);
    this.changePasswordHandler = this.changePasswordHandler.bind(this);
    this.handleOnChangeEmail = this.handleOnChangeEmail.bind(this);
    this.hideChangeEmailModal = this.hideChangeEmailModal.bind(this);
    this.showDeleteAccountModal = this.showDeleteAccountModal.bind(this);
    this.hideDeleteAccountModal = this.hideDeleteAccountModal.bind(this);
    this.showVerifySSOEmailModal = this.showVerifySSOEmailModal.bind(this);
    this.hideVerifyEmailModal = this.hideVerifyEmailModal.bind(this);
    this.handleVerifyEmail = this.handleVerifyEmail.bind(this);
    this.showSSOEmailChangeModal = this.showSSOEmailChangeModal.bind(this);
    this.hideSSOChangeEmailModal = this.hideSSOChangeEmailModal.bind(this);
    this.handleSSOEmailChangeSubmit = this.handleSSOEmailChangeSubmit.bind(
      this,
    );
    this.handleNewSSOEmailInput = this.handleNewSSOEmailInput.bind(this);
    this.showSSOEmailChangeSuccessModal = this.showSSOEmailChangeSuccessModal.bind(
      this,
    );
    this.hideSSOEmailChangeSuccessModal = this.hideSSOEmailChangeSuccessModal.bind(
      this,
    );
    this.handleSSOEmailChangeSuccess = this.handleSSOEmailChangeSuccess.bind(
      this,
    );
    this.showOAuthChangeEmailWarningModal = this.showOAuthChangeEmailWarningModal.bind(
      this,
    );
    this.hideOAuthChangeEmailWarningModal = this.hideOAuthChangeEmailWarningModal.bind(
      this,
    );
    this.showOAuthDeleteModal = this.showOAuthDeleteModal.bind(this);
    this.hideOAuthDeleteModal = this.hideOAuthDeleteModal.bind(this);
    this.validateOAuth = this.validateOAuth.bind(this);
    this.showOAuthChangeEmailModal = this.showOAuthChangeEmailModal.bind(this);
    this.hideOAuthChangeEmailModal = this.hideOAuthChangeEmailModal.bind(this);
  }

  componentDidMount() {
    const { location, history } = this.props;
    if ((location.state as { oAuthChangeEmail?: boolean })?.oAuthChangeEmail) {
      this.showOAuthChangeEmailModal();

      history.replace({
        ...location,
        state: {},
      });
    }

    const {
      state,
    }: {
      state: {
        emailChangeInProgress?: boolean;
        newSSOEmailVarificationInProgress?: boolean;
      };
    } = location;
    if (state?.emailChangeInProgress) {
      removeFromLocalStorage(
        LOCAL_STORAGE_KEYS.EMAIL_CHANGE_FOR_SSO_USER_IN_PROGRESS,
      );
      window.history.replaceState({}, '', location.pathname);
      this.showSSOEmailChangeModal();
    }

    if (state?.newSSOEmailVarificationInProgress) {
      removeFromLocalStorage(
        LOCAL_STORAGE_KEYS.NEW_SSO_EMAIL_VARIFICATION_IN_PROGRESS,
      );
      window.history.replaceState({}, '', location.pathname);
      this.showSSOEmailChangeSuccessModal();
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      updatePasswordRequestStatus,
      updatePasswordRequestMessage,
      updatePasswordRequestError,
      updateMyProfileRequestError,
      updateMyProfileRequestMessage,
      updateMyProfileRequestStatus,
      sendHomeMetaUpdate,
      myProfileDetails,
      getOAuthRedirectUrlRequestStatus,
      getOAuthRedirectUrlRequestError,
      authUrl,

      ssoLoginRequestStatus,
      ssoLoginRequestError,
      redirectUrl,
    } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: updatePasswordRequestStatus,
      prevStatus: prevProps.updatePasswordRequestStatus,
      onSuccess: () => {
        toaster.success(updatePasswordRequestMessage, { theme: Theme.New });
        this.hideChangePasswordModal();
      },
      onFailed: () => {
        toaster.error(updatePasswordRequestError.message, { theme: Theme.New });
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: updateMyProfileRequestStatus,
      prevStatus: prevProps.updateMyProfileRequestStatus,
      onSuccess: () => {
        toaster.success(updateMyProfileRequestMessage, { theme: Theme.New });
        sendHomeMetaUpdate(myProfileDetails);
      },
      onFailed: () => {
        toaster.error(updateMyProfileRequestError.message, {
          theme: Theme.New,
        });
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: getOAuthRedirectUrlRequestStatus,
      prevStatus: prevProps.getOAuthRedirectUrlRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(authUrl);
      },
      onFailed: () => {
        showToasterWithErrorCheck(getOAuthRedirectUrlRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: ssoLoginRequestStatus,
      prevStatus: prevProps.ssoLoginRequestStatus,
      onSuccess: () => {
        if (redirectUrl) {
          const { newSSOEmailVerificationInProgress } = this.state;
          if (newSSOEmailVerificationInProgress) {
            saveToLocalStorage(
              LOCAL_STORAGE_KEYS.NEW_SSO_EMAIL_VARIFICATION_IN_PROGRESS,
              true,
            );
          } else {
            saveToLocalStorage(
              LOCAL_STORAGE_KEYS.EMAIL_CHANGE_FOR_SSO_USER_IN_PROGRESS,
              true,
            );
          }
          window.open(redirectUrl, '_self');
        }
      },
      onFailed: () => {
        if (ssoLoginRequestError?.message?.includes('support')) {
          toaster.error(
            <div>
              Your account is suspended, to revoke this suspension feel free to
              reach out to{' '}
              <button
                type="button"
                className="support-link"
                onClick={() => {
                  openChatSupport();
                }}
              >
                <span className="font-bold red-txt-13">
                  <u>support</u>
                </span>
              </button>
            </div>,
            {
              theme: Theme.New,
              showCloseIcon: true,
              delay: 1000000,
            },
          );
        }
      },
    });
  }

  onInputChange(value: string, event: React.ChangeEvent<HTMLInputElement>) {
    const { name } = event.target;
    this.setState(
      produce((draft: IState) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(
            name,
            draft.values[name],
            hasPermission(Permissions.MY_PROFILE_UPDATE),
          );
        }
      }),
    );
  }

  onSelectChange(selectedOption: TimezoneOption) {
    this.setState({ timeZoneKey: selectedOption.key });
  }

  onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { dirty, errors, values, timeZoneKey } = this.state;
    const { sendUpdateMyProfileRequest } = this.props;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirty);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errors);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(
        key,
        values[key],
        hasPermission(Permissions.MY_PROFILE_UPDATE),
      );
      errorsRef[key] = error;
      isError = isError || !!error;
    });

    if (isError) {
      this.setState({ errors, dirty });
      return;
    }

    const timeZone = this.getTimeZoneOptions().find(
      (tz) => tz.key === timeZoneKey,
    )?.name;

    const payload: UpdateUserProfilePayload = {
      ...values,
      timeZone,
    };

    sendUpdateMyProfileRequest(payload);
  }

  getTimeZoneOptions = () => {
    const timeZones = getTimezoneList();
    return timeZones.map((item) => ({
      key: item.name,
      ...item,
    }));
  };

  getAuthenticationMethod() {
    const { meta } = this.props;
    const authenticationMethod = meta.find(
      (item) => item.code === UserSettingCode.AuthenticationMethod,
    )?.value;
    return authenticationMethod;
  }

  deleteAccountButton = () => {
    const authMethod = this.getAuthenticationMethod();
    const isOAuth =
      authMethod === AuthenticationMethod.Google ||
      authMethod === AuthenticationMethod.Microsoft;

    return (
      <Button
        type="submit"
        variant="secondary"
        className="my-profile-delete-btn"
        onClick={
          isOAuth ? this.showOAuthDeleteModal : this.showDeleteAccountModal
        }
        disabled={!hasPermission(Permissions.ACCOUNT_DATA_DELETE)}
      >
        Delete Account
      </Button>
    );
  };

  handleOnChangeEmail() {
    const { ssoStatus } = this.props;
    if (ssoStatus === SSOStatus.ACTIVE_ENABLED) {
      this.showVerifySSOEmailModal();
    } else if (
      this.getAuthenticationMethod() === AuthenticationMethod.Google ||
      this.getAuthenticationMethod() === AuthenticationMethod.Microsoft
    ) {
      this.showOAuthChangeEmailWarningModal();
    } else {
      this.setState({ changeEmailModal: true });
    }
  }

  hideChangeEmailModal() {
    this.setState({ changeEmailModal: false });
  }

  showSSOEmailChangeModal() {
    this.setState({ showSSOEmailChangeModal: true });
  }

  hideSSOChangeEmailModal() {
    this.setState({ showSSOEmailChangeModal: false });
  }

  showDeleteAccountModal() {
    this.setState({ deleteAccountModal: true });
  }

  hideDeleteAccountModal() {
    this.setState({ deleteAccountModal: false });
  }

  showChangePasswordModal(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.preventDefault();
    this.setState({ showChangePasswordModal: true });
  }

  hideChangePasswordModal() {
    this.setState({ showChangePasswordModal: false });
  }

  changePasswordHandler(payload: UpdatePasswordRequestPayload) {
    const { sendUpdatePasswordRequest } = this.props;
    sendUpdatePasswordRequest(payload);
  }

  showOAuthChangeEmailWarningModal() {
    this.setState({ showOAuthChangeEmailWarningModal: true });
  }

  hideOAuthChangeEmailWarningModal() {
    this.setState({ showOAuthChangeEmailWarningModal: false });
  }

  showOAuthDeleteModal() {
    this.setState({ showOAuthDeleteModal: true });
  }

  hideOAuthDeleteModal() {
    this.setState({ showOAuthDeleteModal: false });
  }

  validateOAuth(action: ActionForAuthentication) {
    const { userId, getOAuthRedirectUrl } = this.props;
    const authMethod = this.getAuthenticationMethod();

    let oauthMethod: OAuthMethod;
    if (authMethod === AuthenticationMethod.Google) {
      oauthMethod = OAuthMethod.Google;
    } else if (authMethod === AuthenticationMethod.Microsoft) {
      oauthMethod = OAuthMethod.Microsoft;
    }
    if (oauthMethod) {
      const timeZone = getCurrentTimeZone();
      getOAuthRedirectUrl(oauthMethod, {
        timeZone,
        action,
        userId,
      });
    }
  }

  showOAuthChangeEmailModal() {
    this.setState({ showOAuthChangeEmailModal: true });
  }

  hideOAuthChangeEmailModal() {
    this.setState({ showOAuthChangeEmailModal: false });
  }

  showVerifySSOEmailModal() {
    this.setState({ showVerifySSOEmailModal: true });
  }

  hideVerifyEmailModal() {
    this.setState({ showVerifySSOEmailModal: false });
  }

  handleVerifyEmail() {
    const { myProfile, sendSSOLoginRequest } = this.props;
    sendSSOLoginRequest(myProfile.email);
  }

  handleNewSSOEmailInput(value: string) {
    const { myProfile } = this.props;
    if (myProfile?.email === value) {
      this.setState({
        newSSOEmailError: 'The email address you entered already exists.',
      });
    } else {
      this.setState({ newSSOEmailError: '' });
    }
    this.setState({
      newSSOEmail: value,
    });
  }

  handleSSOEmailChangeSubmit() {
    const { sendSSOLoginRequest } = this.props;
    const { newSSOEmail, newSSOEmailError } = this.state;
    const { myProfile } = this.props;
    if (newSSOEmailError !== '' || newSSOEmail === '') {
      this.setState({
        newSSOEmailError: 'Email address is required',
      });
      return;
    }
    this.setState({
      newSSOEmailVerificationInProgress: true,
    });
    saveToLocalStorage('new-sso-email', newSSOEmail);
    sendSSOLoginRequest(myProfile.email);
  }

  showSSOEmailChangeSuccessModal() {
    this.setState({
      showSSOEmailChangeSuccessModal: true,
    });
  }

  hideSSOEmailChangeSuccessModal() {
    this.setState({
      showSSOEmailChangeSuccessModal: false,
    });
  }

  handleSSOEmailChangeSuccess() {
    this.setState({ showSSOEmailChangeSuccessModal: false });
    AuthHelper.logout();
  }

  render() {
    const {
      values,
      errors,
      timeZoneKey,
      changeEmailModal,
      showChangePasswordModal,
      deleteAccountModal,
      showOAuthChangeEmailWarningModal,
      showOAuthDeleteModal,
      showOAuthChangeEmailModal,
      showVerifySSOEmailModal,
      newSSOEmail,
      newSSOEmailError,
      showSSOEmailChangeModal,
      showSSOEmailChangeSuccessModal,
    } = this.state;
    const {
      myProfile,
      subscriptionPlan,
      updateMyProfileRequestStatus,
      updatePasswordRequestStatus,
      meta,
      userId,
      getOAuthRedirectUrlRequestStatus,
      ssoStatus,
    } = this.props;

    const isUpdateMyProfileRequestStatusPending =
      updateMyProfileRequestStatus === RequestStatus.Pending;
    const isUpdatePasswordRequestStatusPending =
      updatePasswordRequestStatus === RequestStatus.Pending;

    const authenticationMethod = meta?.find(
      ({ code }) => code === UserSettingCode.AuthenticationMethod,
    )?.value;
    const shouldHidePassword =
      authenticationMethod === AuthenticationMethod.Google ||
      authenticationMethod === AuthenticationMethod.Microsoft ||
      ssoStatus === SSOStatus.ACTIVE_ENABLED;

    return (
      <>
        <GlobalSettings.Header className="globalSettings__Header--margin">
          <GlobalSettings.HeaderLeft>
            <GlobalSettings.HeaderTitle title="Profile" />
          </GlobalSettings.HeaderLeft>
        </GlobalSettings.Header>
        {subscriptionPlan === SubscriptionPlans.FREE && <HeaderBanner />}
        <GlobalSettings.Content className="profile-container">
          <div className="form-container">
            <form onSubmit={this.onFormSubmit}>
              <Input
                name="firstName"
                label="First name"
                placeholder="Enter your first name"
                value={values.firstName}
                variant={errors.firstName && Input.Variant.Error}
                caption={errors.firstName}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                autoComplete="current-firstName"
                autoFocus
                disabled={!hasPermission(Permissions.MY_PROFILE_UPDATE)}
                className="my-profile-input"
              />
              <Input
                name="lastName"
                label="Last name"
                placeholder="Enter your last name"
                value={values.lastName}
                variant={errors.lastName && Input.Variant.Error}
                caption={errors.lastName}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                autoComplete="current-lastName"
                disabled={!hasPermission(Permissions.MY_PROFILE_UPDATE)}
                className="my-profile-input"
              />

              <div className="input-with-cta-wrapper">
                <Input
                  name="email"
                  label="Email"
                  disabled={true}
                  value={myProfile.email}
                  className="my-profile-input"
                />
                {hasPermission(Permissions.CREDENTIALS_UPDATE) && (
                  <Button
                    variant="secondary"
                    className="change-email-btn"
                    onClick={this.handleOnChangeEmail}
                  >
                    Change Email
                  </Button>
                )}
              </div>

              {!shouldHidePassword &&
                hasPermission(Permissions.MY_PROFILE_UPDATE) && (
                  <div className="input-with-cta-wrapper">
                    <Input
                      name="password"
                      label="Password"
                      disabled={true}
                      value="************"
                      className="my-profile-input"
                    />

                    <Button
                      variant="secondary"
                      className="change-email-btn password-btn"
                      onClick={this.showChangePasswordModal}
                    >
                      Change Password
                    </Button>
                  </div>
                )}

              <div className="my-profile-input timezone-wrapper">
                <span className="bs-input-label-prefix">Time Zone</span>
                <Select<TimezoneOption>
                  showSearch
                  className="timezone-select"
                  filterOption={(value, option) =>
                    option.name.toLowerCase().includes(value.toLowerCase())
                  }
                  selectedOptionRenderer={([option]) => (
                    <span>{option?.name}</span>
                  )}
                  optionRenderer={(option) => (
                    <TimezoneOptionRenderer timezone={option?.value} />
                  )}
                  options={this.getTimeZoneOptions()}
                  placeholder="Select Time Zone"
                  selectedOptionKey={timeZoneKey}
                  onChange={([selectedOption]) =>
                    this.onSelectChange(selectedOption)
                  }
                />
              </div>

              <Button
                type="submit"
                className="my-profile-submit-btn"
                loadingText="Saving..."
                isLoading={isUpdateMyProfileRequestStatusPending}
                disabled={isUpdateMyProfileRequestStatusPending}
              >
                Save
              </Button>
            </form>
          </div>

          {hasPermission(Permissions.ACCOUNT_PROFILE_DANGER_ZONE) && (
            <>
              <div className="danger-zone-header">
                <h1>Danger Zone</h1>
                <p>Delete your Saleshandy account permanently.</p>
              </div>

              {hasPermission(Permissions.ACCOUNT_DATA_DELETE) ? (
                this.deleteAccountButton()
              ) : (
                <OverlayTooltip
                  text="Your Saleshandy account must be on a free plan if you want to delete it."
                  placement={Placement.Right}
                  className="tooltip-xxl"
                >
                  {this.deleteAccountButton()}
                </OverlayTooltip>
              )}
            </>
          )}

          <ChangeEmailModal
            show={changeEmailModal}
            onClose={this.hideChangeEmailModal}
          />

          {hasPermission(Permissions.MY_PROFILE_UPDATE) &&
            showChangePasswordModal && (
              <ChangePasswordModal
                show={showChangePasswordModal}
                onClose={this.hideChangePasswordModal}
                onSubmit={this.changePasswordHandler}
                isLoading={isUpdatePasswordRequestStatusPending}
              />
            )}

          <DeleteAccountModal
            show={deleteAccountModal}
            onClose={this.hideDeleteAccountModal}
          />

          <ConfirmationModalV3
            show={showOAuthChangeEmailWarningModal}
            title={
              <span className="oauth-modal-title">
                Your current email is <span className="oauth-modal-title--bold">{myProfile.email}</span>
              </span>
            }
            showCloseIcon={false}
            hideTitleIcon={true}
            content={[
              'Please validate your Google/Microsoft account to change email',
            ]}
            cancelButtonText="Cancel"
            submitButtonText="Validate"
            onClose={this.hideOAuthChangeEmailWarningModal}
            onSubmit={() =>
              this.validateOAuth(ActionForAuthentication.ValidationChangeEmail)
            }
            isSubmitLoading={isRequestPending(getOAuthRedirectUrlRequestStatus)}
          />

          <ConfirmationModalV3
            show={showOAuthDeleteModal}
            title={
              <span className="oauth-modal-title">
                Your current email is <span className="oauth-modal-title--bold">{myProfile.email}</span>
              </span>
            }
            showCloseIcon={false}
            hideTitleIcon={true}
            content={[
              'Please validate your Google/Microsoft account to delete your account',
            ]}
            cancelButtonText="Cancel"
            submitButtonText="Delete"
            onClose={this.hideOAuthDeleteModal}
            onSubmit={() =>
              this.validateOAuth(ActionForAuthentication.DeleteAccount)
            }
            isSubmitLoading={isRequestPending(getOAuthRedirectUrlRequestStatus)}
            className="delete-oauth-modal"
          />

          <ConfirmationModalV3
            show={showOAuthChangeEmailModal}
            title={
              <span className="oauth-modal-title">
                Your current email is <span className="oauth-modal-title--bold">{myProfile.email}</span>
              </span>
            }
            showCloseIcon={false}
            hideTitleIcon={true}
            content={
              <div className="oauth-change-email-modal-content">
                <p>
                  To update your email, please verify your Google or Microsoft
                  account.
                </p>
                <p>Choose a method to continue:</p>
                <OAuthButtons
                  type={ActionForAuthentication.ChangeEmail}
                  userId={userId}
                />
              </div>
            }
            hideCancelButton={true}
            hideSubmitButton={true}
            onClose={this.hideOAuthChangeEmailModal}
            onSubmit={() => {}}
            hideFooter={true}
            className="oauth-change-email-modal"
          />

          <ConfirmationModalV3
            show={showVerifySSOEmailModal}
            title="Verify your Email"
            showCloseIcon={false}
            hideTitleIcon={true}
            icon={<InfoCircle height="24" width="24" color="#2563EB" />}
            content="You'll be redirected to your SSO app for verification. Once done, you can update your email."
            cancelButtonText="Cancel"
            submitButtonText="Okay"
            onClose={this.hideVerifyEmailModal}
            onSubmit={this.handleVerifyEmail}
          />

          <ConfirmationModalV3
            show={showSSOEmailChangeModal}
            title={`Your current email is ${myProfile.email}`}
            showCloseIcon={false}
            hideTitleIcon={true}
            content={
              <div>
                <p>To update your email, please verify SSO account.</p>
                <Input
                  name="email"
                  label="New Email"
                  type="email"
                  placeholder="johndoe@example.com"
                  value={newSSOEmail}
                  onChange={this.handleNewSSOEmailInput}
                  autoComplete="current-email"
                  className="modal-input"
                  variant={newSSOEmailError && Input.Variant.Error}
                  caption={newSSOEmailError}
                />
              </div>
            }
            cancelButtonText="Cancel"
            submitButtonText="Verify"
            onClose={this.hideSSOChangeEmailModal}
            onSubmit={this.handleSSOEmailChangeSubmit}
          />

          <ConfirmationModalV3
            show={showSSOEmailChangeSuccessModal}
            title="Email Changed Successfully"
            showCloseIcon={false}
            hideTitleIcon={true}
            content={`Your login email address has been updated to ${newSSOEmail}`}
            hideCancelButton={true}
            submitButtonText="Login Again"
            onClose={this.hideSSOEmailChangeSuccessModal}
            onSubmit={this.handleSSOEmailChangeSuccess}
          />
        </GlobalSettings.Content>
      </>
    );
  }
}

export default MyProfileContent;
