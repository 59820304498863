import React from 'react';
import type { IProps } from './types';
import { getAllFeaturesList } from './utils/get-all-features';

import Modal from '../../atoms/modal';
import FeaturesHeader from './components/features-header';
import FeaturesSection from './components/features-section';

const AllFeaturesListModal: React.FC<IProps> = ({ show, onClose }) => {
  const featuresList = getAllFeaturesList();

  return (
    <Modal
      show={show}
      onClose={onClose}
      showCloseIcon={true}
      hideFooter={true}
      backdrop="static"
      className="all-features-list-modal"
      titleContent="Compare Plans & See all features"
    >
      <div className="features-table">
        <FeaturesHeader />
        <div className="features-content">
          {featuresList.map((item) => (
            <FeaturesSection
              id={item.id}
              title={item.title}
              features={item.features}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AllFeaturesListModal;
