import React, { useState } from 'react';
import { Cross, Plus } from '@saleshandy/icons';

import { Domain, Mailbox } from '../types';
import { DomainStatus } from '../enums';
import { EmailAccountInfrastructureServiceProvider } from '../../email-accounts/enums/create-email-infrastructure';

import {
  OverlayPopover,
  Placement,
} from '../../../shared/design-system/components/overlay';
import hasPermission from '../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../shared/utils/access-control/enums/permissions';
import { accessibleOnClick } from '../../../shared/utils/accessible-on-click';

type DomainsMailboxesPopoverProps = {
  row: Domain;
  onAddMoreEmailAccountClicked: (domain: Domain) => void;
};

const DomainsMailboxesPopover: React.FC<DomainsMailboxesPopoverProps> = ({
  row,
  onAddMoreEmailAccountClicked,
}) => {
  const [showPopover, setShowPopover] = useState(false);

  const renderMailbox = (mailbox: Mailbox) => (
    <div className="domains-mailbox-item" key={mailbox.id}>
      {mailbox.profilePic && (
        <img
          src={mailbox.profilePic}
          alt="Profile"
          className="domains-mailbox-item__profile"
        />
      )}
      <p className="domains-mailbox-item__email">{mailbox.email}</p>
    </div>
  );

  return (
    <div className="domains-mailboxes">
      <OverlayPopover
        show={showPopover}
        onToggle={setShowPopover}
        className="domains-mailboxes-popover"
        content={
          <>
            <div className="domains-mailboxes">
              {row.mailboxes.map((mailbox) => renderMailbox(mailbox))}
            </div>
            <Cross
              className="cursor-pointer"
              color="#1F2937"
              onClick={() => setShowPopover(false)}
            />
          </>
        }
        placement={Placement.AutoStart}
        rootClose
      >
        <span
          className={`table-cell-text domains-mailboxes-count ${
            showPopover ? 'active' : ''
          }`}
        >
          {row.mailboxes?.length ?? '-'}
        </span>
      </OverlayPopover>
      {hasPermission(Permissions.DOMAIN_READ) &&
        row.order?.emailServiceProvider ===
          EmailAccountInfrastructureServiceProvider.MailDoso &&
        row.status === DomainStatus.Active &&
        row.mailboxes?.length < 5 && (
          <div
            className="add-more-maildoso-emails"
            {...accessibleOnClick(() => onAddMoreEmailAccountClicked(row))}
          >
            <Plus />
            <span>Add More</span>
          </div>
        )}
    </div>
  );
};

export default DomainsMailboxesPopover;
