import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import MFAVerify from './mfa-verify';
import { resendOtpRequest, verifyOtpRequest } from '../login/extra-actions';
import { resetMFAData } from '../login/login-slice';

const mapStateToProps = (state: RootState) => ({
  agencyConfig: state.home?.agencyConfig,
  agencyConfigRequestStatus: state.home?.getAgencyConfigRequest.status,
  email: state.login?.email,
  token: state.login?.token,
  trackingId: state.login?.trackingId,
  firstName: state.login?.firstName,
  lastName: state.login?.lastName,
  isRedirectedFromAgency: state.login?.isRedirectedFromAgency,

  verifyOtpRequestStatus: state.login?.verifyOtpRequest?.status,
  verifyOtpRequestMessage: state.login?.verifyOtpRequest?.message,
  verifyOtpRequestError: state.login?.verifyOtpRequest?.error,

  resendOtpRequestStatus: state.login?.resendOtpRequest?.status,
  resendOtpRequestMessage: state.login?.resendOtpRequest?.message,
  resendOtpRequestError: state.login?.resendOtpRequest?.error,
});

const mapDispatchToProps = {
  sendVerifyOtpRequest: (email: string, otp: string) =>
    verifyOtpRequest({ email, otp }),
  sendResendOtpRequest: (email: string) => resendOtpRequest({ email }),
  resetMFAData: () => resetMFAData(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(MFAVerify));
