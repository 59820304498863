import { isEmpty } from 'lodash';
import {
  ContactStatusType,
  ContactTotal,
  ProspectOutcome,
  ProspectOutcomeDisplayName,
} from '../../../../../../enums';
import { areFiltersApplied } from '../../../../../../../prospect/helpers/prospect-filters-resolver';
import { OutcomeSentiment } from '../../../../../../../settings/enums/custom-outcome';
import { ProspectsFilters } from '../../../../../../../../shared/types/prospects-filters';
import toaster, { Theme } from '../../../../../../../../shared/toaster';
import { SelectAllContacts } from '../../../../../../../prospect/types';

export const getSearchValue = (handleClearSearchParam, searchValue): string =>
  handleClearSearchParam ? searchValue : '';

export const updateListRequestAfterBulkActionHandler = ({
  contactsPagination,
  calledFrom,
  BulkAction,
  successCount,
  mappedContactList,
  selectedContacts,
  requestOptions,
  sequenceId,
  searchValue,
  selectedStatus,
  changePagination,
  resetSelectedContacts,
  prospectFilterPayloadCreator,
  sendGetContactStatusCountRequest,
  sendGetSequenceContactListRequest,
  sendGetTagsRequest,
}) => {
  let { pageNum } = contactsPagination;

  if (
    calledFrom === BulkAction.RemoveFromSequence ||
    (calledFrom === BulkAction.AddToSequence &&
      successCount === mappedContactList.length)
  ) {
    pageNum =
      mappedContactList.length === selectedContacts.length && pageNum !== 0
        ? contactsPagination.pageNum - 1
        : 0;
    changePagination({ ...contactsPagination, pageNum });
  }

  if (calledFrom === BulkAction.AddToSequence) {
    resetSelectedContacts();
  }

  if (requestOptions.sendGetContactStatusCountRequest) {
    sendGetContactStatusCountRequest(
      sequenceId,
      prospectFilterPayloadCreator({
        pageNum,
        pageSize: contactsPagination.pageSize,
        searchValue,
        selectedStatus: null,
      }),
    );
  }

  if (requestOptions.sendGetSequenceContactListRequest) {
    sendGetSequenceContactListRequest(
      sequenceId,
      prospectFilterPayloadCreator({
        pageNum,
        pageSize: contactsPagination.pageSize,
        searchValue,
        selectedStatus,
      }),
    );
  }

  if (requestOptions.sendGetTagsRequest) {
    sendGetTagsRequest();
  }
};

export const onSelectedSequenceIdMatched = ({
  selectedSequenceId,
  sequenceId,
  setResetSelected,
}) => {
  if (selectedSequenceId.toString() === sequenceId) {
    setResetSelected(true);
  }
};

export const getProspectName = ({ firstName, lastName }): string =>
  `${firstName || ''} ${lastName || ''}`;

export const getIsEmptyList = ({
  isRequestPending,
  currentSequenceStep,
  searchValue,
  sequenceProspectsFilters,
  contactList,
  selectedStatus,
  isSomeStatusSelected,
}): boolean =>
  isEmpty(currentSequenceStep) ||
  (searchValue === null &&
    !areFiltersApplied(sequenceProspectsFilters) &&
    isEmpty(contactList) &&
    !isRequestPending &&
    selectedStatus === ContactTotal.TOTAL &&
    !isSomeStatusSelected);

export const getFilterGadgetClassNameFromId = ({
  id,
  selectedStepFilterId,
}): string =>
  id === selectedStepFilterId ? 'active-step-filter' : 'inactive-step-filter';

const flattenTypes = (arr) =>
  arr.reduce((acc, item) => {
    // If the item has a dropdown segment, add the types from segmentOptions
    if (item.isDropDownSegment) {
      acc.push(...item.segmentOptions.map((segment) => segment.type));
    }

    return acc;
  }, []);

export const getFilterGadgetClassNameFromType = ({
  type,
  selectedStatus,
  filter,
}): string => {
  // const filterTypes = flattenTypes(filter);

  const filterTypes = filter.find(
    (item) =>
      item.isDropDownSegment &&
      item.segmentOptions?.some((segment) => segment.type === selectedStatus),
  );

  // if (
  //   type === selectedStatus ||
  //   (filterTypes.includes(selectedStatus) && filterTypes.includes(type))
  // ) {
  //   return 'active-status-filter';
  // }

  // return 'inactive-status-filter';
  if (filterTypes) {
    if (type === filterTypes.type) {
      return 'active-status-filter';
    }
    // For dropdown options, only highlight the exact match
    if (type === selectedStatus) {
      return 'active-status-filter';
    }
    return 'inactive-status-filter';
  }

  // For non-dropdown items, simple equality check
  return type === selectedStatus
    ? 'active-status-filter'
    : 'inactive-status-filter';
};

export const getFilterGadgetColorFromType = ({
  type,
  selectedStatus,
  countColor,
}): string => (type === selectedStatus ? null : countColor);

export const executeOnRequestError = (error, handler) => {
  if (error) {
    handler();
  }
};

export const checkIsFilterApplied = (sequenceProspectsFilters): boolean => {
  let isFilterApplied = false;

  Object.keys(sequenceProspectsFilters).forEach((key) => {
    if (sequenceProspectsFilters[key].length > 0) {
      if (key === 'status') {
        const filterApplied = sequenceProspectsFilters[key].find(
          (item) => !item?.isHeaderFilter,
        );

        if (filterApplied) {
          isFilterApplied = true;
        }
      } else {
        isFilterApplied = true;
      }
    }
  });

  return isFilterApplied;
};

export const handleMarkAsPaused = ({
  selectedContacts,
  pauseDelayInDays,
  changeStatusOfContacts,
}) => {
  const contactAndStepIds = selectedContacts?.map((item) => ({
    contactId: item.id,
    stepId: item.stepId,
  }));

  if (pauseDelayInDays) {
    changeStatusOfContacts(
      ContactStatusType.Paused,
      contactAndStepIds,
      pauseDelayInDays,
    );
  } else {
    changeStatusOfContacts(ContactStatusType.Paused, contactAndStepIds);
  }
};

export const handleViewActionStatModal = (
  pauseResumeResultCount,
  setShowActionStatModal,
) => {
  if (
    pauseResumeResultCount &&
    (pauseResumeResultCount.success || pauseResumeResultCount.failed)
  ) {
    setShowActionStatModal(true);
  }
};

export const getProspectOutcomeOptions = [
  {
    id: 2,
    key: ProspectOutcome.Interested,
    value: ProspectOutcomeDisplayName.Interested,
    backgroundColor: '#F5F3FF',
    borderColor: '#DDD6FE',
    hoverBackgroundColor: '#EDE9FE',
    hoverBorderColor: '#C4B5FD',
  },
  {
    id: 3,
    key: ProspectOutcome.NotInterested,
    value: ProspectOutcomeDisplayName.NotInterested,
    backgroundColor: '#FFF7ED',
    borderColor: '#FED7AA',
    hoverBackgroundColor: '#FFEDD5',
    hoverBorderColor: '#FDBA74',
  },
  {
    id: 4,
    key: ProspectOutcome.MeetingBooked,
    value: ProspectOutcomeDisplayName.MeetingBooked,
    backgroundColor: '#EFF6FF',
    borderColor: '#BFDBFE',
    hoverBackgroundColor: '#DBEAFE',
    hoverBorderColor: '#93C5FD',
  },
  {
    id: 5,
    key: ProspectOutcome.OutOfOffice,
    value: ProspectOutcomeDisplayName.OutOfOffice,
    backgroundColor: '#ECFEFF',
    borderColor: '#A5F3FC',
    hoverBackgroundColor: '#CFFAFE',
    hoverBorderColor: '#67E8F9',
  },
  {
    id: 6,
    key: ProspectOutcome.Closed,
    value: ProspectOutcomeDisplayName.Closed,
    backgroundColor: '#ECFDF5',
    borderColor: '#A7F3D0',
    hoverBackgroundColor: '#D1FAE5',
    hoverBorderColor: '#6EE7B7',
  },
  {
    id: 7,
    key: ProspectOutcome.NotNow,
    value: ProspectOutcomeDisplayName.NotNow,
    backgroundColor: '#FEF2F2',
    borderColor: '#FECACA',
    hoverBackgroundColor: '#FEE2E2',
    hoverBorderColor: '#FCA5A5',
  },
  {
    id: 8,
    key: ProspectOutcome.DoNotContact,
    value: ProspectOutcomeDisplayName.DoNotContact,
    backgroundColor: '#FEF2F2',
    borderColor: '#FECACA',
    hoverBackgroundColor: '#FEE2E2',
    hoverBorderColor: '#FCA5A5',
  },
];

export const getCustomProspectOutcomeOptions = [
  {
    id: 2,
    key: ProspectOutcome.Interested,
    name: ProspectOutcomeDisplayName.Interested,
    bgColor: '#F5F3FF',
    textColor: '#DDD6FE',
    hoverBackgroundColor: '#EDE9FE',
    isDefault: true,
    sentiment: OutcomeSentiment.Positive,
    total: 0,
    emoji: 'emoji',
  },
  {
    id: 3,
    key: ProspectOutcome.NotInterested,
    name: ProspectOutcomeDisplayName.NotInterested,
    bgColor: '#FFF7ED',
    textColor: '#FED7AA',
    hoverBackgroundColor: '#FFEDD5',
    isDefault: true,
    sentiment: OutcomeSentiment.Negative,
    total: 0,
    emoji: 'emoji',
  },
  {
    id: 4,
    key: ProspectOutcome.MeetingBooked,
    name: ProspectOutcomeDisplayName.MeetingBooked,
    bgColor: '#EFF6FF',
    textColor: '#BFDBFE',
    hoverBackgroundColor: '#DBEAFE',
    isDefault: true,
    sentiment: OutcomeSentiment.Positive,
    total: 0,
    emoji: 'emoji',
  },
  {
    id: 5,
    key: ProspectOutcome.OutOfOffice,
    name: ProspectOutcomeDisplayName.OutOfOffice,
    bgColor: '#ECFEFF',
    textColor: '#A5F3FC',
    hoverBackgroundColor: '#CFFAFE',
    isDefault: true,
    sentiment: OutcomeSentiment.Neutral,
    total: 0,
    emoji: 'emoji',
  },
  {
    id: 6,
    key: ProspectOutcome.Closed,
    name: ProspectOutcomeDisplayName.Closed,
    bgColor: '#ECFDF5',
    textColor: '#A7F3D0',
    hoverBackgroundColor: '#D1FAE5',
    isDefault: true,
    sentiment: OutcomeSentiment.Positive,
    total: 0,
    emoji: 'emoji',
  },
  {
    id: 7,
    key: ProspectOutcome.NotNow,
    name: ProspectOutcomeDisplayName.NotNow,
    bgColor: '#FEF2F2',
    textColor: '#FECACA',
    hoverBackgroundColor: '#FEE2E2',
    isDefault: true,
    sentiment: OutcomeSentiment.Neutral,
    total: 0,
    emoji: 'emoji',
  },
  {
    id: 8,
    key: ProspectOutcome.DoNotContact,
    name: ProspectOutcomeDisplayName.DoNotContact,
    bgColor: '#FEF2F2',
    textColor: '#FECACA',
    hoverBackgroundColor: '#FEE2E2',
    isDefault: true,
    sentiment: OutcomeSentiment.Negative,
    total: 0,
    emoji: 'emoji',
  },
];

export const setDealValueBannerHideValue = () =>
  localStorage.setItem('is-deal-value-banner-show', 'true');

export const isDealValueBannerShow = () =>
  localStorage.getItem('is-deal-value-banner-show');

export const getAppliedSequenceProspectsFilters = (
  sequenceProspectsFilters: ProspectsFilters,
  search: string,
) => {
  const status = sequenceProspectsFilters?.status?.map(({ id }) => id);
  const tags = sequenceProspectsFilters?.tags?.map(({ id }) => id);
  const owners = sequenceProspectsFilters?.owners?.map(({ id }) => id);
  const outcomes = sequenceProspectsFilters?.outcomes?.map(({ id }) => id);
  const stepIds = sequenceProspectsFilters?.['current step']?.map(
    ({ id }) => id,
  );
  const verification = sequenceProspectsFilters?.['verification status']?.map(
    ({ id }) => id,
  );
  const creationSource = sequenceProspectsFilters?.['creation source']?.map(
    ({ id }) => id,
  );
  const createdDate = sequenceProspectsFilters?.['created date']?.map(
    ({ date }) => date,
  );

  return {
    ...(status?.length > 0 && { status }),
    ...(tags?.length > 0 && { tags }),
    ...(owners?.length > 0 && { owners }),
    ...(verification?.length > 0 && { verification }),
    ...(outcomes?.length > 0 && { outcomes }),
    ...(stepIds?.length > 0 && { stepIds }),
    ...(createdDate?.length > 0 && { createdDate }),
    ...(creationSource?.length > 0 && { creationSource }),
    ...(search && { search }),
  };
};

type GetBulkSelectedProspectCount = (prospects: SelectAllContacts) => number;

export const validateSelectedContacts = (
  handler: VoidFunction,
  selectedAllProspects: SelectAllContacts | null,
  selectedContacts: any[],
  getBulkSelectedProspectCount: GetBulkSelectedProspectCount,
) => {
  const hasBulkSelectedProspects =
    (selectedAllProspects?.isAllProspectsSelected &&
      getBulkSelectedProspectCount(selectedAllProspects) > 0) ||
    selectedContacts.length > 0;

  if (hasBulkSelectedProspects) {
    handler();
  } else {
    toaster.error('Please select at least 1 prospect to complete the action', {
      theme: Theme.New,
    });
  }
};
