import React from 'react';
import { Range, getTrackBackground } from 'react-range';
import { GetLeadFinderPlanResponse } from '../../../../types/response/get-lead-finder-credit-plans';

interface CreditSliderProps {
  step: number;
  min: number;
  max: number;
  value: number[];
  onChange: (v: number[]) => void;
  plans: GetLeadFinderPlanResponse[];
}

const CreditSlider: React.FC<CreditSliderProps> = ({
  step = 1,
  min = 1,
  max = 8,
  value,
  plans,
  onChange,
}) => {
  const handleChange = (v: number[]) => {
    onChange(v);
  };

  const getMarkText = (index: number) => {
    const MarkText = plans[index].name;
    return MarkText;
  };

  const getMarkMargin = (index: number) => {
    if (index === 0) return '5px';
    if (index === max - 1) return '-6px';
    return '0px';
  };

  return (
    <div
      className="range"
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: 'inherit',
      }}
    >
      <Range
        values={value}
        step={step}
        min={min}
        max={max}
        rtl={false}
        onChange={(v: number[]) => handleChange(v)}
        renderMark={({ props, index }) => (
          <div
            {...props}
            className="range__mark"
            style={{
              ...props.style,
              height: '4px',
              width: '4px',
              borderRadius: '50px',
              backgroundColor: index * step < value[0] ? '#2563EB' : '#275DF5',
              marginTop: '1.5px',
              marginLeft: getMarkMargin(index),
              transition: 'background-color 0.3s ease',
            }}
          >
            <div className="range__mark-text" style={{ marginLeft: getMarkMargin(index) }}>{getMarkText(index)}</div>
          </div>
        )}
        renderTrack={({ props, children }) => (
          <div
            role="none"
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className="range__track"
            style={{
              ...props.style,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '8px',
              background: getTrackBackground({
                values: value,
                colors: ['#2563EB', '#DBEAFE'],
                min,
                max,
                rtl: false,
              }),
              borderRadius: '4px',
              position: 'relative',
              paddingRight: '3px',
              transition: 'background 0.3s ease',
            }}
          >
            <div
              ref={props.ref}
              className="range__track-ch"
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className={
              (value[0] === min && 'range__thumb-first') ||
              (value[0] === max && 'range__thumb-last')
            }
            style={{
              ...props.style,
              height: '24px',
              width: '24px',
              borderRadius: '50px',
              outlineStyle: 'solid',
              outlineColor: '#1D4ED8',
              outlineWidth: '2px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
              transition: 'transform 0.2s ease',
            }}
          />
        )}
      />
    </div>
  );
};

export default CreditSlider;
