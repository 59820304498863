import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Accordion, Pills, SkeletonLoading } from '@saleshandy/design-system';
import { Minus, Plus, Filter } from '@saleshandy/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DateTime } from 'luxon';
import KeywordInputComponent from '../keyword-search-input/keyword-search-input';
import Tag from '../../../../shared/design-system/components/tag/tag';
import {
  useSavedLeadFilter,
  FILTER_SAVED_LIST,
} from '../hooks/useLeadSavedFilter';
import { Placement } from '../../../../shared/design-system/components/overlay';
import { FilterComponentType, SavedTabFilters } from '../../type';
import TagSavedFilters from './components/tags';
import DateFilter from '../../../../shared/design-system/components/molecules/leads-filter/date-filter';

export type SavedTabProps = {
  leadTags: any[];
  isLeadTagsLoading: boolean;
  activeTabKey;
  savedFilters: SavedTabFilters;
  setSavedFilters: React.Dispatch<React.SetStateAction<SavedTabFilters>>;
};

const SavedTab: React.FC<SavedTabProps> = ({
  leadTags,
  isLeadTagsLoading,
  activeTabKey,
  savedFilters,
  setSavedFilters,
}) => {
  const keywordInputRef: any = useRef();

  const {
    handleQuery,
    handleGetAllKeysByFilterFromQuery,
    handleIndividualTag,
  } = useSavedLeadFilter(activeTabKey);

  const [selectedFilter, setSelectedFilter] = useState<any>([]);

  const overlayRef = useRef(null);
  const accordionKey = 3830;
  const [showTagTooltip, setShowTagTooltip] = useState<any>(null);

  const onSelectFilter = (id, filter) => {
    setSelectedFilter((prevSelectedFilter) => {
      const index = prevSelectedFilter.findIndex((ele) => ele.id === id);
      if (index === -1) {
        return [
          ...prevSelectedFilter,
          {
            id,
            filter,
          },
        ];
      }
      return prevSelectedFilter.filter((ele) => ele.id !== id);
    });
  };

  const isSelectedFilter = useMemo(
    () => (filter) => selectedFilter.some((f) => f.id === filter.id),
    [selectedFilter],
  );

  const handleDeleteTagFilter = (filter) => {
    if (filter.key === 'createdDate') {
      setSavedFilters((prevFilters) => ({
        ...prevFilters,
        createdDate: [],
      }));
    } else if (filter.key === 'tags') {
      setSavedFilters((prevFilters) => ({
        ...prevFilters,
        tags: [],
      }));
    }

    handleQuery({
      filterKey: filter.key,
      filterValue: {
        tags: [],
        createdDate: [],
      },
      filterType:
        filter.key === 'createdDate'
          ? FilterComponentType.SAVED_LEAD_DATE
          : FilterComponentType.TAG_CHECKBOX,
    });
  };

  useEffect(() => {
    if (keywordInputRef.current) {
      keywordInputRef.current.focus();
    }
    return () => {
      setSelectedFilter([]);
    };
  }, []);

  const getLebelTooltip = (text: string) => (
    <Tooltip id="popover-basic" className="bs-tooltip-inner">
      {text}
    </Tooltip>
  );

  const handleDateChange = (createdDates: Date[]) => {
    const dates = createdDates?.filter((e) => !!e);
    setSavedFilters({
      ...savedFilters,
      createdDate: dates,
    });

    if (dates?.length === 0) {
      handleQuery({
        filterKey: 'createdDate',
        filterValue: {
          createdDate: [],
        },
        filterType: FilterComponentType.SAVED_LEAD_DATE,
      });
      return;
    }

    if (dates?.length < 2) {
      return;
    }

    setSavedFilters({
      ...savedFilters,
      createdDate: dates,
    });

    const dateTimes = [
      DateTime.fromJSDate(dates[0]),
      DateTime.fromJSDate(dates[1]),
    ];

    handleQuery({
      filterKey: 'createdDate',
      filterValue: {
        createdDate: [
          dateTimes[0].toFormat('dd MMM, yy'),
          dateTimes[1].toFormat('dd MMM, yy'),
        ],
      },
      filterType: FilterComponentType.SAVED_LEAD_DATE,
    });
  };

  return (
    <>
      <KeywordInputComponent
        handleQuery={handleQuery}
        keywordInputRef={keywordInputRef}
        onHandleQuery={(value: string) => {
          setSavedFilters({
            ...savedFilters,
            search: value,
          });
        }}
      />
      <h3 className="leads-filter">
        <div>
          <Filter className="sh-filter" />
          Filter
        </div>
      </h3>
      <div>
        <div
          key={accordionKey}
          ref={overlayRef}
          className="people-tab-accordian"
        >
          <Accordion allowMultipleExpanded key={accordionKey} allowZeroExpanded>
            {FILTER_SAVED_LIST &&
              FILTER_SAVED_LIST.map((filter: any) => {
                const allTags = handleGetAllKeysByFilterFromQuery(filter);
                return (
                  <Accordion.Item
                    key={filter.id}
                    uuid={filter.id}
                    dangerouslySetExpanded={selectedFilter?.some(
                      (f) => f?.id === filter?.id,
                    )}
                    className={`${
                      isSelectedFilter(filter)
                        ? 'accordion__item_selected'
                        : 'accordion__item'
                    }`}
                  >
                    <Accordion.ItemHeading
                      onClick={() => onSelectFilter(filter.id, filter)}
                      className={`${
                        allTags.size > 0 && !isSelectedFilter(filter)
                          ? 'accordion-item-heading-tag'
                          : 'accordion-item-heading'
                      } ${allTags.size ? 'accordion-item-selected-tag' : ''}`}
                    >
                      <Accordion.ItemButton className="::before:none w-100">
                        <div className="w-100 accordian-label">
                          <div className="d-flex">
                            <div className="icon">{filter.icon}</div>
                            <div className="label">{filter?.label}</div>
                          </div>
                          <div className="d-flex align-items-center">
                            {(filter?.label === 'Tags'
                              ? savedFilters.tags?.length
                              : savedFilters.createdDate?.length) > 0 && (
                              <Tag
                                value={
                                  filter?.label === 'Tags'
                                    ? savedFilters.tags.length
                                    : 1
                                }
                                variant={Tag.Variant.Remove}
                                className="bg-white rounded-pill"
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  handleDeleteTagFilter(filter);
                                }}
                              />
                            )}
                            <div className="d-flex align-items-center justify-content-end">
                              <Accordion.ItemState>
                                {({ expanded }) =>
                                  expanded ? (
                                    <Minus className="accordion-minus-icon" />
                                  ) : (
                                    <Plus className="accordion-plus-icon" />
                                  )
                                }
                              </Accordion.ItemState>
                            </div>
                          </div>
                        </div>
                        {allTags.size > 0 && !isSelectedFilter(filter) && (
                          <div className="filter-applied-collapse">
                            {Array.from(allTags)
                              .slice(0, 5)
                              .map((tag: any) => (
                                <span
                                  onMouseEnter={() =>
                                    setShowTagTooltip(tag?.label)
                                  }
                                  onMouseLeave={() => setShowTagTooltip(null)}
                                >
                                  <OverlayTrigger
                                    placement={Placement.BottomStart}
                                    overlay={getLebelTooltip(tag?.label)}
                                    show={
                                      showTagTooltip === tag?.label &&
                                      tag?.label?.length > 15
                                    }
                                  >
                                    <Tag
                                      key={tag?.label}
                                      value={
                                        tag?.label.length > 15
                                          ? `${tag?.label.substring(0, 15)}..`
                                          : tag?.label
                                      }
                                      variant={Tag.Variant.Remove}
                                      className="filter-tag"
                                      onClick={(e) => {
                                        e?.stopPropagation();
                                        handleIndividualTag(tag);
                                      }}
                                    />
                                  </OverlayTrigger>
                                </span>
                              ))}
                            {Array.from(allTags)?.length > 5 && (
                              <Tag
                                value={
                                  <div className="d-flex align-items-center">
                                    <Plus
                                      className="gray-txt-15 mr-1"
                                      width={13}
                                      height={13}
                                    />
                                    <p className="mb-0">
                                      {Array.from(allTags)?.length - 5}
                                    </p>
                                  </div>
                                }
                                className="filter-tag more-tag"
                              />
                            )}
                          </div>
                        )}
                      </Accordion.ItemButton>
                    </Accordion.ItemHeading>

                    <Accordion.ItemPanel>
                      {filter?.type?.map((subFilter) => (
                        <React.Fragment
                          key={`subfilter-${filter?.id}-${subFilter.id}`}
                        >
                          {subFilter?.value ===
                            FilterComponentType.TAG_CHECKBOX && (
                            <>
                              <div>
                                {isLeadTagsLoading ? (
                                  <SkeletonLoading height={32} width={235} />
                                ) : (
                                  <TagSavedFilters
                                    leadTags={leadTags}
                                    leadTagsIds={savedFilters.tags}
                                    onLeadTagsIdsChange={(leadTagsIds) => {
                                      setSavedFilters((prevFilters) => ({
                                        ...prevFilters,
                                        tags: leadTagsIds,
                                      }));
                                      handleQuery({
                                        filterKey: 'tags',
                                        filterValue: {
                                          tags: leadTagsIds,
                                        },
                                        filterType:
                                          FilterComponentType.TAG_CHECKBOX,
                                      });
                                    }}
                                  />
                                )}

                                {savedFilters.tags?.length > 0 && (
                                  <div className="pill">
                                    {savedFilters.tags.map((tagId) => {
                                      const tagValue = leadTags.find(
                                        (ele) => ele.id === tagId,
                                      );
                                      const handlePillClose = (idToRemove) => {
                                        const updatedTags = savedFilters.tags.filter(
                                          (tag) => tag !== idToRemove,
                                        );
                                        setSavedFilters((prevFilters) => ({
                                          ...prevFilters,
                                          tags: updatedTags,
                                        }));
                                        handleQuery({
                                          filterKey: 'tags',
                                          filterValue: {
                                            tags: updatedTags,
                                          },
                                          filterType:
                                            FilterComponentType.TAG_CHECKBOX,
                                        });
                                      };

                                      if (!tagValue || !tagId) {
                                        return;
                                      }
                                      // Render the 'name' property of the tagValue object
                                      return (
                                        <Pills
                                          theme="outline"
                                          size="md"
                                          className="pill-saved-filter pills-container"
                                          label={
                                            tagValue?.name?.length > 15
                                              ? `${tagValue?.name?.substring(
                                                  0,
                                                  15,
                                                )}...`
                                              : tagValue?.name
                                          }
                                          showCloseIcon={true}
                                          onClose={() => handlePillClose(tagId)}
                                          key={tagId}
                                        />
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </>
                          )}

                          {subFilter?.value ===
                            FilterComponentType.SAVED_LEAD_DATE && (
                            <>
                              <div className="lead-filter-date-piker">
                                <DateFilter
                                  onSelect={handleDateChange}
                                  values={savedFilters?.createdDate}
                                />
                              </div>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </Accordion.ItemPanel>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default SavedTab;
