export enum SubscriptionPlans {
  FREE = 'free',
  TRIAL = 'trial',
  PAID_MONTHLY = 'paid-monthly',
  PAID_YEARLY = 'paid-yearly',
  EMAIL_STARTER_MONTHLY = 'email-starter-monthly',
  EMAIL_STARTER_YEARLY = 'email-starter-yearly',
  EMAIL_OUTREACH_MONTHLY = 'email-outreach-monthly',
  EMAIL_OUTREACH_YEARLY = 'email-outreach-yearly',
  EMAIL_OUTREACH_LIFETIME = 'ltd-email-outreach',
  EMAIL_OUTREACH_PRO_LIFETIME = 'ltd-email-outreach-pro',
  OLD_OUTREACH_MONTHLY = 'old-outreach-monthly',
  OLD_OUTREACH_YEARLY = 'old-outreach-yearly',
  OUTREACH_BASIC_MONTHLY = 'email-outreach-basic-monthly',
  OUTREACH_BASIC_YEARLY = 'email-outreach-basic-yearly',
  OUTREACH_PRO_MONTHLY = 'email-outreach-pro-monthly',
  OUTREACH_PRO_YEARLY = 'email-outreach-pro-yearly',
  OUTREACH_SCALE_MONTHLY = 'email-outreach-scale-monthly',
  OUTREACH_SCALE_YEARLY = 'email-outreach-scale-yearly',
  OUTREACH_BASIC_MONTHLY_8_2023 = 'email-outreach-basic-monthly-08-2023',
  OUTREACH_BASIC_YEARLY_8_2023 = 'email-outreach-basic-yearly-08-2023',
  OUTREACH_PRO_MONTHLY_8_2023 = 'email-outreach-pro-monthly-08-2023',
  OUTREACH_PRO_YEARLY_8_2023 = 'email-outreach-pro-yearly-08-2023',
  OUTREACH_SCALE_MONTHLY_8_2023 = 'email-outreach-scale-monthly-08-2023',
  OUTREACH_SCALE_YEARLY_8_2023 = 'email-outreach-scale-yearly-08-2023',
  LEAD_FINDER_FREE = 'lead-finder-free',
  LEAD_FINDER_TRIAL = 'lead-finder-trial',
  LEAD_FINDER_EMAIL_STARTER_MONTHLY = 'lead-finder-email-starter-monthly',
  LEAD_FINDER_EMAIL_PRO_MONTHLY = 'lead-finder-email-pro-monthly',
  LEAD_FINDER_EMAIL_SCALE_MONTHLY = 'lead-finder-email-scale-monthly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_1_MONTHLY = 'lead-finder-email-scale-plus-1-monthly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_2_MONTHLY = 'lead-finder-email-scale-plus-2-monthly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_3_MONTHLY = 'lead-finder-email-scale-plus-3-monthly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_4_MONTHLY = 'lead-finder-email-scale-plus-4-monthly',
  LEAD_FINDER_EMAIL_PHONE_STARTER_MONTHLY = 'lead-finder-email-phone-starter-monthly',
  LEAD_FINDER_EMAIL_PHONE_PRO_MONTHLY = 'lead-finder-email-phone-pro-monthly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_MONTHLY = 'lead-finder-email-phone-scale-monthly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_1_MONTHLY = 'lead-finder-email-phone-scale-plus-1-monthly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_2_MONTHLY = 'lead-finder-email-phone-scale-plus-2-monthly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_3_MONTHLY = 'lead-finder-email-phone-scale-plus-3-monthly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_4_MONTHLY = 'lead-finder-email-phone-scale-plus-4-monthly',
  LEAD_FINDER_EMAIL_STARTER_YEARLY = 'lead-finder-email-starter-yearly',
  LEAD_FINDER_EMAIL_PRO_YEARLY = 'lead-finder-email-pro-yearly',
  LEAD_FINDER_EMAIL_SCALE_YEARLY = 'lead-finder-email-scale-yearly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_1_YEARLY = 'lead-finder-email-scale-plus-1-yearly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_2_YEARLY = 'lead-finder-email-scale-plus-2-yearly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_3_YEARLY = 'lead-finder-email-scale-plus-3-yearly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_4_YEARLY = 'lead-finder-email-scale-plus-4-yearly',
  LEAD_FINDER_EMAIL_PHONE_STARTER_YEARLY = 'lead-finder-email-phone-starter-yearly',
  LEAD_FINDER_EMAIL_PHONE_PRO_YEARLY = 'lead-finder-email-phone-pro-yearly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_YEARLY = 'lead-finder-email-phone-scale-yearly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_1_YEARLY = 'lead-finder-email-phone-scale-plus-1-yearly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_2_YEARLY = 'lead-finder-email-phone-scale-plus-2-yearly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_3_YEARLY = 'lead-finder-email-phone-scale-plus-3-yearly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_4_YEARLY = 'lead-finder-email-phone-scale-plus-4-yearly',
}

export enum LeadFinderSubscriptionPlans {
  LEAD_FINDER_FREE = 'lead-finder-free',
  LEAD_FINDER_TRIAL = 'lead-finder-trial',
  LEAD_FINDER_EMAIL_STARTER_MONTHLY = 'lead-finder-email-starter-monthly',
  LEAD_FINDER_EMAIL_PRO_MONTHLY = 'lead-finder-email-pro-monthly',
  LEAD_FINDER_EMAIL_SCALE_MONTHLY = 'lead-finder-email-scale-monthly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_1_MONTHLY = 'lead-finder-email-scale-plus-1-monthly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_2_MONTHLY = 'lead-finder-email-scale-plus-2-monthly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_3_MONTHLY = 'lead-finder-email-scale-plus-3-monthly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_4_MONTHLY = 'lead-finder-email-scale-plus-4-monthly',
  LEAD_FINDER_EMAIL_PHONE_STARTER_MONTHLY = 'lead-finder-email-phone-starter-monthly',
  LEAD_FINDER_EMAIL_PHONE_PRO_MONTHLY = 'lead-finder-email-phone-pro-monthly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_MONTHLY = 'lead-finder-email-phone-scale-monthly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_1_MONTHLY = 'lead-finder-email-phone-scale-plus-1-monthly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_2_MONTHLY = 'lead-finder-email-phone-scale-plus-2-monthly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_3_MONTHLY = 'lead-finder-email-phone-scale-plus-3-monthly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_4_MONTHLY = 'lead-finder-email-phone-scale-plus-4-monthly',
  LEAD_FINDER_EMAIL_STARTER_YEARLY = 'lead-finder-email-starter-yearly',
  LEAD_FINDER_EMAIL_PRO_YEARLY = 'lead-finder-email-pro-yearly',
  LEAD_FINDER_EMAIL_SCALE_YEARLY = 'lead-finder-email-scale-yearly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_1_YEARLY = 'lead-finder-email-scale-plus-1-yearly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_2_YEARLY = 'lead-finder-email-scale-plus-2-yearly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_3_YEARLY = 'lead-finder-email-scale-plus-3-yearly',
  LEAD_FINDER_EMAIL_SCALE_PLUS_4_YEARLY = 'lead-finder-email-scale-plus-4-yearly',
  LEAD_FINDER_EMAIL_PHONE_STARTER_YEARLY = 'lead-finder-email-phone-starter-yearly',
  LEAD_FINDER_EMAIL_PHONE_PRO_YEARLY = 'lead-finder-email-phone-pro-yearly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_YEARLY = 'lead-finder-email-phone-scale-yearly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_1_YEARLY = 'lead-finder-email-phone-scale-plus-1-yearly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_2_YEARLY = 'lead-finder-email-phone-scale-plus-2-yearly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_3_YEARLY = 'lead-finder-email-phone-scale-plus-3-yearly',
  LEAD_FINDER_EMAIL_PHONE_SCALE_PLUS_4_YEARLY = 'lead-finder-email-phone-scale-plus-4-yearly',
}

export const LeadFinderEmailOnlySubscriptionPlans = [
  'lead-finder-free',
  'lead-finder-trial',
  'lead-finder-email-starter-monthly',
  'lead-finder-email-pro-monthly',
  'lead-finder-email-scale-monthly',
  'lead-finder-email-scale-plus-1-monthly',
  'lead-finder-email-scale-plus-2-monthly',
  'lead-finder-email-scale-plus-3-monthly',
  'lead-finder-email-scale-plus-4-monthly',
  'lead-finder-email-starter-yearly',
  'lead-finder-email-pro-yearly',
  'lead-finder-email-scale-yearly',
  'lead-finder-email-scale-plus-1-yearly',
  'lead-finder-email-scale-plus-2-yearly',
  'lead-finder-email-scale-plus-3-yearly',
  'lead-finder-email-scale-plus-4-yearly',
];

export const LeadFinderEmailPhoneSubscriptionPlans = [
  'lead-finder-email-phone-starter-monthly',
  'lead-finder-email-phone-pro-monthly',
  'lead-finder-email-phone-scale-monthly',
  'lead-finder-email-phone-scale-plus-1-monthly',
  'lead-finder-email-phone-scale-plus-2-monthly',
  'lead-finder-email-phone-scale-plus-3-monthly',
  'lead-finder-email-phone-scale-plus-4-monthly',
  'lead-finder-email-phone-starter-yearly',
  'lead-finder-email-phone-pro-yearly',
  'lead-finder-email-phone-scale-yearly',
  'lead-finder-email-phone-scale-plus-1-yearly',
  'lead-finder-email-phone-scale-plus-2-yearly',
  'lead-finder-email-phone-scale-plus-3-yearly',
  'lead-finder-email-phone-scale-plus-4-yearly',
];

export enum SubscriptionPlanTitle {
  StarterMonthly = 'Starter (Monthly)',
  StarterYearly = 'Starter (Yearly)',
  OutreachMonthly = 'Outreach (Monthly)',
  OutreachYearly = 'Outreach (Yearly)',
  EmailOutreach = 'Email Outreach',
  EmailOutreachPro = 'Outreach (Lifetime)',
  CancelledProMonthly = 'Cancelled Pro ( Monthly )',
  CancelledProYearly = 'Cancelled Pro ( Yearly )',
  OutreachBasicMonthly = 'Outreach Starter (Monthly)',
  OutreachBasicYearly = 'Outreach Starter (Yearly)',
  OutreachProMonthly = 'Outreach Pro (Monthly)',
  OutreachProYearly = 'Outreach Pro (Yearly)',
  OutreachScaleMonthly = 'Outreach Scale (Monthly)',
  OutreachScaleYearly = 'Outreach Scale (Yearly)',
  OutreachScaleMonthly100k = 'Outreach Scale Plus 100K (Monthly)',
  OutreachScaleYearly100k = 'Outreach Scale Plus 100K (Yearly)',
  OutreachScaleMonthly200k = 'Outreach Scale Plus 200K (Monthly)',
  OutreachScaleYearly200k = 'Outreach Scale Plus 200K (Yearly)',
  OutreachScaleMonthly300k = 'Outreach Scale Plus 300K (Monthly)',
  OutreachScaleYearly300k = 'Outreach Scale Plus 300K (Yearly)',
  OutreachScaleMonthly400k = 'Outreach Scale Plus 400K (Monthly)',
  OutreachScaleYearly400k = 'Outreach Scale Plus 400K (Yearly)',
  OutreachScaleMonthly500k = 'Outreach Scale Plus 500K (Monthly)',
  OutreachScaleYearly500k = 'Outreach Scale Plus 500K (Yearly)',

  Custom = 'Custom',

  LeadFinderFree = 'Lead Free',
  LeadFinderTrial = 'Lead Trial',
  LeadFinderEmailStarterMonthly = 'Lead Starter (Monthly)',
  LeadFinderEmailProMonthly = 'Lead Pro (Monthly)',
  LeadFinderEmailScaleMonthly = 'Lead Scale (Monthly)',
  LeadFinderEmailScalePlus1Monthly = 'Lead Scale Plus 10K (Monthly)',
  LeadFinderEmailScalePlus2Monthly = 'Lead Scale Plus 20K (Monthly)',
  LeadFinderEmailScalePlus3Monthly = 'Lead Scale Plus 50K (Monthly)',
  LeadFinderEmailScalePlus4Monthly = 'Lead Scale Plus 100K (Monthly)',
  LeadFinderEmailStarterYearly = 'Lead Starter (Yearly)',
  LeadFinderEmailProYearly = 'Lead Pro (Yearly)',
  LeadFinderEmailScaleYearly = 'Lead Scale (Yearly)',
  LeadFinderEmailScalePlus1Yearly = 'Lead Scale Plus 10K (Yearly)',
  LeadFinderEmailScalePlus2Yearly = 'Lead Scale Plus 20K (Yearly)',
  LeadFinderEmailScalePlus3Yearly = 'Lead Scale Plus 50K (Yearly)',
  LeadFinderEmailScalePlus4Yearly = 'Lead Scale Plus 100K (Yearly)',
  LeadFinderEmailPhoneStarterMonthly = 'Lead Starter (Email+Phone) (Monthly)',
  LeadFinderEmailPhoneProMonthly = 'Lead Pro (Email+Phone) (Monthly)',
  LeadFinderEmailPhoneScaleMonthly = 'Lead Scale (Email+Phone) (Monthly)',
  LeadFinderEmailPhoneScalePlus1Monthly = 'Lead Scale Plus 10K (Email+Phone) (Monthly)',
  LeadFinderEmailPhoneScalePlus2Monthly = 'Lead Scale Plus 20K (Email+Phone) (Monthly)',
  LeadFinderEmailPhoneScalePlus3Monthly = 'Lead Scale Plus 50K (Email+Phone) (Monthly)',
  LeadFinderEmailPhoneScalePlus4Monthly = 'Lead Scale Plus 100K (Email+Phone) (Monthly)',
  LeadFinderEmailPhoneStarterYearly = 'Lead Starter (Email+Phone) (Yearly)',
  LeadFinderEmailPhoneProYearly = 'Lead Pro (Email+Phone) (Yearly)',
  LeadFinderEmailPhoneScaleYearly = 'Lead Scale (Email+Phone) (Yearly)',
  LeadFinderEmailPhoneScalePlus1Yearly = 'Lead Scale Plus 10K (Email+Phone) (Yearly)',
  LeadFinderEmailPhoneScalePlus2Yearly = 'Lead Scale Plus 20K (Email+Phone) (Yearly)',
  LeadFinderEmailPhoneScalePlus3Yearly = 'Lead Scale Plus 50K (Email+Phone) (Yearly)',
  LeadFinderEmailPhoneScalePlus4Yearly = 'Lead Scale Plus 100K (Email+Phone) (Yearly)',
}

export enum SubscriptionPlanPrice {
  StarterMonthly = 9,
  StarterYearly = 7,
  OutreachMonthly = 33,
  OutreachYearly = 25,
  EmailOutreachLifetime = 99,
  EmailOutreachProMonthly = 59,
  EmailOutreachProYearly = 49,
  EmailOutreachProLifetime = 79,
  OldOureachMonthly = 29,
  OldOureachYearly = 25,
  OutreachBasicMonthly = 34,
  OutreachBasicYearly = 27,
  OutreachProMonthly = 94,
  OutreachProYearly = 79,
  OutreachScaleMonthly = 179,
  OutreachScaleYearly = 159,
}

export enum SubscriptionPlanModes {
  MonthlyMode = 1,
  YearlyMode = 12,
  LTDMode = 0,
}

export enum ModifyPlanAction {
  Update = 'update',
  Downgrade = 'downgrade',
  AddSlots = 'add-slots',
}

export enum PlanPurchaseAction {
  Upgrade = 'upgrade',
  Downgrade = 'downgrade',
  Subscribe = 'subscribe',
}

export enum PlanType {
  User = 'user',
  EmailAccount = 'email-account',
  Slot = 'slot',
  ValueBased = 'value-based',
  LTD = 'ltd',
  LTD2 = 'ltd-2',
}

export const SubscriptionPlanIdMap = Object.freeze({
  free: 1,
  trial: 2,
  [SubscriptionPlans.PAID_MONTHLY]: 3,
  [SubscriptionPlans.PAID_YEARLY]: 5,
  [SubscriptionPlans.EMAIL_STARTER_MONTHLY]: 17,
  [SubscriptionPlans.EMAIL_STARTER_YEARLY]: 18,
  [SubscriptionPlans.EMAIL_OUTREACH_MONTHLY]: 19,
  [SubscriptionPlans.EMAIL_OUTREACH_YEARLY]: 20,
  [SubscriptionPlans.EMAIL_OUTREACH_LIFETIME]: 10,
  [SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME]: 13,
  [SubscriptionPlans.OUTREACH_BASIC_MONTHLY]: 21,
  [SubscriptionPlans.OUTREACH_PRO_MONTHLY]: 22,
  [SubscriptionPlans.OUTREACH_SCALE_MONTHLY]: 23,
  [SubscriptionPlans.OUTREACH_BASIC_YEARLY]: 24,
  [SubscriptionPlans.OUTREACH_PRO_YEARLY]: 25,
  [SubscriptionPlans.OUTREACH_SCALE_YEARLY]: 26,
  [SubscriptionPlans.OUTREACH_BASIC_MONTHLY_8_2023]: 77,
  [SubscriptionPlans.OUTREACH_PRO_MONTHLY_8_2023]: 78,
  [SubscriptionPlans.OUTREACH_SCALE_MONTHLY_8_2023]: 79,
  [SubscriptionPlans.OUTREACH_BASIC_YEARLY_8_2023]: 80,
  [SubscriptionPlans.OUTREACH_PRO_YEARLY_8_2023]: 81,
  [SubscriptionPlans.OUTREACH_SCALE_YEARLY_8_2023]: 82,
});
