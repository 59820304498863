import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StripeCheckout from 'react-stripe-checkout';
import type { IProps } from './billing-summary-modal-container';
import Modal from '../../../../../../../../shared/design-system/components/atoms/modal';
import Button from '../../../../../../../../shared/design-system/components/atoms/button';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';
import Input from '../../../../../../../../shared/design-system/components/input/input';
import { validate } from '../../../../../../../../shared/design-system/components/molecules/upgrade-plan-billing-section/validator';
import { isRequestPending } from '../../../../utils/helper';
import { Images } from '../../../../../../../../shared/app-constants';
import { getSubtotalDiscountAmount } from '../../../../../../../../shared/design-system/components/molecules/upgrade-plan-billing-section/helper/helper';
import { executeOnRequestStatus } from '../../../../../../../../shared/utils/execute-on-request-status';
import { supportUrls } from '../../../../../../../../shared/utils/urls';
import { getTotalAmountForOutreachPlan } from './utils/helper';
import ImageIcon from '../../../../../../../../shared/components/images/image-icon';
import { AnalyticsEvents } from '../../../../../../../../shared/enums/analytics';

const BillingSummaryModal: React.FC<IProps> = ({
  show,
  onClose,
  selectedPlanDetails,
  couponCodeDiscount,
  couponCodeDiscountType,
  isUserModifyingSubscription,
  onPurchase,
  //* Request Response State
  sendApplyCouponCodeRequestMessage,
  isSendApplyCouponCodeRequestError,
  sendApplyCouponCodeRequestStatus,
  sendPurchaseLeadFinderSubscriptionRequestStatus,
  sendPurchaseLeadFinderModifySubscriptionRequestStatus,
  sendGetCalculatePayResponse,
  sendGetCalculatePayRequestStatus,
  //* API Request
  sendApplyCouponCodeRequest,
  //* Redux Util Function
  deleteAppliedCouponCode,
  currentPlanName,
}) => {
  const { t } = useTranslation();
  const totalAmount = selectedPlanDetails?.amount;
  const planId = Number(selectedPlanDetails?.id);
  const planName = selectedPlanDetails?.planName;
  const isApplyCouponCodeInProgress = isRequestPending(
    sendApplyCouponCodeRequestStatus,
  );
  const isPurchaseInProgress =
    isRequestPending(sendPurchaseLeadFinderSubscriptionRequestStatus) ||
    isRequestPending(sendPurchaseLeadFinderModifySubscriptionRequestStatus);

  const totalAmountToPay: number = getTotalAmountForOutreachPlan({
    totalAmount: Number(totalAmount),
    discountType: couponCodeDiscountType,
    discount: couponCodeDiscount,
    sendGetCalculatePayResponse,
  });

  const [havePromoCode, setHavePromoCode] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [error, setError] = useState<string>('');

  const userHasPromoCode = (value) => {
    setHavePromoCode(value);
  };

  const onCouponCodeChange = (value) => {
    setCouponCode(value);
  };

  const handleDeleteAppliedCouponCode = () => {
    onCouponCodeChange('');
    deleteAppliedCouponCode();
    userHasPromoCode(false);
  };

  const onApplyCouponCode = (e) => {
    e.preventDefault();

    let isError = false;

    const checkError = validate('couponCode', couponCode);
    isError = isError || !!checkError;
    setError(checkError);

    if (isError) {
      return;
    }

    sendApplyCouponCodeRequest({
      couponCode,
      amount: Number(totalAmountToPay),
      planId,
    });
  };

  const onPurchaseHandler = (token) => {
    onPurchase(token, couponCode);
  };

  const handleModalClose = () => {
    handleDeleteAppliedCouponCode();
    onClose();
  };

  const handlePayNowAnalyticsEvent = () => {
    window.analytics?.track?.({
      event: AnalyticsEvents.ClickedOnPayNow,
      properties: {
        Source: 'Subscription',
        current_plan: currentPlanName,
        'Clicked Plan': planName,
      },
    });
  };

  //* useEffect
  // Purchase subscription
  useEffect(() => {
    executeOnRequestStatus({
      status: sendPurchaseLeadFinderSubscriptionRequestStatus,
      onFailed: () => {
        handleDeleteAppliedCouponCode();
      },
    });
  }, [sendPurchaseLeadFinderSubscriptionRequestStatus]);

  // Modify subscription
  useEffect(() => {
    executeOnRequestStatus({
      status: sendPurchaseLeadFinderModifySubscriptionRequestStatus,
      onFailed: () => {
        handleDeleteAppliedCouponCode();
      },
    });
  }, [sendPurchaseLeadFinderModifySubscriptionRequestStatus]);

  const renderCouponCodeView = () => (
    <div className="promo-code-container">
      {havePromoCode && (
        <div className="coupon-code-input-wrapper mt-3">
          <Input
            name="couponCode"
            placeholder="Enter Coupon"
            value={couponCode}
            variant={
              ((error || isSendApplyCouponCodeRequestError) &&
                Input.Variant.Error) ||
              (couponCodeDiscount && Input.Variant.Succeeded)
            }
            caption={error || sendApplyCouponCodeRequestMessage}
            onChange={onCouponCodeChange}
            className="coupon-code-input"
            button={
              !isApplyCouponCodeInProgress &&
              !couponCodeDiscount && {
                buttonTitle: 'Apply',
                onClick: (e) => onApplyCouponCode(e),
              }
            }
            icons={[
              isApplyCouponCodeInProgress && {
                place: Input.IconPlace.Right,
                identifier: 'spinner-alt',
                colorDefault: true,
                className: 'icon-loading',
              },
              ...(couponCodeDiscount
                ? [
                    {
                      place: Input.IconPlace.Right,
                      identifier: 'trash',
                      colorDefault: true,
                      className: 'icon-1 icon-2',
                      onClick: () => handleDeleteAppliedCouponCode(),
                    },
                  ]
                : []),
            ]}
          />
        </div>
      )}

      {couponCodeDiscount && (
        <div className="d-flex justify-content-between subtotal-discount-amount mt-4">
          <span className="semibold-1 popover-arrow-color-txt">
            {t('labels.discount')}
          </span>
          <span className="semibold-2 popover-arrow-color-txt">{`$${getSubtotalDiscountAmount(
            couponCodeDiscountType,
            Number(totalAmount),
            couponCodeDiscount,
          )}`}</span>
        </div>
      )}
    </div>
  );

  const renderStripeButton = () => (
    <StripeCheckout
      token={onPurchaseHandler}
      name="Saleshandy"
      image={Images.SHIcon70}
      stripeKey={process.env.REACT_APP_STRIPE_KEY}
      amount={totalAmountToPay * 100}
      zipCode
      billingAddress
      ComponentClass="div"
    >
      <Button
        onClick={() => handlePayNowAnalyticsEvent()}
        variant={Button.Variant.Primary}
        isLoading={isPurchaseInProgress}
        disabled={isPurchaseInProgress}
        className="solid-primary-new w-100"
      >
        {t('labels.pay_now')}
      </Button>
    </StripeCheckout>
  );

  return (
    <Modal
      show={show}
      className="billing-summary-modal"
      showCloseIcon={true}
      onClose={handleModalClose}
      hideFooter={true}
      backdrop="static"
      titleContent="Billing Summary"
    >
      <div className="d-flex justify-content-between align-items-center">
        <span className="regular-1 gray-txt-12">Plan Name</span>
        <span className="regular-2 font-medium popover-arrow-color-txt">
          {planName}
        </span>
      </div>

      <div className="divider-bottom">
        <div className="d-flex justify-content-between align-items-center">
          <span className="regular-1 gray-txt-12">Total Amount</span>
          <span className="semibold-2 popover-arrow-color-txt">
            ${totalAmount}
          </span>
        </div>
        <div className="d-flex flex-column align-items-end">
          {!havePromoCode && (
            <span
              role="button"
              className="font-10 font-medium blue-txt-11 mt-1 pointer"
              {...accessibleOnClick(userHasPromoCode)}
            >
              Have a discount code?
            </span>
          )}
        </div>
        {renderCouponCodeView()}
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <span className="semibold-1 popover-arrow-color-txt">
          Total Amount to pay today
        </span>
        <span className="semibold-2 popover-arrow-color-txt">
          {isRequestPending(sendGetCalculatePayRequestStatus) ? (
            <div className="loader-container d-flex justify-content-center align-items-center">
              <ImageIcon src={Images.Loader} />
            </div>
          ) : (
            `$${totalAmountToPay}`
          )}
        </span>
      </div>

      {isUserModifyingSubscription && (
        <div className="text-center mt-3">
          <span className="regular-1 font-10 gray-txt-15 ">
            This amount is calculated on a pro-rata basis
          </span>
        </div>
      )}

      <div className="mt-2">
        <p className="regular-1 font-10 gray-txt-15 text-center">
          Your subscription will auto-renew until you cancel. By upgrading, you
          agree to our{' '}
          <a
            href={supportUrls.refundPolicy}
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            refund policy.
          </a>
        </p>
      </div>

      {renderStripeButton()}
    </Modal>
  );
};

export default BillingSummaryModal;
