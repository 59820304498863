import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { DateFilterDropDown } from './types';
import {
  formatDateRangeForDatePickerTooltip,
  getDateObj,
  handleClickOutside,
  handleDateRangeChange,
  handleOnSelect,
} from './helper';
import Select from '../../design-system/components/select';
import durationOptions, { getCurrentTimeInUTC } from './date-filter-options';
import ImageIcon from '../images/image-icon';
import { Images } from '../../app-constants';
import DatePickerComponent from '../../design-system/components/atoms/date-picker/DatePicker';
import { OverlayTooltip } from '../../design-system/components/overlay';
import { getUserTimeZone } from '../../utils/user-details';

const DateFilter = ({
  selectedDateRangeOption,
  startDate,
  endDate,
  displayText,
  onDateTimeSelectionChange,
  durationOptions: customDurationOptions = durationOptions,
  shouldHaveMinMaxValueLimit = true,
  shouldHaveRangeLimit = false,
  shouldShowDateTooltip = false,
  shouldHaveMaxValueLimit = false,
  shouldHaveMinValueLimit = false,
  shouldHaveOneYearLimit = false,
}) => {
  const dateFilterRef = useRef<HTMLDivElement>(null);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [datePickerToolTipText, setDatePickerToolTipText] = useState('');
  const [dateFilter, setDateFilter] = useState({
    selectedDateRangeOption,
    startDate,
    endDate,
    displayText,
  });

  const selectDisplayText = (start: string, end: string) => {
    const { startDateObj, endDateObj } = getDateObj(start, end);

    const dateFormat = 'DD MMM, YY';

    if (!endDateObj) {
      return <span>{moment(startDateObj).format(dateFormat)}</span>;
    }
    return (
      <span>
        {moment(startDateObj).format(dateFormat)} -{' '}
        {moment(endDateObj).format(dateFormat)}
      </span>
    );
  };

  const onDateRangeChangeHandler = (value, isCustom = false) => {
    if (dateFilter?.selectedDateRangeOption === value) {
      return;
    }

    handleDateRangeChange({
      value,
      isCustom,
      setDateFilter,
      onDateTimeSelectionChange,
      customDurationOptions,
      shouldHaveRangeLimit,
      shouldHaveOneYearLimit,
    });
  };

  const onSelectHandler = (value: string, isCustom: boolean, key: string) => {
    handleOnSelect({
      value,
      isCustom,
      isDatePickerOpen,
      setIsDatePickerOpen,
      onDateRangeChangeHandler,
    });
  };

  const onClickOutSide = (event) => {
    handleClickOutside({
      event,
      dateFilterRef,
      setIsDatePickerOpen,
    });
  };

  const isDividerVisible = (key) => key === 'this_week' || key === 'custom';

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutSide);

    return () => {
      document.removeEventListener('mousedown', onClickOutSide);
    };
  }, []);

  useEffect(() => {
    if (shouldShowDateTooltip) {
      const timeZone = getUserTimeZone();
      const date = formatDateRangeForDatePickerTooltip(
        startDate,
        endDate,
        timeZone,
      );
      setDatePickerToolTipText(date);
    }
  }, [selectedDateRangeOption]);

  return (
    <div className="date-filter-select-wrap" ref={dateFilterRef}>
      <Select<DateFilterDropDown>
        className="date-filter-select"
        options={customDurationOptions}
        selectedOptionKey={selectedDateRangeOption}
        selectedOptionRenderer={([option]) => {
          if (shouldShowDateTooltip && datePickerToolTipText?.length) {
            return (
              <OverlayTooltip text={datePickerToolTipText ?? ''}>
                {option?.displayText === 'Custom' ? (
                  <div className="date-renderer">
                    <ImageIcon src={Images.Calendar} />
                    {selectDisplayText(startDate, endDate)}
                  </div>
                ) : (
                  <div className="date-renderer">
                    <ImageIcon src={Images.Calendar} />
                    <span>{option?.displayText}</span>
                  </div>
                )}
              </OverlayTooltip>
            );
          }
          return option?.displayText === 'Custom' ? (
            <div className="date-renderer">
              <ImageIcon src={Images.Calendar} />
              {selectDisplayText(startDate, endDate)}
            </div>
          ) : (
            <div className="date-renderer">
              <ImageIcon src={Images.Calendar} />
              <span>{option?.displayText}</span>
            </div>
          );
        }}
        onChange={([option]) =>
          onSelectHandler(option.value, option.value === 'custom', option.key)
        }
        optionRenderer={(option) => (
          <span
            className={`date-option-renderer ${
              isDividerVisible(option.key) && 'br-top'
            }`}
          >
            {option.displayText}
          </span>
        )}
      />
      {isDatePickerOpen && (
        <div className="report-date-picker">
          <DatePickerComponent
            start_date={
              dateFilter.selectedDateRangeOption === 'custom' &&
              new Date(dateFilter.startDate)
            }
            onDateChange={(date) => onDateRangeChangeHandler(date, true)}
            end_date={
              dateFilter.selectedDateRangeOption === 'custom' &&
              dateFilter.endDate
                ? new Date(dateFilter.endDate)
                : null
            }
            {...(shouldHaveMinMaxValueLimit &&
              !shouldHaveRangeLimit && {
                min_date: new Date(
                  getCurrentTimeInUTC().minus({ days: 90 }).toString(),
                ),
                max_date: new Date(),
              })}
            {...(shouldHaveMaxValueLimit && {
              max_date: new Date(),
            })}
            {...(shouldHaveMinValueLimit && {
              min_date: new Date(
                getCurrentTimeInUTC().minus({ days: 90 }).toString(),
              ),
            })}
          />
        </div>
      )}
    </div>
  );
};

export default DateFilter;
