import api from '../../../api';
import { dateToString } from '../../../shared/functions/date';
import { ResponseSuccess } from '../../../shared/types';
import {
  AddTagsToLeadsPayload,
  BulkActionPayload,
  BulkActionRemoveTagsPayload,
  BulkAddToProspectSavedDto,
  BulkAddToSequenceSavedDto,
  BulkLeadExportSavedDto,
  BulkLeadRevealPeopleDto,
  RemoveTagsToLeadsPayload,
  RevealSingleLeadPayload,
} from '../type';

export const getLeads = async (leadPayload) => {
  const queryParams = new URLSearchParams(window.location?.search);
  if (queryParams?.get('purgeCache') === '1') {
    leadPayload.purgeCache = true;
  }
  return api.post<ResponseSuccess>(`/lead-finder/leads/search`, {
    ...leadPayload,
  });
};

export const fetchLeadStatusPoll = async (leadPayload) =>
  api.post<ResponseSuccess>(`/lead-finder/status`, {
    ...leadPayload,
  });

export const getSavedLeads = async (leadPayload) => {
  const keyword = leadPayload?.keyword;
  const tags = leadPayload?.tags;
  const createdDate = leadPayload?.createdDate;
  let queryParams = `start=${leadPayload?.start}&take=${leadPayload?.take}`;
  if (keyword) {
    queryParams += `&search=${keyword}`;
  }
  if (tags) {
    queryParams += `&tags=${tags}`;
  }
  if (createdDate) {
    queryParams += `&createdDate=${createdDate}`;
  }
  return api.get<ResponseSuccess>(`/lead-finder/leads?${queryParams}`);
};

export const editSavedFilterAPI = async (filterId, apiParam) =>
  api.patch(
    // eslint-disable-next-line no-underscore-dangle
    `/lead-finder/leads/filters/${filterId}`,
    apiParam,
  );

export const deleteSavedFilterAPI = async (filterId) =>
  api.delete(
    // eslint-disable-next-line no-underscore-dangle
    `/lead-finder/leads/filters/${filterId}`,
  );

export const addSavedFilterAPI = async (apiParam) =>
  api.post(`/lead-finder/leads/filters`, apiParam);

export const getSavedFilterListAPI = async () =>
  api.get(`/lead-finder/leads/filters`);

export const getFilterSuggestionAPI = async ({ type, search }) =>
  api.get(`/lead-finder/filter-suggestion/${type}?search=${search}`);

export const revealSingleLeadAPI = async (payload: RevealSingleLeadPayload) => {
  const { revealLeadId, ...restPayload } = payload;
  return api.post(`/lead-finder/leads/reveal/${revealLeadId}`, restPayload);
};

export const leadAddToProspectAPI = async (prospectId) =>
  api.get(`/lead-finder/leads/add-to-prospect/${prospectId}`);

export const leadAddToSequenceAPI = async (leadId, payload) =>
  api.post(`/lead-finder/leads/add-to-sequence/${leadId}`, payload);

export const bulkRevealLeadAPI = async (payload) =>
  api.post(`/lead-finder/leads/bulk-actions/reveal`, payload);

export const bulkPeopleRevealLeadAPI = async (
  payload: BulkLeadRevealPeopleDto,
) => {
  const filteredPayload: BulkLeadRevealPeopleDto = {
    ...payload,
    ...(payload?.tagIds?.length > 0 && {
      tagIds: payload?.tagIds,
    }),
    ...(payload?.newTags?.length > 0 && {
      newTags: payload?.newTags,
    }),
  };

  return api.post(
    `/lead-finder/leads/bulk-actions/people-reveal`,
    filteredPayload,
  );
};

export const bulkAddToProspectLeadAPI = async (payload) =>
  api.post(`/lead-finder/leads/bulk-actions/add-to-prospect`, payload);

export const bulkAddToSequenceLeadAPI = async (payload) =>
  api.post(`/lead-finder/leads/bulk-actions/add-to-sequence`, payload);

export const bulkSavedAddToSequenceLeadAPI = async (
  payload: BulkAddToSequenceSavedDto,
) => {
  const filteredPayload: BulkAddToSequenceSavedDto = {
    ...payload,
    leadsFilter: {
      ...(payload.leadsFilter?.tags?.length > 0 && {
        tags: payload.leadsFilter?.tags,
      }),
      ...(payload.leadsFilter?.search?.length > 0 && {
        search: payload.leadsFilter?.search,
      }),
      ...(payload.leadsFilter?.createdDate?.length > 0 && {
        createdDate: payload.leadsFilter?.createdDate,
      }),
    },
  };
  return api.post(
    `/lead-finder/leads/bulk-actions/saved-add-to-sequence`,
    filteredPayload,
  );
};

export const bulkSavedAddToProspectLeadAPI = async (
  payload: BulkAddToProspectSavedDto,
) => {
  const filteredPayload: BulkAddToProspectSavedDto = {
    ...payload,
    leadsFilter: {
      ...(payload.leadsFilter?.tags?.length > 0 && {
        tags: payload.leadsFilter?.tags,
      }),
      ...(payload.leadsFilter?.search?.length > 0 && {
        search: payload.leadsFilter?.search,
      }),
      ...(payload.leadsFilter?.createdDate?.length > 0 && {
        createdDate: payload.leadsFilter?.createdDate,
      }),
    },
  };
  return api.post(
    `/lead-finder/leads/bulk-actions/saved-add-to-prospect`,
    filteredPayload,
  );
};

export const bulkExportLeadAPI = async (payload) =>
  api.post(`/lead-finder/leads/export`, payload);

export const exportAllLeadsAPI = async (payload: BulkLeadExportSavedDto) => {
  const dates = payload?.leadsFilter?.createdDate || [];
  let dateString = [];
  if (dates?.length) {
    const startDate = dates[0];
    const endDate = dates[1];
    if (startDate && endDate) {
      dateString = [dateToString(startDate), dateToString(endDate)];
    }
  }
  const filteredPayload: BulkAddToProspectSavedDto = {
    ...payload,
    leadsFilter: {
      ...(payload.leadsFilter?.tags?.length > 0 && {
        tags: payload.leadsFilter?.tags,
      }),
      ...(payload.leadsFilter?.search?.length > 0 && {
        search: payload.leadsFilter?.search,
      }),
      ...(dates?.length > 0 && {
        createdDate: dateString,
      }),
    },
  };
  return api.post(`/lead-finder/leads/export-async`, filteredPayload);
};

export const getTagsToLeads = async () => api.get(`/lead-finder/tags`);

export const addTagsToLeads = async ({
  tagIds,
  leads,
  newTags,
}: AddTagsToLeadsPayload) =>
  api.post<ResponseSuccess>('/lead-finder/tag/assign', {
    tagIds,
    leads,
    newTags,
  });

export const removeTagsToLeads = async ({
  tagIds,
  leads,
}: RemoveTagsToLeadsPayload) =>
  api.post<ResponseSuccess>('/lead-finder/tag/un-assign', {
    tagIds,
    leads,
  });

export const addTagsToBulkLeads = async (payload: BulkActionPayload) => {
  const filteredPayload: BulkActionPayload = {
    ...payload,
    leadsFilter: {
      ...(payload.leadsFilter?.tags?.length > 0 && {
        tags: payload.leadsFilter?.tags,
      }),
      ...(payload.leadsFilter?.search?.length > 0 && {
        search: payload.leadsFilter?.search,
      }),
      ...(payload.leadsFilter?.createdDate?.length > 0 && {
        createdDate: payload.leadsFilter?.createdDate,
      }),
    },
  };
  return api.post<ResponseSuccess>(
    '/lead-finder/tag/bulk-assign',
    filteredPayload,
  );
};

export const removeTagsFromBulkLeads = async (
  payload: BulkActionRemoveTagsPayload,
) => {
  const filteredPayload: BulkActionRemoveTagsPayload = {
    ...payload,
    leadsFilter: {
      ...(payload.leadsFilter?.tags?.length > 0 && {
        tags: payload.leadsFilter?.tags,
      }),
      ...(payload.leadsFilter?.search?.length > 0 && {
        search: payload.leadsFilter?.search,
      }),
      ...(payload.leadsFilter?.createdDate?.length > 0 && {
        createdDate: payload.leadsFilter?.createdDate,
      }),
    },
  };
  return api.post<ResponseSuccess>(
    '/lead-finder/tag/bulk-un-assign',
    filteredPayload,
  );
};
