import { isNull, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InfoCircle } from '@saleshandy/icons';
import { useSelector } from 'react-redux';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import MultiSelect from '../../../../../../shared/design-system/components/atoms/multi-select';
import Input from '../../../../../../shared/design-system/components/input';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { CustomDomainType } from '../../../../types/custom-domain';
import { DomainModal } from '../../custom-domain';
import CustomDomainDnsRecord from '../custom-domain-dns-record';
import { AgencyConfig } from '../../../../../home/types';
import { supportUrls } from '../../../../../../shared/utils/urls';
import Alert from '../../../../../../shared/design-system/ui/alert';
import { RootState } from '../../../../../../store/root-reducer';

export type IProps = {
  show: boolean;
  hide: () => void;
  verifyAndSave: (newDomain, emailAccountIds) => void;
  verificationStatus: RequestStatus;
  modalTitle?: DomainModal;
  customDomain?: CustomDomainType;
  emailAccounts?: MultiSelectOption[];
  selectedValues?: MultiSelectOption[];
  agencyConfig: Partial<AgencyConfig>;
  isEmailAccountsLoading?: boolean;
};

export type MultiSelectOption = {
  value: string;
  label: string;
};

const CustomDomainModal: React.FC<IProps> = ({
  show,
  hide,
  modalTitle,
  customDomain,
  emailAccounts,
  verifyAndSave,
  verificationStatus,
  selectedValues,
  agencyConfig,
  isEmailAccountsLoading,
}) => {
  // states.
  const [hostName, setHostName] = useState<string>();
  const [domain, setDomain] = useState<string>();
  const [domainInputField, setDomainInputField] = useState<{ error: string }>({
    error: null,
  });
  const [emailAccountIds, setEmailAccountIds] = useState<number[]>([]);
  const { REACT_APP_TRACKING_SERVICE_HOST } = process.env;
  const domainValue = REACT_APP_TRACKING_SERVICE_HOST;

  const createCustomDomainRequestError = useSelector(
    (state: RootState) =>
      state.customDomain?.createCustomDomainRequest?.error?.message,
  );
  const updateCustomDomainRequestError = useSelector(
    (state: RootState) =>
      state.customDomain?.updateCustomDomainRequest?.error?.message,
  );

  const setHostAndDomain = (value: string) => {
    if (!(isNull(value) || isUndefined(value))) {
      const domainArray = value.split('.');
      setHostName(domainArray[0]);
      setDomain(value);
    }
  };

  // lifecycle functions.
  useEffect(
    () => () => {
      setDomain('');
      setHostName('');
      setEmailAccountIds(null);
    },
    [],
  );

  useEffect(() => {
    if (customDomain) {
      setHostAndDomain(customDomain.domain);
    }
    if (selectedValues) {
      const optionValues = selectedValues.map((option) => Number(option.value));
      setEmailAccountIds(optionValues);
    }
  }, [customDomain, selectedValues]);

  useEffect(() => {
    if (createCustomDomainRequestError) {
      setDomainInputField({ error: createCustomDomainRequestError });
    }
    if (updateCustomDomainRequestError) {
      setDomainInputField({ error: updateCustomDomainRequestError });
    }
  }, [createCustomDomainRequestError, updateCustomDomainRequestError]);

  // handlers.
  const multiSelectHandler = (options) => {
    const optionValues = options.map((option) => Number(option.value));
    setEmailAccountIds(optionValues);
  };

  const onDomainChangeHandler = (value: string) => {
    setHostAndDomain(value);
  };

  const onCloseModal = () => {
    hide();
  };

  const onVerifyClickHandler = () => {
    if (!domain) {
      setDomainInputField({ error: 'Please enter your domain.' });
      return;
    }
    setDomainInputField({ error: null });
    verifyAndSave(domain, emailAccountIds);
  };

  // render.
  return (
    <Modal
      show={show}
      className="custom-domain-modal-container"
      titleContent={modalTitle}
      onClose={onCloseModal}
      onSubmit={onVerifyClickHandler}
      showCloseIcon={true}
      submitButtonText="Verify &amp; Save"
      isSubmitLoading={verificationStatus === RequestStatus.Pending}
      backdrop="static"
      extraModalProps={{
        centered: true,
        'aria-labelledby': 'contained-modal-title-vcenter',
      }}
    >
      <Row>
        <Col className="mb-1">
          <div className="modal-text pb-1">Email Accounts</div>
          <MultiSelect
            placeholder="Select"
            options={emailAccounts}
            defaultValue={selectedValues}
            onChange={(options) => multiSelectHandler(options)}
            className="multi-select"
            classNamePrefix="multi-select"
            disabled={customDomain.isDefault}
            isLoading={isEmailAccountsLoading}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="modal-text pb-1 mt-3">
            Custom tracking domain name
          </div>
          <Input
            defaultValue={customDomain ? customDomain.domain : null}
            value={domain}
            variant={domainInputField.error && Input.Variant.Error}
            caption={domainInputField.error}
            placeholder="e.g. go.getsaleshandy.com"
            onChange={(value) => onDomainChangeHandler(value)}
          />
        </Col>
      </Row>
      <CustomDomainDnsRecord host={hostName} value={domainValue} />
      {agencyConfig?.showFAQ && (
        <Alert
          className="domain-msg"
          variant="info"
          icon={InfoCircle}
          description={
            <span>
              You’ll need access to your domain hosting service to configure
              custom tracking domain. You will need to add DNS records, CNAME
              and TXT records. Copy host value for each record and add them to
              your domain manager. Refer{' '}
              <a
                href={supportUrls.ctdBLog}
                target="_blank"
                rel="noreferrer noopener"
              >
                this
              </a>{' '}
              article to know more.
            </span>
          }
        />
      )}
    </Modal>
  );
};

export default CustomDomainModal;
