import { BillingOption } from '../../../../../../../shared/utils/billing-options';
import {
  PlanPurchaseAction,
  PlanType,
  SubscriptionPlanTitle,
  SubscriptionPlans,
} from '../../../../../../../shared/utils/subscription-plans';

export enum UpgradePlanQueryParamsKey {
  Action = 'action',
  Plan = 'plan',
  Period = 'period',
}

export const isValueBasedPlan = (planType: PlanType) =>
  planType === PlanType.ValueBased;

export const getPlanIndex = (plans, planId) =>
  plans
    .filter(({ planCode }) => isValueBasedPlan(planCode))
    .findIndex(({ id }) => id === planId);

export const getPlanPeriod = (planCycle: BillingOption) =>
  planCycle === BillingOption.MONTHLY ? '1' : '12';

export const getSubscribeAction = (isUserModifyingSubscription: boolean) =>
  isUserModifyingSubscription
    ? PlanPurchaseAction.Upgrade
    : PlanPurchaseAction.Subscribe;

export const isPremiumFeatureBannerShow = (planCode) =>
  planCode === SubscriptionPlans.TRIAL ||
  planCode === SubscriptionPlans.OUTREACH_BASIC_MONTHLY ||
  planCode === SubscriptionPlans.OUTREACH_BASIC_YEARLY ||
  planCode === SubscriptionPlans.OUTREACH_BASIC_MONTHLY_8_2023 ||
  planCode === SubscriptionPlans.OUTREACH_BASIC_YEARLY_8_2023;

export const isAnnuallyPlan = (planCycle: BillingOption) =>
  planCycle === BillingOption.ANNUALLY;

export const stepWisePlanEmailMonthly = {
  step1: SubscriptionPlanTitle.LeadFinderEmailScalePlus1Monthly,
  step2: SubscriptionPlanTitle.LeadFinderEmailScalePlus2Monthly,
  step3: SubscriptionPlanTitle.LeadFinderEmailScalePlus3Monthly,
  step4: SubscriptionPlanTitle.LeadFinderEmailScalePlus4Monthly,
};
export const stepWisePlanEmailPhoneMonthly = {
  step1: SubscriptionPlanTitle.LeadFinderEmailPhoneScalePlus1Monthly,
  step2: SubscriptionPlanTitle.LeadFinderEmailPhoneScalePlus2Monthly,
  step3: SubscriptionPlanTitle.LeadFinderEmailPhoneScalePlus3Monthly,
  step4: SubscriptionPlanTitle.LeadFinderEmailPhoneScalePlus4Monthly,
};
export const stepWisePlanEmailYearly = {
  step1: SubscriptionPlanTitle.LeadFinderEmailScalePlus1Yearly,
  step2: SubscriptionPlanTitle.LeadFinderEmailScalePlus2Yearly,
  step3: SubscriptionPlanTitle.LeadFinderEmailScalePlus3Yearly,
  step4: SubscriptionPlanTitle.LeadFinderEmailScalePlus4Yearly,
};

export const stepWisePlanEmailPhoneYearly = {
  step1: SubscriptionPlanTitle.LeadFinderEmailPhoneScalePlus1Yearly,
  step2: SubscriptionPlanTitle.LeadFinderEmailPhoneScalePlus2Yearly,
  step3: SubscriptionPlanTitle.LeadFinderEmailPhoneScalePlus3Yearly,
  step4: SubscriptionPlanTitle.LeadFinderEmailPhoneScalePlus4Yearly,
};

export const getCustomScalePlusPlanName = (
  step,
  isAnnualPlan,
  isEmailPhonePlan,
) => {
  let data = null;
  if (isEmailPhonePlan) {
    data = isAnnualPlan
      ? stepWisePlanEmailPhoneYearly[`step${step}`]
      : stepWisePlanEmailPhoneMonthly[`step${step}`];
  } else {
    data = isAnnualPlan
      ? stepWisePlanEmailYearly[`step${step}`]
      : stepWisePlanEmailMonthly[`step${step}`];
  }
  return data;
};
