import { checkIsLtdPlan } from '../../../../../../../shared/design-system/components/molecules/subscription-plan-details/helper/helper';
import { BillingOption } from '../../../../../../../shared/utils/billing-options';
import {
  PlanType,
  SubscriptionPlanModes,
  SubscriptionPlans,
} from '../../../../../../../shared/utils/subscription-plans';
import { GetPlansResponse, SelectedPlanDetails } from '../../../types';

// eslint-disable-next-line import/prefer-default-export
export const onPurchaseSubscription = ({
  isUserModifyingSubscription,
  token,
  numberOfSlotsUserWant,
  selectedPlanDetails,
  couponCode,
  onPurchaseSubscriptionRequest,
  onPurchaseModifySubscriptionRequest,
  sendUpgradeLtdPlanRequest,
  sendModifyLtdPlanRequest,
  existingPlanCode,
  existingSlots,
  getCouponCodeResponse,
}) => {
  if (checkIsLtdPlan(selectedPlanDetails?.planType)) {
    if (existingPlanCode !== selectedPlanDetails?.planCode) {
      sendUpgradeLtdPlanRequest({
        slots: numberOfSlotsUserWant,
        planId: selectedPlanDetails?.planId,
        stripeCardToken: token.id,
        ...(getCouponCodeResponse &&
          !getCouponCodeResponse.isError &&
          couponCode && { couponCode }),
      });
    } else if (existingSlots < numberOfSlotsUserWant) {
      sendModifyLtdPlanRequest({
        slots: Math.abs(numberOfSlotsUserWant - existingSlots),
        stripeCardToken: token.id,
        ...(getCouponCodeResponse &&
          !getCouponCodeResponse.isError &&
          couponCode && { couponCode }),
      });
    }

    return;
  }

  if (!isUserModifyingSubscription) {
    const payload = {
      slots: Math.floor(numberOfSlotsUserWant),
      planId: Number(selectedPlanDetails.planId),
      ...(token && { stripeCardToken: token.id }),
      ...(getCouponCodeResponse &&
        !getCouponCodeResponse.isError &&
        couponCode && { couponCode }),
    };
    onPurchaseSubscriptionRequest(payload);
  } else {
    const payload = {
      accountSubscriptionPlanId: Number(selectedPlanDetails.planId),
      slots: Math.floor(numberOfSlotsUserWant),
      stripeCardToken: token.id,
      ...(getCouponCodeResponse &&
        !getCouponCodeResponse.isError &&
        couponCode && { couponCode }),
    };
    onPurchaseModifySubscriptionRequest(payload);
  }
};

export const getPlanName = (selectedPlanDetails, planCycle: BillingOption) => {
  if (planCycle === BillingOption.MONTHLY) {
    return `${selectedPlanDetails.displayName}`;
  }
  if (planCycle === BillingOption.LIFETIME) {
    return `${selectedPlanDetails.displayName} (Lifetime)`;
  }

  return `${selectedPlanDetails.displayName}`;
};

export const getIsEmailOutreachProPlan = (
  selectedPlanDetails: SelectedPlanDetails,
  planCycle: BillingOption,
) => {
  if (planCycle === BillingOption.LIFETIME) {
    if (
      selectedPlanDetails.planCode ===
      SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME
    ) {
      return true;
    }
  }
  return false;
};

export const getPlanId = (
  getPlansResponse: GetPlansResponse,
  planName: string,
  planCode: SubscriptionPlans,
  planType: PlanType,
) => {
  if (checkIsLtdPlan(planType)) {
    return getPlansResponse?.find(
      (plan) =>
        plan.planName.split('(')[0].trim() === planName.split('(')[0].trim(),
    )?.id;
  }

  if (
    planCode === SubscriptionPlans.PAID_MONTHLY ||
    planCode === SubscriptionPlans.PAID_YEARLY
  ) {
    return getPlansResponse?.find((plan) => plan?.planCode === planCode)?.id;
  }

  return getPlansResponse?.find(
    (plan) =>
      plan?.planName === planName &&
      plan?.planCode !== SubscriptionPlans.PAID_MONTHLY &&
      plan?.planCode !== SubscriptionPlans.PAID_YEARLY,
  )?.id;
};

export const getPlanNameByPlanMode = (
  getPlansResponse: GetPlansResponse,
  planCode: SubscriptionPlans,
  planMode: SubscriptionPlanModes,
) =>
  getPlansResponse?.find(
    (plan) => plan?.planCode === planCode && plan?.planMode === planMode,
  )?.planName;

export const getPlanNameByPlanCycle = (planName, planCycle) => {
  const previousPlanCycle =
    planCycle === BillingOption.ANNUALLY ? 'Monthly' : 'Yearly';
  const currentPlanCycle =
    planCycle === BillingOption.ANNUALLY ? 'Yearly' : 'Monthly';

  return planName.replace(previousPlanCycle, currentPlanCycle);
};

export const getPrice = (
  getPlansResponse: GetPlansResponse,
  timeFrame: BillingOption,
) => {
  const price = {
    emailAccount: null,
    user: null,
  };

  if (timeFrame === BillingOption.MONTHLY) {
    price.emailAccount = getPlansResponse.find(
      (row) =>
        row?.planType === PlanType.EmailAccount &&
        row?.planCode === SubscriptionPlans.PAID_MONTHLY,
    ).amount;

    price.user = getPlansResponse.find(
      (row) =>
        row?.planType === PlanType.User &&
        row?.planCode === SubscriptionPlans.PAID_MONTHLY,
    ).amount;
  } else if (timeFrame === BillingOption.ANNUALLY) {
    price.emailAccount = getPlansResponse.find(
      (row) =>
        row?.planType === PlanType.EmailAccount &&
        row?.planCode === SubscriptionPlans.PAID_YEARLY,
    )?.amount;

    price.user = getPlansResponse.find(
      (row) =>
        row?.planType === PlanType.User &&
        row?.planCode === SubscriptionPlans.PAID_YEARLY,
    )?.amount;
  }

  return price;
};

export const getNumberOfSlotsUserWant = (
  numberOfSlotsUserWant,
  minimumNumberOfSlotsUserCanHave,
) =>
  Number(minimumNumberOfSlotsUserCanHave) > Number(numberOfSlotsUserWant)
    ? minimumNumberOfSlotsUserCanHave
    : numberOfSlotsUserWant;
