import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';

import {
  getAdminSettings,
  getExclusionList,
  getSSOOrganization,
  getSSOSetupUrl,
  updateAdminSettings,
  updateExclusionList,
  updateSSOOrganization,
} from '../../helpers/admin-settings.api';
import {
  UpdateAdminSettingsExcludeList,
  UpdateAdminSettingsRequestPayload,
} from '../../types/request-payload';
import { PaginationOptions } from './types';

export const getAdminSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('adminSettings/getAdminSettingsRequest', async (args, thunkAPI) => {
  try {
    return await getAdminSettings();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateAdminSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateAdminSettingsRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('adminSettings/updateAdminSettingsRequest', async (args, thunkAPI) => {
  try {
    return await updateAdminSettings(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getExclusionListRequest = createAsyncThunk<
  ResponseSuccess,
  PaginationOptions,
  { rejectValue: ResponseErrorWithHandled }
>('adminSettings/getExclusionListRequest', async (args, thunkAPI) => {
  try {
    return await getExclusionList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateExclusionListRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateAdminSettingsExcludeList,
  { rejectValue: ResponseErrorWithHandled }
>('adminSettings/updateExclusionListRequest', async (args, thunkAPI) => {
  try {
    return await updateExclusionList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateSSOOrganizationRequest = createAsyncThunk<
  ResponseSuccess,
  { displayName: string; domains: string[] },
  { rejectValue: ResponseErrorWithHandled }
>('adminSettings/updateSSOOrganization', async (args, thunkAPI) => {
  try {
    return await updateSSOOrganization(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSSOOrganizationRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('adminSettings/getSSOOrganization', async (args, thunkAPI) => {
  try {
    return await getSSOOrganization();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSSOSetupUrlRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('adminSettings/getSSOSetupUrl', async (args, thunkAPI) => {
  try {
    return await getSSOSetupUrl();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});