import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import IndividualLeads from '../individual-leads/individual-leads';
import Pagination from '../../../../shared/design-system/components/pagination/pagination';
import {
  setLeadListingPageSize,
  showingLengthPerPageOptions,
} from '../../helpers/helper';
import Select from '../../../../shared/design-system/components/select';
import { TablePageLengthDropDown } from '../../../../shared/design-system/components/organisms/table/types';
import { Placement } from '../../../../shared/design-system/components/overlay';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import {
  sendGetDetailsOfContactRequestHandler,
  getTagsFilterApplied,
} from '../../../prospect/helpers/helper';
import { setProspectDetails } from '../../../prospect/prospect-slice';
import { RootState } from '../../../../store/root-reducer';

import ProspectDetails from '../../../prospect/components/prospect-list/components/prospect-details';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { LeadFinderTabKey, RR_PAGINATION_LIMIT } from '../../type';

const LeadListing = (props: any) => {
  const {
    topPeopleLimit,
    leadCredits,
    isLoading,
    leadsData,
    handlePagination,
    handleCheckUnCheckSingleLead,
    selectedLeads,
    isBulkRevealing,
    apiParam,
    savedApiParam,
    handleAppendLead,
    disabledLeads,
    deSelectedLeads,
    isSelectedAllLeads,
    handleResetStateAfterAnyAction,
    activeTabKey,
    loadingGetInfoLeads,
    setLoadingGetInfoLeads,
    handleFetchLeadsAfterBulkActions,
    isFirstPollRequest,
    leadFinderRevealType,
    setLeadFinderRevealType,
  } = props;

  const currentPage =
    activeTabKey === LeadFinderTabKey.PEOPLE_TAB
      ? Math.ceil(Number(apiParam?.start) / apiParam?.take)
      : Math.ceil(Number(savedApiParam?.start) / savedApiParam?.take);
  const totalPages =
    activeTabKey === LeadFinderTabKey.PEOPLE_TAB
      ? Math.ceil(
          Number(Math.min(leadsData?.pagination?.total, RR_PAGINATION_LIMIT)) /
            apiParam?.take,
        )
      : Math.ceil(Number(leadsData?.pagination?.total) / savedApiParam?.take);

  const {
    singleProspect,
    singleProspectSequenceDetails,
    getSingleProspectDetailsRequest,
    getSingleProspectSequencesDetailsRequest,
    prospectsFilters,
  } = useSelector((state: RootState) => state.prospect);

  const [selectedContactId, setSelectedContactId] = useState<number>(null);
  const [
    showProspectDetailsModal,
    setShowProspectDetailsModal,
  ] = useState<boolean>(false);

  const viewShowProspectDetailsModal = () => {
    if (!hasPermission(Permissions.PROSPECT_READ)) {
      return;
    }
    setShowProspectDetailsModal(true);
  };

  const getContactId = (id) => {
    sendGetDetailsOfContactRequestHandler({
      id,
      setProspectDetails,
      setSelectedContactId,
      viewShowProspectDetailsModal,
    });
  };

  const hideShowProspectDetailsModal = () => {
    setShowProspectDetailsModal(false);
  };

  const resetSelectedId = () => {
    setSelectedContactId(null);
  };

  return (
    <div className="lead-finder-pagination">
      <div
        className={`lead-listing-container ${
          leadsData?.pagination?.total < 25 ? 'lead-listing-container-less' : ''
        }`}
      >
        {!isLoading && leadsData && (
          <IndividualLeads
            topPeopleLimit={topPeopleLimit}
            leadCredits={leadCredits}
            activeTabKey={activeTabKey}
            leadSampleData={leadsData?.profiles}
            leadsPaginationData={leadsData?.pagination}
            handleCheckUnCheckSingleLead={handleCheckUnCheckSingleLead}
            selectedLeads={selectedLeads}
            isBulkRevealing={isBulkRevealing}
            handleAppendLead={handleAppendLead}
            disabledLeads={disabledLeads}
            deSelectedLeads={deSelectedLeads}
            isSelectedAllLeads={isSelectedAllLeads}
            viewShowProspectDetailsModal={(id: number) => getContactId(id)}
            handleResetStateAfterAnyAction={handleResetStateAfterAnyAction}
            loadingGetInfoLeads={loadingGetInfoLeads}
            setLoadingGetInfoLeads={setLoadingGetInfoLeads}
            handleFetchLeadsAfterBulkActions={handleFetchLeadsAfterBulkActions}
            isFirstPollRequest={isFirstPollRequest}
            apiParam={apiParam}
            leadFinderRevealType={leadFinderRevealType}
            setLeadFinderRevealType={setLeadFinderRevealType}
          />
        )}
      </div>
      {showProspectDetailsModal && selectedContactId && (
        <ProspectDetails
          show={showProspectDetailsModal}
          onHide={hideShowProspectDetailsModal}
          selectedProspectId={selectedContactId}
          resetSelectedProspectId={resetSelectedId}
          selectedProspectData={singleProspect}
          selectedProspectSequences={singleProspectSequenceDetails}
          getSingleProspectDetailsStatus={
            getSingleProspectDetailsRequest.status
          }
          getSingleProspectSequencesDetailsStatus={
            getSingleProspectSequencesDetailsRequest.status
          }
          isTagsFilterApplied={getTagsFilterApplied({ prospectsFilters })}
        />
      )}
      {leadsData && leadsData?.pagination?.total > 25 && !isLoading && (
        <div
          className={classNames([
            'pagination-container d-flex',
            {
              'pagination-container-new': 1212,
            },
          ])}
        >
          <div className="pagination-row lead-pagination w-100 d-flex align-items-center justify-content-end">
            <div className="pagination-select-count w-100 d-flex align-items-center justify-content-end">
              <span className="regular-2 popover-arrow-color-txt mr-2">
                Showing
              </span>
              <Select<TablePageLengthDropDown>
                // options={handleGetShowingLengthPerPageOptions(Number(leadsData?.pagination?.total))}
                options={showingLengthPerPageOptions}
                selectedOptionKey={
                  activeTabKey === LeadFinderTabKey.PEOPLE_TAB
                    ? apiParam.take.toString()
                    : savedApiParam.take.toString()
                }
                selectedOptionRenderer={([option]) => (
                  <span className="blue-txt-15">{option?.key}</span>
                )}
                onChange={([option]) => {
                  setLeadListingPageSize(Number(option.value));
                  handlePagination({
                    start: 1, // apiParam?.start,
                    take: Number(option.value),
                  });
                }}
                optionRenderer={(option) => <span>{option?.value}</span>}
                placeholder="Select Plan"
                placement={Placement.Top}
              />
              <span className="regular-2 popover-arrow-color-txt d-flex ml-2">{`out of ${parseInt(
                leadsData?.pagination?.total,
                10,
              ).toLocaleString('en-US')}`}</span>
            </div>

            <div className="divider" />

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={(e) => {
                if (activeTabKey === LeadFinderTabKey.PEOPLE_TAB) {
                  handlePagination({
                    start: e * apiParam?.take - (apiParam?.take - 1),
                    take: apiParam?.take,
                  });
                } else {
                  handlePagination({
                    start: e * savedApiParam?.take - (savedApiParam?.take - 1),
                    take: savedApiParam?.take,
                  });
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadListing;
