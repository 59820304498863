/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/dot-notation */
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getFilterValuesWithExtraValues,
  getLeadListingPageSize,
  getLeadsActiveTab,
  validateNumberForQueryParams,
} from '../../helpers/helper';
import {
  DEPARTMENT_DATA,
  INDUSTRY_DATA,
  LOCATION_DATA,
} from '../../helpers/filter-data';
import { getLeads, getSavedLeads } from '../../helpers/leads.api';
import { LeadFinderTabKey, SavedTabFilters } from '../../type';
import { RootState } from '../../../../store/root-reducer';
import toaster from '../../../../shared/toaster';
import { dateToString } from '../../../../shared/functions/date';
import { ExceptionTypes } from '../../../../shared/types';

export const useLeadFinder = (
  tabKey: string,
  setSavedFilters: React.Dispatch<React.SetStateAction<SavedTabFilters>>,
) => {
  const history = useHistory();
  const location = useLocation();

  const userEmail = useSelector((state: RootState) => state?.home?.email);

  const queryParams = new URLSearchParams(location?.search);

  const [isLoading, setIsLoading] = useState(false);

  const [urlState, setUrlState] = useState<any>();
  const [apiParam, setApiParam] = useState<any>({
    start: validateNumberForQueryParams(queryParams.get('start')),
    take: validateNumberForQueryParams(
      queryParams.get('take'),
      getLeadListingPageSize(),
    ),
  });
  const [savedApiParam, setSavedApiParam] = useState<any>({
    start: validateNumberForQueryParams(queryParams.get('start')),
    take: validateNumberForQueryParams(
      queryParams.get('take'),
      getLeadListingPageSize(),
    ),
  });
  const [leadsData, setLeadsData] = useState({
    profiles: [],
    pagination: null,
  });

  const handleGeneratePayload = () => {
    const queryParamsUpdated = new URLSearchParams(window.location?.search);
    const paramArray = Array.from(queryParamsUpdated.entries());
    const tempParam = {};
    const savedFilters: SavedTabFilters = {
      createdDate: [],
      search: '',
      tags: [],
    };

    paramArray.forEach(([key, value]) => {
      switch (key) {
        case 'keyword':
          if (!value) {
            tempParam['start'] = 1;
            tempParam['take'] = getLeadListingPageSize();
          } else {
            tempParam[key] = queryParamsUpdated.getAll(key);
            savedFilters.search = value;
          }
          break;
        case 'tags':
          if (!value) {
            tempParam['start'] = 1;
            tempParam['take'] = getLeadListingPageSize();
          } else {
            tempParam[key] = value;
            savedFilters.tags = value
              .split(',')
              .map((tag) => Number(tag.trim()));
          }
          break;
        case 'createdDate':
          if (!value) {
            tempParam['start'] = 1;
            tempParam['take'] = getLeadListingPageSize();
          } else {
            const [formattedStartDate, formattedEndDate] = value.split('-');
            const startDate = new Date(formattedStartDate);
            const endDate = new Date(formattedEndDate);
            const dateObjects = [
              encodeURIComponent(dateToString(startDate)),
              encodeURIComponent(dateToString(endDate)),
            ];
            tempParam[key] = dateObjects.join(',');
            savedFilters.createdDate = [startDate, endDate];
          }
          break;
        case 'name':
        case 'jobChangeRange':
        case 'yearsExperience':
        case 'companySize':
        case 'companyRevenue':
          tempParam[key] = value;
          break;
        case 'employer':
        case 'excludeEmployer':
        case 'skills':
        case 'excludeSkills':
        case 'jobTitle':
        case 'excludeJobTitle':
        case 'managementLevels':
        case 'excludeManagementLevels':
        case 'sicCode':
        case 'excludeSicCode':
        case 'naicsCode':
        case 'excludeNaicsCode':
        case 'contactMethod':
        case 'major':
        case 'excludeMajor':
        case 'school':
        case 'excludeSchool':
        case 'degree':
        case 'excludeDegree':
        case 'link':
        case 'contactInfo':
          tempParam[key] = queryParamsUpdated.getAll(key); // Convert to array
          break;
        case 'department':
        case 'excludeDepartment':
          // Use getAll to handle multiple values for 'department'
          tempParam[key] = getFilterValuesWithExtraValues(
            queryParamsUpdated.getAll(key),
            DEPARTMENT_DATA,
          );
          break;
        case 'location':
        case 'excludeLocation':
          {
            // Use getAll to handle multiple values for 'location'
            const locationPayload = [];
            getFilterValuesWithExtraValues(
              queryParamsUpdated.getAll(key),
              LOCATION_DATA,
            ).forEach((item) => {
              if (
                LOCATION_DATA.some(
                  (data) => data.key === item.substring(1, 3).toUpperCase(),
                )
              ) {
                locationPayload.push(item.replace(/"/g, ''));
              } else {
                locationPayload.push(item);
              }
            });

            tempParam[key] = getFilterValuesWithExtraValues(
              locationPayload,
              LOCATION_DATA,
              true,
            );
          }
          break;
        case 'industry':
        case 'excludeIndustry':
          // Use getAll to handle multiple values for 'location'
          tempParam[key] = getFilterValuesWithExtraValues(
            queryParamsUpdated.getAll(key),
            INDUSTRY_DATA,
          );
          break;
        default:
      }
    });

    if (tabKey === LeadFinderTabKey.SAVED_TAB) {
      if (!tempParam['start']) {
        tempParam['start'] = validateNumberForQueryParams(
          queryParams.get('start'),
        );
      }
      if (!tempParam['take']) {
        tempParam['take'] = validateNumberForQueryParams(
          queryParams.get('take'),
          getLeadListingPageSize(),
        );
      }
      setSavedFilters(savedFilters);
    }

    return tempParam;
  };

  const handleGenerateAPIParam = (key: string) => {
    let payload: any = handleGeneratePayload();
    payload = {
      start: validateNumberForQueryParams(queryParams.get('start')),
      take: validateNumberForQueryParams(
        queryParams.get('take'),
        getLeadListingPageSize(),
      ),
      ...payload,
    };
    if (key === LeadFinderTabKey.PEOPLE_TAB) {
      setApiParam(() => payload);
    } else {
      setSavedApiParam(() => payload);
    }
    return payload;
  };

  const handleRecentSearch = () => {
    const payload: any = handleGeneratePayload();
    const meaningfulParams = Object.keys(payload).filter(
      (key) => key !== 'start' && key !== 'take',
    );

    if (tabKey !== LeadFinderTabKey.PEOPLE_TAB) {
      return;
    }

    if (meaningfulParams.length > 0) {
      if (!userEmail) {
        return;
      }

      const storedUrlsKey = 'storedUrls';
      const storedUrls = JSON.parse(localStorage.getItem(storedUrlsKey)) || {};

      if (!storedUrls[userEmail]) {
        storedUrls[userEmail] = [];
      }

      const currentUrlWithParams =
        window.location.pathname + window.location.search;
      const timestamp = new Date().toISOString();

      const existingUrlIndex = storedUrls[userEmail].findIndex(
        (urlData) =>
          urlData?.url?.toLowerCase() === currentUrlWithParams?.toLowerCase(),
      );

      const maxUrls = 4;

      if (existingUrlIndex !== -1) {
        const updatedEntry = {
          url: currentUrlWithParams,
          timestamp,
          payload,
        };

        storedUrls[userEmail].unshift(
          storedUrls[userEmail].splice(existingUrlIndex, 1)[0],
        );
        storedUrls[userEmail][0] = updatedEntry;

        storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
      } else {
        const urlData = {
          url: currentUrlWithParams,
          timestamp,
          payload,
        };

        storedUrls[userEmail].unshift(urlData);

        storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
      }

      localStorage.setItem(storedUrlsKey, JSON.stringify(storedUrls));

      const paramArray = Array.from(queryParams.entries());

      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      paramArray.forEach(([key]) => {
        queryParams.delete(key);
      });

      history.push({
        search: queryParams.toString(),
        hash: getLeadsActiveTab(location),
      });
    }
  };

  const handleRouteChange = () => {
    const payload: any = handleGeneratePayload();
    const meaningfulParams = Object.keys(payload).filter(
      (key) => key !== 'start' && key !== 'take',
    );

    if (tabKey !== LeadFinderTabKey.PEOPLE_TAB) {
      return;
    }

    if (meaningfulParams.length > 0) {
      if (!userEmail) {
        return;
      }

      const storedUrlsKey = 'storedUrls';
      const storedUrls = JSON.parse(localStorage.getItem(storedUrlsKey)) || {};

      if (!storedUrls[userEmail]) {
        storedUrls[userEmail] = [];
      }

      const currentUrlWithParams = location.pathname + location.search;
      const timestamp = new Date().toISOString();

      const existingUrlIndex = storedUrls[userEmail].findIndex(
        (urlData) =>
          urlData?.url?.toLowerCase() === currentUrlWithParams?.toLowerCase(),
      );

      const maxUrls = 4;

      if (existingUrlIndex !== -1) {
        const updatedEntry = {
          url: currentUrlWithParams,
          timestamp,
          payload,
        };

        storedUrls[userEmail].unshift(
          storedUrls[userEmail].splice(existingUrlIndex, 1)[0],
        );
        storedUrls[userEmail][0] = updatedEntry;

        storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
      } else {
        const urlData = {
          url: currentUrlWithParams,
          timestamp,
          payload,
        };

        storedUrls[userEmail].unshift(urlData);

        storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
      }

      localStorage.setItem(storedUrlsKey, JSON.stringify(storedUrls));
    }
  };

  const handleAppendLead = (lead) => {
    const indexOfProfileToReplace = leadsData?.profiles?.findIndex(
      (profile) => profile?.id === lead?.id,
    );

    if (indexOfProfileToReplace !== -1) {
      const updatedProfiles = [...leadsData.profiles];
      updatedProfiles[indexOfProfileToReplace] = lead;

      setLeadsData({
        ...leadsData,
        profiles: updatedProfiles,
      });
    }
  };

  const handleFetchLeads = async (data: any, key: string) => {
    try {
      setIsLoading(true);
      let res: any = {};
      if (key === LeadFinderTabKey.PEOPLE_TAB) {
        res = await getLeads(data);
      } else {
        res = await getSavedLeads(data);
      }
      if (res?.payload) {
        setLeadsData(res.payload);
      }
    } catch (e: any) {
      if (e?.type === ExceptionTypes.RateLimit) {
        if (!leadsData?.profiles?.length || !leadsData?.pagination?.total) {
          setLeadsData({
            pagination: {
              start: 0,
              take: 0,
              total: 0,
            },
            profiles: [],
          });
        }
        return;
      }
      if (e?.isHandled) {
        setLeadsData({
          pagination: {
            start: 0,
            take: 0,
            total: 0,
          },
          profiles: [],
        });
        return;
      }
      toaster.error(e?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchLeads = async () => {
      const payload = handleGenerateAPIParam(tabKey);
      if (payload?.start && payload?.take) {
        const { start, take, ...rest } = payload;
        if (
          Object.keys(rest || {})?.length &&
          tabKey === LeadFinderTabKey.PEOPLE_TAB
        ) {
          handleFetchLeads(payload, tabKey);
        }
      }
    };
    fetchLeads();
  }, [tabKey]);

  useEffect(() => {
    const fetchLeads = async () => {
      const payload: any = handleGeneratePayload();
      if (payload?.start && payload?.take) {
        handleFetchLeads(payload, tabKey);
      }
    };
    if (tabKey === LeadFinderTabKey.SAVED_TAB) {
      fetchLeads();
    }
  }, [savedApiParam]);

  const searchLeads = (payload?: any, performSearch = true) => {
    if (payload) {
      if (tabKey === LeadFinderTabKey.PEOPLE_TAB) {
        setApiParam(payload);
      } else {
        setSavedApiParam(payload);
      }
      if (performSearch) {
        handleFetchLeads(payload, tabKey);
      }
      return;
    }
    const updatedPayload = handleGenerateAPIParam(tabKey);
    if (performSearch) {
      handleFetchLeads(updatedPayload, tabKey);
    }
  };

  const handlePagination = ({ start, take }) => {
    if (start && take) {
      queryParams.set('start', start);
      queryParams.set('take', take);
      history.push({
        search: queryParams.toString(),
        hash: getLeadsActiveTab(location),
      });
      const updatedPayload = handleGenerateAPIParam(tabKey);
      if (tabKey === LeadFinderTabKey.PEOPLE_TAB) {
        handleFetchLeads(updatedPayload, tabKey);
      }
    }
  };

  useEffect(() => {
    if (location?.search) {
      handleGenerateAPIParam(tabKey);
      setUrlState({
        payload: handleGeneratePayload(),
        url: location?.pathname + location?.search,
      });
    } else {
      setLeadsData({
        profiles: [],
        pagination: null,
      });
    }
  }, [location.search]);

  useEffect(
    () => () => {
      if (!urlState || window.location?.pathname === '/leads') {
        return; // If urlState is not set, do nothing
      }

      if (tabKey !== LeadFinderTabKey.PEOPLE_TAB) {
        return;
      }

      const { payload } = urlState;
      const meaningfulParams = Object.keys(payload)?.filter(
        (key) => key !== 'start' && key !== 'take',
      );

      if (meaningfulParams.length > 0 && userEmail) {
        const storedUrlsKey = 'storedUrls';
        const storedUrls =
          JSON.parse(localStorage.getItem(storedUrlsKey)) || {};

        if (!storedUrls[userEmail]) {
          storedUrls[userEmail] = [];
        }

        const timestamp = new Date().toISOString();

        const existingUrlIndex = storedUrls[userEmail].findIndex(
          (urlData) =>
            urlData?.url?.toLowerCase() === urlState?.url?.toLowerCase(),
        );

        const maxUrls = 4;

        if (existingUrlIndex !== -1) {
          const updatedEntry = {
            url: urlState?.url,
            timestamp,
            payload,
          };

          storedUrls[userEmail].unshift(
            storedUrls[userEmail].splice(existingUrlIndex, 1)[0],
          );
          storedUrls[userEmail][0] = updatedEntry;

          storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
        } else {
          const urlData = {
            url: urlState?.url,
            timestamp,
            payload,
          };

          storedUrls[userEmail].unshift(urlData);

          storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
        }

        localStorage.setItem(storedUrlsKey, JSON.stringify(storedUrls));
      }
    },
    [urlState],
  );

  return {
    isLoading,
    leadsData,
    apiParam,
    savedApiParam,
    handlePagination,
    handleRecentSearch,
    handleGeneratePayload,
    handleAppendLead,
    setLeadsData,
    handleRouteChange,
    searchLeads,
  };
};
