import { EmailAccountType } from '../../../types/create-email-infrastructure';

const ADD_EMAIL_ACCOUNTS_DATA = 'addEmailAccounts_data';
const ADD_EMAIL_ACCOUNTS_DOMAIN_ID = 'addEmailAccounts_domainId';
const ADD_EMAIL_ACCOUNTS_LAST_UPDATED_AT = 'addEmailAccounts_lastUpdatedAt';

export const removeAddEmailAccountsDataFromLS = () => {
  localStorage.removeItem(ADD_EMAIL_ACCOUNTS_DATA);
  localStorage.removeItem(ADD_EMAIL_ACCOUNTS_DOMAIN_ID);
  localStorage.removeItem(ADD_EMAIL_ACCOUNTS_LAST_UPDATED_AT);
};

export const getAddEmailAccountsDataFromLS = (id: string) => {
  const domainId = localStorage.getItem(ADD_EMAIL_ACCOUNTS_DOMAIN_ID);

  if (id !== domainId) {
    removeAddEmailAccountsDataFromLS();
    return [];
  }

  const now = Date.now();
  const lastUpdatedAt = localStorage.getItem(
    ADD_EMAIL_ACCOUNTS_LAST_UPDATED_AT,
  );
  const lastUpdatedTime = new Date(lastUpdatedAt).getTime();

  // Data updated within last 10 min
  if (now - lastUpdatedTime < 10 * 60 * 1000) {
    return JSON.parse(localStorage.getItem(ADD_EMAIL_ACCOUNTS_DATA)) || [];
  }

  removeAddEmailAccountsDataFromLS();
  return [];
};

export const setAddEmailAccountsDataInLS = (
  domainId: string,
  newEmailAccountsToAdd: EmailAccountType[],
) => {
  localStorage.setItem(ADD_EMAIL_ACCOUNTS_DOMAIN_ID, domainId);
  localStorage.setItem(
    ADD_EMAIL_ACCOUNTS_LAST_UPDATED_AT,
    new Date().toISOString(),
  );
  localStorage.setItem(
    ADD_EMAIL_ACCOUNTS_DATA,
    JSON.stringify(newEmailAccountsToAdd),
  );
};
