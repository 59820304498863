/* eslint-disable react/react-in-jsx-scope */
import { AlertCircle } from '@saleshandy/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import { useEffect, useState } from 'react';
import { FilterComponentType } from '../../type';
import { Placement } from '../../../../shared/design-system/components/overlay';
import Select from '../../../../shared/design-system/components/select';

const CustomSelect = ({
  list,
  isPrefix,
  filterKey,
  name,
  className,
  handleQuery,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  placeholder,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');

  const isInvalidRange =
    selectedOption?.key === 'custom' &&
    maxValue &&
    minValue &&
    +maxValue < +minValue;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleCustomSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption?.key === 'custom') {
      setMinValue('');
      setMaxValue('');
    } else {
      const sizeQueryParam =
        selectedOption?.key === 'select' ? '' : selectedOption?.key;
      handleQuery({
        filterKey,
        filterValue: sizeQueryParam,
        filterType: FilterComponentType.DROP_DOWN,
        isSearchLeads: false,
      });
    }
  };

  const handleBlurInput = () => {
    const isValidRange = +maxValue > +minValue;
    if (isValidRange) {
      handleQuery({
        filterKey,
        filterValue: `${minValue || 0}-${maxValue || 0}`,
        filterType: FilterComponentType.DROP_DOWN,
        isSearchLeads: true,
      });
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter' && selectedOption?.key === 'custom') {
      handleBlurInput();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const queryParams = new URLSearchParams(location.search);
    const filterValue = queryParams.get(filterKey);

    if (queryParams.has(filterKey) && filterValue) {
      const matchingOption = list.find((option) => option.key === filterValue);
      setSelectedOption(matchingOption ?? { key: 'custom', label: 'Custom' });
      const [min, max] = (filterValue || '').split('-');
      setMinValue(min || '');
      setMaxValue(max || '');
    } else {
      setSelectedOption(null);
      setMinValue('');
      setMaxValue('');
    }
    // eslint-disable-next-line no-restricted-globals
  }, [location?.search]);

  const getTooltip = (text) => (
    <Tooltip id="popover-basic" className="bs-tooltip-inner">
      {text}
    </Tooltip>
  );

  return (
    <div className={`dropdown-filter ${className}`}>
      {name && <p className="blue-txt-15 label">{name}</p>}
      <Select
        options={list}
        selectedOptionKey={selectedOption?.key || list[0]?.key}
        optionRenderer={(option: any) => <span>{option?.label}</span>}
        onChange={([option]) => {
          handleCustomSelect(option);
        }}
        selectedOptionRenderer={([option]) => <span>{option?.label}</span>}
      />

      {selectedOption?.key === 'custom' && (
        <div className={`input-group ${isInvalidRange ? ' has-error' : ''}`}>
          <div className="d-flex min-input-container">
            {isPrefix && (
              <div className="input-group-prepend">
                <span className="input-group-text regular-2 gray-txt-11 gray-19">
                  $
                </span>
              </div>
            )}
            <NumericFormat
              value={minValue}
              placeholder="Min"
              autoComplete="off"
              maxLength={15}
              // eslint-disable-next-line react/jsx-curly-brace-presence
              thousandSeparator={','}
              className={`form-control gray-txt-11 regular-2 mr-2 ${
                !isPrefix ? 'without-prefix' : ''
              }`}
              onValueChange={(values: any) => setMinValue(values?.value)}
              onKeyPress={handleInputKeyPress}
            />
          </div>
          <div className="d-flex max-input-container">
            {isPrefix && (
              <div className="input-group-prepend">
                <span
                  className={`input-group-text regular-2 gray-txt-11 gray-19 ${
                    isInvalidRange ? 'error-prefix-input' : ''
                  }`}
                >
                  $
                </span>
              </div>
            )}
            <div className="max-input">
              <NumericFormat
                name="value"
                placeholder="Max"
                autoComplete="off"
                value={maxValue}
                maxLength={15}
                thousandSeparator={true}
                onValueChange={(values: any) => setMaxValue(values?.value)}
                className={`form-control gray-txt-11 regular-2 ${
                  isInvalidRange ? ' is-invalid' : ''
                } ${!isPrefix ? 'without-prefix' : ''}`}
                onKeyPress={handleInputKeyPress}
              />
              {isInvalidRange && (
                <div className="error-message">
                  <OverlayTrigger
                    placement={Placement.Bottom}
                    overlay={getTooltip('Max cannot be less than Min')}
                  >
                    <AlertCircle
                      className="red-txt-16"
                      width={16}
                      height={16}
                    />
                  </OverlayTrigger>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
