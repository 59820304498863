import { connect } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';
import OAuthButtons from './oauth-buttons';
import { getOAuthRedirectUrlRequest } from './extra-actions';
import { OAuthMethod } from './types';
import { OAuthPayload } from '../../types';

const mapStateToProps = (state: RootState) => ({
  getOAuthRedirectUrlRequestStatus: state.oauth.getOAuthRedirectUrlRequest.status,
  getOAuthRedirectUrlRequestError: state.oauth.getOAuthRedirectUrlRequest.error,
  authUrl: state.oauth.authUrl,
});

const mapDispatchToProps = {
  getOAuthRedirectUrl: (method: OAuthMethod, payload: OAuthPayload) =>
    getOAuthRedirectUrlRequest({ method, payload }),
};

export default connect(mapStateToProps, mapDispatchToProps)(OAuthButtons);
