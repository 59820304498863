import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import {
  getConnectedUsersAndEmailAccountsRequest,
  getLeadFinderSubscriptionRequest,
  getLeadFinderPlansRequest,
  purchaseLeadFinderSubscriptionRequest,
  applyCouponCodeRequest,
  modifySubscriptionRequest,
  purchaseLeadFinderModifySubscriptionRequest,
  calculatePayRequest,
  upgradePlanRequest,
  downgradePlanRequest,
  upgradeLtdPlanRequest,
  modifyLtdPlanRequest,
} from '../../extra-actions';
import {
  PurchaseSubscriptionRequest,
  ApplyCouponCodeRequest,
  ModifySubscriptionRequest,
  PurchaseModifySubscriptionRequest,
  CalculatePayRequest,
  UpgradePlanRequest,
  DowngradePlanRequest,
  LTDUpgradePayload,
  LTDModifyPayload,
} from '../../types';
import {
  deleteAppliedCouponCode,
  resetCalculatePayState,
  resetConnectedUsersAndEmailAccountsRequest,
  resetModifySubscriptionResponse,
  resetPurchaseLeadFinderModifySubscriptionResponse,
  selectPlan,
} from '../../billing-subscription-slice';
import ModifyLeadFinderSubscription from './modify-lead-finder-subscription';

const mapStateToProps = (state: RootState) => ({
  sendGetLeadFinderSubscriptionRequestStatus:
    state.billingAndSubscription.getLeadFinderSubscriptionRequest.status,
  sendGetLeadFinderSubscriptionRequestError:
    state.billingAndSubscription.getLeadFinderSubscriptionRequest.error,
  sendGetLeadFinderPlansRequestStatus:
    state.billingAndSubscription.getLeadFinderPlansRequest.status,
  sendGetLeadFinderPlansRequestError:
    state.billingAndSubscription.getLeadFinderPlansRequest.error,
  sendPurchaseLeadFinderSubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderSubscriptionRequest.status,
  sendGetLeadFinderSubscriptionResponse:
    state.billingAndSubscription.getLeadFinderSubscriptionResponse,
  sendGetConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,
  sendGetConnectedUsersAndEmailAccountsRequestError:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest.error,
  sendGetConnectedUsersAndEmailAccountsRequestResponse:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsResponse,
  sendApplyCouponCodeRequestStatus:
    state.billingAndSubscription.applyCouponCodeRequest.status,
  sendApplyCouponCodeRequestMessage:
    state.billingAndSubscription.applyCouponCodeRequest.message,
  sendApplyCouponCodeRequestError:
    state.billingAndSubscription.applyCouponCodeRequest.error,
  getCouponCodeResponse: state.billingAndSubscription.applyCouponCodeResponse,
  sendGetLeadFinderPlansResponse:
    state.billingAndSubscription.getLeadFinderPlansResponse,
  sendGetModifySubscriptionRequestStatus:
    state.billingAndSubscription.calculatePayRequest.status,
  sendPurchaseLeadFinderModifySubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderModifySubscriptionRequest
      .status,
  sendGetModifySubscriptionResponse:
    state.billingAndSubscription.modifySubscriptionResponse,
  sendGetCalculatePayResponse:
    state.billingAndSubscription.getCalculatePayResponse,
  selectedPlanDetails: state.billingAndSubscription.selectedPlanDetails,

  upgradeLtdPlanRequestStatus:
    state.billingAndSubscription.upgradeLtdPlanRequest.status,
  upgradeLtdPlanRequestError:
    state.billingAndSubscription.upgradeLtdPlanRequest.error,
  modifyLtdPlanRequestStatus:
    state.billingAndSubscription.modifyLtdPlanRequest.status,
  modifyLtdPlanRequestError:
    state.billingAndSubscription.modifyLtdPlanRequest.error,

  userCurrentPlan: state.home.subscription?.planCode,
  leadFinderSubscriptionPlanStatus: state.home?.leadFinderSubscription?.status,
});

const mapDispatchToProps = {
  sendGetLeadFinderSubscriptionRequest: () =>
    getLeadFinderSubscriptionRequest(),
  sendGetLeadFinderPlansRequest: () => getLeadFinderPlansRequest(),
  sendPurchaseLeadFinderSubscriptionRequest: (
    payload: PurchaseSubscriptionRequest,
  ) => purchaseLeadFinderSubscriptionRequest(payload),
  sendApplyCouponCodeRequest: (payload: ApplyCouponCodeRequest) =>
    applyCouponCodeRequest(payload),
  sendGetConnectedUsersAndEmailAccountsRequest: () =>
    getConnectedUsersAndEmailAccountsRequest(),
  resetConnectedUsersAndEmailAccountsRequest: () =>
    resetConnectedUsersAndEmailAccountsRequest(),
  deleteAppliedCouponCode: () => deleteAppliedCouponCode(),
  sendModifySubscriptionRequest: (payload: ModifySubscriptionRequest) =>
    modifySubscriptionRequest(payload),
  sendPurchaseLeadFinderModifySubscriptionRequest: (
    payload: PurchaseModifySubscriptionRequest,
  ) => purchaseLeadFinderModifySubscriptionRequest(payload),
  resetModifySubscriptionResponse: () => resetModifySubscriptionResponse(),
  resetCalculatePayState: () => resetCalculatePayState(),
  resetPurchaseLeadFinderModifySubscriptionResponse: () =>
    resetPurchaseLeadFinderModifySubscriptionResponse(),
  sendGetCalculatePayRequest: (payload: CalculatePayRequest) =>
    calculatePayRequest(payload),
  sendUpgradePlanRequest: (payload: UpgradePlanRequest) =>
    upgradePlanRequest(payload),
  sendDowngradePlanRequest: (payload: DowngradePlanRequest) =>
    downgradePlanRequest(payload),
  selectPlan: (payload) => selectPlan(payload),
  hideLoading: () => hideLoading(),
  showLoading: () => showLoading(),

  sendUpgradeLtdPlanRequest: (payload: LTDUpgradePayload) =>
    upgradeLtdPlanRequest(payload),

  sendModifyLtdPlanRequest: (payload: LTDModifyPayload) =>
    modifyLtdPlanRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export type LocationStateType = {
  isModifySubscription: boolean;
};

export default connector(ModifyLeadFinderSubscription);
