import { createAsyncThunk } from '@reduxjs/toolkit';
import { ssoLoginCallback } from '../../helpers/auth.api';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import { SSOCallbackRequestPayload } from '../../../settings/types/request-payload';

export const ssoCallbackRequest = createAsyncThunk<
  ResponseSuccess,
  SSOCallbackRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('ssoCallback/ssoCallbackRequest', async (data, thunkAPI) => {
  try {
    const response = await ssoLoginCallback(data);
    return response;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
