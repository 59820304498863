import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { OverlayTooltip, Placement } from '../../overlay';
import { getBadgeVariant } from './helper';
import { SequenceProgress } from '../../../../../components/sequence/enums';
import {
  SequencePauseReasons,
  sequencePauseMessages,
} from '../../../../../components/sequence/enums/sequence-pause-reasons';
import Badge from '../../atoms/badge';
import StatusBadge from '../status-badge';
import { ProductTour } from '../../../../enums/product-tour';
import {
  getAgencyClient,
  getUserTimeZone,
} from '../../../../utils/user-details';

export type SequenceNameProps = {
  name: string;
  row?: any;
};

const sequenceNameJSX = ({ name, row }: SequenceNameProps) => (
  <Link
    id={ProductTour.VisitFirstSequence}
    className="text-container"
    to={`/sequence/${row.id}/steps`}
  >
    {name}
  </Link>
);

const sequenceStatus = (row, t) => {
  if (!row || !row.sequenceStatusData) {
    return null;
  }

  const {
    sequenceProgress,
    sequencepauseReason: sequencePauseReason,
    pausedAt,
  } = row.sequenceStatusData;

  const { variant, label } = getBadgeVariant(sequenceProgress, t);

  if (sequenceProgress === SequenceProgress.Pause) {
    const userTimeZone = getUserTimeZone();

    let date = '';

    if (pausedAt) {
      if (userTimeZone) {
        date = moment(pausedAt).tz(userTimeZone).format('DD MMM, YYYY');
      } else {
        date = moment(pausedAt).format('DD MMM, YYYY');
      }
    }

    const client = getAgencyClient();

    const renderPausedMessage = () => {
      const paused = (
        <p className="sequence-progress">{`${t('messages.paused')}`}</p>
      );

      const showOnlyThesePauseReasonsToClient = [
        SequencePauseReasons.EmailAccountDisconnected,
        SequencePauseReasons.EmailSendingLimitReached,
        SequencePauseReasons.EmailAccountDeleted,
      ];

      if (
        isEmpty(client) ||
        showOnlyThesePauseReasonsToClient.includes(sequencePauseReason)
      ) {
        const reason = sequencePauseMessages[sequencePauseReason];

        return (
          <OverlayTooltip
            text={t(`messages.${reason}`)}
            placement={Placement.Bottom}
            className="tooltip-xl"
          >
            {paused}
          </OverlayTooltip>
        );
      }

      return paused;
    };

    return (
      <div className="sequence-status">
        <div className="sequence-status-badge">
          <Badge className="badge-dot-danger" />
        </div>
        {renderPausedMessage()}
      </div>
    );
  }

  return <StatusBadge label={label} variant={variant} />;
};

export const SequenceNameField = ({ name, row }: SequenceNameProps) => {
  const { t } = useTranslation();

  const formatDate = (isoDateString: string) => {
    if (!isoDateString || !DateTime.fromISO(isoDateString).isValid) {
      return '';
    }

    const userTimeZone = getUserTimeZone() || 'UTC';
    return DateTime.fromISO(isoDateString, { zone: userTimeZone })?.toFormat(
      'dd MMM yyyy, hh:mm a',
    );
  };

  const { sequenceStatusData } = row || {};
  const { sequenceProgress } = sequenceStatusData || {};

  const isPaused = sequenceProgress === SequenceProgress.Pause;
  const pausedAtDate = row?.pausedAt ? formatDate(row?.pausedAt) : null;
  const createdAtDate = row?.sequenceCreatedAt
    ? formatDate(row?.sequenceCreatedAt)
    : null;

  return (
    <div>
      <OverlayTooltip text={name} placement={Placement.Bottom}>
        {sequenceNameJSX({ name, row })}
      </OverlayTooltip>

      <div className="d-flex">
        {sequenceStatus(row, t)}

        <div className="sequence-steps-created-on">
          <span className="sequence-divider-vertical steps-divider">|</span>
          {`${row?.totalSteps} Steps`}{' '}
          {pausedAtDate && (
            <>
              <span className="sequence-divider-vertical">|</span>
              {`Paused at ${pausedAtDate}`}
            </>
          )}
          {!isPaused && createdAtDate && (
            <>
              <span className="sequence-divider-vertical">|</span>
              {`Created on ${createdAtDate}`}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
