import React, { useEffect, useState } from 'react';
import { isRequestPending } from '../../../../utils/helper';
import { getPlanMode } from '../../../select-plan/utils/helper';
import LeadFinderPlanCard from '../lead-finder-plan-card/lead-finder-plan-card';
import type { IProps } from './lead-finder-plans-section-container';
import {
  getBaseLeadFinderScalePlanData,
  getCustomScalePlusPlanData,
  getLeadFinderPlanData,
} from './utils/helper';

const LeadFinderPlansSection: React.FC<IProps> = ({
  planCycle,
  onSelectPlan,
  selectedPlanId,
  //* API Request State
  sendGetLeadFinderPlansResponse,
  sendGetLeadFinderSubscriptionResponse,
  sendPurchaseLeadFinderSubscriptionRequestStatus,
  sendPurchaseLeadFinderModifySubscriptionRequestStatus,
  isEmailPhonePlan,
}) => {
  const isPurchaseInProgress =
    isRequestPending(sendPurchaseLeadFinderSubscriptionRequestStatus) ||
    isRequestPending(sendPurchaseLeadFinderModifySubscriptionRequestStatus);

  //* states
  const [planData, setPlanData] = useState([]);
  const [customScalePlusPlans, setCustomScalePlusPlans] = useState([]);

  //* useEffect
  useEffect(() => {
    if (sendGetLeadFinderPlansResponse) {
      setPlanData([
        ...getLeadFinderPlanData({
          planMode: getPlanMode(planCycle),
          allPlans: sendGetLeadFinderPlansResponse,
          isEmailPhonePlan,
        }),
        ...getBaseLeadFinderScalePlanData({
          planMode: getPlanMode(planCycle),
          allPlans: sendGetLeadFinderPlansResponse,
          isEmailPhonePlan,
        }),
        // {
        //   ...(planCycle === BillingOption.MONTHLY
        //     ? customPlanScale100kMonthly
        //     : customPlanScale100kYearly),
        // },
      ]);
      setCustomScalePlusPlans([
        ...getCustomScalePlusPlanData({
          planMode: getPlanMode(planCycle),
          allPlans: sendGetLeadFinderPlansResponse,
          isEmailPhonePlan,
        }),
      ]);
    }
  }, [
    planCycle,
    sendGetLeadFinderPlansResponse,
    sendGetLeadFinderSubscriptionResponse,
    isEmailPhonePlan,
  ]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      setPlanData([]);
      setCustomScalePlusPlans([]);
    };
  }, []);

  return (
    <div className="lead-finder-plans-card-container">
      {planData.map((plan) => (
        <LeadFinderPlanCard
          key={plan.id}
          plan={plan}
          customScalePlusPlans={customScalePlusPlans}
          planCycle={planCycle}
          handlePlanSelect={(planId, planName) =>
            onSelectPlan(planId, planName)
          }
          sendGetLeadFinderSubscriptionResponse={
            sendGetLeadFinderSubscriptionResponse
          }
          sendGetLeadFinderPlansResponse={sendGetLeadFinderPlansResponse}
          isPurchaseInProgress={
            isPurchaseInProgress && selectedPlanId === plan.id
          }
          isEmailPhonePlan={isEmailPhonePlan}
        />
      ))}
    </div>
  );
};

export default LeadFinderPlansSection;
