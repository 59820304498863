import api from '../../../api';
import {
  ChangePasswordRequestPayload,
  LoginRequestPayload,
  ResetPasswordRequestPayload,
  SignupRequestPayload,
  VerifyUserVerificationCodeRequestPayload,
  ResendUserVerificationCodeRequestPayload,
  AcceptInvitationRequestPayload,
  OnBoardingRequest,
  UserSetPasswordPayload,
  SetPasswordRequest,
  VerifyOtpPayload,
  ResendOtpPayload,
  OAuthPayload,
} from '../types';
import { ResponseSuccess } from '../../../shared/types';
import { OAuthMethod } from '../components/oauth-buttons/types';

export const login = async (payload: LoginRequestPayload) =>
  api.post<ResponseSuccess>('/auth/login', payload);

export const signup = async (payload: SignupRequestPayload) =>
  api.post<ResponseSuccess>('/auth/signup', payload);

export const verifyUserVerificationCode = async (
  payload: VerifyUserVerificationCodeRequestPayload,
) => api.post<ResponseSuccess>('/auth/signup/otp', payload);

export const resendUserVerificationCode = async (
  payload: ResendUserVerificationCodeRequestPayload,
) => api.post<ResponseSuccess>('/auth/signup/otp/resend', payload);

export const resetPassword = async (payload: ResetPasswordRequestPayload) =>
  api.post<ResponseSuccess>('/auth/reset-password', payload);

export const verifyResetPasswordCode = async (code: string) =>
  api.get<ResponseSuccess>(`/auth/reset-password/${code}`);

export const changePassword = async (
  code: string,
  payload: ChangePasswordRequestPayload,
) => api.post<ResponseSuccess>(`/auth/reset-password/${code}`, payload);

export const userSetPassword = async (payload: UserSetPasswordPayload) =>
  api.patch<ResponseSuccess>(`/user/set-password`, payload);

export const verifyInvitaitonToken = async (token: string) =>
  api.get<ResponseSuccess>(`/team/verify-invitation-token?token=${token}`);

export const acceptInvitation = async (
  payload: AcceptInvitationRequestPayload,
) => api.post<ResponseSuccess>('/team/accept-invitation', payload);

export const onBoardingDetails = async (payload: OnBoardingRequest) =>
  api.patch<ResponseSuccess>('/user/onboarding', payload);

export const setPassword = async (payload: SetPasswordRequest) =>
  api.patch<ResponseSuccess>('/auth/set-password', payload);

export const verificationLink = async (token: string) =>
  api.get<ResponseSuccess>('/auth/handle-verification-link', {
    headers: { Authorization: `Bearer ${token}` },
  });

export const resendVerificationEmail = async (email: string) =>
  api.post<ResponseSuccess>('/auth/user-verification-link/resend', {
    email,
  });

export const ssoLogin = async (payload: { email: string }) =>
  api.post<ResponseSuccess>('/auth/login-sso', payload);

export const ssoLoginCallback = async (payload: { accessCode: string }) =>
  api.post<ResponseSuccess>('/auth/login-sso-callback', payload);
export const verifyOtp = async (payload: VerifyOtpPayload) =>
  api.post<ResponseSuccess>('/auth/login/verify-otp', payload);

export const resendOtp = async (payload: ResendOtpPayload) =>
  api.post<ResponseSuccess>('/auth/login/resend-otp', payload);

export const getOAuthRedirectUrl = async (method: OAuthMethod, payload: OAuthPayload) =>
  api.post<ResponseSuccess>(`/auth/oauth-authenticate/${method}`, payload);

export const oauthAuthenticate = async (method: OAuthMethod, code: string, state?: string) =>
  api.post<ResponseSuccess>(`/auth/oauth-authenticate-callback/${method}`, { code, state });
