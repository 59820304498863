import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';
import {
  addTeam,
  addUser,
  agencyInviteUser,
  assignTeam,
  changeUserRole,
  deleteExpireSession,
  deleteTeam,
  deleteUser,
  deleteUserFromTeam,
  disableUser,
  exportUsers,
  getMembersList,
  getTeams,
  getTeamsAndMembersList,
  getTeamsList,
  getUserSetting,
  getUsers,
  modifyTeam,
  reactivateUser,
  resendInvite,
} from '../../helpers/users-and-teams.api';
import {
  AddTeamPayload,
  AddUserPayload,
  AssignTeamPayload,
  ChangeUserRolePayload,
  DatePickerFilter,
  DeleteUserFromTeamPayload,
  ModifyTeamPayload,
  TeamsFilter,
  UsersFilter,
} from '../../types/users-and-teams';
import { UserStatusKeys } from '../../enums/users-and-teams';
import { AgencyInviteUserRequestPayload } from '../../types/request-payload';

export const getUsersRequest = createAsyncThunk<
  ResponseSuccess,
  UsersFilter,
  { rejectValue: ResponseErrorWithHandled }
>('users/getUsersRequest', async (args, thunkAPI) => {
  try {
    return await getUsers(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const changeUserRoleRequest = createAsyncThunk<
  ResponseSuccess,
  ChangeUserRolePayload,
  { rejectValue: ResponseErrorWithHandled }
>('users/changeUserRoleRequest', async (args, thunkAPI) => {
  try {
    return await changeUserRole(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTeamsListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('users/getTeamsListRequest', async (_args, thunkAPI) => {
  try {
    return await getTeamsList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const assignTeamRequest = createAsyncThunk<
  ResponseSuccess,
  AssignTeamPayload,
  { rejectValue: ResponseErrorWithHandled }
>('users/assignTeamRequest', async (args, thunkAPI) => {
  try {
    return await assignTeam(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteUserRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('users/deleteUserRequest', async (args, thunkAPI) => {
  try {
    return await deleteUser(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const disableUserRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('users/disableUserRequest', async (args, thunkAPI) => {
  try {
    return await disableUser(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const reactivateUserRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('users/reactivateUserRequest', async (args, thunkAPI) => {
  try {
    return await reactivateUser(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const resendInviteRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('users/resendInviteRequest', async (args, thunkAPI) => {
  try {
    return await resendInvite(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const addUserRequest = createAsyncThunk<
  ResponseSuccess,
  AddUserPayload,
  { rejectValue: ResponseErrorWithHandled }
>('users/addUserRequest', async (args, thunkAPI) => {
  try {
    return await addUser(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTeamsRequest = createAsyncThunk<
  ResponseSuccess,
  TeamsFilter,
  { rejectValue: ResponseErrorWithHandled }
>('users/getTeamsRequest', async (args, thunkAPI) => {
  try {
    return await getTeams(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getMembersListRequest = createAsyncThunk<
  ResponseSuccess,
  UserStatusKeys[],
  { rejectValue: ResponseErrorWithHandled }
>('users/getMembersListRequest', async (args, thunkAPI) => {
  try {
    return await getMembersList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTeamsAndMembersListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('users/getTeamsAndMembersListRequest', async (_, thunkAPI) => {
  try {
    return await getTeamsAndMembersList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const modifyTeamRequest = createAsyncThunk<
  ResponseSuccess,
  ModifyTeamPayload,
  { rejectValue: ResponseErrorWithHandled }
>('users/modifyTeamRequest', async (args, thunkAPI) => {
  try {
    return await modifyTeam(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteTeamRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('users/deleteTeamRequest', async (args, thunkAPI) => {
  try {
    return await deleteTeam(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const addTeamRequest = createAsyncThunk<
  ResponseSuccess,
  AddTeamPayload,
  { rejectValue: ResponseErrorWithHandled }
>('users/addTeam', async (args, thunkAPI) => {
  try {
    return await addTeam(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAssignTeamSetting = createAsyncThunk<
  ResponseSuccess,
  { id: number },
  { rejectValue: ResponseErrorWithHandled }
>('users/getAssignTeamSetting', async ({ id }, thunkAPI) => {
  try {
    return await getUserSetting(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteUserFromTeamRequest = createAsyncThunk<
  ResponseSuccess,
  DeleteUserFromTeamPayload,
  { rejectValue: ResponseErrorWithHandled }
>('users/deleteUserFromTeam', async (args, thunkAPI) => {
  try {
    return await deleteUserFromTeam(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const agencyInviteUserRequest = createAsyncThunk<
  ResponseSuccess,
  AgencyInviteUserRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('team/agencyInviteUserRequest', async (payload, thunkAPI) => {
  try {
    return await agencyInviteUser(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const exportUsersRequest = createAsyncThunk<
  ResponseSuccess,
  DatePickerFilter,
  { rejectValue: ResponseErrorWithHandled }
>('team/exportUsersRequest', async (payload , thunkAPI) => {
  try {
    return await exportUsers(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteExpireSessionRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('team/deleteExpireSession', async (args, thunkAPI) => {
  try {
    return await deleteExpireSession(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
