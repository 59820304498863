import { createSlice } from '@reduxjs/toolkit';
import { constants } from '../../shared/enums/constants';
import { RequestStatus } from '../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../shared/types';
import { AddProspectResponse } from '../sequence/types';
import { Field } from '../settings/types/field';
import {
  getProspectRequest,
  getProspectCountRequest,
  getSequenceListRequest,
  getStepListRequest,
  getStepDetailsListRequest,
  addToStepRequest,
  removeProspectRequest,
  unsubscribeProspectRequest,
  getSingleProspectDetailsRequest,
  getSingleProspectSequencesDetailsRequest,
  singleProspectSequencesMarkAsRepliedRequest,
  singleProspectRemoveFromSequencesRequest,
  saveContactAttributesRequest,
  saveContactDealValueRequest,
  getFieldsRequest,
  addProspectManuallyRequest,
  singleProspectSequencesMarkAsFinishedRequest,
  verifyProspectsManuallyRequest,
  getTagsRequest,
  addTagsToProspectsRequest,
  removeTagsFromProspectsRequest,
  verifyBulkProspectsRequest,
  deleteBulkProspectsRequest,
  unsubscribeBulkProspectRequest,
  addBulkProspectsToStepRequest,
  addTagsToBulkProspectsRequest,
  removeTagsFromBulkProspectsRequest,
  changePrimaryEmailInProspectRequest,
  deleteSecondaryEmailRequest,
  deleteSecondaryPhoneRequest,
  getProspectListRequest,
  getProspectListCountRequest,
  getProspectListByIdsRequest,
  getFilterViewListRequest,
  pinUnpinSavedFilterRequest,
  updateSavedFilterRequest,
  deleteSavedViewRequest,
  getProspectFiltersFieldsListRequest,
  getProspectFiltersFieldValuesRequest,
  getProspectFiltersAttributeFieldValuesRequest,
  getProspectFiltersSequencesListRequest,
  getProspectFiltersClientsListRequest,
  getProspectFiltersTagsListRequest,
  getProspectFiltersOwnersListRequest,
  getProspectFiltersOwnersListForAgencyRequest,
  getProspectFiltersOutcomeListRequest,
  saveProspectFiltersViewRequest,
  updateProspectFiltersViewRequest,
  deleteProspectFiltersViewRequest,
  getContactListByViewIdRequest,
  getImportedFilesListRequest,
  updateProspectFieldByIdRequest,
  getLongTextByProspectAndColumnIdRequest,
} from './extra-actions';
import { getTagsToLeadsRequest } from '../leads/extra-actions';
import {
  getProspectsFiltersFromLocalStore,
  removeProspectsFiltersFromLocalStore,
  setProspectsFiltersInLocalStore,
} from './helpers/prospect-filters-resolver';
import {
  Filters,
  ProspectPagination,
  SavedFilter,
  SelectAllContacts,
} from './types';
import {
  ProspectsFilters,
  ProspectFilterResetArg,
} from '../../shared/types/prospects-filters';
import { getMergedProspects } from './helpers/utils/get-merged-prospects';
import { ProspectSortBy } from './enums/prospect';
import { SortOrder } from '../../shared/enums/order';
import {
  ProspectFilterField,
  ProspectFiltersFieldsAttributesValue,
} from './components/prospect-filters-modal/types';
import { getSortedProspectFilterFields } from './components/prospect-filters-modal/utils/helpers';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
}

export const prospectsFiltersInitialState: ProspectsFilters = {
  conversationType: [],
  tags: [],
  owners: [],
  status: [],
  outcomes: [],
  sequences: [],
  'verification status': [],
  'task priority': [],
  'created date': [],
  'creation source': [],
  'current step': [],
  'email account': [],
  'client associated': [],
  'imported file': [],
};

interface State {
  getProspectRequest: RequestState;
  getSequenceListRequest: RequestState;
  getStepListRequest: RequestState;
  getStepDetailsListRequest: RequestState;
  getProspectCountRequest: RequestState;
  addToStepRequest: RequestState;
  addToStepRequestResultCount: {
    failed: number;
    successful: number;
    resultData: any;
  };
  removeProspectRequest: RequestState;
  unsubscribeProspectRequest: RequestState;
  getSingleProspectDetailsRequest: RequestState;
  getSingleProspectSequencesDetailsRequest: RequestState;
  singleProspectSequencesMarkAsRepliedRequest: RequestState;
  singleProspectSequencesMarkAsFinishedRequest: RequestState;
  singleProspectRemoveFromSequencesRequest: RequestState;
  saveContactAttributesRequest: RequestState;
  saveContactDealValueRequest: RequestState;
  getFieldsRequest: RequestState;
  addProspectManuallyRequest: RequestState;
  verifyProspectManuallyRequest: RequestState;
  getTagsRequest: RequestState;
  getTagsToLeadsRequest: RequestState;
  addTagsToProspectsRequest: RequestState;
  removeTagsFromProspectsRequest: RequestState;
  verifyBulkProspectsRequest: RequestState;
  deleteBulkProspectsRequest: RequestState;
  unsubscribeBulkProspectRequest: RequestState;
  addBulkProspectsToStepRequest: RequestState;
  addTagsToBulkProspectsRequest: RequestState;
  removeTagsFromBulkProspectsRequest: RequestState;
  changePrimaryEmailInProspectRequest: RequestState;
  deleteSecondaryEmailRequest: RequestState;
  deleteSecondaryPhoneRequest: RequestState;
  getFilterViewListRequest: RequestState;
  pinUnpinSavedFilterRequest: RequestState;
  updateSavedFilterRequest: RequestState;
  deleteSavedViewRequest: RequestState;
  getContactListByViewIdRequest: RequestState;
  getImportedFilesListRequest: RequestState;
  search: string;
  steps: any[];
  sequences: any[];
  selectedContacts: any[];
  selectAllContactDetails: SelectAllContacts;
  singleProspect: any;
  singleProspectSequenceDetails: any[];
  tags: any[];
  leadTags: any[];
  isProspectSubscribed: boolean;
  prospectPaginationData: ProspectPagination;
  addProspectResponse: AddProspectResponse;
  prospectsFilters: ProspectsFilters;

  // New State
  prospects: any[];
  prospectCount: number;
  filters: Filters;
  fields: Field[];
  savedFilters: SavedFilter[];
  selectedSavedView: SavedFilter;
  prospectFiltersFieldsList: ProspectFilterField[];
  prospectFiltersFieldsAttributesValue: ProspectFiltersFieldsAttributesValue;
  prospectFiltersFieldsAttributesLoading: Record<string, boolean>;
  prospectFiltersFieldsAttributesFetched: Record<string, boolean>;

  getProspectListRequest: RequestState;
  getProspectListCountRequest: RequestState;
  getProspectListByIdsRequest: RequestState;
  getProspectFiltersFieldsListRequest: RequestState;
  getProspectFiltersFieldValuesRequest: RequestState;
  getProspectFiltersAttributeFieldValuesRequest: RequestState;
  getProspectFiltersSequencesListRequest: RequestState;
  getProspectFiltersClientsListRequest: RequestState;
  getProspectFiltersTagsListRequest: RequestState;
  getProspectFiltersOwnersListRequest: RequestState;
  getProspectFiltersOwnersListForAgencyRequest: RequestState;
  getProspectFiltersOutcomeListRequest: RequestState;
  saveProspectFiltersViewRequest: RequestState;
  updateProspectFiltersViewRequest: RequestState;
  deleteProspectFiltersViewRequest: RequestState;
  updateProspectFieldByIdRequest: RequestState;
  getLongTextByProspectAndColumnIdRequest: RequestState;
  stepsDetails: {
    id: number;
    number: number;
  }[];
  importedFilesList: { name: string; value: number }[] | [];
  longText: string;
}

const initialState: State = {
  getProspectListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectListCountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectListByIdsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectFiltersFieldsListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectFiltersFieldValuesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectFiltersAttributeFieldValuesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectFiltersSequencesListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectFiltersClientsListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectFiltersTagsListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectFiltersOwnersListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectFiltersOwnersListForAgencyRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectFiltersOutcomeListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  saveProspectFiltersViewRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateProspectFiltersViewRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteProspectFiltersViewRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },

  getProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getStepListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getStepDetailsListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectCountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSingleProspectDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSingleProspectSequencesDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addToStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  singleProspectSequencesMarkAsRepliedRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  singleProspectSequencesMarkAsFinishedRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  singleProspectRemoveFromSequencesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  unsubscribeProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  removeProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  saveContactAttributesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  saveContactDealValueRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getFieldsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addProspectManuallyRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },

  verifyProspectManuallyRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTagsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTagsToLeadsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addTagsToProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  removeTagsFromProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  verifyBulkProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteBulkProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  unsubscribeBulkProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addBulkProspectsToStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addTagsToBulkProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  removeTagsFromBulkProspectsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  changePrimaryEmailInProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteSecondaryEmailRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteSecondaryPhoneRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getFilterViewListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  pinUnpinSavedFilterRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSavedFilterRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteSavedViewRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getContactListByViewIdRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getImportedFilesListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateProspectFieldByIdRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getLongTextByProspectAndColumnIdRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  prospectPaginationData: {
    pageNum: constants.DEFAULT_PAGE_NUM,
    pageSize: constants.PROSPECT_PAGE_SIZE,
  },
  search: null,
  sequences: [],
  steps: [],
  selectedContacts: [],
  selectAllContactDetails: null,
  singleProspect: null,
  singleProspectSequenceDetails: [],
  tags: [],
  leadTags: [],
  isProspectSubscribed: null,
  addToStepRequestResultCount: {
    failed: 0,
    successful: 0,
    resultData: null,
  },
  addProspectResponse: null,
  prospectsFilters:
    getProspectsFiltersFromLocalStore() || prospectsFiltersInitialState,

  // New State
  prospects: [],
  prospectCount: 0,
  filters: {
    pageNum: 1,
    pageSize: 25,
    sortBy: ProspectSortBy.CreateAt,
    sortOrder: SortOrder.DESC,
    search: '',
    selectedFields: [],
    shouldFetchProspectList: false,
    shouldFetchProspectListCount: false,
    shouldFetchSavedFilters: false,
  },
  fields: [],
  savedFilters: [],
  selectedSavedView: null,
  prospectFiltersFieldsList: [],
  prospectFiltersFieldsAttributesValue: null,
  prospectFiltersFieldsAttributesLoading: null,
  prospectFiltersFieldsAttributesFetched: null,
  stepsDetails: [],
  importedFilesList: [],
  longText: '',
};

const prospectSlice = createSlice({
  name: 'prospect',
  initialState,
  reducers: {
    resetGetProspectListRequest: (state) => {
      state.getProspectListRequest = initialState.getProspectListRequest;
    },
    resetGetProspectListCountRequest: (state) => {
      state.getProspectListCountRequest =
        initialState.getProspectListCountRequest;
    },
    resetGetProspectListByIdsRequest: (state) => {
      state.getProspectListByIdsRequest =
        initialState.getProspectListByIdsRequest;
    },
    resetGetProspectFiltersFieldsListRequest: (state) => {
      state.getProspectFiltersFieldsListRequest =
        initialState.getProspectFiltersFieldsListRequest;
    },
    resetSaveProspectFiltersViewRequest: (state) => {
      state.saveProspectFiltersViewRequest =
        initialState.saveProspectFiltersViewRequest;
    },
    resetUpdateProspectFiltersViewRequest: (state) => {
      state.updateProspectFiltersViewRequest =
        initialState.updateProspectFiltersViewRequest;
    },
    resetPinUnpinSavedFilterRequest: (state) => {
      state.pinUnpinSavedFilterRequest =
        initialState.pinUnpinSavedFilterRequest;
    },
    resetUpdateSavedFilterRequest: (state) => {
      state.updateSavedFilterRequest = initialState.updateSavedFilterRequest;
    },
    resetDeleteSavedViewRequest: (state) => {
      state.deleteSavedViewRequest = initialState.deleteSavedViewRequest;
    },

    setFilters: (
      state,
      action: {
        payload: Partial<Filters>;
      },
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },

    resetProspectFiltersSelectedFields: (state) => {
      state.filters = {
        ...state.filters,
        selectedFields: [],
      };
    },

    resetProspect: (state) => {
      state.prospectCount = initialState.prospectCount;
      state.prospectPaginationData = initialState.prospectPaginationData;
      state.prospects = initialState.prospects;
      state.search = initialState.search;
      state.getProspectRequest = initialState.getProspectRequest;
    },

    resetRemoveProspectRequest: (state) => {
      state.removeProspectRequest.status = RequestStatus.Ideal;
      state.removeProspectRequest.error = null;
      state.removeProspectRequest.message = null;
    },

    resetAddToProspectForm: (state) => {
      state.addProspectManuallyRequest =
        initialState.addProspectManuallyRequest;
      state.addProspectResponse = initialState.addProspectResponse;
    },

    resetGetStepDetailsListRequest: (state) => {
      state.getStepDetailsListRequest = initialState.getStepDetailsListRequest;
    },

    resetSequenceAndStepData: (state) => {
      state.sequences = initialState.sequences;
      state.steps = initialState.steps;
      state.getStepListRequest = initialState.getStepListRequest;
      state.getStepDetailsListRequest = initialState.getStepDetailsListRequest;
      state.getSequenceListRequest = initialState.getSequenceListRequest;
      state.addToStepRequest = initialState.addToStepRequest;
      state.removeProspectRequest = initialState.removeProspectRequest;
      state.singleProspectRemoveFromSequencesRequest =
        initialState.singleProspectRemoveFromSequencesRequest;
      state.singleProspectSequencesMarkAsRepliedRequest =
        initialState.singleProspectSequencesMarkAsRepliedRequest;
      state.singleProspectSequencesMarkAsFinishedRequest =
        initialState.singleProspectSequencesMarkAsFinishedRequest;
      state.unsubscribeProspectRequest =
        initialState.unsubscribeProspectRequest;
      state.saveContactAttributesRequest =
        initialState.saveContactAttributesRequest;
      state.saveContactDealValueRequest =
        initialState.saveContactDealValueRequest;
      state.verifyProspectManuallyRequest =
        initialState.verifyProspectManuallyRequest;
    },
    resetStep: (state) => {
      state.steps = initialState.steps;
      state.addToStepRequest = initialState.addToStepRequest;
    },
    selectAllContacts: (state, action) => {
      state.selectAllContactDetails = action.payload;
    },
    clearSelection: (state) => {
      state.selectAllContactDetails = initialState.selectAllContactDetails;
      state.selectedContacts = initialState.selectedContacts;
    },
    resetSaveContactAttributesRequestStatus: (state) => {
      state.saveContactAttributesRequest =
        initialState.saveContactAttributesRequest;
    },
    resetProspectDeleteEmailRequestStatus: (state) => {
      state.deleteSecondaryEmailRequest =
        initialState.deleteSecondaryEmailRequest;
    },
    resetProspectDeletePhoneRequestStatus: (state) => {
      state.deleteSecondaryPhoneRequest =
        initialState.deleteSecondaryPhoneRequest;
    },
    resetProspectPrimaryEmailChangeRequestStatus: (state) => {
      state.changePrimaryEmailInProspectRequest =
        initialState.changePrimaryEmailInProspectRequest;
    },
    resetSingleProspect: (state) => {
      state.singleProspect = initialState.singleProspect;
    },
    checkAll: (state, action) => {
      if (action.payload.status === true) {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          action.payload.rows.forEach(({ id: contactId }) => {
            state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails.deSelectedContactIds.filter(
              (id) => contactId !== id,
            );
          });
        }
        state.selectedContacts = state.selectedContacts.concat(
          action.payload.rows,
        );
      } else {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          action.payload.rows.forEach(({ id }) => {
            state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails?.deSelectedContactIds.concat(
              [id],
            );
          });
        }
        state.selectedContacts = state.selectedContacts.reduce((acc, item) => {
          let isPresent = false;
          action.payload.rows.forEach(({ id }) => {
            if (id === item.id) {
              isPresent = true;
            }
          });
          if (isPresent) {
            return acc;
          }
          return [...acc, item];
        }, []);
      }
    },
    setProspectDetails: (state, action) => {
      const prospectWithId = state.prospects.find(
        (prospect) => prospect.id === action.payload,
      );

      const attributes = {
        id: prospectWithId.id,
        attributes: prospectWithId.attributes,
        tags: prospectWithId.tags,
        isBlacklisted: prospectWithId.isBlacklisted,
      };

      // state.singleProspectSequenceDetails = listOfSequences;
      state.singleProspect = attributes;
      state.isProspectSubscribed = prospectWithId.isSubscribed;
    },
    resetSelectedContacts: (state) => {
      state.selectedContacts = initialState.selectedContacts;
    },
    checkSingle: (state, action) => {
      if (action.payload.status === true) {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails.deSelectedContactIds.filter(
            (id) => id !== action.payload.row.id,
          );
        }
        state.selectedContacts = state.selectedContacts.concat([
          action.payload.row,
        ]);
      } else {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails?.deSelectedContactIds.concat(
            [action.payload.row.id],
          );
        }
        state.selectedContacts = state.selectedContacts.filter(
          ({ id }) => id !== action.payload.row.id,
        );
      }
    },
    changeProspectPagination: (state, action) => {
      state.prospectPaginationData = action.payload;
    },

    saveSearch: (state, action) => {
      state.search = action.payload;
    },
    setProspectsFilters: (state, action) => {
      state.prospectsFilters = { ...action.payload };
      setProspectsFiltersInLocalStore(action.payload);
    },
    resetProspectsFilters: (state, action) => {
      const resetOff: ProspectFilterResetArg = action.payload;
      if (resetOff === 'all') {
        state.prospectsFilters = prospectsFiltersInitialState;

        removeProspectsFiltersFromLocalStore();
      } else {
        state.prospectsFilters[resetOff] = [];
        setProspectsFiltersInLocalStore(state.prospectsFilters);
      }
    },
    resetTags: (state) => {
      state.addTagsToProspectsRequest = initialState.addTagsToProspectsRequest;
      state.removeTagsFromProspectsRequest =
        initialState.removeTagsFromProspectsRequest;
    },
    resetBulkRequests: (state) => {
      state.verifyBulkProspectsRequest =
        initialState.verifyBulkProspectsRequest;
      state.deleteBulkProspectsRequest =
        initialState.deleteBulkProspectsRequest;
      state.unsubscribeBulkProspectRequest =
        initialState.unsubscribeBulkProspectRequest;
      state.addBulkProspectsToStepRequest =
        initialState.addBulkProspectsToStepRequest;
      state.addTagsToBulkProspectsRequest =
        initialState.addTagsToBulkProspectsRequest;
      state.removeTagsFromBulkProspectsRequest =
        initialState.removeTagsFromBulkProspectsRequest;
    },
    resetUnsubscribeRequestState: (state) => {
      state.unsubscribeProspectRequest =
        initialState.unsubscribeProspectRequest;
    },
    setSelectedSavedView: (state, action) => {
      state.selectedSavedView = action.payload;
    },
    removedSelectedSavedView: (state) => {
      state.selectedSavedView = initialState.selectedSavedView;
    },
    resetFilterGroupLogic: (state) => {
      state.filters = {
        ...state.filters,
        filterGroupLogic: null,
      };
    },
    resetProspectFiltersFieldsAttributesValue: (state, action) => {
      if (
        state.prospectFiltersFieldsAttributesValue &&
        action?.payload?.resetAll
      ) {
        state.prospectFiltersFieldsAttributesValue =
          initialState.prospectFiltersFieldsAttributesValue;
        state.prospectFiltersFieldsAttributesFetched =
          initialState.prospectFiltersFieldsAttributesFetched;
        state.prospectFiltersFieldsAttributesLoading =
          initialState.prospectFiltersFieldsAttributesLoading;
        return;
      }

      if (
        state.prospectFiltersFieldsAttributesValue &&
        action?.payload?.key &&
        state.prospectFiltersFieldsAttributesValue?.[action?.payload?.key]
      ) {
        const { meta } = state.prospectFiltersFieldsAttributesValue?.[
          action.payload.key
        ] || { meta: null };

        if ((meta && meta.search !== '') || action.payload.key === 'tags') {
          state.prospectFiltersFieldsAttributesValue[action.payload.key] = {
            meta: null,
            payload: [],
          };
          state.prospectFiltersFieldsAttributesFetched[
            action.payload.key
          ] = false;
        }
      }
    },
    resetImportedFilesListValue: (state) => {
      state.importedFilesList = [];
    },
    resetGetLongTextByProspectAndColumnIdRequest: (state) => {
      state.getLongTextByProspectAndColumnIdRequest =
        initialState.getLongTextByProspectAndColumnIdRequest;
      state.longText = initialState.longText;
    },
  },
  extraReducers: (builder) => {
    // Get Prospect List
    builder.addCase(getProspectListRequest.pending, (state) => {
      state.getProspectListRequest.status = RequestStatus.Pending;
      state.getProspectListRequest.error = null;
    });
    builder.addCase(getProspectListRequest.fulfilled, (state, action) => {
      state.getProspectListRequest.status = RequestStatus.Succeeded;
      state.getProspectListRequest.message = action.payload.message;
      state.prospects = action.payload.payload;
    });
    builder.addCase(getProspectListRequest.rejected, (state, action) => {
      state.getProspectListRequest.status = RequestStatus.Failed;
      state.getProspectListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Prospect List Count
    builder.addCase(getProspectListCountRequest.pending, (state) => {
      state.getProspectListCountRequest.status = RequestStatus.Pending;
      state.getProspectListCountRequest.error = null;
    });
    builder.addCase(getProspectListCountRequest.fulfilled, (state, action) => {
      state.getProspectListCountRequest.status = RequestStatus.Succeeded;
      state.getProspectListCountRequest.message = action.payload.message;
      state.prospectCount = action.payload.payload;
    });
    builder.addCase(getProspectListCountRequest.rejected, (state, action) => {
      state.getProspectListCountRequest.status = RequestStatus.Failed;
      state.getProspectListCountRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Prospect List By Ids
    builder.addCase(getProspectListByIdsRequest.pending, (state) => {
      state.getProspectListByIdsRequest.status = RequestStatus.Pending;
      state.getProspectListByIdsRequest.error = null;
    });
    builder.addCase(getProspectListByIdsRequest.fulfilled, (state, action) => {
      state.getProspectListByIdsRequest.status = RequestStatus.Succeeded;
      state.getProspectListByIdsRequest.message = action.payload.message;
      state.prospects = getMergedProspects(
        state.prospects,
        action.payload.payload,
      );
    });
    builder.addCase(getProspectListByIdsRequest.rejected, (state, action) => {
      state.getProspectListByIdsRequest.status = RequestStatus.Failed;
      state.getProspectListByIdsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Prospect Filter Fields List
    builder.addCase(getProspectFiltersFieldsListRequest.pending, (state) => {
      state.getProspectFiltersFieldsListRequest.status = RequestStatus.Pending;
      state.getProspectFiltersFieldsListRequest.error = null;
    });
    builder.addCase(
      getProspectFiltersFieldsListRequest.fulfilled,
      (state, action) => {
        state.getProspectFiltersFieldsListRequest.status =
          RequestStatus.Succeeded;
        state.getProspectFiltersFieldsListRequest.message =
          action.payload.message;
        state.prospectFiltersFieldsList = getSortedProspectFilterFields(
          action.payload.payload.fields,
        );
      },
    );
    builder.addCase(
      getProspectFiltersFieldsListRequest.rejected,
      (state, action) => {
        state.getProspectFiltersFieldsListRequest.status = RequestStatus.Failed;
        state.getProspectFiltersFieldsListRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Prospect Filter Fields Values
    builder.addCase(
      getProspectFiltersFieldValuesRequest.pending,
      (state, action) => {
        state.getProspectFiltersFieldValuesRequest.status =
          RequestStatus.Pending;
        state.getProspectFiltersFieldValuesRequest.error = null;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          [action.meta.arg.key]: true,
        };
        state.prospectFiltersFieldsAttributesFetched = {
          ...state.prospectFiltersFieldsAttributesFetched,
          [action.meta.arg.key]: true,
        };
      },
    );
    builder.addCase(
      getProspectFiltersFieldValuesRequest.fulfilled,
      (state, action) => {
        state.getProspectFiltersFieldValuesRequest.status =
          RequestStatus.Succeeded;
        state.getProspectFiltersFieldValuesRequest.message =
          action.payload.message;
        state.prospectFiltersFieldsAttributesValue = {
          ...state.prospectFiltersFieldsAttributesValue,
          [action.meta.arg.key]: {
            meta: {
              ...action.meta.arg,
              hasMoreData: action.payload.payload.hasMoreData,
            },
            payload:
              action.meta.arg.page === 1
                ? action.payload.payload.prospectValues
                : [
                    ...(state.prospectFiltersFieldsAttributesValue?.[
                      action.meta.arg.key
                    ]?.payload || []),
                    ...action.payload.payload.prospectValues,
                  ],
          },
        };
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          [action.meta.arg.key]: false,
        };
      },
    );
    builder.addCase(
      getProspectFiltersFieldValuesRequest.rejected,
      (state, action) => {
        state.getProspectFiltersFieldValuesRequest.status =
          RequestStatus.Failed;
        state.getProspectFiltersFieldValuesRequest.error =
          !action.payload.isHandled && action.payload;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          [action.meta.arg.key]: false,
        };
      },
    );

    // Get Prospect Filter Field Attribute Values
    builder.addCase(
      getProspectFiltersAttributeFieldValuesRequest.pending,
      (state, action) => {
        state.getProspectFiltersAttributeFieldValuesRequest.status =
          RequestStatus.Pending;
        state.getProspectFiltersAttributeFieldValuesRequest.error = null;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          [action.meta.arg.key]: true,
        };
        state.prospectFiltersFieldsAttributesFetched = {
          ...state.prospectFiltersFieldsAttributesFetched,
          [action.meta.arg.key]: true,
        };
      },
    );
    builder.addCase(
      getProspectFiltersAttributeFieldValuesRequest.fulfilled,
      (state, action) => {
        state.getProspectFiltersAttributeFieldValuesRequest.status =
          RequestStatus.Succeeded;
        state.getProspectFiltersAttributeFieldValuesRequest.message =
          action.payload.message;
        state.prospectFiltersFieldsAttributesValue = {
          ...state.prospectFiltersFieldsAttributesValue,
          [action.meta.arg.key]: {
            meta: {
              ...action.meta.arg,
              hasMoreData: action.payload.payload.hasMoreData,
            },
            payload:
              action.meta.arg.page === 1
                ? action.payload.payload.prospectAttributeValues
                : [
                    ...(state.prospectFiltersFieldsAttributesValue?.[
                      action.meta.arg.key
                    ]?.payload || []),
                    ...action.payload.payload.prospectAttributeValues,
                  ],
          },
        };
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          [action.meta.arg.key]: false,
        };
      },
    );
    builder.addCase(
      getProspectFiltersAttributeFieldValuesRequest.rejected,
      (state, action) => {
        state.getProspectFiltersAttributeFieldValuesRequest.status =
          RequestStatus.Failed;
        state.getProspectFiltersAttributeFieldValuesRequest.error =
          !action.payload.isHandled && action.payload;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          [action.meta.arg.key]: false,
        };
      },
    );

    // Get Prospect Filter Sequence List
    builder.addCase(getProspectFiltersSequencesListRequest.pending, (state) => {
      state.getProspectFiltersSequencesListRequest.status =
        RequestStatus.Pending;
      state.getProspectFiltersSequencesListRequest.error = null;
      state.prospectFiltersFieldsAttributesLoading = {
        ...state.prospectFiltersFieldsAttributesLoading,
        sequence: true,
      };
      state.prospectFiltersFieldsAttributesFetched = {
        ...state.prospectFiltersFieldsAttributesFetched,
        sequence: true,
      };
    });
    builder.addCase(
      getProspectFiltersSequencesListRequest.fulfilled,
      (state, action) => {
        state.getProspectFiltersSequencesListRequest.status =
          RequestStatus.Succeeded;
        state.getProspectFiltersSequencesListRequest.message =
          action.payload.message;
        state.prospectFiltersFieldsAttributesValue = {
          ...state.prospectFiltersFieldsAttributesValue,
          sequence: {
            meta: {
              ...action.meta.arg,
              key: 'sequence',
              hasMoreData: action.payload.payload.hasMoreData,
            },
            payload:
              action.meta.arg.page === 1
                ? action.payload.payload.sequences?.map((sequence) => ({
                    name: sequence.title,
                    value: sequence.id.toString(),
                  }))
                : [
                    ...(state.prospectFiltersFieldsAttributesValue?.sequence
                      ?.payload || []),
                    ...action.payload.payload.sequences?.map((sequence) => ({
                      name: sequence.title,
                      value: sequence.id.toString(),
                    })),
                  ],
          },
        };
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          sequence: false,
        };
      },
    );
    builder.addCase(
      getProspectFiltersSequencesListRequest.rejected,
      (state, action) => {
        state.getProspectFiltersSequencesListRequest.status =
          RequestStatus.Failed;
        state.getProspectFiltersSequencesListRequest.error =
          !action.payload.isHandled && action.payload;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          sequence: false,
        };
      },
    );

    // Get Prospect Filter Clients List
    builder.addCase(getProspectFiltersClientsListRequest.pending, (state) => {
      state.getProspectFiltersClientsListRequest.status = RequestStatus.Pending;
      state.getProspectFiltersClientsListRequest.error = null;
      state.prospectFiltersFieldsAttributesLoading = {
        ...state.prospectFiltersFieldsAttributesLoading,
        clientSequenceAssociation: true,
      };
      state.prospectFiltersFieldsAttributesFetched = {
        ...state.prospectFiltersFieldsAttributesFetched,
        clientSequenceAssociation: true,
      };
    });
    builder.addCase(
      getProspectFiltersClientsListRequest.fulfilled,
      (state, action) => {
        state.getProspectFiltersClientsListRequest.status =
          RequestStatus.Succeeded;
        state.getProspectFiltersClientsListRequest.message =
          action.payload.message;
        state.prospectFiltersFieldsAttributesValue = {
          ...state.prospectFiltersFieldsAttributesValue,
          clientSequenceAssociation: {
            meta: {
              ...action.meta.arg,
              key: 'clientSequenceAssociation',
              hasMoreData: action.payload.payload.hasMoreData,
            },
            payload:
              action.meta.arg.page === 1
                ? action.payload.payload.clients?.map((client) => ({
                    name: `${client.companyName || ''} (${
                      client.email
                    })`.trim(),
                    value: client.id.toString(),
                  }))
                : [
                    ...(state.prospectFiltersFieldsAttributesValue
                      ?.clientSequenceAssociation?.payload || []),
                    ...action.payload.payload.clients?.map((client) => ({
                      name: `${client.companyName || ''} (${
                        client.email
                      })`.trim(),
                      value: client.id.toString(),
                    })),
                  ],
          },
        };
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          clientSequenceAssociation: false,
        };
      },
    );
    builder.addCase(
      getProspectFiltersClientsListRequest.rejected,
      (state, action) => {
        state.getProspectFiltersClientsListRequest.status =
          RequestStatus.Failed;
        state.getProspectFiltersClientsListRequest.error =
          !action.payload.isHandled && action.payload;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          clientSequenceAssociation: false,
        };
      },
    );

    // Get Prospect Filter Tags List
    builder.addCase(getProspectFiltersTagsListRequest.pending, (state) => {
      state.getProspectFiltersTagsListRequest.status = RequestStatus.Pending;
      state.getProspectFiltersTagsListRequest.error = null;
      state.prospectFiltersFieldsAttributesLoading = {
        ...state.prospectFiltersFieldsAttributesLoading,
        tags: true,
      };
      state.prospectFiltersFieldsAttributesFetched = {
        ...state.prospectFiltersFieldsAttributesFetched,
        tags: true,
      };
    });
    builder.addCase(
      getProspectFiltersTagsListRequest.fulfilled,
      (state, action) => {
        state.getProspectFiltersTagsListRequest.status =
          RequestStatus.Succeeded;
        state.getProspectFiltersTagsListRequest.message =
          action.payload.message;
        state.prospectFiltersFieldsAttributesValue = {
          ...state.prospectFiltersFieldsAttributesValue,
          tags: {
            meta: null,
            payload: [
              ...(state.prospectFiltersFieldsAttributesValue?.tags?.payload ||
                []),
              ...action.payload.payload?.map((tag) => ({
                name: tag.name,
                value: tag.id.toString(),
              })),
            ],
          },
        };
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          tags: false,
        };
      },
    );
    builder.addCase(
      getProspectFiltersTagsListRequest.rejected,
      (state, action) => {
        state.getProspectFiltersTagsListRequest.status = RequestStatus.Failed;
        state.getProspectFiltersTagsListRequest.error =
          !action.payload.isHandled && action.payload;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          tags: false,
        };
      },
    );

    // Get Prospect Filter Owners List
    builder.addCase(getProspectFiltersOwnersListRequest.pending, (state) => {
      state.getProspectFiltersOwnersListRequest.status = RequestStatus.Pending;
      state.getProspectFiltersOwnersListRequest.error = null;
      state.prospectFiltersFieldsAttributesLoading = {
        ...state.prospectFiltersFieldsAttributesLoading,
        owners: true,
      };
      state.prospectFiltersFieldsAttributesFetched = {
        ...state.prospectFiltersFieldsAttributesFetched,
        owners: true,
      };
    });
    builder.addCase(
      getProspectFiltersOwnersListRequest.fulfilled,
      (state, action) => {
        state.getProspectFiltersOwnersListRequest.status =
          RequestStatus.Succeeded;
        state.getProspectFiltersOwnersListRequest.message =
          action.payload.message;
        state.prospectFiltersFieldsAttributesValue = {
          ...state.prospectFiltersFieldsAttributesValue,
          owners: {
            meta: null,
            payload: [
              ...(state.prospectFiltersFieldsAttributesValue?.owners?.payload ||
                []),
              ...action.payload.payload,
            ],
          },
        };
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          owners: false,
        };
      },
    );
    builder.addCase(
      getProspectFiltersOwnersListRequest.rejected,
      (state, action) => {
        state.getProspectFiltersOwnersListRequest.status = RequestStatus.Failed;
        state.getProspectFiltersOwnersListRequest.error =
          !action.payload.isHandled && action.payload;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          owners: false,
        };
      },
    );

    // Get Prospect Filter Owners List For Agency
    builder.addCase(
      getProspectFiltersOwnersListForAgencyRequest.pending,
      (state) => {
        state.getProspectFiltersOwnersListForAgencyRequest.status =
          RequestStatus.Pending;
        state.getProspectFiltersOwnersListForAgencyRequest.error = null;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          owners: true,
        };
        state.prospectFiltersFieldsAttributesFetched = {
          ...state.prospectFiltersFieldsAttributesFetched,
          owners: true,
        };
      },
    );
    builder.addCase(
      getProspectFiltersOwnersListForAgencyRequest.fulfilled,
      (state, action) => {
        state.getProspectFiltersOwnersListForAgencyRequest.status =
          RequestStatus.Succeeded;
        state.getProspectFiltersOwnersListForAgencyRequest.message =
          action.payload.message;

        state.prospectFiltersFieldsAttributesValue = {
          ...state.prospectFiltersFieldsAttributesValue,
          owners: {
            meta: null,
            payload: [
              ...(state.prospectFiltersFieldsAttributesValue?.owners?.payload ||
                []),
              ...action.payload.payload?.map((owner) => ({
                name:
                  `${owner?.firstName || ''} ${owner?.lastName || ''}`.trim() ??
                  owner?.email,
                value: owner?.id?.toString() || '',
              })),
            ],
          },
        };
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          owners: false,
        };
      },
    );
    builder.addCase(
      getProspectFiltersOwnersListForAgencyRequest.rejected,
      (state, action) => {
        state.getProspectFiltersOwnersListForAgencyRequest.status =
          RequestStatus.Failed;
        state.getProspectFiltersOwnersListForAgencyRequest.error =
          !action.payload.isHandled && action.payload;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          owners: false,
        };
      },
    );

    // Get Prospect Filter Outcomes List
    builder.addCase(getProspectFiltersOutcomeListRequest.pending, (state) => {
      state.getProspectFiltersOutcomeListRequest.status = RequestStatus.Pending;
      state.getProspectFiltersOutcomeListRequest.error = null;
      state.prospectFiltersFieldsAttributesLoading = {
        ...state.prospectFiltersFieldsAttributesLoading,
        outcomes: true,
      };
      state.prospectFiltersFieldsAttributesFetched = {
        ...state.prospectFiltersFieldsAttributesFetched,
        outcomes: true,
      };
    });
    builder.addCase(
      getProspectFiltersOutcomeListRequest.fulfilled,
      (state, action) => {
        state.getProspectFiltersOutcomeListRequest.status =
          RequestStatus.Succeeded;
        state.getProspectFiltersOutcomeListRequest.message =
          action.payload.message;
        state.prospectFiltersFieldsAttributesValue = {
          ...state.prospectFiltersFieldsAttributesValue,
          outcomes: {
            meta: null,
            payload: [
              ...(state.prospectFiltersFieldsAttributesValue?.outcomes
                ?.payload || []),
              ...action.payload.payload?.map((outcome) => ({
                name: outcome.name,
                value: outcome.id.toString(),
              })),
            ],
          },
        };
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          outcomes: false,
        };
      },
    );
    builder.addCase(
      getProspectFiltersOutcomeListRequest.rejected,
      (state, action) => {
        state.getProspectFiltersOutcomeListRequest.status =
          RequestStatus.Failed;
        state.getProspectFiltersOutcomeListRequest.error =
          !action.payload.isHandled && action.payload;
        state.prospectFiltersFieldsAttributesLoading = {
          ...state.prospectFiltersFieldsAttributesLoading,
          outcomes: false,
        };
      },
    );

    // Save Prospect Filters View
    builder.addCase(saveProspectFiltersViewRequest.pending, (state) => {
      state.saveProspectFiltersViewRequest.status = RequestStatus.Pending;
      state.saveProspectFiltersViewRequest.error = null;
    });
    builder.addCase(
      saveProspectFiltersViewRequest.fulfilled,
      (state, action) => {
        state.saveProspectFiltersViewRequest.status = RequestStatus.Succeeded;
        state.saveProspectFiltersViewRequest.message = action.payload.message;
        state.selectedSavedView = action.payload.payload;
      },
    );
    builder.addCase(
      saveProspectFiltersViewRequest.rejected,
      (state, action) => {
        state.saveProspectFiltersViewRequest.status = RequestStatus.Failed;
        state.saveProspectFiltersViewRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Update Prospect Filters View
    builder.addCase(updateProspectFiltersViewRequest.pending, (state) => {
      state.updateProspectFiltersViewRequest.status = RequestStatus.Pending;
      state.updateProspectFiltersViewRequest.error = null;
    });
    builder.addCase(
      updateProspectFiltersViewRequest.fulfilled,
      (state, action) => {
        state.updateProspectFiltersViewRequest.status = RequestStatus.Succeeded;
        state.updateProspectFiltersViewRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      updateProspectFiltersViewRequest.rejected,
      (state, action) => {
        state.updateProspectFiltersViewRequest.status = RequestStatus.Failed;
        state.updateProspectFiltersViewRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Delete Prospect Filters View
    builder.addCase(deleteProspectFiltersViewRequest.pending, (state) => {
      state.deleteProspectFiltersViewRequest.status = RequestStatus.Pending;
      state.deleteProspectFiltersViewRequest.error = null;
    });
    builder.addCase(
      deleteProspectFiltersViewRequest.fulfilled,
      (state, action) => {
        state.deleteProspectFiltersViewRequest.status = RequestStatus.Succeeded;
        state.deleteProspectFiltersViewRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      deleteProspectFiltersViewRequest.rejected,
      (state, action) => {
        state.deleteProspectFiltersViewRequest.status = RequestStatus.Failed;
        state.deleteProspectFiltersViewRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Prospect Request
    builder.addCase(getProspectRequest.pending, (state) => {
      state.getProspectRequest.status = RequestStatus.Pending;
      state.getProspectRequest.error = null;
    });
    builder.addCase(getProspectRequest.fulfilled, (state, action) => {
      state.getProspectRequest.status = RequestStatus.Succeeded;
      state.getProspectRequest.message = action.payload.message;
      state.prospects = action.payload.payload.contacts;
    });
    builder.addCase(getProspectRequest.rejected, (state, action) => {
      state.getProspectRequest.status = RequestStatus.Failed;
      state.getProspectRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // Prospect Count Request
    builder.addCase(getProspectCountRequest.pending, (state) => {
      state.getProspectCountRequest.status = RequestStatus.Pending;
      state.getProspectCountRequest.error = null;
    });
    builder.addCase(getProspectCountRequest.fulfilled, (state, action) => {
      state.getProspectCountRequest.status = RequestStatus.Succeeded;
      state.getProspectCountRequest.message = action.payload.message;
      state.prospectCount = action.payload.payload;
    });
    builder.addCase(getProspectCountRequest.rejected, (state, action) => {
      state.getProspectCountRequest.status = RequestStatus.Failed;
      state.getProspectCountRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // Sequence List
    builder.addCase(getSequenceListRequest.pending, (state) => {
      state.getSequenceListRequest.status = RequestStatus.Pending;
      state.getSequenceListRequest.error = null;
    });
    builder.addCase(getSequenceListRequest.fulfilled, (state, action) => {
      state.getSequenceListRequest.status = RequestStatus.Succeeded;
      state.getSequenceListRequest.message = action.payload.message;
      state.sequences = action.payload.payload;
    });
    builder.addCase(getSequenceListRequest.rejected, (state, action) => {
      state.getSequenceListRequest.status = RequestStatus.Failed;
      state.getSequenceListRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // Step List
    builder.addCase(getStepListRequest.pending, (state) => {
      state.getStepListRequest.status = RequestStatus.Pending;
      state.getStepListRequest.error = null;
    });
    builder.addCase(getStepListRequest.fulfilled, (state, action) => {
      state.getStepListRequest.status = RequestStatus.Succeeded;
      state.getStepListRequest.message = action.payload.message;
      state.steps = action.payload.payload;
    });
    builder.addCase(getStepListRequest.rejected, (state, action) => {
      state.getStepListRequest.status = RequestStatus.Failed;
      state.getStepListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Step  Details List
    builder.addCase(getStepDetailsListRequest.pending, (state) => {
      state.getStepDetailsListRequest.status = RequestStatus.Pending;
      state.getStepDetailsListRequest.error = null;
    });
    builder.addCase(getStepDetailsListRequest.fulfilled, (state, action) => {
      state.getStepDetailsListRequest.status = RequestStatus.Succeeded;
      state.getStepDetailsListRequest.message = action.payload.message;
      state.stepsDetails = action.payload.payload;
    });
    builder.addCase(getStepDetailsListRequest.rejected, (state, action) => {
      state.getStepDetailsListRequest.status = RequestStatus.Failed;
      state.getStepDetailsListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Remove Prospect
    builder.addCase(removeProspectRequest.pending, (state) => {
      state.removeProspectRequest.status = RequestStatus.Pending;
      state.removeProspectRequest.error = null;
    });
    builder.addCase(removeProspectRequest.fulfilled, (state, action) => {
      state.removeProspectRequest.status = RequestStatus.Succeeded;
      state.removeProspectRequest.message = action.payload.message;
      state.steps = action.payload.payload;
    });
    builder.addCase(removeProspectRequest.rejected, (state, action) => {
      state.removeProspectRequest.status = RequestStatus.Failed;
      state.removeProspectRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Unsubscribe Prospect Request
    builder.addCase(unsubscribeProspectRequest.pending, (state) => {
      state.unsubscribeProspectRequest.status = RequestStatus.Pending;
      state.unsubscribeProspectRequest.error = null;
    });
    builder.addCase(unsubscribeProspectRequest.fulfilled, (state, action) => {
      state.unsubscribeProspectRequest.status = RequestStatus.Succeeded;
      state.unsubscribeProspectRequest.message = action.payload.message;
      state.steps = action.payload.payload;
    });
    builder.addCase(unsubscribeProspectRequest.rejected, (state, action) => {
      state.unsubscribeProspectRequest.status = RequestStatus.Failed;
      state.unsubscribeProspectRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Single Contact Details
    builder.addCase(getSingleProspectDetailsRequest.pending, (state) => {
      state.getSingleProspectDetailsRequest.status = RequestStatus.Pending;
      state.getSingleProspectDetailsRequest.error = null;
    });
    builder.addCase(
      getSingleProspectDetailsRequest.fulfilled,
      (state, action) => {
        state.getSingleProspectDetailsRequest.status = RequestStatus.Succeeded;
        state.getSingleProspectDetailsRequest.message = action.payload.message;
        state.singleProspect = action.payload.payload;
      },
    );
    builder.addCase(
      getSingleProspectDetailsRequest.rejected,
      (state, action) => {
        state.getSingleProspectDetailsRequest.status = RequestStatus.Failed;
        state.getSingleProspectDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Single Contact Sequence Details
    builder.addCase(
      getSingleProspectSequencesDetailsRequest.pending,
      (state) => {
        state.getSingleProspectSequencesDetailsRequest.status =
          RequestStatus.Pending;
        state.getSingleProspectSequencesDetailsRequest.error = null;
      },
    );
    builder.addCase(
      getSingleProspectSequencesDetailsRequest.fulfilled,
      (state, action) => {
        state.getSingleProspectSequencesDetailsRequest.status =
          RequestStatus.Succeeded;
        state.getSingleProspectSequencesDetailsRequest.message =
          action.payload.message;
        state.singleProspectSequenceDetails = action.payload.payload;
      },
    );
    builder.addCase(
      getSingleProspectSequencesDetailsRequest.rejected,
      (state, action) => {
        state.getSingleProspectSequencesDetailsRequest.status =
          RequestStatus.Failed;
        state.getSingleProspectSequencesDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Mark Single Contact Sequence as Replied
    builder.addCase(
      singleProspectSequencesMarkAsRepliedRequest.pending,
      (state) => {
        state.singleProspectSequencesMarkAsRepliedRequest.status =
          RequestStatus.Pending;
        state.singleProspectSequencesMarkAsRepliedRequest.error = null;
      },
    );
    builder.addCase(
      singleProspectSequencesMarkAsRepliedRequest.fulfilled,
      (state, action) => {
        state.singleProspectSequencesMarkAsRepliedRequest.status =
          RequestStatus.Succeeded;
        state.singleProspectSequencesMarkAsRepliedRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      singleProspectSequencesMarkAsRepliedRequest.rejected,
      (state, action) => {
        state.singleProspectSequencesMarkAsRepliedRequest.status =
          RequestStatus.Failed;
        state.singleProspectSequencesMarkAsRepliedRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Mark Single Contact Sequence as Finished
    builder.addCase(
      singleProspectSequencesMarkAsFinishedRequest.pending,
      (state) => {
        state.singleProspectSequencesMarkAsFinishedRequest.status =
          RequestStatus.Pending;
        state.singleProspectSequencesMarkAsFinishedRequest.error = null;
      },
    );
    builder.addCase(
      singleProspectSequencesMarkAsFinishedRequest.fulfilled,
      (state, action) => {
        state.singleProspectSequencesMarkAsFinishedRequest.status =
          RequestStatus.Succeeded;
        state.singleProspectSequencesMarkAsFinishedRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      singleProspectSequencesMarkAsFinishedRequest.rejected,
      (state, action) => {
        state.singleProspectSequencesMarkAsFinishedRequest.status =
          RequestStatus.Failed;
        state.singleProspectSequencesMarkAsFinishedRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Remove From Sequence for single contact
    builder.addCase(
      singleProspectRemoveFromSequencesRequest.pending,
      (state) => {
        state.singleProspectRemoveFromSequencesRequest.status =
          RequestStatus.Pending;
        state.singleProspectRemoveFromSequencesRequest.error = null;
      },
    );
    builder.addCase(
      singleProspectRemoveFromSequencesRequest.fulfilled,
      (state, action) => {
        state.singleProspectRemoveFromSequencesRequest.status =
          RequestStatus.Succeeded;
        state.singleProspectRemoveFromSequencesRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      singleProspectRemoveFromSequencesRequest.rejected,
      (state, action) => {
        state.singleProspectRemoveFromSequencesRequest.status =
          RequestStatus.Failed;
        state.singleProspectRemoveFromSequencesRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Add Contact to step
    builder.addCase(addToStepRequest.pending, (state) => {
      state.addToStepRequest.status = RequestStatus.Pending;
      state.addToStepRequest.error = null;
    });
    builder.addCase(addToStepRequest.fulfilled, (state, action) => {
      state.addToStepRequest.status = RequestStatus.Succeeded;
      state.addToStepRequest.message = action.payload.message;
      state.addToStepRequestResultCount.failed = action.payload.payload.failed;
      state.addToStepRequestResultCount.successful =
        action.payload.payload.successful;
      state.addToStepRequestResultCount.resultData = action.payload.payload;
    });
    builder.addCase(addToStepRequest.rejected, (state, action) => {
      state.addToStepRequest.status = RequestStatus.Failed;
      state.addToStepRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Save contact attributes
    builder.addCase(saveContactAttributesRequest.pending, (state) => {
      state.saveContactAttributesRequest.status = RequestStatus.Pending;
      state.saveContactAttributesRequest.error = null;
    });
    builder.addCase(saveContactAttributesRequest.fulfilled, (state, action) => {
      state.saveContactAttributesRequest.status = RequestStatus.Succeeded;
      state.saveContactAttributesRequest.message = action.payload.message;
      state.singleProspect = action.payload.payload;
    });
    builder.addCase(saveContactAttributesRequest.rejected, (state, action) => {
      state.saveContactAttributesRequest.status = RequestStatus.Failed;
      state.saveContactAttributesRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // saveContactDealValueRequest
    builder.addCase(saveContactDealValueRequest.pending, (state) => {
      state.saveContactDealValueRequest.status = RequestStatus.Pending;
      state.saveContactDealValueRequest.error = null;
    });
    builder.addCase(saveContactDealValueRequest.fulfilled, (state, action) => {
      state.saveContactDealValueRequest.status = RequestStatus.Succeeded;
      state.saveContactDealValueRequest.message = action.payload.message;
      state.singleProspect = action.payload.payload;
    });
    builder.addCase(saveContactDealValueRequest.rejected, (state, action) => {
      state.saveContactDealValueRequest.status = RequestStatus.Failed;
      state.saveContactDealValueRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Fields
    builder.addCase(getFieldsRequest.pending, (state) => {
      state.getFieldsRequest.status = RequestStatus.Pending;
      state.getFieldsRequest.error = null;
    });
    builder.addCase(getFieldsRequest.fulfilled, (state, action) => {
      state.getFieldsRequest.status = RequestStatus.Succeeded;
      state.getFieldsRequest.message = action.payload.message;
      state.fields = action.payload.payload;
    });
    builder.addCase(getFieldsRequest.rejected, (state, action) => {
      state.getFieldsRequest.status = RequestStatus.Failed;
      state.getFieldsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Add prospect manually
    builder.addCase(addProspectManuallyRequest.pending, (state) => {
      state.addProspectManuallyRequest.status = RequestStatus.Pending;
      state.addProspectManuallyRequest.error = null;
    });
    builder.addCase(addProspectManuallyRequest.fulfilled, (state, action) => {
      state.addProspectManuallyRequest.status = RequestStatus.Succeeded;
      state.addProspectResponse = action.payload.payload;
      state.addProspectManuallyRequest.message = action.payload.message;
    });
    builder.addCase(addProspectManuallyRequest.rejected, (state, action) => {
      state.addProspectManuallyRequest.status = RequestStatus.Failed;
      state.addProspectManuallyRequest.error =
        !action.payload?.isHandled && action.payload;
    });

    // Verify Prospect Manually
    builder.addCase(verifyProspectsManuallyRequest.pending, (state) => {
      state.verifyProspectManuallyRequest.status = RequestStatus.Pending;
      state.verifyProspectManuallyRequest.error = null;
    });
    builder.addCase(
      verifyProspectsManuallyRequest.fulfilled,
      (state, action) => {
        state.verifyProspectManuallyRequest.status = RequestStatus.Succeeded;
        state.verifyProspectManuallyRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      verifyProspectsManuallyRequest.rejected,
      (state, action) => {
        state.verifyProspectManuallyRequest.status = RequestStatus.Failed;
        state.verifyProspectManuallyRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Tags
    builder.addCase(getTagsRequest.pending, (state) => {
      state.getTagsRequest.status = RequestStatus.Pending;
      state.getTagsRequest.error = null;
    });
    builder.addCase(getTagsRequest.fulfilled, (state, action) => {
      state.getTagsRequest.status = RequestStatus.Succeeded;
      state.getTagsRequest.message = action.payload.message;
      state.tags = action.payload.payload;
    });
    builder.addCase(getTagsRequest.rejected, (state, action) => {
      state.getTagsRequest.status = RequestStatus.Failed;
      state.getTagsRequest.error = !action.payload.isHandled && action.payload;
    });

    builder.addCase(getTagsToLeadsRequest.pending, (state) => {
      state.getTagsToLeadsRequest.status = RequestStatus.Pending;
      state.getTagsToLeadsRequest.error = null;
    });
    builder.addCase(getTagsToLeadsRequest.fulfilled, (state, action) => {
      state.getTagsToLeadsRequest.status = RequestStatus.Succeeded;
      state.getTagsToLeadsRequest.message = action.payload.message;
      state.leadTags = action.payload.payload;
    });
    builder.addCase(getTagsToLeadsRequest.rejected, (state, action) => {
      state.getTagsToLeadsRequest.status = RequestStatus.Failed;
      state.getTagsToLeadsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Add Tags to Prospects
    builder.addCase(addTagsToProspectsRequest.pending, (state) => {
      state.addTagsToProspectsRequest.status = RequestStatus.Pending;
      state.addTagsToProspectsRequest.error = null;
    });
    builder.addCase(addTagsToProspectsRequest.fulfilled, (state, action) => {
      state.addTagsToProspectsRequest.status = RequestStatus.Succeeded;
      state.addTagsToProspectsRequest.message = action.payload.message;
    });
    builder.addCase(addTagsToProspectsRequest.rejected, (state, action) => {
      state.addTagsToProspectsRequest.status = RequestStatus.Failed;
      state.addTagsToProspectsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Remove Tags to Prospects
    builder.addCase(removeTagsFromProspectsRequest.pending, (state) => {
      state.removeTagsFromProspectsRequest.status = RequestStatus.Pending;
      state.removeTagsFromProspectsRequest.error = null;
    });
    builder.addCase(
      removeTagsFromProspectsRequest.fulfilled,
      (state, action) => {
        state.removeTagsFromProspectsRequest.status = RequestStatus.Succeeded;
        state.removeTagsFromProspectsRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      removeTagsFromProspectsRequest.rejected,
      (state, action) => {
        state.removeTagsFromProspectsRequest.status = RequestStatus.Failed;
        state.removeTagsFromProspectsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Verify Bulk Prospects
    builder.addCase(verifyBulkProspectsRequest.pending, (state) => {
      state.verifyBulkProspectsRequest.status = RequestStatus.Pending;
      state.verifyBulkProspectsRequest.error = null;
    });
    builder.addCase(verifyBulkProspectsRequest.fulfilled, (state, action) => {
      state.verifyBulkProspectsRequest.status = RequestStatus.Succeeded;
      state.verifyBulkProspectsRequest.message = action.payload.payload.message;
    });
    builder.addCase(verifyBulkProspectsRequest.rejected, (state, action) => {
      state.verifyBulkProspectsRequest.status = RequestStatus.Failed;
      state.verifyBulkProspectsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Bulk Prospects
    builder.addCase(deleteBulkProspectsRequest.pending, (state) => {
      state.deleteBulkProspectsRequest.status = RequestStatus.Pending;
      state.deleteBulkProspectsRequest.error = null;
    });
    builder.addCase(deleteBulkProspectsRequest.fulfilled, (state, action) => {
      state.deleteBulkProspectsRequest.status = RequestStatus.Succeeded;
      state.deleteBulkProspectsRequest.message = action.payload.payload.message;
    });
    builder.addCase(deleteBulkProspectsRequest.rejected, (state, action) => {
      state.deleteBulkProspectsRequest.status = RequestStatus.Failed;
      state.deleteBulkProspectsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Unsubscribe Bulk Prospects
    builder.addCase(unsubscribeBulkProspectRequest.pending, (state) => {
      state.unsubscribeBulkProspectRequest.status = RequestStatus.Pending;
      state.unsubscribeBulkProspectRequest.error = null;
    });
    builder.addCase(
      unsubscribeBulkProspectRequest.fulfilled,
      (state, action) => {
        state.unsubscribeBulkProspectRequest.status = RequestStatus.Succeeded;
        state.unsubscribeBulkProspectRequest.message =
          action.payload.payload.message;
        state.steps = action.payload.payload;
      },
    );
    builder.addCase(
      unsubscribeBulkProspectRequest.rejected,
      (state, action) => {
        state.unsubscribeBulkProspectRequest.status = RequestStatus.Failed;
        state.unsubscribeBulkProspectRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Add Bulk Prospects To Step
    builder.addCase(addBulkProspectsToStepRequest.pending, (state) => {
      state.addBulkProspectsToStepRequest.status = RequestStatus.Pending;
      state.addBulkProspectsToStepRequest.error = null;
    });
    builder.addCase(
      addBulkProspectsToStepRequest.fulfilled,
      (state, action) => {
        state.addBulkProspectsToStepRequest.status = RequestStatus.Succeeded;
        state.addBulkProspectsToStepRequest.message =
          action.payload.payload.message;
        state.addToStepRequestResultCount.failed =
          action.payload.payload.failed;
        state.addToStepRequestResultCount.successful =
          action.payload.payload.successful;
        state.addToStepRequestResultCount.resultData = action.payload.payload;
      },
    );
    builder.addCase(addBulkProspectsToStepRequest.rejected, (state, action) => {
      state.addBulkProspectsToStepRequest.status = RequestStatus.Failed;
      state.addBulkProspectsToStepRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Add Tags To Bulk Prospects
    builder.addCase(addTagsToBulkProspectsRequest.pending, (state) => {
      state.addTagsToBulkProspectsRequest.status = RequestStatus.Pending;
      state.addTagsToBulkProspectsRequest.error = null;
    });
    builder.addCase(
      addTagsToBulkProspectsRequest.fulfilled,
      (state, action) => {
        state.addTagsToBulkProspectsRequest.status = RequestStatus.Succeeded;
        state.addTagsToBulkProspectsRequest.message =
          action.payload.payload.message;
      },
    );
    builder.addCase(addTagsToBulkProspectsRequest.rejected, (state, action) => {
      state.addTagsToBulkProspectsRequest.status = RequestStatus.Failed;
      state.addTagsToBulkProspectsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Remove Tags From Bulk Prospects
    builder.addCase(removeTagsFromBulkProspectsRequest.pending, (state) => {
      state.removeTagsFromBulkProspectsRequest.status = RequestStatus.Pending;
      state.removeTagsFromBulkProspectsRequest.error = null;
    });
    builder.addCase(
      removeTagsFromBulkProspectsRequest.fulfilled,
      (state, action) => {
        state.removeTagsFromBulkProspectsRequest.status =
          RequestStatus.Succeeded;
        state.removeTagsFromBulkProspectsRequest.message =
          action.payload.payload.message;
      },
    );
    builder.addCase(
      removeTagsFromBulkProspectsRequest.rejected,
      (state, action) => {
        state.removeTagsFromBulkProspectsRequest.status = RequestStatus.Failed;
        state.removeTagsFromBulkProspectsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Prospect primary email change
    builder.addCase(changePrimaryEmailInProspectRequest.pending, (state) => {
      state.changePrimaryEmailInProspectRequest.status = RequestStatus.Pending;
      state.changePrimaryEmailInProspectRequest.error = null;
    });
    builder.addCase(
      changePrimaryEmailInProspectRequest.fulfilled,
      (state, action) => {
        state.changePrimaryEmailInProspectRequest.status =
          RequestStatus.Succeeded;
        state.changePrimaryEmailInProspectRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      changePrimaryEmailInProspectRequest.rejected,
      (state, action) => {
        state.changePrimaryEmailInProspectRequest.status = RequestStatus.Failed;
        state.changePrimaryEmailInProspectRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Delete secondary email from prospect
    builder.addCase(deleteSecondaryEmailRequest.pending, (state) => {
      state.deleteSecondaryEmailRequest.status = RequestStatus.Pending;
      state.deleteSecondaryEmailRequest.error = null;
    });
    builder.addCase(deleteSecondaryEmailRequest.fulfilled, (state, action) => {
      state.deleteSecondaryEmailRequest.status = RequestStatus.Succeeded;
      state.deleteSecondaryEmailRequest.message = action.payload.message;
    });
    builder.addCase(deleteSecondaryEmailRequest.rejected, (state, action) => {
      state.deleteSecondaryEmailRequest.status = RequestStatus.Failed;
      state.deleteSecondaryEmailRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete secondary Phone from prospect
    builder.addCase(deleteSecondaryPhoneRequest.pending, (state) => {
      state.deleteSecondaryPhoneRequest.status = RequestStatus.Pending;
      state.deleteSecondaryPhoneRequest.error = null;
    });
    builder.addCase(deleteSecondaryPhoneRequest.fulfilled, (state, action) => {
      state.deleteSecondaryPhoneRequest.status = RequestStatus.Succeeded;
      state.deleteSecondaryPhoneRequest.message = action.payload.message;
    });
    builder.addCase(deleteSecondaryPhoneRequest.rejected, (state, action) => {
      state.deleteSecondaryPhoneRequest.status = RequestStatus.Failed;
      state.deleteSecondaryPhoneRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Filter View List
    builder.addCase(getFilterViewListRequest.pending, (state) => {
      state.getFilterViewListRequest.status = RequestStatus.Pending;
      state.getFilterViewListRequest.error = null;
    });
    builder.addCase(getFilterViewListRequest.fulfilled, (state, action) => {
      state.getFilterViewListRequest.status = RequestStatus.Succeeded;
      state.savedFilters = action.payload.payload;
      state.getFilterViewListRequest.message = action.payload.message;
    });
    builder.addCase(getFilterViewListRequest.rejected, (state, action) => {
      state.getFilterViewListRequest.status = RequestStatus.Failed;
      state.getFilterViewListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Pin Unpin Saved Filter
    builder.addCase(pinUnpinSavedFilterRequest.pending, (state) => {
      state.pinUnpinSavedFilterRequest.status = RequestStatus.Pending;
      state.pinUnpinSavedFilterRequest.error = null;
    });
    builder.addCase(pinUnpinSavedFilterRequest.fulfilled, (state, action) => {
      state.pinUnpinSavedFilterRequest.status = RequestStatus.Succeeded;
      // state.savedFilters = action.payload.payload;
      state.pinUnpinSavedFilterRequest.message = action.payload.message;
    });
    builder.addCase(pinUnpinSavedFilterRequest.rejected, (state, action) => {
      state.pinUnpinSavedFilterRequest.status = RequestStatus.Failed;
      state.pinUnpinSavedFilterRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Saved Filter
    builder.addCase(updateSavedFilterRequest.pending, (state) => {
      state.updateSavedFilterRequest.status = RequestStatus.Pending;
      state.updateSavedFilterRequest.error = null;
    });
    builder.addCase(updateSavedFilterRequest.fulfilled, (state, action) => {
      state.updateSavedFilterRequest.status = RequestStatus.Succeeded;
      if (
        state.selectedSavedView &&
        state.selectedSavedView?.id === action.payload.payload?.id
      ) {
        state.selectedSavedView = {
          ...(state.selectedSavedView || {}),
          ...(action.payload.payload || {}),
        };
      }
      state.updateSavedFilterRequest.message = action.payload.message;
    });
    builder.addCase(updateSavedFilterRequest.rejected, (state, action) => {
      state.updateSavedFilterRequest.status = RequestStatus.Failed;
      state.updateSavedFilterRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Saved Filter
    builder.addCase(deleteSavedViewRequest.pending, (state) => {
      state.deleteSavedViewRequest.status = RequestStatus.Pending;
      state.deleteSavedViewRequest.error = null;
    });
    builder.addCase(deleteSavedViewRequest.fulfilled, (state, action) => {
      state.deleteSavedViewRequest.status = RequestStatus.Succeeded;
      // state.savedFilters = action.payload.payload;
      state.deleteSavedViewRequest.message = action.payload.message;
    });
    builder.addCase(deleteSavedViewRequest.rejected, (state, action) => {
      state.deleteSavedViewRequest.status = RequestStatus.Failed;
      state.deleteSavedViewRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Contact By View Id
    builder.addCase(getContactListByViewIdRequest.pending, (state) => {
      state.getContactListByViewIdRequest.status = RequestStatus.Pending;
      state.getContactListByViewIdRequest.error = null;
      state.prospects = [];
      state.prospectCount = 0;
    });
    builder.addCase(
      getContactListByViewIdRequest.fulfilled,
      (state, action) => {
        state.getContactListByViewIdRequest.status = RequestStatus.Succeeded;
        state.prospects = action.payload.payload.contacts;
        state.selectedSavedView = {
          ...(state.selectedSavedView || {}),
          ...action.payload.payload.view,
          ...(state.selectedSavedView?.teams?.length > 0 && {
            teams: state.selectedSavedView?.teams,
          }),
        };
        state.filters = {
          ...state.filters,
          pageNum: 1,
          search: '',
          filterGroupLogic: action.payload.payload.view.filterCriteria,
        };
        state.getContactListByViewIdRequest.message = action.payload.message;
      },
    );
    builder.addCase(getContactListByViewIdRequest.rejected, (state, action) => {
      state.getContactListByViewIdRequest.status = RequestStatus.Failed;
      state.getContactListByViewIdRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Imported Files List
    builder.addCase(getImportedFilesListRequest.pending, (state) => {
      state.getImportedFilesListRequest.status = RequestStatus.Pending;
      state.getImportedFilesListRequest.error = null;
    });
    builder.addCase(getImportedFilesListRequest.fulfilled, (state, action) => {
      state.getImportedFilesListRequest.status = RequestStatus.Succeeded;
      state.importedFilesList =
        action?.payload?.payload?.length > 0 ? action.payload.payload : [];
    });
    builder.addCase(getImportedFilesListRequest.rejected, (state, action) => {
      state.getImportedFilesListRequest.status = RequestStatus.Failed;
      state.getImportedFilesListRequest.error =
        !action?.payload?.isHandled && action?.payload;
    });
    // Update Prospect field
    builder.addCase(updateProspectFieldByIdRequest.pending, (state) => {
      state.updateProspectFieldByIdRequest.status = RequestStatus.Pending;
      state.updateProspectFieldByIdRequest.error = null;
    });
    builder.addCase(
      updateProspectFieldByIdRequest.fulfilled,
      (state, action) => {
        state.updateProspectFieldByIdRequest.status = RequestStatus.Succeeded;
        state.updateProspectFieldByIdRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      updateProspectFieldByIdRequest.rejected,
      (state, action) => {
        state.updateProspectFieldByIdRequest.status = RequestStatus.Failed;
        state.updateProspectFieldByIdRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    builder.addCase(
      getLongTextByProspectAndColumnIdRequest.pending,
      (state) => {
        state.getLongTextByProspectAndColumnIdRequest.status =
          RequestStatus.Pending;
        state.getLongTextByProspectAndColumnIdRequest.error = null;
      },
    );
    builder.addCase(
      getLongTextByProspectAndColumnIdRequest.fulfilled,
      (state, action) => {
        state.getLongTextByProspectAndColumnIdRequest.status =
          RequestStatus.Succeeded;
        state.getLongTextByProspectAndColumnIdRequest.message =
          action.payload.message;
        state.longText = action.payload.payload.attributeValue;
      },
    );
    builder.addCase(
      getLongTextByProspectAndColumnIdRequest.rejected,
      (state, action) => {
        state.getLongTextByProspectAndColumnIdRequest.status =
          RequestStatus.Failed;
        state.getLongTextByProspectAndColumnIdRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
  },
});
export const {
  resetGetProspectListRequest,
  setFilters,
  resetProspectFiltersSelectedFields,
  resetGetProspectFiltersFieldsListRequest,
  resetSaveProspectFiltersViewRequest,
  resetUpdateProspectFiltersViewRequest,
  resetPinUnpinSavedFilterRequest,
  resetUpdateSavedFilterRequest,
  resetDeleteSavedViewRequest,

  changeProspectPagination,
  resetProspect,
  saveSearch,
  resetSequenceAndStepData,
  selectAllContacts,
  clearSelection,
  checkAll,
  checkSingle,
  resetStep,
  resetSelectedContacts,
  setProspectDetails,
  resetAddToProspectForm,
  setProspectsFilters,
  resetProspectsFilters,
  resetTags,
  resetRemoveProspectRequest,
  resetBulkRequests,
  resetUnsubscribeRequestState,
  resetSaveContactAttributesRequestStatus,
  resetProspectDeleteEmailRequestStatus,
  resetProspectDeletePhoneRequestStatus,
  resetProspectPrimaryEmailChangeRequestStatus,
  setSelectedSavedView,
  removedSelectedSavedView,
  resetFilterGroupLogic,
  resetProspectFiltersFieldsAttributesValue,
  resetGetStepDetailsListRequest,
  resetGetProspectListByIdsRequest,
  resetGetLongTextByProspectAndColumnIdRequest,
  resetSingleProspect,
} = prospectSlice.actions;

export default prospectSlice.reducer;
