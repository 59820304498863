import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Route, Switch } from 'react-router-dom';

import { IProps } from './type';

import HelmetNoIndex from '../../shared/components/helmet-no-index';
import HelpscoutButton from '../../shared/components/helpscout-button';

import Login from './components/login';
import SSOLogin from './components/sso-login';
import Signup from './components/signup';
import ResetPassword from './components/reset-password';
import ChangePassword from './components/change-password';
import AcceptInvitationSignup from './components/invitation-signup';
import HelpscoutBeaconEvents from '../../shared/enums/helpscout-beacon-events';
import LTDSignup from './components/ltd-signup';
import LTDCheckout from './components/ltd-checkout';
import AgencyClientLogin from './components/agency-client-login';

import {
  destroyChatSupport,
  initializeIntercom,
} from '../../shared/utils/helpscout-handlers';
import { openIntercomHome } from '../../shared/utils/open-chat-support';
import { isWhiteLabel } from '../../shared/utils/user-details';
import { getIsRequestPending } from '../../shared/utils';
import { isInternalUrl } from '../../shared/utils/is-internal-url';
import ssoCallback from './components/sso-callback';
import MFAVerify from './components/mfa-verify';

const Auth: React.FC<IProps> = ({
  sendGetAgencyConfigRequest,
  agencyConfig,
  location,
  agencyConfigRequestStatus,
}) => {
  const agencyConfigPayload = {
    baseUrl: window.location.origin,
  };

  useEffect(() => {
    sendGetAgencyConfigRequest(agencyConfigPayload);
  }, []);

  useEffect(() => {
    if (
      agencyConfig?.showChatSupport &&
      window.location.pathname?.toLowerCase() !== '/agency/login'
    ) {
      initializeIntercom();

      setTimeout(() => {
        const params = new URLSearchParams(location.search);
        const chatOpen = params.get('chatopen');
        if (chatOpen === 'true') {
          openIntercomHome();
        }
      }, 1000);
    }

    return () => {
      destroyChatSupport();
    };
  }, [agencyConfig]);

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('event', {
        type: HelpscoutBeaconEvents.PAGE_VIEWED,
        url: document.location.href,
        title: document.title,
      });
      window.Beacon(HelpscoutBeaconEvents.SUGGEST);
    }
  }, [window.Beacon, document.location.href]);

  const containerClassName = classNames([
    'auth',
    {
      'ltd-container':
        window.location.pathname?.toLowerCase() === '/ltd-signup' ||
        window.location.pathname?.toLowerCase() === '/ltd-checkout',
    },
  ]);

  return (
    <div className={containerClassName}>
      <HelmetNoIndex />
      {/* {agencyConfig?.logo?.favicon !== '' && (
        <HelmetIcon icon={agencyConfig?.logo?.favicon} />
      )} */}
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/sso-login" component={SSOLogin} />
        <Route path="/sso-callback" component={ssoCallback} />
        <Route path="/agency/login" component={AgencyClientLogin} />
        <Route path="/signup" component={Signup} />
        <Route path="/LTD-Signup" component={LTDSignup} />
        <Route path="/LTD-Checkout" component={LTDCheckout} />
        <Route path="/mfa-verify" component={MFAVerify} />
        <Route exact path="/reset-password/:code" component={ChangePassword} />
        <Route
          exact
          path="/accept-invite/:token"
          component={AcceptInvitationSignup}
        />
        <Route exact path="/reset-password" component={ResetPassword} />
      </Switch>

      {agencyConfig?.showChatSupport &&
        (!isWhiteLabel() || isInternalUrl()) &&
        !getIsRequestPending(agencyConfigRequestStatus) && <HelpscoutButton />}
    </div>
  );
};

export default Auth;
