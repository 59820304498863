import validator from 'validator';

export const validate = (name, value) => {
  switch (name) {
    case 'couponCode':
      if (validator.isEmpty(value, { ignore_whitespace: true })) {
        return 'ENTER A VALID COUPON CODE';
      }
  }
  return '';
};
