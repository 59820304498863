import { SubscriptionPlanTitle } from '../../../../../../../../../shared/utils/subscription-plans';

export const LeadFinderSubscriptionPlansKey = {
  leadNormal: {
    1: {
      email: [
        // Old Plans
        // 'lead-finder-email-starter-monthly',
        // 'lead-finder-email-pro-monthly',
        // 'lead-finder-email-scale-monthly',

        // New Plans - Sept 24
        'lead-finder-email-starter-monthly-09-2024',
        'lead-finder-email-pro-monthly-09-2024',
        'lead-finder-email-scale-monthly-09-2024',
      ],
      emailPhone: [
        // Old Plans
        // 'lead-finder-email-phone-starter-monthly',
        // 'lead-finder-email-phone-pro-monthly',
        // 'lead-finder-email-phone-scale-monthly',

        // New Plans - Sept 24
        'lead-finder-email-phone-starter-monthly-09-2024',
        'lead-finder-email-phone-pro-monthly-09-2024',
        'lead-finder-email-phone-scale-monthly-09-2024',
      ],
    },
    12: {
      email: [
        // Old Plans
        // 'lead-finder-email-starter-yearly',
        // 'lead-finder-email-pro-yearly',
        // 'lead-finder-email-scale-yearly',

        // New Plans - Sept 24
        'lead-finder-email-starter-yearly-09-2024',
        'lead-finder-email-pro-yearly-09-2024',
        'lead-finder-email-scale-yearly-09-2024',
      ],
      emailPhone: [
        // Old Plans
        // 'lead-finder-email-phone-starter-yearly',
        // 'lead-finder-email-phone-pro-yearly',
        // 'lead-finder-email-phone-scale-yearly',

        // New Plans - Sept 24
        'lead-finder-email-phone-starter-yearly-09-2024',
        'lead-finder-email-phone-pro-yearly-09-2024',
        'lead-finder-email-phone-scale-yearly-09-2024',
      ],
    },
  },
  leadScale: {
    1: {
      email: [
        // Old Plans
        // 'lead-finder-email-scale-plus-1-monthly',
        // 'lead-finder-email-scale-plus-2-monthly',
        // 'lead-finder-email-scale-plus-3-monthly',
        // 'lead-finder-email-scale-plus-4-monthly',

        // New Plans - Sept 24
        'lead-finder-email-scale-plus-10k-monthly',
        'lead-finder-email-scale-plus-20k-monthly',
        'lead-finder-email-scale-plus-50k-monthly',
        'lead-finder-email-scale-plus-100k-monthly',
      ],
      emailPhone: [
        // Old Plans
        // 'lead-finder-email-phone-scale-plus-1-monthly',
        // 'lead-finder-email-phone-scale-plus-2-monthly',
        // 'lead-finder-email-phone-scale-plus-3-monthly',
        // 'lead-finder-email-phone-scale-plus-4-monthly',

        // New Plans - Sept 24
        'lead-finder-email-phone-scale-plus-10k-monthly',
        'lead-finder-email-phone-scale-plus-20k-monthly',
        'lead-finder-email-phone-scale-plus-50k-monthly',
        'lead-finder-email-phone-scale-plus-100k-monthly',
      ],
    },
    12: {
      email: [
        // Old Plans
        // 'lead-finder-email-scale-plus-1-yearly',
        // 'lead-finder-email-scale-plus-2-yearly',
        // 'lead-finder-email-scale-plus-3-yearly',
        // 'lead-finder-email-scale-plus-4-yearly',

        // New Plans - Sept 24
        'lead-finder-email-scale-plus-10k-yearly',
        'lead-finder-email-scale-plus-20k-yearly',
        'lead-finder-email-scale-plus-50k-yearly',
        'lead-finder-email-scale-plus-100k-yearly',
      ],
      emailPhone: [
        // Old Plans
        // 'lead-finder-email-phone-scale-plus-1-yearly',
        // 'lead-finder-email-phone-scale-plus-2-yearly',
        // 'lead-finder-email-phone-scale-plus-3-yearly',
        // 'lead-finder-email-phone-scale-plus-4-yearly',

        // New Plans - Sept 24
        'lead-finder-email-phone-scale-plus-10k-yearly',
        'lead-finder-email-phone-scale-plus-20k-yearly',
        'lead-finder-email-phone-scale-plus-50k-yearly',
        'lead-finder-email-phone-scale-plus-100k-yearly',
      ],
    },
  },
};

export const customPlanScale100kMonthly = {
  id: 92,
  amount: '11111.00',
  planName: SubscriptionPlanTitle.OutreachScaleMonthly100k,
  planCode: 'outreach-scale-plus-100k-monthly',
  currency: 'USD',
  planValidityDays: 30,
  planType: 'value-based',
  planMode: 1,
  isCustomPlan: true,
};

export const customPlanScale100kYearly = {
  id: 97,
  amount: '36.00',
  planName: SubscriptionPlanTitle.OutreachScaleYearly100k,
  planCode: 'outreach-scale-plus-100k-yearly',
  currency: 'USD',
  planValidityDays: 365,
  planType: 'value-based',
  planMode: 12,
  isCustomPlan: true,
};

export const getBaseLeadFinderScalePlanData = ({
  planMode,
  allPlans,
  isEmailPhonePlan,
}) => {
  const scalePlanCode =
    LeadFinderSubscriptionPlansKey.leadScale[planMode][
      isEmailPhonePlan ? 'emailPhone' : 'email'
    ][0];

  const index = allPlans.findIndex((plan) => scalePlanCode === plan.planCode);

  return [{ ...allPlans[index], isCustomPlan: true }];
};

export const getLeadFinderPlanData = ({
  planMode,
  allPlans,
  isEmailPhonePlan,
}) => {
  const planCodes =
    LeadFinderSubscriptionPlansKey.leadNormal[planMode][
      isEmailPhonePlan ? 'emailPhone' : 'email'
    ];

  return allPlans.filter((plan) => planCodes.includes(plan.planCode));
};

export const getCustomScalePlusPlanData = ({
  planMode,
  allPlans,
  isEmailPhonePlan,
}) => {
  const planCodes =
    LeadFinderSubscriptionPlansKey.leadScale[planMode][
      isEmailPhonePlan ? 'emailPhone' : 'email'
    ];

  return allPlans.filter((plan) => planCodes.includes(plan.planCode));
};

export const isLeadFinderScalePlusPlan = (planCode) => {
  const planCodes = [
    ...LeadFinderSubscriptionPlansKey.leadScale[1].email,
    ...LeadFinderSubscriptionPlansKey.leadScale[1].emailPhone,
    ...LeadFinderSubscriptionPlansKey.leadScale[12].email,
    ...LeadFinderSubscriptionPlansKey.leadScale[12].emailPhone,
  ];

  return planCodes.includes(planCode);
};
