import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  AdminSettings,
  AdminSettingsPriority,
  organizationData,
} from '../../types/admin-settings';
import {
  getAdminSettingsRequest,
  getExclusionListRequest,
  getSSOOrganizationRequest,
  getSSOSetupUrlRequest,
  updateAdminSettingsRequest,
  updateExclusionListRequest,
  updateSSOOrganizationRequest,
} from './extra-actions';
import { ExclusionListEmailsAndDomains, PaginationOptions } from './types';

export const getExclusionListPaginationInitialState = () => ({
  totalItems: 0,
  currentPage: 1,
  itemsPerPage: 50,
  totalPages: 1,
  itemCount: 0,
});

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  createSSOOrganizationRequest: RequestState;
  updateSSOOrganizationRequest: RequestState;
  getSSOOrganizationRequest: RequestState;
  getSSOSetupUrlRequest: RequestState;
  getAdminSettingsRequest: RequestState;
  updateAdminSettingsRequest: RequestState;
  getExclusionListRequest: RequestState;
  updateExclusionListRequest: RequestState;
  adminSettings: AdminSettings[];
  adminSettingsPriority: AdminSettingsPriority[];
  exclusionList: ExclusionListEmailsAndDomains[];
  exclusionListPaginationOptions: PaginationOptions;
  ssoSetupUrl: string;
  organizationData: organizationData;
};

const initialState: State = {
  ssoSetupUrl: null,
  createSSOOrganizationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSSOOrganizationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSSOOrganizationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSSOSetupUrlRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAdminSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateAdminSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getExclusionListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateExclusionListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  adminSettings: null,
  adminSettingsPriority: null,
  exclusionList: [],
  exclusionListPaginationOptions: getExclusionListPaginationInitialState(),
  organizationData: null,
};

const adminSettingsSlice = createSlice({
  name: 'adminSettings',
  initialState,
  reducers: {
    resetUpdateAdminSettings: (state) => {
      state.updateAdminSettingsRequest =
        initialState.updateAdminSettingsRequest;
    },
    resetAdminSettings: (state) => {
      state.getAdminSettingsRequest = initialState.getAdminSettingsRequest;
      state.updateAdminSettingsRequest =
        initialState.updateAdminSettingsRequest;
      state.adminSettings = initialState.adminSettings;
      state.adminSettingsPriority = initialState.adminSettingsPriority;
    },
    resetExclusionList: (state) => {
      state.exclusionList = initialState.exclusionList;
      state.exclusionListPaginationOptions =
        initialState.exclusionListPaginationOptions;
    },

    resetUpdateExclusionRequestStatus: (state) => {
      state.updateExclusionListRequest =
        initialState.updateExclusionListRequest;
    },
  },
  extraReducers: (builder) => {
    // Get Admin Settings
    builder.addCase(getAdminSettingsRequest.pending, (state) => {
      state.getAdminSettingsRequest.status = RequestStatus.Pending;
      state.getAdminSettingsRequest.error = null;
    });
    builder.addCase(getAdminSettingsRequest.fulfilled, (state, action) => {
      state.getAdminSettingsRequest.status = RequestStatus.Succeeded;
      state.getAdminSettingsRequest.message = action.payload.message;
      state.adminSettings = action.payload.payload[0].shAccountSettingData;
      state.adminSettingsPriority =
        action.payload.payload[0].priorityAndDistributionData;
    });
    builder.addCase(getAdminSettingsRequest.rejected, (state, action) => {
      state.getAdminSettingsRequest.status = RequestStatus.Failed;
      state.getAdminSettingsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Admin Settings
    builder.addCase(updateAdminSettingsRequest.pending, (state) => {
      state.updateAdminSettingsRequest.status = RequestStatus.Pending;
      state.updateAdminSettingsRequest.error = null;
    });
    builder.addCase(updateAdminSettingsRequest.fulfilled, (state, action) => {
      state.updateAdminSettingsRequest.status = RequestStatus.Succeeded;
      state.updateAdminSettingsRequest.message = action.payload.message;
      state.ssoSetupUrl = action.payload.payload?.ssoSetupUrl;
    });
    builder.addCase(updateAdminSettingsRequest.rejected, (state, action) => {
      state.updateAdminSettingsRequest.status = RequestStatus.Failed;
      state.updateAdminSettingsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Exclusion List
    builder.addCase(getExclusionListRequest.pending, (state) => {
      state.getExclusionListRequest.status = RequestStatus.Pending;
      state.getExclusionListRequest.error = null;
    });
    builder.addCase(getExclusionListRequest.fulfilled, (state, action) => {
      state.getExclusionListRequest.status = RequestStatus.Succeeded;
      state.getExclusionListRequest.message = action.payload.message;

      if (action.meta.arg.currentPage === 1) {
        state.exclusionList = action.payload.payload.items || [];
      } else if (action.meta.arg.currentPage > 1) {
        state.exclusionList = [
          ...(state.exclusionList || []),
          ...(action.payload.payload.items || []),
        ];
      }
      state.exclusionListPaginationOptions = action.payload.payload.meta;
    });
    builder.addCase(getExclusionListRequest.rejected, (state, action) => {
      state.getExclusionListRequest.status = RequestStatus.Failed;
      state.getExclusionListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Exclusion List
    builder.addCase(updateExclusionListRequest.pending, (state) => {
      state.updateExclusionListRequest.status = RequestStatus.Pending;
      state.updateAdminSettingsRequest.error = null;
    });
    builder.addCase(updateExclusionListRequest.fulfilled, (state, action) => {
      state.updateExclusionListRequest.status = RequestStatus.Succeeded;
      state.updateExclusionListRequest.message = action.payload.message;
    });
    builder.addCase(updateExclusionListRequest.rejected, (state, action) => {
      state.updateExclusionListRequest.status = RequestStatus.Failed;
      state.updateExclusionListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update SSO Organization
    builder.addCase(updateSSOOrganizationRequest.pending, (state) => {
      state.updateSSOOrganizationRequest.status = RequestStatus.Pending;
      state.updateSSOOrganizationRequest.error = null;
    });
    builder.addCase(updateSSOOrganizationRequest.fulfilled, (state, action) => {
      state.updateSSOOrganizationRequest.status = RequestStatus.Succeeded;
      state.updateSSOOrganizationRequest.message = action.payload.message;
    });
    builder.addCase(updateSSOOrganizationRequest.rejected, (state, action) => {
      state.updateSSOOrganizationRequest.status = RequestStatus.Failed;
      state.updateSSOOrganizationRequest.error = action.error;
    });

    // Get SSO Organization
    builder.addCase(getSSOOrganizationRequest.pending, (state) => {
      state.getSSOOrganizationRequest.status = RequestStatus.Pending;
      state.getSSOOrganizationRequest.error = null;
    });
    builder.addCase(getSSOOrganizationRequest.fulfilled, (state, action) => {
      state.getSSOOrganizationRequest.status = RequestStatus.Succeeded;
      state.getSSOOrganizationRequest.message = action.payload.message;
      state.organizationData = action.payload.payload;
    });
    builder.addCase(getSSOOrganizationRequest.rejected, (state, action) => {
      state.getSSOOrganizationRequest.status = RequestStatus.Failed;
      state.getSSOOrganizationRequest.error = action.error;
    });

    // Get SSO Setup Url
    builder.addCase(getSSOSetupUrlRequest.pending, (state) => {
      state.getSSOSetupUrlRequest.status = RequestStatus.Pending;
      state.getSSOSetupUrlRequest.error = null;
    });
    builder.addCase(getSSOSetupUrlRequest.fulfilled, (state, action) => {
      state.getSSOSetupUrlRequest.status = RequestStatus.Succeeded;
      state.getSSOSetupUrlRequest.message = action.payload.message;
      state.ssoSetupUrl = action.payload.payload.url;
    });
    builder.addCase(getSSOSetupUrlRequest.rejected, (state, action) => {
      state.getSSOSetupUrlRequest.status = RequestStatus.Failed;
      state.getSSOSetupUrlRequest.error = action.error;
    });
  },
});

export const {
  resetUpdateAdminSettings,
  resetAdminSettings,
  resetExclusionList,

  resetUpdateExclusionRequestStatus,
} = adminSettingsSlice.actions;

export { getAdminSettingsRequest };
export default adminSettingsSlice.reducer;
