/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { ToggleButtonRadioProps } from 'react-bootstrap';
import { ForwardRef } from '../../../../types/forward-ref';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';

export type Option = {
  label: string;
  value: any;
  disabled?: boolean;
};

type RadioButtonGroupProps = Omit<
  ToggleButtonRadioProps<any>,
  'value' | 'onChange' | 'name'
> & {
  options: Option[];
  value: any;
  onChange: (value: any, event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabledLabel?: string;
};

const RadioButtonTransfer = React.forwardRef<
  HTMLDivElement,
  RadioButtonGroupProps
>(({ options, value, onChange, className, disabledLabel }) => (
  <div className={`radio-button ${className}`}>
    <div className="radio-button__container">
      {options.map((option, index) => (
        <>
          <input
            key={
              option.value === value ? `${option.value}-checked` : option.value
            }
            type="radio"
            id={`radio-${index + 1}`}
            name="tabs"
            checked={value === option.value}
            disabled={option.label === disabledLabel}
          />
          <label
            className="radio-button__label semibold-1 gray-txt-15"
            htmlFor={`radio-${index + 1}`}
            key={
              option.value === value ? `${option.value}-checked` : option.value
            }
            {...accessibleOnClick((e) => onChange(option.value, e))}
          >
            {option.label}
          </label>
        </>
      ))}
      <span className="glider" />
    </div>
  </div>
)) as ForwardRef<HTMLDivElement, RadioButtonGroupProps>;

export default RadioButtonTransfer;
