import { RequestStatus } from '../../../shared/enums/request-status';

export const getIsRequestPending = (status): boolean =>
  status === RequestStatus.Pending;

export const truncateText = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }

  return text;
};

export const formatPercentage = (percentage?: string): string => {
  if (!percentage) return '';

  const num = Number(percentage);
  if (Number.isNaN(num)) return '';

  return num % 1 === 0 ? `${parseInt(percentage, 10)}` : `${num.toFixed(2)}`;
};

export const formatNumberToKM = (num: number): string => {
  if (num >= 1_000_000) {
    const numberString = (num / 1_000_000).toFixed(
      num % 1_000_000 === 0 ? 0 : 1,
    );
    const numberStringArr = numberString?.split('.');
    if (numberStringArr?.length === 2 && numberStringArr?.[1] === '0') {
      numberStringArr.pop();
    }
    return `${numberStringArr?.join('.')}M`;
  }
  if (num >= 1000) {
    const numberString = (num / 1000).toFixed(num % 1000 === 0 ? 0 : 1);
    const numberStringArr = numberString?.split('.');
    if (numberStringArr?.length === 2 && numberStringArr?.[1] === '0') {
      numberStringArr.pop();
    }
    return `${numberStringArr?.join('.')}K`;
  }
  return num.toString();
};
