import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import { getUserSettingsRequest } from '../../../../../home/extra-actions';
import { resetPurchaseLeadFinderOneTimePlan, resetSubscription } from '../../billing-subscription-slice';
import {
  calculatePayRequest,
  getLeadFinderOneTimePlansRequest,
  getLeadFinderSubscriptionRequest,
  purchaseLeadFinderModifySubscriptionRequest,
  purchaseLeadFinderOneTimePlanRequest,
  purchaseLeadFinderSubscriptionRequest,
} from '../../extra-actions';
import {
  CalculatePayRequest,
  PurchaseModifySubscriptionRequest,
  PurchaseSubscriptionRequest,
} from '../../types';
import LeadFinderPlans from './lead-finder-plans';
import { PurchaseLeadFinderCreditsRequest } from '../../types/purchase-lead-finder-credits';

const mapStateToProps = (state: RootState) => ({
  getCouponCodeResponse: state.billingAndSubscription.applyCouponCodeResponse,
  sendGetLeadFinderSubscriptionRequestStatus:
    state.billingAndSubscription.getLeadFinderSubscriptionRequest.status,
  sendGetLeadFinderSubscriptionResponse:
    state.billingAndSubscription.getLeadFinderSubscriptionResponse,
  sendGetLeadFinderPlansResponse:
    state.billingAndSubscription.getLeadFinderPlansResponse,
  sendPurchaseLeadFinderSubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderSubscriptionRequest.status,
  sendPurchaseLeadFinderSubscriptionRequestMessage:
    state.billingAndSubscription.purchaseLeadFinderSubscriptionRequest.message,
  sendPurchaseLeadFinderModifySubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderModifySubscriptionRequest
      .status,
  sendPurchaseLeadFinderModifySubscriptionRequestMessage:
    state.billingAndSubscription.purchaseLeadFinderModifySubscriptionRequest
      .message,
  sendPurchaseModifySubscriptionResponse:
    state.billingAndSubscription.purchaseModifySubscriptionResponse,
  sendGetCalculatePayRequestStatus:
    state.billingAndSubscription.calculatePayRequest.status,

  couponCodeDiscount:
    state.billingAndSubscription.applyCouponCodeResponse?.discountValue,
  couponCodeDiscountType:
    state.billingAndSubscription.applyCouponCodeResponse?.discountType,
  leadFinderSubscriptionPlanResponse: state.home.leadFinderSubscription,
  getLeadFinderOneTimePlansRequestStatus:
    state.billingAndSubscription.getLeadFinderOneTimePlansRequest.status,
  getLeadFinderOneTimePlansResponse:
    state.billingAndSubscription.getLeadFinderOneTimePlansResponse,

  sendPurchaseLeadFinderOneTimePlanRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderOneTimePlanRequest.status,
  sendPurchaseLeadFinderOneTimePlanRequestMessage:
    state.billingAndSubscription.purchaseLeadFinderOneTimePlanRequest.message,
  sendPurchaseLeadFinderOneTimePlanResponse:
    state.billingAndSubscription.purchaseLeadFinderOneTimePlanResponse,
});

const mapDispatchToProps = {
  sendGetCalculatePayRequest: (payload: CalculatePayRequest) =>
    calculatePayRequest(payload),
  sendPurchaseLeadFinderSubscriptionRequest: (
    payload: PurchaseSubscriptionRequest,
  ) => purchaseLeadFinderSubscriptionRequest(payload),
  sendPurchaseLeadFinderModifySubscriptionRequest: (
    payload: PurchaseModifySubscriptionRequest,
  ) => purchaseLeadFinderModifySubscriptionRequest(payload),
  sendGetLeadFinderSubscriptionRequest: () =>
    getLeadFinderSubscriptionRequest(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  resetSubscription: () => resetSubscription(),
  sendGetLeadFinderOneTimePlansRequest: () =>
    getLeadFinderOneTimePlansRequest(),
  sendPurchaseLeadFinderOneTimePlanRequest: (payload: PurchaseLeadFinderCreditsRequest) =>
    purchaseLeadFinderOneTimePlanRequest(payload),
  resetPurchaseLeadFinderOneTimePlan: () => resetPurchaseLeadFinderOneTimePlan(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(LeadFinderPlans);
