import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { ssoLoginRequest } from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
  showError?: boolean;
};

type State = {
  ssoLoginRequest: RequestState;
  redirectUrl: string;
};

const initialState: State = {
  ssoLoginRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: '',
    showError: false,
  },
  redirectUrl: '',
};

const ssoLoginSlice = createSlice({
  name: 'ssoLogin',
  initialState,
  reducers: {
    hideError: (state) => {
      state.ssoLoginRequest.showError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ssoLoginRequest.pending, (state) => {
      state.ssoLoginRequest.status = RequestStatus.Pending;
      state.ssoLoginRequest.showError = false;
    });
    builder.addCase(ssoLoginRequest.fulfilled, (state, action) => {
      state.ssoLoginRequest.status = RequestStatus.Succeeded;
      state.ssoLoginRequest.message = action.payload.message;
      state.redirectUrl = action.payload?.payload?.redirectUrl;
    });
    builder.addCase(ssoLoginRequest.rejected, (state, action) => {
      state.ssoLoginRequest.status = RequestStatus.Failed;
      state.ssoLoginRequest.error = action.payload;
    });
  },
});

export const { hideError } = ssoLoginSlice.actions;
export default ssoLoginSlice.reducer;
