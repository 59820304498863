import React from 'react';
import { AlertCircle } from '@saleshandy/icons';

import Modal from '../../../shared/design-system/ui/modal';

interface GoingBackToEmailAccountsModalProps {
  show: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onlyEmailAccounts?: boolean;
}

const GoingBackToEmailAccountsModal: React.FC<GoingBackToEmailAccountsModalProps> = ({
  show,
  onSubmit,
  onClose,
  onlyEmailAccounts = false,
}) => (
  <Modal
    show={show}
    className="going-back-to-email-accounts-confirmation-modal"
    title={
      <div className="d-flex align-items-center gap-10">
        <AlertCircle width={24} height={24} color="#D97706" />
        <span className="line-height-1">Are you sure you want to go back</span>
      </div>
    }
    onHide={onClose}
    primaryBtnLabel="Yes"
    secondaryBtnLabel="Cancel"
    primaryBtnClickedHandler={onSubmit}
    secondaryBtnClickedHandler={onClose}
  >
    <>
      <p className="warning-note">
        {`Going back will remove all ${
          !onlyEmailAccounts ? 'selected domains and' : ''
        } email accounts from your cart.`}
      </p>
      <p className="confirmation-text">Are you sure you want to proceed?</p>
    </>
  </Modal>
);

export default GoingBackToEmailAccountsModal;
