import { NotificationType } from '../../types';

export function getHeaderHelper(locationPathName, Routes) {
  switch (locationPathName) {
    case Routes.SEQUENCE:
      return Routes.SEQUENCE[0].toUpperCase() + Routes.SEQUENCE.slice(1);
    case Routes.TASKS:
      return Routes.TASKS[0].toUpperCase() + Routes.TASKS.slice(1);
    case Routes.AGENCY_CLIENT:
      return 'Client Management';
    case Routes.UNIFIED_INBOX:
      return 'Unified Inbox';
    case Routes.PROSPECTS:
      return Routes.PROSPECTS[0].toUpperCase() + Routes.PROSPECTS.slice(1);
    case Routes.EMAIL_ACCOUNTS:
      return 'Email Accounts';
    case Routes.DOMAINS:
      return 'Domains';
    case Routes.TEMPLATES:
      return Routes.TEMPLATES[0].toUpperCase() + Routes.TEMPLATES.slice(1);
    case Routes.REPORTS:
      return 'Analytics';
    case Routes.EMAIL_INSIGHTS:
      return '1:1 Email Insights';
    case Routes.EMAIL_WARMUP:
      return (
        Routes.EMAIL_WARMUP[0].toUpperCase() + Routes.EMAIL_WARMUP.slice(1)
      );
    case Routes.GROWTH_HUB:
      return 'Growth Hub';
    case Routes.AGENCY_PORTAL:
      return 'Agency Portal';
    case Routes.SETTINGS:
      return Routes.SETTINGS[0].toUpperCase() + Routes.SETTINGS.slice(1);
    case Routes.LEADS:
      return 'Lead Finder';
    default:
      return '';
  }
}

export function routeStateChange({
  getHeader,
  path,
  Routes,
  sequenceRoutes,
  setSingleSequenceHeader,
  emailWarmupRoutes,
  setEmailWarmupHeader,
  setEmailWarmupHeaderWithReport,
  setCreateEmailInfrastructureHeader,
  setAddEmailAccountsHeader,
  setEmailAccountsHeader,
  setHeaderTitle,
}) {
  if (
    getHeader(path?.[1]).toLowerCase() === Routes.SEQUENCE &&
    sequenceRoutes.includes(path?.[3])
  ) {
    setSingleSequenceHeader(true);
  } else {
    setSingleSequenceHeader(false);
  }

  if (getHeader(path?.[1]).toLowerCase() === Routes.EMAIL_WARMUP) {
    if (emailWarmupRoutes.includes(path?.[3])) {
      setEmailWarmupHeader(true);
      setEmailWarmupHeaderWithReport(true);
    } else {
      setEmailWarmupHeader(true);
      setEmailWarmupHeaderWithReport(false);
    }
  } else {
    setEmailWarmupHeader(false);
    setEmailWarmupHeaderWithReport(false);
  }

  if (path?.[1] === Routes.EMAIL_ACCOUNTS) {
    if (path?.[2] === 'create-email-accounts') {
      setCreateEmailInfrastructureHeader(true);
      setEmailAccountsHeader(false);
      setAddEmailAccountsHeader(false);
    } else if (path?.[2] === 'add-email-accounts') {
      setAddEmailAccountsHeader(true);
      setEmailAccountsHeader(false);
      setCreateEmailInfrastructureHeader(false);
    } else {
      setEmailAccountsHeader(true);
      setCreateEmailInfrastructureHeader(false);
      setAddEmailAccountsHeader(false);
    }
  } else {
    setEmailAccountsHeader(false);
    setCreateEmailInfrastructureHeader(false);
    setAddEmailAccountsHeader(false);
  }

  // changing header as the page changes
  setHeaderTitle(getHeader(path?.[1]));
}

export const formatNumberToK = (num: number): string => {
  if (num >= 1000) {
    const numberString = (num / 1000).toFixed(num % 1000 === 0 ? 0 : 1);
    const numberStringArr = numberString?.split('.');
    if (numberStringArr?.length === 2 && numberStringArr?.[1] === '0') {
      numberStringArr.pop();
    }
    return `${numberStringArr?.join('.')}K`;
  }
  return num.toString();
};

export const getProgressBarText = (
  type: NotificationType,
  totalCount: number,
) => {
  if (
    type === NotificationType.ProspectImportAsync ||
    type === NotificationType.SequenceProspectImportAsync
  ) {
    return `Importing ${formatNumberToK(totalCount)} Prospects`;
  }
  if (
    type === NotificationType.LeadRevealCompletedAsync ||
    type === NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync
  ) {
    return `Revealing ${formatNumberToK(totalCount)} Leads`;
  }
  return '';
};

export const getProgressBarCompletionText = (
  type: NotificationType,
  totalCount: number,
) => {
  if (
    type === NotificationType.ProspectImportAsync ||
    type === NotificationType.SequenceProspectImportAsync
  ) {
    return `Imported ${formatNumberToK(totalCount)} Prospects`;
  }
  if (
    type === NotificationType.LeadRevealCompletedAsync ||
    type === NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync
  ) {
    return `Revealed ${formatNumberToK(totalCount)} Leads`;
  }
  return '';
};

export const getCtaText = (type: NotificationType) => {
  if (
    type === NotificationType.ProspectImportAsync ||
    type === NotificationType.SequenceProspectImportAsync
  ) {
    return 'View List';
  }
  if (
    type === NotificationType.LeadRevealCompletedAsync ||
    type === NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync
  ) {
    return 'View Leads';
  }
  return '';
};

export const getProgressBarPercentage = (
  completedCount: number,
  totalCount: number,
  maxPercentageValue = 99,
): number => {
  if (totalCount === 0) return 0;
  const percentage = Math.round((completedCount / totalCount) * 100);
  return Math.min(percentage, maxPercentageValue);
};
