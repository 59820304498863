import { SubscriptionPlans } from '../../../../../shared/utils';
import { ShAccountSettingsCode } from '../../../enums/admin-settings';
import { AdminSettings } from '../../../types/admin-settings';

export const getAdminSettingsValues = (
  adminSettings: AdminSettings[],
): {
  isVerificationActive: boolean;
  prospectDuplicationAllowed: boolean;
  prospectSharing: boolean;
  AutoAddToProspectOnLeadReveal: boolean;
  isAICategorization: boolean;
  trackUniboxOpen: boolean;
  trackUniboxClick: boolean;
  isAddProspectFromBusinessDomain: boolean;
  trackExternalMails: boolean;
  enableExclusionList: boolean;
  isSSOEnabled: boolean;
  isMFAEnabled: boolean;
} => {
  let isVerificationActive = false;
  let prospectDuplicationAllowed = false;
  let prospectSharing = false;
  let AutoAddToProspectOnLeadReveal = false;
  let isAICategorization = false;
  let trackUniboxOpen = false;
  let trackUniboxClick = false;
  let isAddProspectFromBusinessDomain = false;
  let trackExternalMails = false;
  let enableExclusionList = false;
  let isSSOEnabled = false;
  let isMFAEnabled = false;

  if (adminSettings && adminSettings.length > 0) {
    adminSettings.forEach((setting) => {
      if (setting.code === ShAccountSettingsCode.IsVerificationActive) {
        isVerificationActive = Boolean(Number(setting.value));
      }
      if (setting.code === ShAccountSettingsCode.ProspectDuplicationAllowed) {
        prospectDuplicationAllowed = Boolean(Number(setting.value));
      }
      if (setting.code === ShAccountSettingsCode.ProspectSharing) {
        prospectSharing = Boolean(Number(setting.value));
      }
      if (
        setting.code === ShAccountSettingsCode.AutoAddToProspectOnLeadReveal
      ) {
        AutoAddToProspectOnLeadReveal = Boolean(Number(setting.value));
      }
      if (setting.code === ShAccountSettingsCode.AICategorization) {
        isAICategorization = Boolean(Number(setting.value));
      }
      if (setting.code === ShAccountSettingsCode.TrackUniboxOpen) {
        trackUniboxOpen = Boolean(Number(setting.value));
      }
      if (setting.code === ShAccountSettingsCode.TrackUniboxClick) {
        trackUniboxClick = Boolean(Number(setting.value));
      }
      if (
        setting.code === ShAccountSettingsCode.AddProspectFromBusinessDomain
      ) {
        isAddProspectFromBusinessDomain = Boolean(Number(setting.value));
      }
      if (setting.code === ShAccountSettingsCode.TrackExternalMails) {
        trackExternalMails = Boolean(Number(setting.value));
      }
      if (setting.code === ShAccountSettingsCode.EnableExclusionList) {
        enableExclusionList = Boolean(Number(setting.value));
      }
      if (setting.code === ShAccountSettingsCode.IsSSOEnabled) {
        isSSOEnabled = Boolean(Number(setting.value));
      }
      if (setting.code === ShAccountSettingsCode.IsMFAEnabled) {
        isMFAEnabled = Boolean(Number(setting.value));
      }
    });
  }

  return {
    isVerificationActive,
    prospectDuplicationAllowed,
    prospectSharing,
    AutoAddToProspectOnLeadReveal,
    isAICategorization,
    trackUniboxOpen,
    trackUniboxClick,
    isAddProspectFromBusinessDomain,
    trackExternalMails,
    enableExclusionList,
    isSSOEnabled,
    isMFAEnabled,
  };
};

export const getToggleOnOrOffMessage = (isOn: boolean, t): string =>
  isOn
    ? t('messages.are_you_sure_you_want_to_turn_this_off')
    : t('messages.are_you_sure_you_want_to_turn_this_on');

export const getEmailVerificationModalContent = (isOn: boolean, t): string => `
  ${
    isOn
      ? t('messages.turning_this_toggle_off_will_not_allow_you')
      : t('messages.turning_this_toggle_on_will_allow_you')
  } 
  ${t('messages.and_your_team_member_to_verify_prospects_while_importing_them')}
`;

export const getProspectSharingOnOffMessage = (isOn: boolean, t): string =>
  isOn
    ? t('messages.prospect_sharing_toggle_on')
    : t('messages.prospect_sharing_toggle_off');

export const getProspectDuplicationAllowedModalContent = (
  isOn: boolean,
  t,
): string[] => [
  `${
    isOn
      ? t('messages.turning_this_toggle_off_will_not_allow_you')
      : t('messages.turning_this_toggle_on_will_allow_you')
  } ${t('messages.to_have_one_prospect_in_multiple_sequences_simultaneously')}`,
  isOn
    ? t('messages.change_will_not_affect')
    : t('messages.change_will_apply_to_future_imports'),
];

export const getProspectSharingModalContent = (isOn: boolean): string[] => [
  `${
    isOn
      ? 'Disabling this will prevent prospects sharing for team members so every member will be able to access their own prospects only.'
      : 'Enabling this will allow your team members to access prospects of each other.'
  }`,
];

export const getAutoAddToProspectOnLeadRevealOnOffMessage = (
  isOn: boolean,
  t,
): string =>
  isOn
    ? t('messages.are_you_sure_you_want_to_turn_this_off')
    : t('messages.are_you_sure_you_want_to_turn_this_on');
export const getAutoAddToProspectOnLeadRevealModalContent = (
  isOn: boolean,
  t,
): string[] => [
  `${
    isOn
      ? t('messages.turning_this_toggle_off_will_not_allow_you')
      : t('messages.turning_this_toggle_on_will_allow_you')
  } ${t('messages.to_auto_add_to_prospect_on_lead_reveal')}`,
];

export const checkLifeTimeSubcriptionPlan = (subPlan: string): boolean => {
  return subPlan === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME || subPlan === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME;
}