/* eslint-disable import/no-cycle */
/* eslint-disable radix */
import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import UpgradePlanBillingSection from '../../../../../../shared/design-system/components/molecules/upgrade-plan-billing-section';
import UpgradePlanDetails from '../../../../../../shared/design-system/components/molecules/upgrade-plans-details';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { BillingOption } from '../../../../../../shared/utils/billing-options';
import { showGeneralErrorNotification } from '../../../../../../shared/utils/errors';
import { executeOnRequestStatus } from '../../../../../../shared/utils/execute-on-request-status';
import { ModifyPlanAction } from '../../../../../../shared/utils/subscription-plans';

import { getIsUserSubscribed, isRequestPending } from '../../utils/helper';
import { IProps } from '../modify-lead-finder-subscription/modify-lead-finder-subscription-container';
import {
  getNumberOfSlotsUserWant,
  getPlanId,
  onPurchaseSubscription,
} from './utils/helper';
import { checkIsLtdPlan } from '../../../../../../shared/design-system/components/molecules/subscription-plan-details/helper/helper';
import { getPlanName } from '../modify-subscription/utils/helper';

const LeadFinderUpgradePlan: React.FC<{
  containerProps: IProps;
  planCycle: BillingOption;
  isRedirectedFromEmailAccounts: boolean;
}> = ({ containerProps, planCycle, isRedirectedFromEmailAccounts }) => {
  const [numberOfSlotsUserWant, setNumberOfSlotsUserWant] = useState(1);
  const [
    minimumNumberOfSlotsUserCanHave,
    setMinimumNumberOfSlotsUserCanHave,
  ] = useState('1');
  const [couponCode, setCouponCode] = useState('');
  const [havePromoCode, setHavePromoCode] = useState(false);
  const [isUserModifyingSubscription, setIsModifySubscription] = useState(
    false,
  );

  // get plans
  const {
    sendGetLeadFinderPlansResponse,
    sendGetLeadFinderPlansRequestStatus,
    sendGetLeadFinderPlansRequestError,
  } = containerProps;

  const {
    sendPurchaseLeadFinderSubscriptionRequest,
    sendPurchaseLeadFinderSubscriptionRequestStatus,
  } = containerProps;

  const {
    sendGetLeadFinderSubscriptionRequestError,
    sendGetLeadFinderSubscriptionRequestStatus,
    sendGetLeadFinderSubscriptionResponse,
  } = containerProps;

  const {
    sendGetConnectedUsersAndEmailAccountsRequestResponse,
    sendGetConnectedUsersAndEmailAccountsRequestStatus,
  } = containerProps;

  const { deleteAppliedCouponCode, getCouponCodeResponse } = containerProps;

  // modify subscriptions
  const {
    sendPurchaseLeadFinderModifySubscriptionRequestStatus,
    sendPurchaseLeadFinderModifySubscriptionRequest,
  } = containerProps;

  // Calculate pay
  const { sendGetCalculatePayRequest } = containerProps;

  // Upgrade Downgrade plan
  const {
    sendUpgradeLtdPlanRequest,
    sendModifyLtdPlanRequest,
  } = containerProps;

  // Selected Plan
  const { selectedPlanDetails } = containerProps;

  useEffect(() => {
    executeOnRequestStatus({
      status: sendGetLeadFinderPlansRequestStatus,
      onFailed: () => {
        showGeneralErrorNotification(
          sendGetLeadFinderPlansRequestError?.message,
        );
      },
    });
  }, [sendGetLeadFinderPlansRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: sendPurchaseLeadFinderSubscriptionRequestStatus,
      onFailed: () => {
        containerProps.hideLoading();
      },
    });
  }, [sendPurchaseLeadFinderSubscriptionRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: sendGetLeadFinderSubscriptionRequestStatus,
      onSuccess: () => {
        const { slots } = sendGetLeadFinderSubscriptionResponse;

        setNumberOfSlotsUserWant(slots);
      },
      onFailed: () => {
        showGeneralErrorNotification(
          sendGetLeadFinderSubscriptionRequestError.message,
        );
      },
    });
  }, [sendGetLeadFinderSubscriptionRequestStatus]);

  useEffect(() => {
    if (
      sendGetLeadFinderSubscriptionRequestStatus === RequestStatus.Succeeded &&
      sendGetConnectedUsersAndEmailAccountsRequestStatus ===
        RequestStatus.Succeeded
    ) {
      const { slots, planCode } = sendGetLeadFinderSubscriptionResponse;
      const {
        totalUsers,
        totalEmailAccounts,
      } = sendGetConnectedUsersAndEmailAccountsRequestResponse;

      setNumberOfSlotsUserWant(slots);
      setMinimumNumberOfSlotsUserCanHave(
        totalUsers > totalEmailAccounts ? totalUsers : totalEmailAccounts,
      );

      const isUserSubscribed = getIsUserSubscribed(planCode);
      setIsModifySubscription(isUserSubscribed);
    }
  }, [
    sendGetConnectedUsersAndEmailAccountsRequestStatus,
    sendGetLeadFinderSubscriptionRequestStatus,
  ]);

  useEffect(() => {
    const { slots } = sendGetLeadFinderSubscriptionResponse;

    if (
      !isRedirectedFromEmailAccounts &&
      isUserModifyingSubscription &&
      (selectedPlanDetails?.action !== ModifyPlanAction.AddSlots ||
        (slots !== numberOfSlotsUserWant && numberOfSlotsUserWant !== 1))
    ) {
      const currentPlanId = getPlanId(
        sendGetLeadFinderPlansResponse,
        selectedPlanDetails?.displayName,
        selectedPlanDetails?.planCode,
        selectedPlanDetails?.planType,
      );

      sendGetCalculatePayRequest({
        slots: numberOfSlotsUserWant,
        accountSubscriptionPlanId:
          currentPlanId || sendGetLeadFinderSubscriptionResponse?.planId,
      });
    }
  }, [selectedPlanDetails]);

  // Debounce the calculate pay request when slots value change.
  const debouncedSave = useCallback(
    debounce(
      (slots, currentPlanId) =>
        sendGetCalculatePayRequest({
          slots,
          accountSubscriptionPlanId: currentPlanId,
        }),
      500,
    ),
    [],
  );

  // Function to update the number of slots
  const onNumberOfSlotsChange = (value) => {
    // Get current plan id by filtering the plan array based on
    const currentPlanId = getPlanId(
      sendGetLeadFinderPlansResponse,
      selectedPlanDetails?.displayName ||
        sendGetLeadFinderSubscriptionResponse?.planName,
      selectedPlanDetails?.planCode,
      selectedPlanDetails?.planType,
    );

    setNumberOfSlotsUserWant(value);

    if (
      isUserModifyingSubscription &&
      !checkIsLtdPlan(sendGetLeadFinderSubscriptionResponse?.planType)
    ) {
      debouncedSave(
        value,
        currentPlanId || sendGetLeadFinderSubscriptionResponse?.planId,
      );
    }
  };

  // Function to make an api call after user payed the amount on stripe
  const onPurchase = (token) => {
    onPurchaseSubscription({
      isUserModifyingSubscription,
      token,
      numberOfSlotsUserWant: getNumberOfSlotsUserWant(
        numberOfSlotsUserWant,
        minimumNumberOfSlotsUserCanHave,
      ),
      selectedPlanDetails,
      couponCode,
      onPurchaseSubscriptionRequest: (payload) => {
        sendPurchaseLeadFinderSubscriptionRequest(payload);
      },
      onPurchaseModifySubscriptionRequest: (payload) => {
        sendPurchaseLeadFinderModifySubscriptionRequest(payload);
      },
      sendUpgradeLtdPlanRequest: (payload) => {
        sendUpgradeLtdPlanRequest(payload);
      },
      sendModifyLtdPlanRequest: (payload) => {
        sendModifyLtdPlanRequest(payload);
      },
      existingPlanCode: sendGetLeadFinderSubscriptionResponse?.planCode,
      existingSlots: sendGetLeadFinderSubscriptionResponse.slots,
      getCouponCodeResponse,
    });
  };

  // Set coupon code value
  const onCouponCodeChange = (value) => {
    setCouponCode(value);
  };

  // User has a promo code
  const userHasPromoCode = (value) => {
    setHavePromoCode(value);
  };

  const planName = getPlanName(
    planCycle,
    selectedPlanDetails?.displayName,
    selectedPlanDetails?.planCode,
  );

  return (
    <>
      <div className="billing-subscription-container">
        <div className="billing-section d-flex justify-content-between">
          <div className="billing-section__container">
            <UpgradePlanDetails
              planDetailHeading="your_plan_details"
              planName={planName}
              planDescription="user_plan_description"
              planPrice={selectedPlanDetails?.planPrice}
              billingOption={planCycle}
              numberOfSlotsUserWant={numberOfSlotsUserWant}
              numberOfSlotsUserHave={
                sendGetLeadFinderSubscriptionResponse?.slots
              }
              minimumNumberOfSlotsUserCanHave={Number(
                minimumNumberOfSlotsUserCanHave,
              )}
              onNumberOfSlotsChange={onNumberOfSlotsChange}
              licensesText="user_subscription_text"
              isUserModifyingSubscription={isUserModifyingSubscription}
              sendModifySubscriptionRequest={
                sendPurchaseLeadFinderModifySubscriptionRequest
              }
              isBillingSummaryPending={isRequestPending(
                sendPurchaseLeadFinderModifySubscriptionRequestStatus,
              )}
              selectedPlanDetails={selectedPlanDetails}
            />
          </div>
          <div className="billing-section__bill-wrap">
            <UpgradePlanBillingSection
              planPrice={selectedPlanDetails?.planPrice}
              planName={planName}
              planCode={selectedPlanDetails?.planCode}
              planType={selectedPlanDetails?.planType}
              billingOption={planCycle}
              numberOfSlotsUserWant={getNumberOfSlotsUserWant(
                Number(numberOfSlotsUserWant),
                Number(minimumNumberOfSlotsUserCanHave),
              )}
              minimumNumberOfSlotsUserCanHave={Number(
                minimumNumberOfSlotsUserCanHave,
              )}
              onPurchase={onPurchase}
              planId={getPlanId(
                sendGetLeadFinderPlansResponse,
                selectedPlanDetails?.displayName,
                selectedPlanDetails?.planCode,
                selectedPlanDetails?.planType,
              )}
              isBillingSummaryPending={isRequestPending(
                sendPurchaseLeadFinderModifySubscriptionRequestStatus,
              )}
              onDeleteAppliedCouponCode={deleteAppliedCouponCode}
              couponCode={couponCode}
              onCouponCodeChange={onCouponCodeChange}
              havePromoCode={havePromoCode}
              userHasPromoCode={userHasPromoCode}
              isUserModifyingSubscription={isUserModifyingSubscription}
              isLeadFinderUpgrade={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadFinderUpgradePlan;
