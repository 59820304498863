import React from 'react';
import { Images } from '../../../../../../../../shared/app-constants';
import ImageIcon from '../../../../../../../../shared/components/images/image-icon';
import { OverlayTooltip } from '../../../../../../../../shared/design-system/components/overlay';
import toaster, { Theme } from '../../../../../../../../shared/toaster';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';

const ColdEmailPlanExploreBanner: React.FC = () => {
  const couponCode = 'BLACKF35';

  const copyCouponCode = () => {
    navigator.clipboard.writeText(couponCode);
    toaster.success('Coupon code has been copied successfully', {
      theme: Theme.New,
    });
  };

  return (
    <div className="d-flex justify-content-center align-items-center banner-announcement">
      <div className="d-flex align-items-center">
        <ImageIcon src={Images.Discount} />
        <div className="ml-2">
          <span className="regular-1 font-medium popover-arrow-color-txt">
            <span className="font-weight-bold">
              Black Friday/Cyber Monday special:{' '}
            </span>
            Get flat 35% discount on yearly plans. Apply code
            <OverlayTooltip text="Click to copy coupon code">
              <span
                role="button"
                {...accessibleOnClick(copyCouponCode)}
                className="semibold-1 gray-txt-12 ml-2 mr-2 coupon-code"
              >
                {couponCode}
              </span>
            </OverlayTooltip>
            during checkout
          </span>
        </div>
      </div>
    </div>
  );
};

export default ColdEmailPlanExploreBanner;
