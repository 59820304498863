import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import SSOLoginForm from './sso-login-form';
import { hideError } from '../sso-login/sso-login-slice';
import { ssoLoginRequest } from '../sso-login/extra-actions';

const mapStateToProps = (state: RootState) => ({
  ssoLoginRequestStatus: state.ssoLogin.ssoLoginRequest.status,
  ssoLoginRequestMessage: state.ssoLogin.ssoLoginRequest.message,
  ssoLoginRequestError: state.ssoLogin.ssoLoginRequest.error,
  ssoLoginRequestShowError: state.ssoLogin.ssoLoginRequest.showError,

  redirectUrl: state.ssoLogin.redirectUrl,

  agencyConfig: state.home.agencyConfig,

  isWhitelabel: !!state.home.agencyConfig?.clientBaseUrl,
  companyName: state.home.agencyConfig?.companyName,
  clientLogo: state.home.agencyConfig?.clientLogo,

  agencyConfigRequestStatus: state.home.getAgencyConfigRequest.status,
});

const mapDispatchToProps = {
  hideError: () => hideError(),
  sendSSOLoginRequest: (email: string) => ssoLoginRequest({ email }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(SSOLoginForm));
