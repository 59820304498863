import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import store from '../../../store';
import { MAX_DAYS_RANGE } from '../../../components/unified-inbox/helpers/helper';
import toaster, { Theme } from '../../toaster';

export const getDateObj = (start: string, end: string) => ({
  startDateObj: start ? new Date(start) : null,
  endDateObj: end ? new Date(end) : null,
});

export const isValidRange = (startDate, endDate) => {
  const daysDiff = moment(new Date(endDate)).diff(
    moment(new Date(startDate)),
    'days',
  );

  return daysDiff <= MAX_DAYS_RANGE;
};

export const setCurrentTimestamp = (dateToChange) => {
  const currentBrowserTimeStamp = new Date();
  const currentHour = currentBrowserTimeStamp.getHours();
  const currentMinute = currentBrowserTimeStamp.getMinutes();
  const currentSecond = currentBrowserTimeStamp.getSeconds();
  return new Date(
    new Date(dateToChange).setHours(currentHour, currentMinute, currentSecond),
  );
};

export const handleDateRangeChange = ({
  value,
  isCustom = false,
  setDateFilter,
  onDateTimeSelectionChange,
  customDurationOptions,
  shouldHaveRangeLimit = false,
  shouldHaveOneYearLimit = false,
}) => {
  const state = store.getState();
  const { timeZone } = state.myProfile.myProfile;

  if (isCustom) {
    const fetch = !!(value[0] && value[1]);

    if (shouldHaveOneYearLimit && value[0] && value[1]) {
      const start = moment(new Date(value[0]));
      const end = moment(new Date(value[1]));
      const diffInDays = end.diff(start, 'days');

      if (diffInDays > 365) {
        setDateFilter({
          selectedDateRangeOption: 'custom',
          startDate: new Date(),
          endDate: null,
          displayText: 'Custom',
        });

        toaster.error('Date range cannot exceed one year!', {
          theme: Theme.New,
        });

        return;
      }
    }

    if (!isValidRange(value[0], value[1]) && shouldHaveRangeLimit) {
      setDateFilter({
        selectedDateRangeOption: 'custom',
        startDate: new Date(),
        endDate: null,
        displayText: 'Custom',
      });

      toaster.error('Date range should not exceed 90 days!', {
        theme: Theme.New,
      });

      return;
    }

    setDateFilter({
      selectedDateRangeOption: 'custom',
      startDate: DateTime.fromJSDate(setCurrentTimestamp(value[0])).setZone(
        timeZone,
      ),
      endDate:
        value[1] &&
        DateTime.fromJSDate(setCurrentTimestamp(value[1])).setZone(timeZone),
      displayText: 'Custom',
    });

    if (fetch) {
      const startDate = DateTime.fromJSDate(
        setCurrentTimestamp(value[0]),
      ).setZone(timeZone);
      const endDate = DateTime.fromJSDate(
        setCurrentTimestamp(value[1]),
      ).setZone(timeZone);

      onDateTimeSelectionChange(
        'custom',
        startDate,
        value[1] && endDate,
        'Custom',
      );
    }
  } else {
    const selectedDate = customDurationOptions.find((d) => d.label === value);

    setDateFilter({
      selectedDateRangeOption: value,
      startDate: selectedDate.startDate.setZone(timeZone),
      endDate: selectedDate.endDate.setZone(timeZone),
      displayText: selectedDate.displayText,
    });

    onDateTimeSelectionChange(
      value,
      selectedDate.startDate.setZone(timeZone),
      selectedDate.endDate.setZone(timeZone),
      selectedDate.displayText,
    );
  }
};

export const handleOnSelect = ({
  value,
  isCustom,
  isDatePickerOpen,
  setIsDatePickerOpen,
  onDateRangeChangeHandler,
}) => {
  setIsDatePickerOpen(false);

  if (isCustom && !isDatePickerOpen) {
    setIsDatePickerOpen(true);
  } else {
    onDateRangeChangeHandler(value, isCustom);
  }
};

export const handleClickOutside = ({
  event,
  dateFilterRef,
  setIsDatePickerOpen,
}) => {
  if (
    dateFilterRef &&
    dateFilterRef.current &&
    !dateFilterRef.current.contains(event.target)
  ) {
    setIsDatePickerOpen(false);
  }
};

export const formatDateRangeForDatePickerTooltip = (
  startDate: DateTime,
  endDate: DateTime,
  timeZone: string = 'Asia/Kolkata',
): string => {
  const formattedStartDate = startDate.setZone(timeZone).toFormat('d LLL, yy');
  const formattedEndDate = endDate.setZone(timeZone).toFormat('d LLL, yy');

  const offset = startDate.setZone(timeZone).toFormat('ZZ');
  const gmtOffset = `GMT${offset}`;
  return `${formattedStartDate} - ${formattedEndDate}\n(${gmtOffset}) ${timeZone}`;
};
