import { useEffect, useRef } from 'react';

export type IProps = {
  callback: () => void;
  interval: number; // In milliseconds
  shouldPoll: boolean;
};

export const usePolling = (
  callback = () => {},
  interval = 5000,
  shouldPoll = false,
) => {
  const intervalId = useRef(null);

  useEffect(() => {
    if (!shouldPoll) {
      if (intervalId?.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
      return;
    }

    callback(); // Run immediately on start

    intervalId.current = setInterval(() => {
      callback();
    }, interval);

    // eslint-disable-next-line consistent-return
    return () => {
      if (intervalId?.current) clearInterval(intervalId.current);
    };
  }, [shouldPoll, interval, callback]);
};
