import React from 'react';
import classNames from 'classnames';
import { BillingOption } from '../../../../utils/billing-options';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';

export const billingOptions = [
  { label: 'Monthly', value: BillingOption.MONTHLY },
  { label: 'Annually', value: BillingOption.ANNUALLY, discount: 20 },
];

type IProps = {
  value: BillingOption;
  onChange: (value: BillingOption) => void;
  disabledLabel: string;
};

const PlanCycleSwitch: React.FC<IProps> = ({
  value,
  onChange,
  disabledLabel,
}) => {
  const getOptionClassNames = (option) =>
    classNames([
      'plan-cycle-switch--option',
      `${option.value}`,
      {
        selected: value === option.value,
        disabled: disabledLabel === option.label,
      },
    ]);

  return (
    <div className="plan-cycle-switch">
      {billingOptions.map((option) => (
        <div
          className={getOptionClassNames(option)}
          {...accessibleOnClick(() => {
            if (disabledLabel !== option.label) {
              onChange(option.value);
            }
          })}
        >
          <span className="plan-cycle-switch--option-label">
            {option.label}
          </span>
          {option.discount && (
            <span className="discount-percentage">
              Save {option.discount}%
            </span>
          )}
        </div>
      ))}

      <div
        className={`selected-option-bg ${
          value === BillingOption.MONTHLY
            ? BillingOption.MONTHLY
            : BillingOption.ANNUALLY
        }`}
      />
    </div>
  );
};

export default PlanCycleSwitch;
