import React from 'react';
import { Images } from '../../../../../app-constants';
import ImageIcon from '../../../../../components/images/image-icon';
import { FeatureMeta as FeatureMetaProps } from '../types';

const FeatureMeta: React.FC<FeatureMetaProps> = ({
  isAvailable,
  helperText,
  isComingSoon,
}) => {
  let src = Images.CheckSingleGreen;
  let alt = 'Available';

  if (!isAvailable) {
    src = Images.CrossRed;
    alt = 'Not Available';
  }

  return isComingSoon ? (
    <>
      <p className="m-0 font-14 gray-txt-15 font-medium font-italic">
        Coming Soon
      </p>
    </>
  ) : (
    <>
      <ImageIcon src={src} alt={alt} />
      <span className="font-8 gray-txt-15">{helperText}</span>
    </>
  );
};

export const EmailWarmupFeatureMeta: React.FC<FeatureMetaProps> = ({
  isAvailable,
  helperText,
  isComingSoon,
}) => (
  <div className="feature-meta d-flex justify-content-center align-items-center flex-column email-warmup-bg">
    <FeatureMeta
      isAvailable={isAvailable}
      helperText={helperText}
      isComingSoon={isComingSoon}
    />
  </div>
);

export const EmailOutreachFeatureMeta: React.FC<FeatureMetaProps> = ({
  isAvailable,
  helperText,
  isComingSoon,
}) => (
  <div className="feature-meta d-flex justify-content-center align-items-center  flex-column email-outreach-bg">
    <FeatureMeta
      isAvailable={isAvailable}
      helperText={helperText}
      isComingSoon={isComingSoon}
    />
  </div>
);

export const EmailOutreachProFeatureMeta: React.FC<FeatureMetaProps> = ({
  isAvailable,
  helperText,
  isComingSoon,
}) => (
  <div className="feature-meta d-flex justify-content-center align-items-center  flex-column email-outreach-pro-bg">
    <FeatureMeta
      isAvailable={isAvailable}
      helperText={helperText}
      isComingSoon={isComingSoon}
    />
  </div>
);
