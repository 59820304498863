import React, { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { Pencil } from '@saleshandy/icons';
import type { IProps } from './types';
import {
  AuthHelper,
  executeOnRequestStatus,
  getIsRequestPending,
  getIsRequestSucceeded,
  initializeSentryIntegrations,
  initializeThirdPartyIntegrations,
  redirectWithoutRefresh,
} from '../../../../shared/utils';
import { isInternalUrl } from '../../../../shared/utils/is-internal-url';
import HelmetIcon from '../../../../shared/components/helmet-icon';
import { SHLogoDark } from '../../../../shared/svg';
import { SHLogoTypography } from '../../../../shared/svg/sh-typography';
import TestimonialsCarousel from '../../../../shared/components/testimonials-carousel';
import Button from '../../../../shared/design-system/ui/button';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import toaster, { Theme } from '../../../../shared/toaster';
import { openChatSupport } from '../../../../shared/utils/open-chat-support';
import { ExceptionTypes } from '../../../../shared/types';
import { setAgencyClientRedirectURLOnLogout } from '../agency-client-login/helper';

const OTP_LENGTH = 4;
const OTP_RESEND_TIME = 60;

const MFAVerify: React.FC<IProps> = ({
  agencyConfig,
  agencyConfigRequestStatus,
  isRedirectedFromAgency,
  email,
  token,
  firstName,
  lastName,
  trackingId,
  sendResendOtpRequest,
  sendVerifyOtpRequest,
  resetMFAData,
  verifyOtpRequestStatus,
  verifyOtpRequestError,
  resendOtpRequestStatus,
}) => {
  const [otp, setOtp] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [resendTimer, setResendTimer] = useState(OTP_RESEND_TIME);

  const isWhitelabel = !!agencyConfig?.clientBaseUrl;
  const isAgencyLoading =
    getIsRequestPending(agencyConfigRequestStatus) && !isInternalUrl();
  const isVerifyBtnLoading = getIsRequestPending(verifyOtpRequestStatus);
  const isVerifyBtnDisabled =
    isVerifyBtnLoading || otp?.length < OTP_LENGTH || !!error;

  const onResend = () => {
    if (!getIsRequestPending(resendOtpRequestStatus)) {
      sendResendOtpRequest(email);
      setResendTimer(OTP_RESEND_TIME);
    }
  };

  const onInputChange = (inputOtp: string) => {
    setOtp(inputOtp);
    setError('');
  };

  const onVerify = () => {
    sendVerifyOtpRequest(email, otp);
  };

  const redirectToLoginPage = () => {
    redirectWithoutRefresh(isRedirectedFromAgency ? '/agency/login' : '/login');
  };

  const onEditAction = () => {
    redirectToLoginPage();
  };

  useEffect(() => {
    if (!email) {
      redirectToLoginPage();
    }
  }, [email]);

  useEffect(() => {
    if (!isVerifyBtnDisabled) {
      sendVerifyOtpRequest(email, otp);
    }
  }, [otp]);

  useEffect(() => {
    let timeout: NodeJS.Timeout = null;
    if (resendTimer > 0) {
      timeout = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
    return () => clearTimeout(timeout);
  }, [resendTimer]);

  useEffect(() => {
    executeOnRequestStatus({
      status: verifyOtpRequestStatus,
      onSuccess: () => {
        if (token) {
          AuthHelper.login({ token });

          if (isRedirectedFromAgency) {
            setAgencyClientRedirectURLOnLogout();
          }

          initializeThirdPartyIntegrations({
            trackingId,
            showChatSupport: agencyConfig?.showChatSupport,
            firstName,
            lastName,
            email,
          });
        }
        initializeSentryIntegrations(email);
      },
      onFailed: () => {
        if (verifyOtpRequestError?.message?.includes('support')) {
          toaster.error(
            <div>
              Your account is suspended, to revoke this suspension feel free to
              reach out to{' '}
              <span
                className="font-bold red-txt-13"
                {...accessibleOnClick(() => {
                  openChatSupport();
                })}
              >
                <u>support</u>
              </span>
            </div>,
            {
              theme: Theme.New,
              showCloseIcon: true,
              delay: 1000000,
            },
          );
        } else if (
          verifyOtpRequestError.type === ExceptionTypes.Silent &&
          verifyOtpRequestError.message
        ) {
          setError(verifyOtpRequestError.message);
        }
      },
    });
  }, [verifyOtpRequestStatus]);

  useEffect(
    () => () => {
      resetMFAData();
    },
    [],
  );

  return (
    <>
      {getIsRequestSucceeded(agencyConfigRequestStatus) && (
        <HelmetIcon icon={agencyConfig?.favicon} />
      )}
      {isAgencyLoading || isWhitelabel || isRedirectedFromAgency ? null : (
        <div className="testimonials-container">
          <div className="d-flex align-items-center">
            <SHLogoDark />
            <div className="bs-ml-10">
              <SHLogoTypography />
            </div>
          </div>

          <TestimonialsCarousel />
          <p className="copyright">
            &copy;{`${new Date().getFullYear()} Saleshandy`}
          </p>
        </div>
      )}

      <div
        className={
          isAgencyLoading || isWhitelabel || isRedirectedFromAgency
            ? 'auth-wrapper w-100 d-flex'
            : 'auth-wrapper'
        }
      >
        <div className="auth-container">
          <div className="auth-form mfa-form">
            <h1 className="auth-container--title verification-title">
              Verification Code
            </h1>

            <div className="mfa-form--message-container">
              <div className="message">
                Please enter the code we have sent to
              </div>
              <div className="email-container">
                <span className="email">{email}</span>
                <Pencil
                  height={16}
                  width={16}
                  className="edit-action"
                  {...accessibleOnClick(onEditAction)}
                />
              </div>
            </div>

            <div>
              <OTPInput
                value={otp}
                onChange={onInputChange}
                shouldAutoFocus={true}
                inputType="number"
                numInputs={OTP_LENGTH}
                containerStyle="otp-container"
                inputStyle={`otp-input ${error ? 'otp-input-error' : ''}`}
                renderInput={(props) => <input {...props} />}
              />
              {error && <div className="mfa-form--error-message">{error}</div>}
            </div>

            <div className="mfa-form--hint-message">
              Please check spam or junk folder if not received in inbox
            </div>

            <div className="mfa-form--resend-container">
              {resendTimer <= 0 ? (
                <span className="resend-btn" {...accessibleOnClick(onResend)}>
                  Resend Code
                </span>
              ) : (
                <>
                  <span className="strong-text">
                    Didn’t get verification code?
                  </span>{' '}
                  Resend code in {resendTimer.toString().padStart(2, '0')}s
                </>
              )}
            </div>

            <Button
              isFullWidth
              isLoading={isVerifyBtnLoading}
              disabled={isVerifyBtnDisabled}
              loadingText="Verifying..."
              onClick={onVerify}
            >
              Verify
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MFAVerify;
