export const LEAD_EMAIL_EXCLUDED_DOMAINS = [
  'gmail.com',
  'outlook.com',
  'icloud.com',
  'yahoo.com',
  'aol.com',
  'hotmail.com',
  'protonmail.com',
  'zoho.com',
  'yandex.com',
  'mail.com',
  'gmx.com',
  'fastmail.com',
  'tutanota.com',
  'hushmail.com',
  'runbox.com',
  'inbox.com',
  'rediffmail.com',
  'qq.com',
  'rambler.ru',
  'web.de',
];
