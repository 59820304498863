import { useState } from 'react';
import { toaster } from '@saleshandy/design-system';
import { getTagsToLeads } from '../../helpers/leads.api';

export const useLeadApiState = () => {
  const [leadTags, setLeadTags] = useState([]);
  const [isLeadTagsLoading, setIsLeadTagsLoading] = useState(false);

  const fetchLeadTags = async (applyLoading = false) => {
    try {
      if (applyLoading) {
        setIsLeadTagsLoading(true);
      }
      const response = await getTagsToLeads();
      const data = response.payload || [];
      setLeadTags(data);
    } catch (error) {
      toaster.error('Error fetching tags');
    } finally {
      if (applyLoading) {
        setIsLeadTagsLoading(false);
      }
    }
  };

  return {
    leadTags,
    setLeadTags,
    isLeadTagsLoading,
    fetchLeadTags,
  };
};
