import React, { useEffect } from 'react';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import queryString from 'query-string';
import { IProps } from './types';
import { RequestStatus } from '../../../../shared/enums/request-status';
import toaster, { Theme } from '../../../../shared/toaster';
import store from '../../../../store';
import { OAuthMethod } from '../oauth-buttons';
import {
  initializeSentryIntegrations,
  initializeThirdPartyIntegrations,
} from '../../../../shared/utils/initialize-third-party-integrations';
import { AuthHelper } from '../../../../shared/utils/auth-helper';
import { AnalyticsEvents } from '../../../../shared/enums/analytics';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  redirectWithoutRefresh,
  setToken,
} from '../../../../shared/utils';
import { ActionForAuthentication } from '../../types';

const OAuthAuthenticateCallback: React.FC<IProps> = (props) => {
  const {
    match,
    location,
    history,
    status,
    message,
    error,
    sendOAuthAuthenticateRequest,
    token,
    trackingId,
    firstName,
    lastName,
    email,
    action,
    agencyConfig,
    sendGetAgencyConfigRequest,
    getAgencyConfigRequestStatus,
    sendDeleteMyProfileRequest,
    deleteMyProfileRequestStatus,
    deleteMyProfileRequestError,
    deleteMyProfileRequestMessage,
    resetDeleteMyProfileRequest,
  } = props;

  const redirectToHome = () => {
    history.push('/');
  };

  const redirectToLogin = () => {
    history.push('/login');
  };

  // Handle initial authentication
  useEffect(() => {
    const { method } = match.params;
    const query = location.search;
    location.search = '';

    const { code, state, error: queryError } = queryString.parse(query);

    if (queryError) {
      toaster.error('Authentication failed', {
        theme: Theme.New,
        showCloseIcon: true,
      });
      redirectToLogin();
    } else if (code) {
      // Convert string method to OAuthMethod enum
      const oauthMethod = method as OAuthMethod;
      sendOAuthAuthenticateRequest(
        oauthMethod,
        code.toString(),
        state?.toString(),
      );
      store.dispatch(showLoading());
    } else {
      // No code or error in the URL
      redirectToLogin();
    }
  }, []);

  // Handle authentication success and fetch agency config if needed
  useEffect(() => {
    if (status === RequestStatus.Succeeded || status === RequestStatus.Failed) {
      const clientBaseDomain = window.sessionStorage.getItem('oauth-whitelabel-domain');
      store.dispatch(hideLoading());

      if (status === RequestStatus.Succeeded && token && email) {
        const agencyConfigPayload = {
          baseUrl: window.location.origin,
          email,
        };
        sendGetAgencyConfigRequest(agencyConfigPayload);
      }

      if (
        status === RequestStatus.Succeeded &&
        !token &&
        !email &&
        message === 'Logged in successfully'
      ) {
        if (clientBaseDomain) {
          window.location.href = `http://${clientBaseDomain}/oauth/whitelabel/callback?message=email-changed-successfully`;
          return;
        }
        if (AuthHelper.isAuthenticated) {
          toaster.success('Email changed successfully', { theme: Theme.New });
          redirectToHome();
        }
        redirectToLogin();
      }

      if (status === RequestStatus.Failed) {
        if (clientBaseDomain) {
          window.location.href = `http://${clientBaseDomain}/oauth/whitelabel/callback?message=authentication-failed`;
          return;
        }
        const errorMessage = error?.message || 'Authentication failed.';
        toaster.error(errorMessage, {
          theme: Theme.New,
          showCloseIcon: true,
          delay: 10000,
        });
        redirectToLogin();
      }
    }
  }, [status, token, email]);

  // Handle post-authentication actions based on agency config
  useEffect(() => {
    if (
      getAgencyConfigRequestStatus === RequestStatus.Succeeded &&
      agencyConfig &&
      token
    ) {
      // Handle agency redirect if applicable
      if (agencyConfig?.clientBaseUrl) {
        // Use optional chaining and nullish coalescing for safer parsing
        const urlParts = agencyConfig.clientBaseUrl?.split('://') || [];
        const domainParts = urlParts[1]?.split(':') || [];
        const clientBaseDomain = domainParts[0];
        
        if (clientBaseDomain && clientBaseDomain !== window.location.hostname) {
          window.sessionStorage.setItem('oauth-whitelabel-domain', clientBaseDomain);
        }

        const redirectUrl = `${
          agencyConfig.clientBaseUrl
        }/oauth/whitelabel/callback?token=${token}&action=${
          action || ''
        }&trackingId=${trackingId || ''} &firstName=${
          firstName || ''
        } &lastName=${lastName || ''} &email=${email || ''}`;
        window.location.href = redirectUrl;
        return;
      }

      // Otherwise handle normal authentication flow
      switch (action) {
        case ActionForAuthentication.Invitation:
        case ActionForAuthentication.Signup:
          setToken(token);
          if (trackingId) {
            window.analytics?.track?.({
              userId: trackingId,
              event: AnalyticsEvents.SignupCompleted,
              properties: {
                firstName,
                lastName,
                email,
                id: trackingId,
                source: 'v3',
              },
            });
          }

          redirectWithoutRefresh({
            pathname: '/sequence',
            search: 'signup=completed',
          });
          break;
        case ActionForAuthentication.ValidationChangeEmail:
          setToken(token);

          redirectWithoutRefresh({
            pathname: '/settings/profile',
            state: {
              oAuthChangeEmail: true,
            },
          });
          break;
        case ActionForAuthentication.DeleteAccount:
          sendDeleteMyProfileRequest('null');
          break;
        case ActionForAuthentication.Login:
        default:
          AuthHelper.login({ token });
          initializeThirdPartyIntegrations({
            trackingId,
            showChatSupport: agencyConfig?.showChatSupport,
            firstName,
            lastName,
            email,
          });
          initializeSentryIntegrations(email);
          redirectToHome(); // Redirect to home on success
          toaster.success(message, { theme: Theme.New });
      }
    }
  }, [getAgencyConfigRequestStatus, agencyConfig]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteMyProfileRequestStatus,
      onSuccess: () => {
        toaster.success(deleteMyProfileRequestMessage, { theme: Theme.New });
        AuthHelper.logout({ redirect: true, pathName: '/signup' });
        resetDeleteMyProfileRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: deleteMyProfileRequestError,
          onError: () => {
            if (deleteMyProfileRequestError.code !== 1001) {
              toaster.error(deleteMyProfileRequestError.message, {
                theme: Theme.New,
              });
              resetDeleteMyProfileRequest();
            }
          },
        });
      },
    });
  }, [deleteMyProfileRequestStatus]);

  return null;
};

export default OAuthAuthenticateCallback;
