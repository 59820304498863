import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { loginReducer } from '../components/auth/components/login';
import { ssoLoginReducer } from '../components/auth/components/sso-login';
import { signupReducer } from '../components/auth/components/signup';
import { resetPasswordReducer } from '../components/auth/components/reset-password';
import { resetPasswordCallbackReducer } from '../components/auth/components/reset-password-callback';
import {
  changePasswordReducer,
  setUserPasswordReducer,
} from '../components/auth/components/change-password';
import { sequenceReducer } from '../components/sequence';
import { scheduleReducer } from '../components/settings/components/schedule';
import { safetySettingsReducer } from '../components/settings/components/safety-settings';
import { customFieldReducer } from '../components/settings/components/custom-fields';
import { myProfileReducer } from '../components/settings/components/my-profile';
import { invitationSignupReducer } from '../components/auth/components/invitation-signup';
import { reset } from './actions';
import { prospectReducer } from '../components/prospect';
import { customDomainReducer } from '../components/settings/components/custom-domain';
import { adminSettingsReducer } from '../components/settings/components/admin-settings';
import { homeReducer } from '../components/home';
import { reportsReducer } from '../components/reports';
import { templateReducer } from '../components/templates';
import { emailModalReducer } from '../components/sequence/shared/modals/email-modal/test-email';
import { pageHeaderBannerAlertReducer } from '../shared/design-system/components/atoms/page-header';
import { billingReducer } from '../components/settings/components/billing-subscription';
import { updatePreferredAppReducer } from '../shared/components/connect-email-account-options';
import { apiTokensReducer } from '../components/settings/components/api-tokens';
import { emailDailySendingLimitReducer } from '../components/email-accounts/components/email-accounts-content/components/email-daily-sending-limit';
import { mailboxEmailsReducer } from '../components/mailbox-emails';
import { emailWarmupReducer } from '../components/email-warmup';
import { growthHubReducer } from '../components/growth-hub';
import { unifiedInboxReducer } from '../components/unified-inbox';
import { usersAndTeamsReducer } from '../components/settings/components/users-and-teams';
import { tasksReducer } from '../components/tasks';
import { outOfOfficeReducer } from '../components/settings/components/out-of-office';
import { webhookReducer } from '../components/settings/components/webhook';
import { doNotContactReducer } from '../components/settings/components/do-not-contact';
import { customOutcomeReducer } from '../components/settings/components/custom-outcomes';
import { agencyClientReducer } from '../components/agency-client-management';
import { whitelabelReducer } from '../components/settings/components/whitelabel';
import { apiCacheReducer } from '../shared/reducers/api-cache-slice';
import { leadsReducer } from '../components/leads';
import { emailAccountsReducer } from '../components/email-accounts';
import { domainsReducer } from '../components/domains';
import { oauthReducer } from '../components/auth/components/oauth-buttons';
import { ssoCallbackReducer } from '../components/auth/components/sso-callback';

const combinedReducer = combineReducers({
  ssoLogin: ssoLoginReducer,
  ssoCallback: ssoCallbackReducer,
  loadingBar: loadingBarReducer,
  login: loginReducer,
  signup: signupReducer,
  oauth: oauthReducer,
  resetPassword: resetPasswordReducer,
  resetPasswordCallback: resetPasswordCallbackReducer,
  changePassword: changePasswordReducer,
  setUserPassword: setUserPasswordReducer,
  sequence: sequenceReducer,
  schedule: scheduleReducer,
  prospect: prospectReducer,
  leads: leadsReducer,
  safetySettings: safetySettingsReducer,
  customField: customFieldReducer,
  customDomain: customDomainReducer,
  myProfile: myProfileReducer,
  adminSettings: adminSettingsReducer,
  invitationSignup: invitationSignupReducer,
  emailModal: emailModalReducer,
  home: homeReducer,
  reports: reportsReducer,
  templates: templateReducer,
  pageHeader: pageHeaderBannerAlertReducer,
  billingAndSubscription: billingReducer,
  updatePreferredApp: updatePreferredAppReducer,
  apiToken: apiTokensReducer,
  emailDailySendingLimit: emailDailySendingLimitReducer,
  mailboxEmails: mailboxEmailsReducer,
  emailWarmup: emailWarmupReducer,
  growthHub: growthHubReducer,
  unifiedInbox: unifiedInboxReducer,
  usersAndTeams: usersAndTeamsReducer,
  tasks: tasksReducer,
  outOfOffice: outOfOfficeReducer,
  webhook: webhookReducer,
  doNotContact: doNotContactReducer,
  customOutcome: customOutcomeReducer,
  agencyClient: agencyClientReducer,
  whitelabel: whitelabelReducer,
  apiCache: apiCacheReducer,
  emailAccount: emailAccountsReducer,
  domains: domainsReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === reset.type) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
