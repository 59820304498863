/* eslint-disable consistent-return */
import React from 'react';
import classNames from 'classnames';
import {
  ContactStatusType,
  ContactStatusTypeDisplayName,
} from '../../../../../components/sequence/enums';

const ContactStatusTag = ({ status, isSequenceProspectTag = false }) => {
  let capitalizedStatus = ContactStatusTypeDisplayName[status]
    ? ContactStatusTypeDisplayName[status]
    : '';

  if (capitalizedStatus) {
    capitalizedStatus =
      capitalizedStatus?.charAt(0)?.toUpperCase() + capitalizedStatus?.slice(1);
  }

  const activeTagClass = classNames([
    'bs-tag',
    { 'bs-tag-blue-2': isSequenceProspectTag },
    { 'bs-tag-green': !isSequenceProspectTag },
  ]);
  const finishedTagClass = classNames([
    'bs-tag',
    { 'bs-tag-blue-2': isSequenceProspectTag },
    { 'bs-tag-green': !isSequenceProspectTag },
  ]);
  const repliedTagClass = classNames([
    'bs-tag',
    { 'bs-tag-green-2': isSequenceProspectTag },
    { 'bs-tag-lime': !isSequenceProspectTag },
  ]);
  const bouncedTagClass = classNames([
    'bs-tag',
    { 'bs-tag-red-2': isSequenceProspectTag },
    { 'bs-tag-red': !isSequenceProspectTag },
  ]);
  const unsubscribedTagClass = classNames([
    'bs-tag',
    { 'bs-tag-red-2': isSequenceProspectTag },
    { 'bs-tag-gold': !isSequenceProspectTag },
  ]);
  const blacklistTagClass = classNames([
    'bs-tag',
    { 'bs-tag-gray': isSequenceProspectTag },
    { 'bs-tag-gray': !isSequenceProspectTag },
  ]);
  const completedTagClass = classNames([
    'bs-tag',
    { 'bs-tag-indigo-2': isSequenceProspectTag },
    { 'bs-tag-indigo': !isSequenceProspectTag },
  ]);
  const inactiveTagClass = classNames([
    'bs-tag',
    { 'bs-tag-gray-3': isSequenceProspectTag },
    { 'bs-tag-gray': !isSequenceProspectTag },
  ]);
  const waitingTagClass = classNames([
    'bs-tag',
    { 'bs-tag-orange-3': isSequenceProspectTag },
    { 'bs-tag-orange': !isSequenceProspectTag },
  ]);
  const pausedTagClass = classNames([
    'bs-tag',
    { 'bs-tag-gray-3': isSequenceProspectTag },
    { 'bs-tag-gray': !isSequenceProspectTag },
  ]);

  const getClassName = (st: ContactStatusType): string => {
    if (st === ContactStatusType.Active) {
      return activeTagClass;
    }
    if (st === ContactStatusType.Failed) {
      return finishedTagClass;
    }
    if (st === ContactStatusType.Replied) {
      return repliedTagClass;
    }
    if (st === ContactStatusType.Bounced) {
      return bouncedTagClass;
    }
    if (st === ContactStatusType.Unsubscribed) {
      return unsubscribedTagClass;
    }
    if (st === ContactStatusType.Blacklisted) {
      return blacklistTagClass;
    }
    if (st === ContactStatusType.Paused) {
      return pausedTagClass;
    }
    if (st === ContactStatusType.Finished) {
      return finishedTagClass;
    }
    if (st === ContactStatusType.Completed) {
      return completedTagClass;
    }
    if (st === ContactStatusType.Inactive) {
      return inactiveTagClass;
    }
    if (st === ContactStatusType.Waiting) {
      return waitingTagClass;
    }
  };

  return (
    <span className={`${getClassName(status)} regular-2 bs-mr-6`}>
      {capitalizedStatus}
    </span>
  );
};

export default ContactStatusTag;
