/* eslint-disable no-restricted-globals */
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { StatusBadgeVariant } from '../../../../../shared/design-system/components/molecules/status-badge/types';
import toaster, { Theme } from '../../../../../shared/toaster';
import store from '../../../../../store';
import {
  EmailAccountMethod,
  EmailAccountType,
  EmailAccountsSortBy,
  EmailServiceProvider,
} from '../../../enums/email-account';
import { Encryption } from '../../../enums/smtp-imap-encryption';
import { SMTPIMAP } from '../../../enums/smtp-or-imap';
import {
  EmailProviderDropDown,
  SmtpImapProviders,
  LastTestConnection,
} from '../components/connect-smtp-imap-account-modal/types';
import history from '../../../../../shared/history';
import { Order } from '../../../../../shared/enums/order';
import { EmailAccountsFilters } from '../../../types/email-account';

const freeGmailAccounts = ['gmail.com', 'googlemail.com'];

export const getEmailAccountType = (emailAccount) =>
  emailAccount.type === EmailAccountType.Gmail
    ? EmailAccountMethod.Gmail
    : EmailAccountMethod.Microsoft;

export const showHideLoading = (isLoading, prevPropsIsLoading) => {
  if (isLoading !== prevPropsIsLoading) {
    if (isLoading) {
      store.dispatch(showLoading());
    } else {
      store.dispatch(hideLoading());
    }
  }
};

export const showToasterWithErrorCheck = (error) => {
  if (error) {
    if (typeof error === 'string') {
      toaster.error(error, {
        theme: Theme.New,
        showCloseIcon: true,
        delay: 10000,
      });

      return;
    }
    toaster.error(error.message, {
      theme: Theme.New,
      showCloseIcon: true,
      delay: 10000,
    });
  }
};

export const shouldShowAddEmailAccountModal = (
  showAddEmailAccountModalProp,
  prevShowAddEmailAccountModalProp,
): boolean =>
  showAddEmailAccountModalProp !== prevShowAddEmailAccountModalProp &&
  showAddEmailAccountModalProp;

export const isSavedSMTPAccount = (emailAccount) =>
  emailAccount.smtpImapConnectionPayload
    ? emailAccount?.smtpImapConnectionPayload?.smtpConnectionErrorDetails !==
        null ||
      emailAccount?.smtpImapConnectionPayload?.imapConnectionErrorDetails !==
        null
    : false;

const smtpHosts = {
  [SmtpImapProviders.GmailGsuite]: 'smtp.gmail.com',
  [SmtpImapProviders.Office365]: 'smtp.office365.com',
  [SmtpImapProviders.OutlookHotmailLive]: 'smtp-mail.outlook.com',
  [SmtpImapProviders.YahooMail]: 'smtp.mail.yahoo.com',
  [SmtpImapProviders.Zoho]: 'smtp.zoho.com',
  [SmtpImapProviders.Godaddy]: 'smtpout.secureserver.net',
  [SmtpImapProviders.Sendgrid]: 'smtp.sendgrid.net',
  [SmtpImapProviders.Yandex]: 'smtp.yandex.com',
};

const smtpPorts = {
  [SmtpImapProviders.GmailGsuite]: 465,
  [SmtpImapProviders.Office365]: 587,
  [SmtpImapProviders.OutlookHotmailLive]: 587,
  [SmtpImapProviders.YahooMail]: 465,
  [SmtpImapProviders.Zoho]: 465,
  [SmtpImapProviders.Godaddy]: 465,
  [SmtpImapProviders.Sendgrid]: 465,
  [SmtpImapProviders.Yandex]: 465,
};

const smtpEncryptions = {
  [SmtpImapProviders.GmailGsuite]: Encryption.SSL,
  [SmtpImapProviders.Office365]: Encryption.TLS,
  [SmtpImapProviders.OutlookHotmailLive]: Encryption.TLS,
  [SmtpImapProviders.YahooMail]: Encryption.SSL,
  [SmtpImapProviders.Zoho]: Encryption.SSL,
  [SmtpImapProviders.Godaddy]: Encryption.SSL,
  [SmtpImapProviders.Sendgrid]: Encryption.SSL,
  [SmtpImapProviders.Yandex]: Encryption.SSL,
};

const imapHosts = {
  [SmtpImapProviders.GmailGsuite]: 'imap.gmail.com',
  [SmtpImapProviders.Office365]: 'outlook.office365.com',
  [SmtpImapProviders.OutlookHotmailLive]: 'imap-mail.outlook.com',
  [SmtpImapProviders.YahooMail]: 'imap.mail.yahoo.com',
  [SmtpImapProviders.Zoho]: 'imap.zoho.com',
  [SmtpImapProviders.Godaddy]: 'imap.secureserver.net',
  [SmtpImapProviders.Sendgrid]: null,
  [SmtpImapProviders.Yandex]: 'imap.yandex.com',
};

const imapPorts = {
  [SmtpImapProviders.GmailGsuite]: 993,
  [SmtpImapProviders.Office365]: 993,
  [SmtpImapProviders.OutlookHotmailLive]: 993,
  [SmtpImapProviders.YahooMail]: 993,
  [SmtpImapProviders.Zoho]: 993,
  [SmtpImapProviders.Godaddy]: 993,
  [SmtpImapProviders.Sendgrid]: null,
  [SmtpImapProviders.Yandex]: 993,
};

const imapEncryptions = {
  [SmtpImapProviders.GmailGsuite]: Encryption.SSL,
  [SmtpImapProviders.Office365]: Encryption.SSL,
  [SmtpImapProviders.OutlookHotmailLive]: Encryption.SSL,
  [SmtpImapProviders.YahooMail]: Encryption.SSL,
  [SmtpImapProviders.Zoho]: Encryption.SSL,
  [SmtpImapProviders.Godaddy]: Encryption.SSL,
  [SmtpImapProviders.Sendgrid]: null,
  [SmtpImapProviders.Yandex]: Encryption.SSL,
};

export const getDefaultValuesForSmtpImapProviders = (
  provider: SmtpImapProviders,
) => ({
  smtpHostValue: smtpHosts[provider],
  smtpPortValue: smtpPorts[provider],
  smtpEncryptionValue: smtpEncryptions[provider],
  imapHostValue: imapHosts[provider],
  imapPortValue: imapPorts[provider],
  imapEncryptionValue: imapEncryptions[provider],
});

export const getDefaultSmtpImapValues = (value) => {
  if (value === SmtpImapProviders.GmailGsuite) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.GmailGsuite);
  }

  if (value === SmtpImapProviders.Office365) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Office365);
  }

  if (value === SmtpImapProviders.OutlookHotmailLive) {
    return getDefaultValuesForSmtpImapProviders(
      SmtpImapProviders.OutlookHotmailLive,
    );
  }

  if (value === SmtpImapProviders.YahooMail) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.YahooMail);
  }

  if (value === SmtpImapProviders.Zoho) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Zoho);
  }

  if (value === SmtpImapProviders.Godaddy) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Godaddy);
  }

  if (value === SmtpImapProviders.Sendgrid) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Sendgrid);
  }

  if (value === SmtpImapProviders.Yandex) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Yandex);
  }

  return {
    smtpHostValue: null,
    smtpPortValue: null,
    smtpEncryptionValue: Encryption.SSL,
    imapHostValue: null,
    imapPortValue: null,
    imapEncryptionValue: Encryption.SSL,
  };
};

const getEmailProviderListValue = (provider) => ({
  key: provider,
  value: provider,
});

export const getEmailProviderValue = (key) => {
  const emailProviderLists = [
    {
      key: 'smtp.gmail.com',
      value: getEmailProviderListValue(SmtpImapProviders.GmailGsuite),
    },
    {
      key: 'smtp.office365.com',
      value: getEmailProviderListValue(SmtpImapProviders.Office365),
    },
    {
      key: 'smtp-mail.outlook.com',
      value: getEmailProviderListValue(SmtpImapProviders.OutlookHotmailLive),
    },
    {
      key: 'smtp.mail.yahoo.com',
      value: getEmailProviderListValue(SmtpImapProviders.YahooMail),
    },
    {
      key: 'smtp.zoho.com',
      value: getEmailProviderListValue(SmtpImapProviders.Zoho),
    },
    {
      key: 'smtpout.secureserver.net',
      value: getEmailProviderListValue(SmtpImapProviders.Godaddy),
    },
    {
      key: 'smtp.sendgrid.net',
      value: getEmailProviderListValue(SmtpImapProviders.Sendgrid),
    },
    {
      key: 'smtp.yandex.com',
      value: getEmailProviderListValue(SmtpImapProviders.Yandex),
    },
  ];

  const result = emailProviderLists.find(
    (e) =>
      e.key.split('.').slice(-2, -1)[0] === key.split('.').slice(-2, -1)[0],
  );

  return result?.value || { key: 'Other', value: 'Other' };
};

export const emailProviderDropDown: EmailProviderDropDown[] = [
  { key: SmtpImapProviders.GmailGsuite, value: SmtpImapProviders.GmailGsuite },
  { key: SmtpImapProviders.Office365, value: SmtpImapProviders.Office365 },
  {
    key: SmtpImapProviders.OutlookHotmailLive,
    value: SmtpImapProviders.OutlookHotmailLive,
  },
  { key: SmtpImapProviders.YahooMail, value: SmtpImapProviders.YahooMail },
  { key: SmtpImapProviders.Zoho, value: SmtpImapProviders.Zoho },
  { key: SmtpImapProviders.Godaddy, value: SmtpImapProviders.Godaddy },
  { key: SmtpImapProviders.Sendgrid, value: SmtpImapProviders.Sendgrid },
  { key: SmtpImapProviders.Yandex, value: SmtpImapProviders.Yandex },
  { key: 'Other', value: 'Other' },
];

export const encryptionOption = [
  {
    label: Encryption.SSL,
    value: Encryption.SSL,
  },
  {
    label: Encryption.TLS,
    value: Encryption.TLS,
  },
  {
    label: 'None',
    value: 'none',
  },
];

const getIsConnectionError = (
  requestFrom,
  connectionType,
  testConnectionResponse,
  connectSmtpImapResponse,
  smtpImapAccountDetailsResponse,
  updateSmtpImapAccountDetailsResponse,
  lastTestConnection,
) => {
  let isConnectionError;
  let connectionErrorMessage = '';

  if (lastTestConnection === LastTestConnection.Test) {
    isConnectionError = testConnectionResponse?.isConnectionEstablished;
    connectionErrorMessage = testConnectionResponse?.error;
  } else if (lastTestConnection === LastTestConnection.ConnectAndSave) {
    isConnectionError =
      connectSmtpImapResponse?.[connectionType]?.isConnectionEstablished;
    connectionErrorMessage = connectSmtpImapResponse?.[connectionType]?.error;
  } else if (lastTestConnection === LastTestConnection.Update) {
    isConnectionError =
      updateSmtpImapAccountDetailsResponse?.[connectionType]
        ?.isConnectionEstablished;
    connectionErrorMessage =
      updateSmtpImapAccountDetailsResponse?.[connectionType]?.error;
  } else if (smtpImapAccountDetailsResponse && requestFrom === SMTPIMAP.IMAP) {
    isConnectionError = !smtpImapAccountDetailsResponse?.imapError
      ?.isErrorInImap;
    connectionErrorMessage =
      smtpImapAccountDetailsResponse?.imapError?.imapConnectionError;
  } else if (smtpImapAccountDetailsResponse && requestFrom === SMTPIMAP.SMTP) {
    isConnectionError = !smtpImapAccountDetailsResponse?.smtpError
      ?.isErrorInSmtp;
    connectionErrorMessage =
      smtpImapAccountDetailsResponse?.smtpError?.smtpConnectionError;
  }

  return { isConnectionError, connectionErrorMessage };
};

export const getVariantTypeForSmtpImap = ({
  testConnectionResponse,
  connectSmtpImapResponse,
  smtpImapAccountDetailsResponse,
  updateSmtpImapAccountDetailsResponse,
  requestFrom,
  connectionType,
  lastTestConnection,
}) => {
  let variant: StatusBadgeVariant = StatusBadgeVariant.DANGER;
  const { isConnectionError, connectionErrorMessage } = getIsConnectionError(
    requestFrom,
    connectionType,
    testConnectionResponse,
    connectSmtpImapResponse,
    smtpImapAccountDetailsResponse,
    updateSmtpImapAccountDetailsResponse,
    lastTestConnection,
  );

  if (isConnectionError) {
    variant = StatusBadgeVariant.SUCCESS;
  }

  return { variant, connectionErrorMessage };
};

export const getIsButtonIsLoading = (
  isConnectAccountRequestPending,
  isUpdateSmtpImapAccountRequestPending,
) => isConnectAccountRequestPending || isUpdateSmtpImapAccountRequestPending;

export const getIsButtonDisable = (
  isConnectAndSaveButtonDisable,
  isConnectAccountRequestPending,
  isUpdateSmtpImapAccountRequestPending,
  isSenderValid,
  isDisabled,
) =>
  isConnectAndSaveButtonDisable ||
  isConnectAccountRequestPending ||
  isUpdateSmtpImapAccountRequestPending ||
  isSenderValid ||
  isDisabled;

export const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const isSMTPTestButtonDisable = ({
  useUsername,
  username,
  smtpEmailAddress,
  smtpEmailPassword,
  smtpHost,
  smtpPort,
  smtpEncryption,
  senderFirstName,
  emailProvider,
}) => {
  let isUsernameValidate = true;

  if (useUsername) {
    isUsernameValidate = username?.length > 0;
  }

  return (
    isUsernameValidate &&
    smtpEmailAddress?.length > 0 &&
    smtpEmailPassword?.length > 0 &&
    smtpHost?.length > 0 &&
    smtpPort !== null &&
    smtpEncryption?.length > 0 &&
    senderFirstName?.length > 0 &&
    emailProvider !== null
  );
};

export const isIMAPTestButtonDisable = (
  imapEmailAddress,
  imapEmailPassword,
  imapHost,
  imapPort,
  imapEncryption,
) =>
  imapEmailAddress?.length > 0 &&
  imapEmailPassword?.length > 0 &&
  imapHost?.length > 0 &&
  imapPort !== null &&
  imapEncryption?.length > 0;

export const getEmailServiceProvider = (emailProvider, emailAddress) => {
  let provider;

  switch (emailProvider.value) {
    case SmtpImapProviders.GmailGsuite: {
      const domainName = emailAddress.split('@').pop();
      if (freeGmailAccounts.includes(domainName)) {
        provider = EmailServiceProvider.Gmail;
      } else {
        provider = EmailServiceProvider.Gsuite;
      }
      break;
    }
    case SmtpImapProviders.OutlookHotmailLive:
      provider = EmailServiceProvider.Microsoft;
      break;
    case SmtpImapProviders.Office365:
      provider = EmailServiceProvider.O365;
      break;
    case SmtpImapProviders.YahooMail:
      provider = EmailServiceProvider.Yahoo;
      break;
    case SmtpImapProviders.Godaddy:
      provider = EmailServiceProvider.Godaddy;
      break;
    case SmtpImapProviders.Sendgrid:
      provider = EmailServiceProvider.Sendgrid;
      break;
    case SmtpImapProviders.Zoho:
      provider = EmailServiceProvider.Zoho;
      break;
    case SmtpImapProviders.Yandex:
      provider = EmailServiceProvider.Yandex;
      break;
    default:
      provider = EmailServiceProvider.Other;
      break;
  }

  return provider;
};

export const useSameEmailPasswordSwitchDisable = (
  emailAddress,
  emailPassword,
  username = null,
) => {
  const isDisable = emailAddress?.length === 0 || emailPassword?.length === 0;
  if (username) {
    return username?.length === 0 && isDisable;
  }

  return isDisable;
};

export const showTestConnectionSignal = ({
  sendTestConnectionResponse,
  sendConnectSmtpImapResponse,
  sendGetSmtpImapAccountDetailsResponse,
  sendUpdateSmtpImapAccountDetailsResponse,
  badgeVariant,
}) =>
  (sendTestConnectionResponse ||
    sendConnectSmtpImapResponse ||
    sendGetSmtpImapAccountDetailsResponse ||
    sendUpdateSmtpImapAccountDetailsResponse) &&
  badgeVariant === StatusBadgeVariant.DANGER;

export const isUseSameEmailPasswordToggleOn = (
  username,
  smtpEmailAddress,
  smtpEmailPassword,
  imapEmailAddress,
  imapEmailPassword,
) => {
  const isEmailAddressSame =
    smtpEmailAddress === imapEmailAddress || username === imapEmailAddress;
  const isEmailPasswordSame = smtpEmailPassword === imapEmailPassword;

  return isEmailAddressSame && isEmailPasswordSame;
};

export const setHotPortEncryptionValue = ({
  smtpHostValue,
  smtpPortValue,
  smtpEncryptionValue,
  imapHostValue,
  imapPortValue,
  imapEncryptionValue,
  setSMTPHost,
  setSMTPPort,
  setSMTPEncryption,
  setIMAPHost,
  setIMAPPort,
  setIMAPEncryption,
}) => {
  if (smtpHostValue) {
    setSMTPHost();
  }
  if (smtpPortValue) {
    setSMTPPort();
  }
  if (smtpEncryptionValue) {
    setSMTPEncryption();
  }
  if (imapHostValue) {
    setIMAPHost();
  }
  if (imapPortValue) {
    setIMAPPort();
  }
  if (imapEncryptionValue) {
    setIMAPEncryption();
  }
};

export const isConnectButtonDisable = (isSMTPTestDisable, isIMAPTestDisabled) =>
  isSMTPTestDisable || isIMAPTestDisabled;

export const checkEmailValidationBeforeTesting = ({
  isSmtpEmailValid,
  isImapEmailValid,
  requestFrom,
  isSMTPEmailNotValid,
  isIMAPEmailNotValid,
  onEmailIsValid,
}) => {
  if (!isSmtpEmailValid && requestFrom === SMTPIMAP.SMTP) {
    isSMTPEmailNotValid();
  } else if (!isImapEmailValid && requestFrom === SMTPIMAP.IMAP) {
    isIMAPEmailNotValid();
  } else {
    onEmailIsValid();
  }
};

export const checkEmailValidationBeforeConnecting = ({
  isSmtpEmailValid,
  isImapEmailValid,
  isSMTPEmailNotValid,
  isIMAPEmailNotValid,
  onEmailIsValid,
}) => {
  if (!isSmtpEmailValid) {
    isSMTPEmailNotValid();
  } else if (!isImapEmailValid) {
    isIMAPEmailNotValid();
  } else {
    onEmailIsValid();
  }
};

export const getInitialFiltersForEmailAccounts = (): EmailAccountsFilters => {
  const query = new URLSearchParams(history.location.search);

  const queriedPage = query.get('page');
  const page =
    queriedPage && !isNaN(Number(queriedPage)) ? parseInt(queriedPage, 10) : 1;

  const queriedOrder = query.get('order');
  const order =
    Number(queriedOrder) === Order.Asc || Number(queriedOrder) === Order.Desc
      ? Number(queriedOrder)
      : Order.Desc;

  const search = query.get('search') || '';

  const queriedSortByKey = query.get('sortByKey');
  const sortByKey = (Object.values(EmailAccountsSortBy) as string[]).includes(
    queriedSortByKey,
  )
    ? (queriedSortByKey as EmailAccountsSortBy)
    : EmailAccountsSortBy.SetupScore;
  const clientIds = query.get('clients');

  return {
    page,
    limit: 25,
    order,
    search,
    sortByKey,
    clientIds: clientIds
      ? clientIds.split(',').map((id) => parseInt(id, 10))
      : [],
    tagIds: [],
    sequenceIds: [],
    status: [],
    owners: [],
    emailServiceProvider: [],
  };
};

export const setURLParamsForEmailAccountsFilters = (
  filters: EmailAccountsFilters,
) => {
  const query = new URLSearchParams();

  query.set('page', filters.page.toString());
  query.set('order', filters.order.toString());
  query.set('sortByKey', filters.sortByKey);
  query.set('search', filters.search);
  query.set('clientIds', filters.clientIds.toString());
  query.set('tagIds', filters.tagIds.toString());
  query.set('sequenceIds', filters.sequenceIds.toString());
  query.set('status', filters.status.toString());
  query.set('owners', filters.owners.toString());
  query.set('emailServiceProvider', filters.emailServiceProvider.toString());

  history.push({
    search: query.toString(),
  });
};

const emailAccountSearchQuery = 'email-account-search-query';

export const getEmailAccountsSearchQuery = () =>
  localStorage.getItem(emailAccountSearchQuery) || '';

export const setEmailAccountsSearchQuery = (query: string) =>
  localStorage.setItem(emailAccountSearchQuery, query);

export const removeEmailAccountsSearchQuery = () =>
  localStorage.removeItem(emailAccountSearchQuery);
