import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../../../shared/types';
import {
  getCustomFields,
  getPropsectsOverview,
} from '../../../../../settings/helpers/field.api';
import { sendTestEmail } from './helpers/test-email.api';
import { ProspectOverviewParams, SendTestEmailPayload } from './types/request-payload';

export const sendTestEmailRequest = createAsyncThunk<
  ResponseSuccess,
  SendTestEmailPayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/sendTestEmailRequest', async (args, thunkAPI) => {
  try {
    return await sendTestEmail(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getFieldsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getFieldsRequest', async (args, thunkAPI) => {
  try {
    return await getCustomFields();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getPropsectsOverviewRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectOverviewParams,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/getPropsectsOverviewRequest',
  async ({ sequenceId, ...params }, thunkAPI) => {
    try {
      return await getPropsectsOverview(sequenceId, params);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
