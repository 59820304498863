import { Permissions } from '../../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../../shared/utils/access-control/has-permission';
import { isAgency } from '../../../../../shared/utils/user-details';
import { Field } from '../../../../settings/types/field';
import { ProspectSortBy } from '../../../enums/prospect';
import { ProspectAttribute } from '../types';

const prospectSystemAttributes: ProspectAttribute[] = [
  {
    id: 'name',
    label: 'Name',
    isDefault: true,
    isNonRemovable: true,
    sortBy: ProspectSortBy.FirstName,
    isEditable: false,
  },
  {
    id: 'email',
    label: 'Email',
    isDefault: true,
    isNonRemovable: true,
    isEditable: false,
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
    isDefault: true,
    isEditable: true,
  },
  {
    id: 'prospectStatus',
    label: 'Latest Status',
    isDefault: true,
    isEditable: false,
  },
  {
    id: 'sequenceAssociated',
    label: 'Sequence Associated',
    isDefault: true,
    isEditable: false,
  },
  {
    id: 'prospectOutcomes',
    label: 'Prospect Outcomes',
    isDefault: true,
    isEditable: false,
  },
  {
    id: 'prospectTags',
    label: 'Prospect Tags',
    isDefault: true,
    isEditable: false,
  },
  {
    id: 'verificationStatus',
    label: 'Email Verification Status',
    isDefault: true,
    isEditable: false,
  },
  {
    id: 'emailServiceProvider',
    label: 'Prospect ESP',
    isDefault: false,
    isEditable: false,
  },
  {
    id: 'lastContacted',
    label: 'Last Contacted',
    isDefault: true,
    isEditable: false,
  },
  {
    id: 'department',
    label: 'Department',
    isDefault: true,
    isEditable: true,
  },
  {
    id: 'company',
    label: 'Company',
    isDefault: true,
    isEditable: true,
  },
  {
    id: 'companyIndustry',
    label: 'Company Industry',
    isDefault: true,
    isEditable: true,
  },
  {
    id: 'companyWebsite',
    label: 'Company Website',
    isDefault: true,
    isEditable: true,
  },
  {
    id: 'prospectOwner',
    label: 'Prospect Owner',
    isDefault: true,
    isEditable: false,
  },
  {
    id: 'creationDate',
    label: 'Creation Date',
    isEditable: false,
  },
  {
    id: 'lastUpdated',
    label: 'Last Updated',
    isEditable: false,
  },
  {
    id: 'verificationSubStatus',
    label: 'Email Verification Sub Status',
    isEditable: false,
  },
  {
    id: 'freeEmailAccount',
    label: 'Free Email Account',
    isEditable: false,
  },
  {
    id: 'mxRecord',
    label: 'MX Record',
    isEditable: false,
  },
  {
    id: 'creationSource',
    label: 'Creation Source',
    isEditable: false,
  },
  {
    id: 'numberOfEmailsSent',
    label: 'Number of Emails sent',
    isEditable: false,
  },
  {
    id: 'clientAssociated',
    label: 'Client Associated',
    isEditable: false,
  },
  {
    id: 'linkedIn',
    label: 'LinkedIn',
    isEditable: true,
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    isEditable: true,
  },
  {
    id: 'experience',
    label: 'Experience',
    isEditable: true,
  },
  {
    id: 'twitter',
    label: 'Twitter',
    isEditable: true,
  },
  {
    id: 'facebook',
    label: 'Facebook',
    isEditable: true,
  },
  {
    id: 'city',
    label: 'City',
    isEditable: true,
  },
  {
    id: 'state',
    label: 'State',
    isEditable: true,
  },
  {
    id: 'country',
    label: 'Country',
    isEditable: true,
  },
  {
    id: 'companyLinkedIn',
    label: 'Company LinkedIn',
    isEditable: true,
  },
  {
    id: 'companyPhone',
    label: 'Company Phone',
    isEditable: true,
  },
  {
    id: 'companyDomain',
    label: 'Company Domain',
    isEditable: true,
  },
  {
    id: 'companySize',
    label: 'Company Size',
    isEditable: true,
  },
  {
    id: 'companyRevenue',
    label: 'Company Revenue',
    isEditable: true,
  },
  {
    id: 'companyFoundedYear',
    label: 'Company Founded Year',
    isEditable: true,
  },
  {
    id: 'numberOfOpens',
    label: 'Number of Opens',
    isDefault: true,
    isEditable: false,
  },
  {
    id: 'numberOfClicks',
    label: 'Number of Clicks',
    isDefault: true,
    isEditable: false,
  },
  {
    id: 'recentlyOpenDate',
    label: 'Recently open date',
    isEditable: false,
  },
  {
    id: 'recentlyClickDate',
    label: 'Recently click date',
    isEditable: false,
  },
  {
    id: 'recentlyReplyDate',
    label: 'Recently reply date',
    isEditable: false,
  },
];

const skipSystemAttributes = [
  'name',
  'email',
  'prospectStatus',
  'sequenceAssociated',
  'prospectOutcomes',
  'prospectTags',
  'lastContacted',
  'prospectOwner',
  'creationDate',
  'lastUpdated',
  'verificationStatus',
  'verificationSubStatus',
  'mxRecord',
  'freeEmailAccount',
  'emailServiceProvider',
  'creationSource',
  'numberOfEmailsSent',
  'clientAssociated',
  'numberOfOpens',
  'numberOfClicks',
  'recentlyOpenDate',
  'recentlyClickDate',
  'recentlyReplyDate',
];

export const getProspectSystemAttributes = (fields: Field[]) =>
  prospectSystemAttributes
    .map((attribute) => {
      // Check if the field label exists in skipSystemAttributes array
      if (skipSystemAttributes.includes(attribute.id)) {
        // Check permission for client associated column
        if (attribute.id === 'clientAssociated') {
          const isClientAssociatedVisible =
            hasPermission(Permissions.CLIENT_READ) && isAgency();

          if (isClientAssociatedVisible) {
            return attribute;
          }

          return null;
        }

        return attribute;
      }

      // Check if the field is an API field and update the id based on fields
      const fieldFromApi = fields.find(
        (field) => field.label === attribute.label,
      );

      if (fieldFromApi) {
        return {
          ...attribute,
          id: fieldFromApi.id.toString(),
          fieldType: fieldFromApi.fieldType,
          isEditable: attribute.isEditable,
        };
      }

      return attribute;
    })
    .filter((item) => item !== null);
