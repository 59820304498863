export const setToken = (token: string) => localStorage.setItem('token', token);
export const getToken = () => localStorage.getItem('token');
export const removeToken = () => localStorage.removeItem('token');

let sessionExpireHandler: () => void = null;

export const setSessionExpireHandler = (handler: () => void) => {
  sessionExpireHandler = handler;
};

export const triggerSessionExpiration = () => {
  sessionExpireHandler?.();
};
