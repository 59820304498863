import React, { useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { useLocation } from 'react-router';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  AuthHelper,
  executeOnRequestStatus,
  initializeSentryIntegrations,
  initializeThirdPartyIntegrations,
  redirectWithoutRefresh,
} from '../../../../shared/utils';

import { IProps } from './types';
import { getFromLocalStorage, LOCAL_STORAGE_KEYS, removeFromLocalStorage, saveToLocalStorage } from '../../../email-accounts/helpers/email-accounts-infra-local-storage';
import { SSOCallbackRequestPayload } from '../../../settings/types/request-payload';
import history from '../../../../shared/history';
import { openChatSupport } from '../../../../shared/utils/open-chat-support';
import toaster, { Theme } from '../../../../shared/toaster';

const SSOCallback: React.FC<IProps> = ({
  agencyConfig,

  ssoCallbackRequestStatus,
  ssoCallbackRequestError,
  token,
  trackingId,
  firstName,
  lastName,
  email,
  ssoWhitelabelDomain,

  sendSSOCallbackRequest,
}) => {
  const location = useLocation();

  useEffect(() => {
    // Extract the saml parameter from URL
    const searchParams = new URLSearchParams(location.search);
    const accessCode = searchParams.get('saml_access_code');
    const whiteLabelRedirect = searchParams.get('whiteLabelRedirect');
    const WLToken = searchParams.get('token');
    const WLTrackingId = searchParams.get('trackingId');
    const WLFirstName = searchParams.get('firstName');
    const WLLastName = searchParams.get('lastName');
    const WLEmail = searchParams.get('email');
    const WLError = searchParams.get('error');
    const newSSOEmail = getFromLocalStorage('new-sso-email');
    const isEmailChangeRequest = getFromLocalStorage(
      LOCAL_STORAGE_KEYS.EMAIL_CHANGE_FOR_SSO_USER_IN_PROGRESS,
    );
    const isNewSSOEmailVarificationRequest = getFromLocalStorage(
      LOCAL_STORAGE_KEYS.NEW_SSO_EMAIL_VARIFICATION_IN_PROGRESS,
    );
    const storedSSOWhitelabelDomain = window.sessionStorage.getItem('sso-whitelabel-domain');

    if (storedSSOWhitelabelDomain !== null && storedSSOWhitelabelDomain !== window.location.hostname && accessCode) {
      const whiteLabelRedirectURL = `http://${storedSSOWhitelabelDomain}/sso-callback?saml_access_code=${accessCode}`;
      window.open(whiteLabelRedirectURL, '_self');
      return;
    }

    if (whiteLabelRedirect) {
      if (WLError && !AuthHelper.isAuthenticated) {
        history.push('/sso-login');
        toaster.error(WLError,{
          theme: Theme.New,
          delay: 3000
        })
        return;
      }

      // for sso email change verification
      if (
        AuthHelper.isAuthenticated &&
        getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL_CHANGE_FOR_SSO_USER_IN_PROGRESS)
      ) {
        history.push({
          pathname: '/settings/profile',
          state: {
            emailChangeInProgress: true,
          },
        });
        removeFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL_CHANGE_FOR_SSO_USER_IN_PROGRESS);
        return;
      }

      // for changed sso email varification
      if (
        AuthHelper.isAuthenticated &&
        getFromLocalStorage(LOCAL_STORAGE_KEYS.NEW_SSO_EMAIL_VARIFICATION_IN_PROGRESS)
      ) {
        history.push({
          pathname: '/settings/profile',
          state: {
            newSSOEmailVarificationInProgress: true,
          },
        });
        removeFromLocalStorage(LOCAL_STORAGE_KEYS.NEW_SSO_EMAIL_VARIFICATION_IN_PROGRESS);
        return;
      }

      if (WLToken) {
        AuthHelper.login({ token: WLToken });
        initializeThirdPartyIntegrations({
          trackingId: WLTrackingId,
          showChatSupport: agencyConfig?.showChatSupport,
          firstName: WLFirstName,
          lastName: WLLastName,
          email: WLEmail,
        });
        initializeSentryIntegrations(WLEmail);
      }
      return;
    }

    if (!accessCode && AuthHelper.isAuthenticated) {
      return redirectWithoutRefresh('/');
    }

    const payload: SSOCallbackRequestPayload = {
      accessCode,
    };

    if (isEmailChangeRequest) {
      payload.isEmailChangeRequest = true;
    }
    if (isNewSSOEmailVarificationRequest) {
      payload.isEmailChangeRequest2 = true;
      payload.newEmail = newSSOEmail;
    }
    if (accessCode) {
      sendSSOCallbackRequest(payload);
    }

    return undefined;
  }, [location.search, sendSSOCallbackRequest]);

  useEffect(() => {
    executeOnRequestStatus({
      status: ssoCallbackRequestStatus,
      onSuccess: () => {
        if (ssoWhitelabelDomain && window.location.hostname !== ssoWhitelabelDomain) {
          const whiteLabelRedirectURL = `http://${ssoWhitelabelDomain}/sso-callback?whiteLabelRedirect=true&token=${token || ''}&trackingId=${trackingId || ''}&firstName=${firstName || ''}&lastName=${lastName || ''}&email=${email || ''}`;
          window.sessionStorage.setItem('sso-whitelabel-domain', ssoWhitelabelDomain);
          window.open(whiteLabelRedirectURL, '_self');
        } 
        // for sso email change verification
        if (getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL_CHANGE_FOR_SSO_USER_IN_PROGRESS)) {
          history.push({
            pathname: '/settings/profile',
            state: {
              emailChangeInProgress: true,
            },
          });
          removeFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL_CHANGE_FOR_SSO_USER_IN_PROGRESS);
        }

        // for changed sso email varification
        if (getFromLocalStorage(LOCAL_STORAGE_KEYS.NEW_SSO_EMAIL_VARIFICATION_IN_PROGRESS)) {
          history.push({
            pathname: '/settings/profile',
            state: {
              newSSOEmailVarificationInProgress: true,
            },
          });
          removeFromLocalStorage(LOCAL_STORAGE_KEYS.NEW_SSO_EMAIL_VARIFICATION_IN_PROGRESS);
        }

        // for login
        if (token) {
          AuthHelper.login({ token });

          initializeThirdPartyIntegrations({
            trackingId,
            showChatSupport: agencyConfig?.showChatSupport,
            firstName,
            lastName,
            email,
          });
        }
        initializeSentryIntegrations(email);
        
      },
      onFailed: () => {
        if (getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL_CHANGE_FOR_SSO_USER_IN_PROGRESS)) {
          removeFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL_CHANGE_FOR_SSO_USER_IN_PROGRESS);
        }
        if (getFromLocalStorage(LOCAL_STORAGE_KEYS.NEW_SSO_EMAIL_VARIFICATION_IN_PROGRESS)) {
          removeFromLocalStorage(LOCAL_STORAGE_KEYS.NEW_SSO_EMAIL_VARIFICATION_IN_PROGRESS);
        }
        if (ssoWhitelabelDomain) {
          const whiteLabelRedirectURL = `https://${ssoWhitelabelDomain}/sso-callback?whiteLabelRedirect=true&error=${ssoCallbackRequestError?.message}`;

          window.open(whiteLabelRedirectURL, '_self');
        } else {
          if (ssoCallbackRequestError?.message?.includes('support')) {
            toaster.error(
              <div>
                Your account is suspended, to revoke this suspension feel free to
                reach out to{' '}
                <button
                  type="button"
                  onClick={() => openChatSupport()}
                  className="font-bold red-txt-13 underline bg-transparent border-0 p-0 cursor-pointer"
                >
                  <u>support</u>
                </button>
              </div>,
              {
                theme: Theme.New,
                showCloseIcon: true,
                delay: 1000000,
              },
            );
          }
          history.push('/sso-login');
        }
      },
    });
  }, [ssoCallbackRequestStatus]);

  return (
    <>
      <div className="auth-wrapper w-100 d-flex">
        <div className="auth-container">
          <div className="auth-form">
            <div className="w-100 h-100 text-center mt-5 sso-login-spinner">
              {ssoCallbackRequestStatus === RequestStatus.Failed ? (
                <div className="error-message">
                  {ssoCallbackRequestError?.message ||
                    'An error occurred during SSO login. Please try again.'}
                </div>
              ) : (
                <>
                  <Spinner className="spinner--blue" animation="border" />
                  <p className="sso-login-spinner-text">
                    Just few seconds more...
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SSOCallback;
