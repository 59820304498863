import history from '../../../shared/history';
import { LeadFinderTabKey } from '../type';
import { ContactVerificationStatusTypeDisplayName } from '../../sequence/enums';
import { LEAD_EMAIL_EXCLUDED_DOMAINS } from '../constants/lead-email-excluded-domains';
import { constants } from '../../../shared/enums/constants';

/* eslint-disable */
export const getLeadsActiveTab = (location) => {
  let key = LeadFinderTabKey.PEOPLE_TAB;
  if (location?.hash) {
    const locationHash = location?.hash.replace(/^#/, '');
    key = Object.values(LeadFinderTabKey).includes(locationHash)
      ? locationHash
      : LeadFinderTabKey.PEOPLE_TAB;
  }
  return key;
};

export const validateNumberForQueryParams = (
  value: string,
  fallbackValue: number = 1,
) =>
  isNaN(Number(value)) || Number(value) < 1 ? fallbackValue : Number(value);

export const deepClone = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getExceptFilterValues = (keys, list) => {
  const values = keys.filter((value) => {
    return !list.some(
      (item) =>
        item.key.toLowerCase() === value.toLowerCase() ||
        (item.children &&
          item.children.some(
            (childItem) => childItem.key.toLowerCase() === value.toLowerCase(),
          )),
    );
  });

  return values;
};

export const getExceptFilterKeys = (values, list) => {
  const keys = values.filter((value) => {
    return !list.some(
      (item) =>
        item.value.toLowerCase() === value.toLowerCase() ||
        (item.children &&
          item.children.some(
            (childItem) =>
              childItem.value.toLowerCase() === value.toLowerCase(),
          )),
    );
  });

  return keys;
};

export const getFilterValues = (keys: string[], list) => {
  const values = [];

  if (!keys?.length || !list?.length) return values;

  keys?.forEach((key: string) => {
    for (const continent of list) {
      if (typeof key === 'string') {
        if (continent?.key?.toLowerCase() === key?.toLowerCase()) {
          values.push(continent?.value || continent?.label);
          break;
        }
        if (continent?.children?.length > 0) {
          for (const child of continent.children) {
            if (child.key.toLowerCase() === key.toLowerCase()) {
              values.push(child.value || child?.label);
              break;
            }
          }
        }
      }
    }
  });

  return values;
};

export const getFilterValuesWithExtraValues = (
  keys,
  list,
  locationData?: boolean,
) => {
  const values = getFilterValues(keys, list);
  const extraValues = getExceptFilterValues(keys, list);
  const parentKeys = keys?.filter((key) => key?.length === 2);

  const queryParam = new URLSearchParams(history?.location?.search);
  const locationFilterKey =
    queryParam.get('location') || queryParam.get('excludeLocation');

  const exactMatch = (locationFilterKey?.match(/\"/g) || [])?.length === 2;

  let data = [...values, ...extraValues];

  if (exactMatch && locationData) {
    const result = [];
    keys?.map((key) => {
      if (parentKeys?.length) {
        parentKeys?.filter((parentKey) => {
          if (!key?.substring(0, 2).includes(parentKey)) {
            if (!result?.includes(key)) {
              result?.push(key);
            }
          }
        });
      } else {
        if (!result?.includes(key)) {
          result?.push(key);
        }
      }
    });
    let getValueForResultData = getFilterValues(result, list);

    data = data?.map((value) => {
      if (getValueForResultData?.includes(value)) {
        return `"${value}"`;
      } else {
        return value;
      }
    });
  }

  // final data return
  return data;
};

export const getFilterKeys = (Values, list) => {
  const Keys = [];

  Values.forEach((value: any) => {
    for (const continent of list) {
      if (continent.value.toLowerCase() === value.toLowerCase()) {
        Keys.push(continent.key);
        break;
      }
      if (continent?.children?.length > 0) {
        for (const country of continent.children) {
          if (country.value.toLowerCase() === value.toLowerCase()) {
            Keys.push(country.key);
            break;
          }
        }
      }
    }
  });

  return Keys;
};

export const getFilterKeysWithExtraKeys = (keys, list) => {
  const values = getFilterKeys(keys, list);
  const extraValues = getExceptFilterKeys(keys, list);
  return [...values, ...extraValues];
};

export const findParentKey = (data, targetKey) => {
  for (const continent of data) {
    if (continent?.children?.length > 0) {
      const childIndex = continent?.children?.findIndex(
        (child) => child?.key?.toLowerCase() === targetKey?.toLowerCase(),
      );
      if (childIndex !== -1) {
        return continent?.key;
      }
    }
  }
  return null; // Return null if the targetKey is not found in any children array
};

export const getTimeAgoString = (timestamp) => {
  var timestampDate: any = new Date(timestamp);

  var currentDate: any = new Date();

  var timeDifference = currentDate - timestampDate;

  var seconds = Math.floor(timeDifference / 1000); // Convert milliseconds to seconds
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);

  if (seconds < 60) {
    return 'Just Now';
  } else if (minutes < 60) {
    return minutes + ' minutes ago';
  } else if (hours < 24) {
    return hours + ' hours ago';
  } else {
    return days + ' days ago';
  }
};

export const capitalizeFirstLetter = (string = '') => {
  if (string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }
};
export const mapObjectToString = (obj) => {
  let mappedString = '';

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      let formattedValue;

      if (Array.isArray(obj[key])) {
        formattedValue =
          obj[key].length > 1
            ? `${obj[key][0]}+${obj[key].length - 1}`
            : obj[key][0];
      } else {
        formattedValue = obj[key];
      }

      mappedString += `${formattedValue}, `;
    }
  }

  // Remove the trailing comma and space
  mappedString = mappedString.slice(0, -2);

  return mappedString;
};

export const showingLengthPerPageOptions = [
  {
    key: '25',
    value: 25,
  },
  {
    key: '50',
    value: 50,
  },
  {
    key: '100',
    value: 100,
  },
];

export const camelCaseAndRemoveSpecialChars = (string: string) => {
  // Remove special characters
  const cleanedString = string.replace(/[^a-zA-Z0-9]/g, '');

  // Convert to camel case
  const words = cleanedString.split(' ');
  const camelCaseString =
    words[0].toLowerCase() +
    words
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join('');

  return camelCaseString;
};

export const isLeadFinderURL = () => window.location.pathname.includes('leads');

export const gradeTooltips: { [key: string]: string } = {
  A: ContactVerificationStatusTypeDisplayName.Valid,
  B: ContactVerificationStatusTypeDisplayName.Risky,
  'A-': ContactVerificationStatusTypeDisplayName.Valid,
};

export const gradeClassNames: { [key: string]: string } = {
  A: 'valid-email',
  B: 'risky-email',
  'A-': 'valid-email',
};

export const getCircleCheckTooltip = (grade: string): string => {
  return (
    gradeTooltips[grade] || ContactVerificationStatusTypeDisplayName.Unverified
  );
};

export const getCircleCheckIconClassName = (grade: string) => {
  return gradeClassNames[grade] || 'unverified-email';
};

export const getLeadListingPageSize = () => {
  try {
    const savedPageSize = localStorage.getItem('leadListingPageSize');
    return savedPageSize
      ? Number(savedPageSize)
      : constants.LEAD_FINDER_DEFAULT_PAGE_SIZE;
  } catch (error) {
    console.log(
      'Error getting lead listing page size from local storage',
      error,
    );
    return constants.LEAD_FINDER_DEFAULT_PAGE_SIZE;
  }
};

export const setLeadListingPageSize = (pageSize: number) => {
  try {
    localStorage.setItem('leadListingPageSize', pageSize.toString());
  } catch (error) {
    console.log('Error setting lead listing page size in local storage', error);
  }
};

export const filterOutExcludedAndDuplicateDomains = (domains: string[]) => {
  const filteredDomains = [];

  if (!domains?.length) return filteredDomains;
  domains.forEach((domain) => {
    if (
      !LEAD_EMAIL_EXCLUDED_DOMAINS.includes(domain) &&
      !filteredDomains.includes(domain)
    ) {
      filteredDomains.push(domain);
    }
  });
  return filteredDomains;
};

export const getDomainsFromEmails = (
  emailsData: any[],
  employerDomain?: string,
) => {
  const domains = [];
  if (employerDomain) {
    domains.push(employerDomain);
  }
  emailsData?.forEach((emailData) => {
    const domain = emailData?.email?.split('@')[1];
    if (!domain) return;

    domains.push(domain);
  });
  return filterOutExcludedAndDuplicateDomains(domains);
};
