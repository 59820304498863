import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';
import SSOLogin from './sso-login';
import { RouteComponentProps, withRouter } from 'react-router';

const mapStateToProps = (state: RootState) => ({
  agencyConfig: state.home.agencyConfig,
  agencyConfigRequestStatus: state.home.getAgencyConfigRequest.status,
});

const connector = connect(mapStateToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(SSOLogin));
