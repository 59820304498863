import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RootState } from '../../../../../../store/root-reducer';
import {
  exportUsersRequest,
  getTeamsRequest,
  getUsersRequest,
} from '../../extra-actions';
import {
  resetExportUserRequest,
  resetGetTeamsRequest,
  resetGetUsersRequest,
} from '../../users-and-teams-slice';
import { DatePickerFilter, TeamsFilter, UsersFilter } from '../../../../types/users-and-teams';
import UsersAndTeamsContent from './users-and-teams-content';
import { resetConnectedUsersAndEmailAccountsRequest } from '../../../billing-subscription/billing-subscription-slice';
import { getConnectedUsersAndEmailAccountsRequest } from '../../../billing-subscription/extra-actions';

const mapStateToProps = (state: RootState) => ({
  getUsersRequestStatus: state.usersAndTeams.getUsersRequest.status,
  getUsersRequestError: state.usersAndTeams.getUsersRequest.error,
  getUsersRequestMessage: state.usersAndTeams.getUsersRequest.message,

  getTeamsRequestStatus: state.usersAndTeams.getTeamsRequest.status,
  getTeamsRequestError: state.usersAndTeams.getTeamsRequest.error,
  getTeamsRequestMessage: state.usersAndTeams.getTeamsRequest.message,

  users: state.usersAndTeams.users,
  usersPaginationOptions: state.usersAndTeams.usersPaginationOptions,

  teams: state.usersAndTeams.teams,
  timeZone: state.myProfile.myProfile.timeZone,

  planType: state.home.subscription?.planType,
  planCode: state.home.subscription?.planCode,
  slots: state.home.subscription?.slots,
  totalUsers:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsResponse
      ?.totalUsers,

  adminFirstName: state.home?.adminDetails?.firstName || '',
  userName: `${state.home.firstName} ${state.home.lastName}`.trim(),

  getConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,
  getConnectedUsersAndEmailAccountsRequestError:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest.error,
  trackingId: state.home.trackingId,
  planName: state.home.subscription?.planName,

  exportUserRequestStatus: state.usersAndTeams.exportUserRequest.status,
});

const mapDispatchToProps = {
  sendGetUsersRequest: (filters: UsersFilter) => getUsersRequest(filters),
  resetGetUsersRequest: () => resetGetUsersRequest(),

  sendGetTeamsRequest: (filters: TeamsFilter) => getTeamsRequest(filters),
  resetGetTeamsRequest: () => resetGetTeamsRequest(),

  sendGetConnectedUsersAndEmailAccountsRequest: () =>
    getConnectedUsersAndEmailAccountsRequest(),
  resetConnectedUsersAndEmailAccountsRequest: () =>
    resetConnectedUsersAndEmailAccountsRequest(),

  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),

  sendExportUsersRequest: (dateFilters: DatePickerFilter) => exportUsersRequest(dateFilters),
  resetExportUserRequest: () => resetExportUserRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(UsersAndTeamsContent);
