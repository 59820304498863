import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import OAuthAuthenticateCallback from './oauth-authenticate-callback';
import { oauthAuthenticateRequest } from '../oauth-buttons/extra-actions';
import { OAuthMethod } from '../oauth-buttons';
import { getAgencyConfigRequest } from '../../../home/extra-actions';
import { deleteMyProfileRequest } from '../../../settings/components/my-profile/extra-actions';
import { resetDeleteMyProfileRequest } from '../../../settings/components/my-profile/my-profile-slice';

const mapStateToProps = (state: RootState) => ({
  status: state.oauth.oauthAuthenticateRequest.status,
  message: state.oauth.oauthAuthenticateRequest.message,
  error: state.oauth.oauthAuthenticateRequest.error,

  token: state.oauth.token,
  trackingId: state.oauth.trackingId,
  firstName: state.oauth.firstName,
  lastName: state.oauth.lastName,
  email: state.oauth.email,
  action: state.oauth.action,
  agencyConfig: state.home.agencyConfig,

  deleteMyProfileRequestStatus: state.myProfile.deleteMyProfileRequest.status,
  deleteMyProfileRequestMessage: state.myProfile.deleteMyProfileRequest.message,
  deleteMyProfileRequestError: state.myProfile.deleteMyProfileRequest.error,

  getAgencyConfigRequestStatus: state.home.getAgencyConfigRequest.status,
});

const mapDispatchToProps = {
  sendOAuthAuthenticateRequest: (
    method: OAuthMethod,
    code: string,
    state?: string,
  ) => oauthAuthenticateRequest({ method, code, state }),
  sendGetAgencyConfigRequest: (agencyConfigPayload: {
    baseUrl: string;
    email?: string;
  }) => getAgencyConfigRequest(agencyConfigPayload),
  sendDeleteMyProfileRequest: (password: string) =>
    deleteMyProfileRequest(password),
  resetDeleteMyProfileRequest: () => resetDeleteMyProfileRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ method: string }>;

export default connector(OAuthAuthenticateCallback);
