/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router';
import { ImageIcon, toaster } from '@saleshandy/design-system';
import StripeCheckout from 'react-stripe-checkout';
import type { IProps } from './lead-finder-plans-container';
import BillingSummaryModal from './components/billing-summary-modal';
import { executeOnRequestStatus } from '../../../../../../shared/utils/execute-on-request-status';
import { getIsLeadFinderUserSubscribed } from '../../utils/helper';
import PlansSection from './components/plans-section';
import { BillingOption } from '../../../../../../shared/utils/billing-options';
import { getDisabledLabel } from '../modify-subscription/utils/helper';
import { Images } from '../../../../../../shared/app-constants';
import { Plan } from '../../types';
import {
  LeadFinderEmailOnlySubscriptionPlans,
  LeadFinderEmailPhoneSubscriptionPlans,
  SubscriptionPlans,
  SubscriptionPlanTitle,
} from '../../../../../../shared/utils/subscription-plans';
import {
  getIsRequestPending,
  redirectWithoutRefresh,
  redirectWithRefresh,
} from '../../../../../../shared/utils';
import { showToastMessage } from '../../../../../../shared/design-system/components/molecules/upgrade-plan-billing-section/helper/helper';
import {
  getPlanIndex,
  getPlanPeriod,
  getSubscribeAction,
  UpgradePlanQueryParamsKey,
  isValueBasedPlan,
  isPremiumFeatureBannerShow,
} from './utils/helper';
import { getPlanId } from '../upgrade-plan/utils/helper';
import { openChatSupport } from '../../../../../../shared/utils/open-chat-support';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import { Routes } from '../../../../../../shared/routes';
import PlanCycleSwitch from '../../../../../../shared/design-system/components/molecules/plan-cycle-switch';
import { getLeadFinderPlanMetaData } from './components/lead-finder-plan-card/utils/helper';
import CreditSlider from './components/credit-slider/credit-slider';
import Button from '../../../../../../shared/design-system/ui/button';
import { GetLeadFinderPlanResponse } from '../../types/response/get-lead-finder-credit-plans';
import { handle3dSecureCardPayment } from '../../../../../../shared/components/handle-3d-secure-card-payment/handle3dSecureCardPayment';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import UpgradePlanStatic from '../upgrade-plan-static/upgrade-plan-static';

const LeadFinderPlans: React.FC<IProps> = ({
  //* API Request State
  getCouponCodeResponse,
  sendGetLeadFinderSubscriptionRequestStatus,
  sendGetLeadFinderSubscriptionResponse,
  sendGetLeadFinderPlansResponse,
  sendPurchaseLeadFinderSubscriptionRequestStatus,
  sendPurchaseLeadFinderSubscriptionRequestMessage,
  sendPurchaseLeadFinderModifySubscriptionRequestStatus,
  sendPurchaseLeadFinderModifySubscriptionRequestMessage,
  leadFinderSubscriptionPlanResponse,
  getLeadFinderOneTimePlansRequestStatus,
  getLeadFinderOneTimePlansResponse,
  sendPurchaseLeadFinderOneTimePlanRequestStatus,
  sendPurchaseLeadFinderOneTimePlanRequestMessage,
  sendPurchaseLeadFinderOneTimePlanResponse,
  //* API Request
  sendPurchaseLeadFinderSubscriptionRequest,
  sendPurchaseLeadFinderModifySubscriptionRequest,
  sendGetCalculatePayRequest,
  sendGetLeadFinderSubscriptionRequest,
  sendGetUserSettingsRequest,
  resetSubscription,
  sendGetLeadFinderOneTimePlansRequest,
  sendPurchaseLeadFinderOneTimePlanRequest,
  resetPurchaseLeadFinderOneTimePlan,
}) => {
  const location = useLocation();
  const history = useHistory();

  //* States
  const [planCycle, setPlanCycle] = useState<BillingOption>(
    BillingOption.ANNUALLY,
  );
  const [
    isBillingSummaryModalShow,
    setIsBillingSummaryModalShow,
  ] = useState<boolean>(false);
  const [isUserModifyingSubscription, setIsModifySubscription] = useState(
    false,
  );
  const [selectedPlanDetails, setSelectedPlanDetails] = useState<Plan>(null);
  const [isEmailPhonePlan, setIsEmailPhonePlan] = useState<boolean>(false);

  const [creditSliderSelectedValue, setCreditSliderSelectedValue] = useState<
    number[]
  >([1]);
  const [
    selectedLFCreditPlan,
    setSelectedLFCreditPlan,
  ] = useState<GetLeadFinderPlanResponse>(null);

  const [
    is3dSecureResponseStatePending,
    setIs3dSecureResponseStatePending,
  ] = useState<boolean>(false);

  const showBillingSummaryModal = () => setIsBillingSummaryModalShow(true);
  const hideBillingSummaryModal = () => {
    setIsBillingSummaryModalShow(false);
    resetQueryParams();
  };

  const handelBillingOption = (value) => setPlanCycle(value);

  const onSelectPlan = (planId: number, planName: string) => {
    const { planCode } = sendGetLeadFinderSubscriptionResponse;

    if (planName === SubscriptionPlanTitle.Custom) {
      openChatSupport();
      return;
    }

    const selectedPlan = sendGetLeadFinderPlansResponse.find(
      ({ id }) => id === planId,
    );
    setSelectedPlanDetails(selectedPlan);

    if (getIsLeadFinderUserSubscribed(planCode)) {
      sendGetCalculatePayRequest({
        accountSubscriptionPlanId: planId,
        slots: 1,
      });
    }

    showBillingSummaryModal();
    updateQueryParams(planId);
  };

  const onPurchase = (token, couponCode) => {
    if (!isUserModifyingSubscription) {
      const payload = {
        slots: 1,
        planId: Number(selectedPlanDetails?.id),
        ...(token && { stripeCardToken: token.id }),
        ...(getCouponCodeResponse &&
          !getCouponCodeResponse.isError &&
          couponCode && { couponCode }),
      };
      sendPurchaseLeadFinderSubscriptionRequest(payload);
    } else {
      const payload = {
        accountSubscriptionPlanId: Number(selectedPlanDetails?.id),
        slots: 1,
        stripeCardToken: token.id,
        ...(getCouponCodeResponse &&
          !getCouponCodeResponse.isError &&
          couponCode && { couponCode }),
      };
      sendPurchaseLeadFinderModifySubscriptionRequest(payload);
    }
  };

  const sendPurchaseSubscriptionRequestSuccess = (message) => {
    showToastMessage(message);

    sendGetLeadFinderSubscriptionRequest();
    redirectWithoutRefresh(
      '/settings/billing/subscriptions/lead-finder?plan=subscribed',
    );

    resetSubscription();

    setTimeout(() => {
      // Stripe is taking time to update payment is pending or not data
      sendGetUserSettingsRequest();
    }, 500);
  };

  const updateQueryParams = (planId) => {
    const queryParams = new URLSearchParams(location.search);
    const index = getPlanIndex(sendGetLeadFinderPlansResponse, planId);
    const selectPlanIndex = index < 3 ? index + 1 : index - 2;

    queryParams.set(
      UpgradePlanQueryParamsKey.Action,
      getSubscribeAction(isUserModifyingSubscription),
    );
    queryParams.set(UpgradePlanQueryParamsKey.Period, getPlanPeriod(planCycle));
    queryParams.set(UpgradePlanQueryParamsKey.Plan, selectPlanIndex.toString());

    history.push({
      pathname: Routes.Setting_Billing_Upgrade_Lead_Finder_Plan,
      search: queryParams.toString(),
    });
  };

  const resetQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(UpgradePlanQueryParamsKey.Action);
    queryParams.delete(UpgradePlanQueryParamsKey.Period);
    queryParams.delete(UpgradePlanQueryParamsKey.Plan);

    history.push({
      pathname: Routes.Setting_Billing_Upgrade_Lead_Finder_Plan,
      search: queryParams.toString(),
    });
  };

  const selectPlanBasedOnUrl = () => {
    const {
      planName,
      planType,
      planCode,
    } = sendGetLeadFinderSubscriptionResponse;
    const params = queryString.parse(location.search);
    const { plan, period } = params;

    const upgradePlan = sendGetLeadFinderPlansResponse.filter(
      ({ planMode, planType: type }) =>
        Number(period) === planMode && isValueBasedPlan(type),
    )[Number(plan) - 1];

    const subscriptionId = getPlanId(
      sendGetLeadFinderPlansResponse,
      planName,
      planCode,
      planType,
    );

    if (
      upgradePlan?.id &&
      (upgradePlan?.id > subscriptionId ||
        planCode === SubscriptionPlans.FREE ||
        planCode === SubscriptionPlans.TRIAL)
    ) {
      onSelectPlan(upgradePlan?.id, upgradePlan?.planName);
    }
  };

  const getPremiumFeatureTooltipText = (): string =>
    'A/Z variant \nPipedrive Integration \nTeam Management \nAPI \nSender Rotation \nUnified Inbox Reply';

  const getActivePlanName = () => {
    const { planName } = sendGetLeadFinderSubscriptionResponse;
    const leadFinderPlanMetaData = getLeadFinderPlanMetaData(planName);

    return leadFinderPlanMetaData?.displayName || planName;
  };

  const handleTalkToSales = () => {
    if (window.Intercom) {
      // First update Intercom to ensure it's in sync
      window.Intercom('update');

      // Wait a brief moment for the messenger to be ready
      setTimeout(() => {
        window.Intercom(
          'showNewMessage',
          "Hey, I'm interested in buying Lead Finder credits. How can I proceed?",
        );
      }, 0);
    }
    redirectWithoutRefresh('/settings/billing/subscriptions/lead-finder');
  };

  const onPurchaseHandler = (token) => {
    sendPurchaseLeadFinderOneTimePlanRequest({
      stripeCardToken: token.id,
      planId: Number(selectedLFCreditPlan?.id),
    });
  };

  const handleCreditSliderChange = (value) => {
    const plan = getLeadFinderOneTimePlansResponse.find(
      ({ id }) => Number(id) === Number(value[0]),
    );
    setSelectedLFCreditPlan(plan);
    setCreditSliderSelectedValue(value);
  };

  const handle3dSecureCard = async (response) => {
    const apiEndPoint = '/lead-finder/credits/confirm-payment-intent';
    setIs3dSecureResponseStatePending(true);

    const { isError, serverResponse } = await handle3dSecureCardPayment({
      response,
      apiEndPoint,
    });

    setIs3dSecureResponseStatePending(false);

    if (isError) {
      resetPurchaseLeadFinderOneTimePlan();
      redirectWithoutRefresh({
        pathname: '/settings/billing/subscriptions/lead-finder',
        state: {
          LFCreditPaymentFailed: true,
        }
      });
    } else {
      toaster.success(serverResponse.payload.message);
      resetPurchaseLeadFinderOneTimePlan();
      redirectWithRefresh('/settings/billing/subscriptions/lead-finder');
    }
  };

  //* useEffect
  useEffect(() => {
    executeOnRequestStatus({
      status: sendGetLeadFinderSubscriptionRequestStatus,
      onSuccess: () => {
        const { planCode } = sendGetLeadFinderSubscriptionResponse;

        const isUserSubscribed = getIsLeadFinderUserSubscribed(planCode);
        setIsModifySubscription(isUserSubscribed);

        selectPlanBasedOnUrl();
      },
    });
  }, [sendGetLeadFinderSubscriptionRequestStatus]);

  // Purchase subscription
  useEffect(() => {
    executeOnRequestStatus({
      status: sendPurchaseLeadFinderSubscriptionRequestStatus,
      onSuccess: () => {
        sendPurchaseSubscriptionRequestSuccess(
          sendPurchaseLeadFinderSubscriptionRequestMessage,
        );
      },
    });
  }, [sendPurchaseLeadFinderSubscriptionRequestStatus]);

  // Modify subscription
  useEffect(() => {
    executeOnRequestStatus({
      status: sendPurchaseLeadFinderModifySubscriptionRequestStatus,
      onSuccess: () => {
        sendPurchaseSubscriptionRequestSuccess(
          sendPurchaseLeadFinderModifySubscriptionRequestMessage,
        );
      },
    });
  }, [sendPurchaseLeadFinderModifySubscriptionRequestStatus]);

  useEffect(() => {
    if (leadFinderSubscriptionPlanResponse?.planCode) {
      if (
        LeadFinderEmailOnlySubscriptionPlans.includes(
          leadFinderSubscriptionPlanResponse?.planCode,
        )
      ) {
        setIsEmailPhonePlan(false);
      }
      if (
        LeadFinderEmailPhoneSubscriptionPlans.includes(
          leadFinderSubscriptionPlanResponse?.planCode,
        )
      ) {
        setIsEmailPhonePlan(true);
      }
    }
  }, [
    leadFinderSubscriptionPlanResponse &&
      leadFinderSubscriptionPlanResponse?.planCode,
  ]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getLeadFinderOneTimePlansRequestStatus,
      onSuccess: () => {
        setSelectedLFCreditPlan(getLeadFinderOneTimePlansResponse[0]);
      },
    });
  }, [getLeadFinderOneTimePlansRequestStatus]);

  useEffect(() => {
    if (
      sendPurchaseLeadFinderOneTimePlanRequestStatus ===
        RequestStatus.Succeeded &&
      sendPurchaseLeadFinderOneTimePlanRequestMessage
    ) {
      if (sendPurchaseLeadFinderOneTimePlanResponse.requires_action) {
        handle3dSecureCard(sendPurchaseLeadFinderOneTimePlanResponse);
      } else {
        toaster.success(sendPurchaseLeadFinderOneTimePlanRequestMessage);
        resetPurchaseLeadFinderOneTimePlan();
        redirectWithRefresh('/settings/billing/subscriptions/lead-finder');
      }
    }
  }, [sendPurchaseLeadFinderOneTimePlanRequestStatus]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    sendGetLeadFinderOneTimePlansRequest();
    return () => {
      setPlanCycle(BillingOption.ANNUALLY);
      setIsBillingSummaryModalShow(false);
      setIsModifySubscription(false);
      setIsEmailPhonePlan(false);
      setSelectedPlanDetails(null);
    };
  }, []);

  return (
    <div className="outreach-plans">
      {/* Premium Feature Info Banner */}
      {isPremiumFeatureBannerShow(
        sendGetLeadFinderSubscriptionResponse?.planCode,
      ) && (
        <div className="d-flex justify-content-center">
          <div className="pt-3 pb-3 pr-4 pl-4 mt-4 gray-1 text-center premium-feature-card">
            <span className="regular-1 popover-arrow-color-txt">
              You have used any of these{' '}
            </span>
            <OverlayTooltip
              text={getPremiumFeatureTooltipText()}
              className="line-break"
            >
              <span className="semibold-1 blue-txt-11 pointer">
                premium feature
              </span>
            </OverlayTooltip>
            <span className="regular-1 popover-arrow-color-txt">
              <ImageIcon src={Images.PremiumFeature} alt="premium feature" /> in
              the trial. We suggest upgrading to Outreach PRO or a higher plan.
              Otherwise, you will lose access to all the premium features.
            </span>
          </div>
        </div>
      )}
      <div className="lead-finder-plans-header">
        <h1>Find the right Lead Finder plan</h1>
        <span>
          You are currently on the{' '}
          <span className="semibold-1">{getActivePlanName()} plan</span>
        </span>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-column align-items-end mt-4">
          <PlanCycleSwitch
            value={planCycle}
            onChange={handelBillingOption}
            disabledLabel={getDisabledLabel(
              sendGetLeadFinderSubscriptionResponse,
            )}
          />
        </div>
      </div>
      <div className="lead-finder-plans-section">
        <PlansSection
          onSelectPlan={onSelectPlan}
          planCycle={planCycle}
          selectedPlanId={selectedPlanDetails?.id}
          isEmailPhonePlan={isEmailPhonePlan}
        />
        <div className="credit-slider-container">
          <div className="credit-slider-container-header">
            <div className="credit-slider-container-header-title-wrapper">
              <span className="credit-slider-container-header-title">
                Add One-Time Credits to Reveal More
              </span>
              <span className="credit-slider-container-header-description">
                Add one-time credits to your plan to reveal more leads,
                including emails and phone numbers. Credits stay
                post-cancellation
              </span>
            </div>
            <div className="credit-slider-container-header-button-wrapper">
              {selectedLFCreditPlan?.amount > 1000 ? (
                <Button variant="primary" onClick={handleTalkToSales}>
                  Talk to Sales
                </Button>
              ) : (
                <StripeCheckout
                  token={onPurchaseHandler}
                  name="Saleshandy"
                  image={Images.SHIcon70}
                  stripeKey={process.env.REACT_APP_STRIPE_KEY}
                  amount={selectedLFCreditPlan?.amount * 100}
                  zipCode
                  billingAddress
                  ComponentClass="div"
                >
                  <Button
                    variant="primary"
                    onClick={() => {}}
                    isLoading={
                      getIsRequestPending(
                        sendPurchaseLeadFinderOneTimePlanRequestStatus,
                      ) || is3dSecureResponseStatePending
                    }
                    disabled={
                      getIsRequestPending(
                        sendPurchaseLeadFinderOneTimePlanRequestStatus,
                      ) || is3dSecureResponseStatePending
                    }
                    loadingText="Buying..."
                  >
                    Buy Now
                  </Button>
                </StripeCheckout>
              )}
            </div>
          </div>
          <div className="credit-slider-wrapper">
            <div className="credit-slider-selected-value-wrapper">
              <span className="credit-slider-selected-value">
                {selectedLFCreditPlan?.name} Credits
              </span>
              <span className="credit-slider-selected-price">
                ${selectedLFCreditPlan?.amount}
              </span>
            </div>
            {getLeadFinderOneTimePlansResponse?.length > 0 && (
              <CreditSlider
                value={creditSliderSelectedValue}
                onChange={handleCreditSliderChange}
                step={1}
                min={1}
                max={getLeadFinderOneTimePlansResponse?.length}
                plans={getLeadFinderOneTimePlansResponse}
              />
            )}
          </div>
        </div>
      </div>
      <div className="secondary-banner-container">
        <UpgradePlanStatic />
      </div>
      <BillingSummaryModal
        show={isBillingSummaryModalShow}
        onClose={hideBillingSummaryModal}
        selectedPlanDetails={selectedPlanDetails}
        onPurchase={onPurchase}
        isUserModifyingSubscription={isUserModifyingSubscription}
      />
    </div>
  );
};

export default LeadFinderPlans;
