/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getIsEmailOutreachProPlan,
  getNumberOfSlotsUserWant,
} from '../../../../../components/settings/components/billing-subscription/components/upgrade-plan/utils/helper';
import { BillingOption } from '../../../../utils/billing-options';
import { SubscriptionPlans } from '../../../../utils/subscription-plans';

import InputNumber from '../../input-number';
import { checkIsLtdPlan } from '../subscription-plan-details/helper/helper';
import { IProps } from './types';

const UpgradePlanDetails: React.FC<IProps> = ({
  planDetailHeading,
  planName,
  planDescription,
  planPrice,
  billingOption,
  numberOfSlotsUserWant,
  numberOfSlotsUserHave,
  minimumNumberOfSlotsUserCanHave,
  onNumberOfSlotsChange,
  selectedPlanDetails,
  licensesText,
  isBillingSummaryPending,
  isUserModifyingSubscription: isModifySubscription,
  sendModifySubscriptionRequest,
}) => {
  const { t } = useTranslation();
  const [numberOfAccounts, setNumberOfAccounts] = useState<number>(null);
  const [isSlotInputDisabled, setIsSlotInputDisabled] = useState(false);

  const [
    showNumberInputWarningText,
    setShowNumberInputWarningText,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (
      billingOption === BillingOption.LIFETIME &&
      selectedPlanDetails?.planCode ===
        SubscriptionPlans.EMAIL_OUTREACH_LIFETIME
    ) {
      setNumberOfAccounts(1);
      setIsSlotInputDisabled(true);
    } else {
      setNumberOfAccounts(numberOfSlotsUserWant);
    }
  }, [numberOfSlotsUserWant]);

  const onChangeHandler = (value) => {
    if (
      billingOption === BillingOption.LIFETIME &&
      selectedPlanDetails?.planCode ===
        SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME
    ) {
      // if (value > 20) {
      //   setShowNumberInputWarningText(true);
      // } else {
      setNumberOfAccounts(value);
      onNumberOfSlotsChange(value);
      setShowNumberInputWarningText(false);
      // }
    } else if (value >= minimumNumberOfSlotsUserCanHave) {
      setNumberOfAccounts(value);
      onNumberOfSlotsChange(value);
      setShowNumberInputWarningText(false);
    } else {
      setShowNumberInputWarningText(true);
    }
  };

  const getMemberText = (member: number) => {
    if (member === 1) {
      return 'member';
    }

    return 'members';
  };

  const planPriceDetailClassName = classNames([
    'd-flex flex-column mt-1',
    {
      'mt-3': billingOption === BillingOption.LIFETIME,
    },
  ]);

  const numberOfSlots = getNumberOfSlotsUserWant(
    numberOfAccounts,
    minimumNumberOfSlotsUserCanHave,
  );

  return (
    <div className="subscription-plan__container">
      <div className="card">
        <div className="card-body">
          <div className="subscription-plan__info">
            <div>
              <span className="semibold-1 gray-txt-12">
                {planDetailHeading && t(`messages.${planDetailHeading}`)}
              </span>
              <h3 className="semibold-3 mt-1">{planName}</h3>
            </div>
            <div className="plan-price-wrap">
              <div className="d-flex justify-content-between align-items-start">
                <span className="semibold-1 mt-1">$</span>
                <span className="semibold-5">{planPrice}</span>
                <div className={planPriceDetailClassName}>
                  <span className="plan-price-detail">per slot</span>
                  {billingOption !== BillingOption.LIFETIME && (
                    <span className="plan-price-detail">per month</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <p className="regular-1 gray-txt-15">
            {t(`messages.${planDescription}`)}
          </p>

          <div className="subscription-plan__number-input">
            <div className="d-flex align-items-center">
              <InputNumber
                size={InputNumber.Size.Medium}
                value={numberOfSlots}
                key={numberOfAccounts}
                onChange={(v) => onChangeHandler(v)}
                disabled={isBillingSummaryPending || isSlotInputDisabled}
                // {...(getIsEmailOutreachProPlan(
                //   selectedPlanDetails,
                //   billingOption,
                // ) &&
                //   {
                // max: 20,
                // showUpHandlerWithTooltip: true,
                // upHandlerTooltipText:
                //   'The maximum number of slots allowed in this plan is 20. You cannot exceed the limit from here.',
                //   })}
                min={
                  checkIsLtdPlan(selectedPlanDetails?.planType)
                    ? numberOfSlotsUserHave
                    : minimumNumberOfSlotsUserCanHave || 1
                }
                {...(!checkIsLtdPlan(selectedPlanDetails?.planType) && {
                  showDownHandlerWithTooltip: true,
                  downHandlerTooltipText:
                    "You can't reduce the user slots below the number of existing team members and email accounts. Please remove the equivalent number of slots from your account to decrease your slots.",
                })}
                className="slot-input"
              />
              <span className="font-10 font-weight-medium gray-txt-11 ml-3">
                {t(`messages.${licensesText}`)}
              </span>
            </div>

            <p className="font-12 orange-txt-12 font-italic mt-3 mb-0">
              {numberOfSlots < 2 ? (
                <span>
                  With <b>1</b> slot, you can connect <b>1</b> email account
                  only.
                </span>
              ) : (
                <span>
                  With{' '}
                  <b>
                    {getNumberOfSlotsUserWant(
                      numberOfAccounts,
                      minimumNumberOfSlotsUserCanHave,
                    )}
                  </b>{' '}
                  slots, you and your team members combined can connect{' '}
                  <b>{numberOfSlots}</b> email accounts + you can invite{' '}
                  <b>{numberOfSlots - 1}</b> team{' '}
                  {getMemberText(numberOfSlots - 1)} for free.
                </span>
              )}
            </p>

            {showNumberInputWarningText && (
              <p className="number-input-waring-text">
                {t('messages.number_input_alert_message')}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanDetails;
