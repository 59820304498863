import { ReactNode } from 'react';

/**
 * Type of filter.
 * Can be a dropdown or a date picker
 */
export enum FilterType {
  DropDown = 'DropDown',
  Date = 'Date',
}

export type DateFilterProps = {
  /**
   *  Select change handler
   */
  onSelect: (x: any) => void;
  /**
   * Selected values.
   */
  values: any[];
  isDisabled?: boolean;
};

export type SelectFilterProps = DateFilterProps & {
  /**
   * Placeholder for the input.
   */
  placeholder: string;
  /**
   * Options to display as suggestions.
   */
  options: any[];
  /**
   * Key to extract the the values for `options`.
   */
  labelKey?: string;
  /**
   * Unique identifier of the option passed in `options`.
   */
  uniqueKey?: string;
  placement?: 'auto' | 'top' | 'bottom';
  isMultiSelect?: boolean;
  isDisabled?: boolean;
  containerClassName?: string;
};

export type ProspectsFilterProps = SelectFilterProps & {
  /**
   * Name/label for the filter.
   */
  label: string;
  /**
   * ClassName for the parent component.
   */
  containerClassName?: string;
  /**
   * Type of filter. Can be dropdown or date picker
   */
  filterType?: FilterType;
  /**
   * Is multi select enabled.
   */
  isMultiSelect?: boolean;
  isDisabled?: boolean;
  /**
   * Format Group Label for React-Select
   */
  formatGroupLabel?: ReactNode | null;
};
