import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../../../store/root-reducer';
import { MyProfile } from '../../../../types/my-profile';
import {
  deleteMyProfileRequest,
  updateMyProfileRequest,
  updatePasswordRequest,
} from '../../extra-actions';
import {
  UpdateUserProfilePayload,
  UpdatePasswordPayload,
} from '../../../../types/request-payload';
import MyProfileContent from './my-profle-content';
import { getUserSettingsRequest } from '../../../../../home/extra-actions';
import { onSettingsTabProfileUpdate } from '../../../../../home/home-slice';
import { OAuthMethod } from '../../../../../auth/components/oauth-buttons';
import { OAuthPayload } from '../../../../../auth/types';
import { getOAuthRedirectUrlRequest } from '../../../../../auth/components/oauth-buttons/extra-actions';
import { ssoLoginRequest } from '../../../../../auth/components/sso-login/extra-actions';
import { resetDeleteMyProfileRequest } from '../../my-profile-slice';

const mapStateToProps = (state: RootState) => ({
  updatePasswordRequestStatus: state.myProfile.updatePasswordRequest.status,
  updatePasswordRequestMessage: state.myProfile.updatePasswordRequest.message,
  updatePasswordRequestError: state.myProfile.updatePasswordRequest.error,

  updateMyProfileRequestStatus: state.myProfile.updateMyProfileRequest.status,
  updateMyProfileRequestMessage: state.myProfile.updateMyProfileRequest.message,
  updateMyProfileRequestError: state.myProfile.updateMyProfileRequest.error,

  ssoLoginRequestStatus: state.ssoLogin.ssoLoginRequest.status,
  ssoLoginRequestMessage: state.ssoLogin.ssoLoginRequest.message,
  ssoLoginRequestError: state.ssoLogin.ssoLoginRequest.error,

  deleteMyProfileRequestStatus: state.myProfile.deleteMyProfileRequest.status,
  deleteMyProfileRequestMessage: state.myProfile.deleteMyProfileRequest.message,
  deleteMyProfileRequestError: state.myProfile.deleteMyProfileRequest.error,

  redirectUrl: state.ssoLogin.redirectUrl,

  subscriptionPlan: state.home.subscription?.planCode,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
  myProfileDetails: state.myProfile.myProfile,

  meta: state.home.meta,
  userId: state.home.userId,

  getOAuthRedirectUrlRequestStatus:
    state.oauth.getOAuthRedirectUrlRequest.status,
  getOAuthRedirectUrlRequestError: state.oauth.getOAuthRedirectUrlRequest.error,
  authUrl: state.oauth.authUrl,
  ssoStatus: state.home.ssoStatus,
});

const mapDispatchToProps = {
  sendUpdateMyProfileRequest: (payload: UpdateUserProfilePayload) =>
    updateMyProfileRequest(payload),
  sendUpdatePasswordRequest: (payload: UpdatePasswordPayload) =>
    updatePasswordRequest(payload),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  sendHomeMetaUpdate: (payload) => onSettingsTabProfileUpdate(payload),
  getOAuthRedirectUrl: (method: OAuthMethod, payload: OAuthPayload) =>
    getOAuthRedirectUrlRequest({ method, payload }),
  sendSSOLoginRequest: (email: string) => ssoLoginRequest({ email }),
  sendDeleteMyProfileRequest: (password: string) =>
    deleteMyProfileRequest(password),
  resetDeleteMyProfileRequest: () => resetDeleteMyProfileRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    getMyProfileRequest: () => void;
    myProfile: MyProfile;
  };

export default withRouter(connector(MyProfileContent));
