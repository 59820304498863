import { DateTime } from 'luxon';
import { DiscountType } from '../../../../../../components/settings/components/billing-subscription/types/apply-coupon-code';
import { RequestStatus } from '../../../../../enums/request-status';
import toaster, { Theme } from '../../../../../toaster';
import { BillingOption } from '../../../../../utils/billing-options';
import { showGeneralErrorNotification } from '../../../../../utils/errors';
import { ModifyPlanAction } from '../../../../../utils/subscription-plans';
import { checkIsLtdPlan } from '../../subscription-plan-details/helper/helper';
import { getTotalAfterDiscount } from './get-total-after-discount';
import { getTotalAmount } from './get-total-amount';

export const getAlreadyPaidLabel = (action: ModifyPlanAction) =>
  action === ModifyPlanAction.Update ? 'unused_balance' : 'credits';

export const getFinalSubTotal = ({
  isUserModifyingSubscription,
  sendGetCalculatePayResponse,
  numberOfSlotsUserWant,
  planPrice,
  billingOption,
  couponCodeDiscountType,
  couponCodeDiscount,
  action,
}) => {
  let subTotalPrice = 0;

  if (billingOption === BillingOption.ANNUALLY) {
    subTotalPrice = 12 * numberOfSlotsUserWant * planPrice;
  } else {
    subTotalPrice = numberOfSlotsUserWant * planPrice;
  }

  const totalAmountPrice = getTotalAmount(
    billingOption,
    isUserModifyingSubscription,
    subTotalPrice,
    sendGetCalculatePayResponse,
    action,
  );

  const totalPriceAfterDiscount = getTotalAfterDiscount(
    couponCodeDiscountType,
    totalAmountPrice,
    couponCodeDiscount,
  );

  return { subTotalPrice, totalPriceAfterDiscount };
};

export const getSubtotalDiscountAmount = (
  couponCodeDiscountType,
  totalAmount,
  couponCodeDiscount,
): number =>
  couponCodeDiscountType === DiscountType.Percentage
    ? (totalAmount * couponCodeDiscount) / 100
    : couponCodeDiscount;

export const getAmount = (totalAfterDiscount, totalAmount) =>
  totalAfterDiscount ? totalAfterDiscount * 100 : totalAmount * 100;

export const showToastMessage = (message: string) =>
  message.toLowerCase().includes('successful')
    ? toaster.success(message, { theme: Theme.New })
    : toaster.warning(message);

export const showErrorNotification = (requestStatus, requestError) => {
  if (requestStatus === RequestStatus.Failed && requestError) {
    showGeneralErrorNotification(requestError.message);
  }
};

export const getRemainingDays = (timeStamp) => {
  const date = new Date(timeStamp * 1000);

  return Math.ceil(
    DateTime.fromJSDate(date, {
      zone: 'Etc/UTC',
    })
      .diff(DateTime.now().toUTC(), ['days'])
      .toObject().days,
  );
};

export const checkShouldShowTotalAmoutToPayText = ({
  planType,
  planCode,
  existingPlanSlots,
  numberOfSlotsUserWant,
  existingPlanCode,
}): boolean =>
  checkIsLtdPlan(planType) &&
  (Number(existingPlanSlots) !== Number(numberOfSlotsUserWant) ||
    planCode !== existingPlanCode);

export const getTotalAmountMinusDiscount = ({
  totalAmount,
  discountType,
  discount,
}) => {
  const discountedAmount = getSubtotalDiscountAmount(
    discountType,
    totalAmount,
    discount,
  );

  if (discountedAmount) {
    return (totalAmount - discountedAmount).toFixed(2);
  }

  return totalAmount.toFixed(2);
};
