import React from 'react';
import {
  CircleCheck,
  AlertTriangle,
  Forbid,
  Hourglass,
} from '@saleshandy/icons';
import { User } from '../../../../../types/users-and-teams';
import {
  UserStatusKeys,
  UserStatusLabel,
} from '../../../../../enums/users-and-teams';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';
import TruncatedTextWithTooltip from '../../../../../../../shared/components/truncated-text-with-tooltip';

const UserNameCell: React.FC<{
  user: User;
  userId: number;
  isLastRow: boolean;
  nameCol?: boolean;
}> = ({
  user: { id, firstName, lastName, email, status },
  userId,
  isLastRow,
  nameCol = false,
}) => {
  const name = `${firstName || ''} ${lastName || ''}`.trim();

  const getStatusIcon = () => {
    if (status === UserStatusKeys.Invited) {
      return <Hourglass />;
    }
    if (status === UserStatusKeys.Failed) {
      return <AlertTriangle />;
    }
    if (status === UserStatusKeys.InActive) {
      return <Forbid />;
    }

    return <CircleCheck />;
  };

  const getStatusLabel = () => {
    if (status === UserStatusKeys.Invited) {
      return UserStatusLabel.Invited;
    }
    if (status === UserStatusKeys.Failed) {
      return UserStatusLabel.Failed;
    }
    if (status === UserStatusKeys.InActive) {
      return UserStatusLabel.InActive;
    }

    return UserStatusLabel.Active;
  };

  return (
    <div className="user-name-cell">
      {nameCol ? (
        <h1>
          <TruncatedTextWithTooltip
            string={name || ''}
            length={20}
            tooltipClassName="tooltip-xxl"
            placement={isLastRow ? Placement.Top : Placement.Bottom}
          />
          {userId === id ? ' (You)' : ''}
        </h1>
      ) : (
        <div className="user-name-cell-inner">
          <OverlayTooltip
            text={getStatusLabel()}
            placement={isLastRow ? Placement.Top : Placement.Bottom}
          >
            <span className={getStatusLabel().toLowerCase()}>
              {getStatusIcon()}
            </span>
          </OverlayTooltip>
          <p>
            <TruncatedTextWithTooltip
              string={email || ''}
              length={30}
              tooltipClassName="tooltip-xxl"
              placement={isLastRow ? Placement.Top : Placement.Bottom}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default UserNameCell;
