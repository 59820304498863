import React, { useEffect, useMemo } from 'react';
import { Google } from '../../../../shared/svg/google';
import { Microsoft } from '../../../../shared/svg/microsoft';
import { OAuthButtonsProps, OAuthMethod } from './types';
import Button from '../../../../shared/design-system/ui/button';
import {
  executeOnRequestStatus,
  getCurrentTimeZone,
  redirectWithRefresh,
} from '../../../../shared/utils';
import { showToasterWithErrorCheck } from '../../../email-accounts/components/email-accounts-content/utils/helpers';
import { ActionForAuthentication } from '../../types';

const OAuthButtons: React.FC<OAuthButtonsProps> = ({
  type = ActionForAuthentication.Login,
  className = '',
  getOAuthRedirectUrlRequestStatus,
  getOAuthRedirectUrlRequestError,
  authUrl,
  email,
  userId,
  getOAuthRedirectUrl,
}) => {
  const [loadingMethod, setLoadingMethod] = React.useState<OAuthMethod | null>(
    null,
  );

  useEffect(() => {
    executeOnRequestStatus({
      status: getOAuthRedirectUrlRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(authUrl);
      },
      onFailed: () => {
        showToasterWithErrorCheck(getOAuthRedirectUrlRequestError);
      },
    });
  }, [getOAuthRedirectUrlRequestStatus]);

  const handleOAuthClick = (method: OAuthMethod) => {
    const timeZone = getCurrentTimeZone();
    setLoadingMethod(method);
    getOAuthRedirectUrl(method, {
      timeZone,
      invitedEmail: email,
      userId,
      action: type,
    });
  };

  const getProviderButtonText = (provider: 'Google' | 'Microsoft') => {
    if (type === ActionForAuthentication.Login) {
      return `Log In with ${provider}`;
    }
    if (type === ActionForAuthentication.Signup) {
      return `Sign up with ${provider}`;
    }
    if (type === ActionForAuthentication.ChangeEmail) {
      return `Sign in with ${provider}`;
    }
    return `Continue with ${provider}`;
  };

  const googleButtonText = useMemo(() => getProviderButtonText('Google'), [
    type,
  ]);
  const microsoftButtonText = useMemo(
    () => getProviderButtonText('Microsoft'),
    [type],
  );

  return (
    <div className={`oauth-buttons-container ${className}`}>
      <Button
        type="button"
        variant="secondary"
        isFullWidth
        className="oauth-button google-button"
        onClick={() => handleOAuthClick(OAuthMethod.Google)}
        isLoading={loadingMethod === OAuthMethod.Google}
        loadingText="Connecting to Google..."
        disabled={!!loadingMethod}
      >
        <Google /> {googleButtonText}
      </Button>
      <Button
        type="button"
        variant="secondary"
        isFullWidth
        className="oauth-button microsoft-button"
        onClick={() => handleOAuthClick(OAuthMethod.Microsoft)}
        isLoading={loadingMethod === OAuthMethod.Microsoft}
        loadingText="Connecting to Microsoft..."
        disabled={!!loadingMethod}
      >
        <Microsoft /> {microsoftButtonText}
      </Button>
      {(type === ActionForAuthentication.Login ||
        type === ActionForAuthentication.Signup) && (
        <div className="oauth-divider">
          <span>OR</span>
        </div>
      )}
    </div>
  );
};

export default OAuthButtons;
