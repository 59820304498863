export type LoginRequestPayload = {
  email: string;
  password: string;
};

export type SignupRequestPayload = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phone?: string;
  timeZone?: string;
  rewardfulReferralId?: string;
  celloReferralCode?: string;
  isLtdUser?: boolean;
  landingPage?: string;
  referralPage?: string;
};

export type UserSignUpEventCelloPayload = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phone?: string;
  timeZone?: string;
  rewardfulReferralId?: string;
  isLtdUser?: boolean;
  landingPage?: string;
  referralPage?: string;
};

export type VerifyUserVerificationCodeRequestPayload = {
  email: string;
  code: number;
};

export type ResendUserVerificationCodeRequestPayload = {
  email: string;
};

export type ResetPasswordRequestPayload = {
  email: string;
};

export type ChangePasswordRequestPayload = {
  password: string;
};

export type UserSetPasswordPayload = {
  newPassword: string;
};

export type AcceptInvitationRequestPayload = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phone?: string;
  timeZone?: string;
  token?: string;
};

export type OnBoardingRequest = {
  userType?: string;
  agencyType?: string;
  clientsCount?: string;
  monthlyemailsCount?: string;
  useCase?: string;
  priorColdEmailToolExperience?: string;
  teamSize?: string;
  avgDealSize?: string;
  usagePreference?: string;
  occupation?: string;
  leadsSizeCount?: string;
  source?: string;
  sourceDetails?: string;
};

export type SetPasswordRequest = {
  firstName: string;
  lastName: string;
  phone?: string;
  password: string;
  email: string;
  country: string;
};

export type VerifyOtpPayload = {
  email: string;
  otp: string;
};

export type ResendOtpPayload = {
  email: string;
};

export enum ActionForAuthentication {
  Signup = 'signup',
  Login = 'login',
  ChangeEmail = 'change-email',
  ValidationChangeEmail = 'validation-change-email',
  Invitation = 'invitation',
  DeleteAccount = 'delete-account',
}

export type OAuthPayload = {
  timeZone: string;
  action: ActionForAuthentication;
  userId?: number;
  invitedEmail?: string;
};
