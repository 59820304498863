import { connect, ConnectedProps } from 'react-redux';
import { BillingOption } from '../../../../../../../../shared/utils/billing-options';
import { RootState } from '../../../../../../../../store/root-reducer';
import LeadFinderPlansSection from './lead-finder-plans-section';

const mapStateToProps = (state: RootState) => ({
  getCouponCodeResponse: state.billingAndSubscription.applyCouponCodeResponse,
  sendGetLeadFinderSubscriptionRequestStatus:
    state.billingAndSubscription.getLeadFinderSubscriptionRequest.status,
  sendGetLeadFinderSubscriptionResponse:
    state.billingAndSubscription.getLeadFinderSubscriptionResponse,
  sendGetLeadFinderPlansResponse:
    state.billingAndSubscription.getLeadFinderPlansResponse,
  sendPurchaseLeadFinderSubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderSubscriptionRequest.status,
  sendPurchaseLeadFinderModifySubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderModifySubscriptionRequest
      .status,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  onSelectPlan: (planId: number, planName: string) => void;
  planCycle: BillingOption;
  selectedPlanId: number;
  isEmailPhonePlan: boolean;
};

export default connector(LeadFinderPlansSection);
