import React from 'react';

import { EmailAccountInfrastructureServiceProvider } from '../../../enums/create-email-infrastructure';

import { Images } from '../../../../../shared/app-constants';
import ImageIcon from '../../../../../shared/components/images/image-icon';

import { Google } from '../../../../../shared/svg/google';
import { Microsoft } from '../../../../../shared/svg/microsoft';

interface IProps {
  esp: EmailAccountInfrastructureServiceProvider;
}

const EmailInfrastructureServiceProvider: React.FC<IProps> = ({ esp }) => {
  const renderESPIcon = () => {
    if (esp === EmailAccountInfrastructureServiceProvider.Google) {
      return <Google />;
    }
    if (esp === EmailAccountInfrastructureServiceProvider.Microsoft) {
      return <Microsoft />;
    }
    if (esp === EmailAccountInfrastructureServiceProvider.MailDoso) {
      return <ImageIcon src={Images.MaildosoSquare} />;
    }
    return null;
  };

  return <>{renderESPIcon()}</>;
};

export default EmailInfrastructureServiceProvider;
