/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { Images } from '../../../../../../shared/app-constants';
import { supportUrls } from '../../../../../../shared/utils/urls';

type Testimonials = {
  id: number;
  message: React.ReactNode;
  name: string;
  profileURL: string;
  role: string;
};

type Customer = {
  id: number;
  logo: string;
};

const testimonials: Testimonials[] = [
  {
    id: 1,
    message: (
      <span>
        "One of the{' '}
        <span className="color">simplest cold email automation</span> platforms
        with good pricing. Customer support is highly helpful."
      </span>
    ),
    name: 'Cong Nguyen',
    profileURL: Images.Testimonial4Bg,
    role: 'Synodus, Founder & CEO',
  },
  {
    id: 2,
    message: (
      <span>
        "The <span className="color">future of cold email</span> tools has
        arrived. The simplicity and straightforwardness of the product are
        great."
      </span>
    ),
    name: 'Thomas Foley',
    profileURL: Images.Testimonial3Bg,
    role: 'HighTouch, Founder',
  },
  {
    id: 3,
    message: (
      <span>
        "The <span className="color">perfect outreach platform</span> for our
        business. You can add unlimited email accounts to increase volume at any
        scale."
      </span>
    ),
    name: 'Gino Taka',
    profileURL: Images.Testimonial2Bg,
    role: 'Skyrocket, Founder',
  },
];

const customers: Customer[] = [
  {
    id: 1,
    logo: Images.GoDaddyLogo,
  },
  {
    id: 2,
    logo: Images.CloudtalkLogo,
  },
  {
    id: 3,
    logo: Images.VrooziLogo,
  },
  {
    id: 4,
    logo: Images.IntegratelyLogo,
  },
  {
    id: 5,
    logo: Images.NeptikLogoBlack,
  },
  {
    id: 6,
    logo: Images.OpslyftLogo,
  },
  {
    id: 7,
    logo: Images.RevvixLogo,
  },
  {
    id: 8,
    logo: Images.DorikLogo,
  },
];

const UpgradePlanStatic: React.FC = (): JSX.Element => (
  <div className="static-content-container">
    <div className="secondary-banner">
      <span className="secondary-banner-text">
        🤔 Have questions? We have your questions answered.{' '}
        <a
          href={supportUrls.leadFinderPlanViewFAQ}
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          View FAQs
        </a>
      </span>
    </div>

    <div className="static-content-customers">
      <span className="static-content-customers-text">
        Over 10,000 businesses are generating $15M in revenue with Saleshandy
      </span>

      <div className="static-content-customers-image-wrapper">
        {customers.map((customer) => (
          <div
            key={customer.id}
            className="static-content-customers-image-wrapper-item"
          >
            <img src={customer.logo} alt={`customer-${customer.id}`} />
          </div>
        ))}
      </div>

      <div className="static-content-g2-rating-wrapper">
        <div className="static-content-g2-rating">
          <div className="static-content-g2-rating-logo">
            <img src={Images.G2Logo} alt="g2-logo" />
          </div>
          <div className="static-content-g2-rating-divider" />
          <div className="static-content-g2-rating-rating">
            <img src={Images.G2Rating} alt="g2-rating" />
          </div>
        </div>
        <div className="static-content-g2-achievement">
          <img src={Images.G2Achievement} alt="g2-achievement" />
        </div>
        <div className="static-content-g2-certificates-wrapper">
          <span className="static-content-g2-certificates-wrapper-text">
            Ensuring Trust, Securing Success.
          </span>
          <div className="static-content-g2-certificates">
            <img src={Images.Certificates} alt="certificates" />
          </div>
        </div>
      </div>
    </div>
    <div className="static-content-testimonials">
      <div className="static-content-testimonials-text">
        <span>Don't just take it from us</span>
      </div>
      <div className="static-content-testimonials-list">
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className={`static-content-testimonials-list-item testimonial-${testimonial.id}`}
          >
            <div className="static-content-testimonials-list-item-message">
              {testimonial.message}
            </div>
            <div className="static-content-testimonials-list-item-user-info">
              <div className="static-content-testimonials-list-item-user-info-image">
                <img
                  src={testimonial.profileURL}
                  alt={`user-${testimonial.id}`}
                />
              </div>
              <div className="static-content-testimonials-list-item-user-info-name-role">
                <div className="static-content-testimonials-list-item-user-info-name">
                  <span>{testimonial.name}</span>
                </div>
                <div className="static-content-testimonials-list-item-user-info-role">
                  <span>{testimonial.role}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default UpgradePlanStatic;
