import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  OverlayPopover,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import DataCard from './data-card/data-card';

const ProspectSequenceName = ({ sequences, allOutcomes }) => {
  const { t } = useTranslation();

  const [isVisibleAllSequences, setIsVisibleAllSequences] = useState<boolean>(
    false,
  );

  const mappedCell = sequences.length
    ? sequences.map((sequence) => ({
        sequenceId: sequence.id,
        sequence: sequence.title,
        status: sequence.status,
        prospectId: sequence.prospectId,
        categoryId: sequence.categoryId,
      }))
    : [];

  const cls = classNames([
    'regular-2 sequence-name font-medium blue-txt-11',
    {
      'underline-txt': isVisibleAllSequences,
    },
  ]);

  const onToggle = () => {
    setIsVisibleAllSequences(!isVisibleAllSequences);
  };

  const showAllSequences = () => {
    setIsVisibleAllSequences(true);
  };

  const renderOverlayPopoverSpan = (
    <OverlayPopover
      show={isVisibleAllSequences}
      onToggle={onToggle}
      className="sequence-name-popover"
      content={
        <DataCard
          data={mappedCell}
          showAllSequences={onToggle}
          allOutcomes={allOutcomes}
        />
      }
      placement={Placement.Auto}
      rootClose
    >
      <span
        role="button"
        {...accessibleOnClick(showAllSequences)}
        className={cls}
      >
        {`${mappedCell.length} ${t('labels.sequences')}`}
      </span>
    </OverlayPopover>
  );

  const renderSpan = () => {
    const firstElement = mappedCell.shift();

    if (firstElement) {
      return (
        <OverlayPopover
          show={isVisibleAllSequences}
          onToggle={onToggle}
          className="sequence-name-popover"
          content={
            <DataCard
              data={[firstElement]}
              showAllSequences={onToggle}
              allOutcomes={allOutcomes}
            />
          }
          placement={Placement.Auto}
          rootClose
        >
          <span
            role="button"
            {...accessibleOnClick(showAllSequences)}
            className="regular-2 sequence-name font-medium text-decoration-none blue-txt-11"
          >
            {firstElement.sequence}
          </span>
        </OverlayPopover>
      );
    }

    return '-';
  };

  return (
    <div className="sequence-name-wrap">
      {mappedCell?.length > 1 ? renderOverlayPopoverSpan : renderSpan()}
    </div>
  );
};

export default ProspectSequenceName;
