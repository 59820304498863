/* eslint-disable radix */
import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';
import { Source } from '../../../shared/enums/source';
import { ContactStatusType } from '../../sequence/enums';
import {
  ProspectParams,
  ProspectCountParams,
  AddToStepParams,
  markAsRepliedParam,
  AddTagsToProspectsPayload,
  RemoveTagsFromProspectsPayload,
  BulkActionPayload,
  changePrimaryEmailInProspectPayload,
  deleteSecondaryEmailParams,
  deleteSecondaryPhoneParams,
  ProspectListPayload,
  ProspectListByIdsPayload,
  PinUnpinActionType,
  ProspectFiltersValuesPayload,
  ProspectFiltersSequencesClientsPayload,
  ProspectFiltersSaveViewPayload,
  ProspectFiltersUpdateViewPayload,
  TagsGenerationType,
  ProspectFieldPayload,
} from '../types';

// Prospect

export const getProspectList = async ({
  filterGroupLogic,
  ...rest
}: ProspectListPayload) =>
  api.post<ResponseSuccess>('/contacts/list', {
    ...(filterGroupLogic && { filterGroupLogic }),
    ...rest,
  });

export const getProspectListCount = async ({
  filterGroupLogic,
  ...rest
}: ProspectListPayload) =>
  api.post<ResponseSuccess>('/contacts/count', {
    ...(filterGroupLogic && { filterGroupLogic }),
    ...rest,
  });

export const getProspectListByIds = async (payload: ProspectListByIdsPayload) =>
  api.post<ResponseSuccess>('/contacts/list-by-ids', payload);

export const getProspect = async (params: ProspectParams) =>
  api.get<ResponseSuccess>('/contacts', { params });

export const getProspectCount = async (params: ProspectCountParams) =>
  api.get<ResponseSuccess>('/contacts/count', { params });

export const getSequenceList = async () =>
  api.get<ResponseSuccess>('sequences/list?numberOfSteps=gt:0');

export const getStepList = async (sequenceId) =>
  api.get<ResponseSuccess>(`/sequences/${Number(sequenceId)}/steps`);

export const getStepDetailsList = async (sequenceId: string) =>
  api.get<ResponseSuccess>(`/sequences/${Number(sequenceId)}/step-filter-data`);

export const addContactsToStep = async ({
  contactIds,
  sequenceId,
  stepId,
}: AddToStepParams) =>
  api.post<ResponseSuccess>(`/sequences/${Number(sequenceId)}/contacts`, {
    stepId,
    contactIds,
  });

export const removeProspect = async (contactIds: number[]) =>
  api.patch<ResponseSuccess>(`/contacts`, {
    contactIds,
  });

export const unsubscribeProspect = async (contactIds: number[]) =>
  api.post<ResponseSuccess>('/contacts/unsubscribe', {
    contactIds,
  });

export const getSingleProspectDetails = async (contactId) =>
  api.get<ResponseSuccess>(`/contacts/${parseInt(contactId)}`);

export const getMinimalSingleProspectSequencesDetails = async (contactId) =>
  api.get<ResponseSuccess>(
    `/contacts/${parseInt(contactId)}/minimal/sequences`,
  );

export const singleProspectSequencesMarkAsReplied = async (
  contactAndStepIds: markAsRepliedParam[],
) =>
  api.patch<ResponseSuccess>(`/contacts/status`, {
    contactAndStepIds,
    status: ContactStatusType.Replied,
  });

export const singleProspectSequencesMarkAsFinished = async (
  contactAndStepIds: markAsRepliedParam[],
) =>
  api.patch<ResponseSuccess>(`/contacts/status`, {
    contactAndStepIds,
    status: ContactStatusType.Finished,
  });

export const singleProspectRemoveFromSequences = async ({
  sequenceId,
  contactIds,
}) =>
  api.patch<ResponseSuccess>(`/sequences/${parseInt(sequenceId)}/contacts`, {
    contactIds,
  });

export const saveContactAttributes = async (contactId: number, attributes) =>
  api.post<ResponseSuccess>(`/contacts/${contactId}/profile`, {
    attributes,
  });

export const saveContactDealValue = async (sequenceId, attributes) =>
  api.patch<ResponseSuccess>(`/contacts/${parseInt(sequenceId)}/deal-value`, {
    attributes,
  });

export const addProspectManually = async (attributes) =>
  api.post<ResponseSuccess>(
    `/contacts/`,
    {
      attributes,
    },
    { headers: { 'sh-source': Source.ProspectManually } },
  );

export const verifyProspects = async ({ contactIds }) =>
  api.post<ResponseSuccess>('/contacts/verify', {
    contactIds,
  });

export const getTags = async (generationType = TagsGenerationType.User) =>
  api.get<ResponseSuccess>(`/tag`, {
    params: {
      ...(generationType &&
        generationType !== TagsGenerationType.All && {
          generationType,
        }),
    },
  });

export const addTagsToProspects = async ({
  ids,
  prospects,
  newTags,
}: AddTagsToProspectsPayload) =>
  api.post<ResponseSuccess>('/tag/assign', {
    ids,
    prospects,
    newTags,
  });

export const removeTagsFromProspects = async (
  payload: RemoveTagsFromProspectsPayload,
) => api.post<ResponseSuccess>('/tag/un-assign', payload);

export const verifyBulkProspects = async (payload: BulkActionPayload) =>
  api.post<ResponseSuccess>('/contacts/bulk-verify', payload);

export const deleteBulkProspects = async (payload: BulkActionPayload) =>
  api.patch<ResponseSuccess>('/contacts/bulk-delete', payload);

export const unsubscribeBulkProspects = async (payload: BulkActionPayload) =>
  api.post<ResponseSuccess>('/contacts/bulk-unsubscribe', payload);

export const addBulkProspectsToStep = async ({
  sequenceId,
  ...payload
}: BulkActionPayload) =>
  api.post<ResponseSuccess>(
    `/sequences/${parseInt(sequenceId)}/bulk-contacts`,
    payload,
  );

export const addTagsToBulkProspects = async (payload: BulkActionPayload) =>
  api.post<ResponseSuccess>('/tag/bulk-assign', payload);

export const removeTagsFromBulkProspects = async (payload: BulkActionPayload) =>
  api.post<ResponseSuccess>('/tag/bulk-un-assign', payload);

export const changePrimaryEmailInProspect = async ({
  primaryEmailProspectInfoId,
  contactId,
}: changePrimaryEmailInProspectPayload) =>
  api.post<ResponseSuccess>(`/contacts/${contactId}/change-primary-email`, {
    primaryEmailProspectInfoId,
  });

export const deleteSecondaryEmail = async ({
  prospectInfoId,
  contactId,
}: deleteSecondaryEmailParams) =>
  api.delete<ResponseSuccess>(`/contacts/${contactId}/email/${prospectInfoId}
    `);

export const deleteSecondaryPhone = async ({
  prospectInfoId,
  contactId,
}: deleteSecondaryPhoneParams) =>
  api.delete<ResponseSuccess>(`/contacts/${contactId}/phone/${prospectInfoId}
        `);

export const getFilterViewList = async (params: ProspectListPayload) =>
  api.get<ResponseSuccess>('/contacts/prospect-view/list', { params });

export const pipUnpinSavedFilter = async (
  viewId: number,
  action: PinUnpinActionType,
) =>
  api.patch<ResponseSuccess>(`/contacts/pin-view/${viewId}`, {
    action,
  });

export const updateSavedFilter = async (
  viewId: number,
  payload: { filterName: string; isShared: boolean; teamIds: number[] },
) => api.patch<ResponseSuccess>(`/contacts/prospect-view/${viewId}`, payload);

export const deleteSavedView = async (viewId: number) =>
  api.delete<ResponseSuccess>(`/contacts/prospect-view/${viewId}`);

export const getProspectFiltersFieldsList = async () =>
  api.get<ResponseSuccess>('/contacts/fields/list');

export const getProspectFiltersFieldValues = async ({
  key: fieldName,
  page,
  pageSize,
  search,
  selectedIds,
}: ProspectFiltersValuesPayload) =>
  api.get<ResponseSuccess>('/contacts/field/values', {
    params: {
      fieldName,
      page,
      pageSize,
      ...(search !== '' && { search }),
      ...(selectedIds?.length > 0 && { selectedIds: selectedIds.join(',') }),
    },
  });

export const getProspectFiltersAttributeFieldValues = async ({
  key: fieldId,
  page,
  pageSize,
  search,
  selectedIds,
}: ProspectFiltersValuesPayload) =>
  api.get<ResponseSuccess>(`/contacts/attribute/${fieldId}/values`, {
    params: {
      page,
      pageSize,
      ...(search !== '' && { search }),
      ...(selectedIds?.length > 0 && { selectedIds: selectedIds.join(',') }),
    },
  });

export const getProspectFiltersSequencesList = async ({
  page,
  pageSize,
  search,
  selectedIds,
}: ProspectFiltersSequencesClientsPayload) =>
  api.get<ResponseSuccess>(`/sequences/minimal-list`, {
    params: {
      page,
      pageSize,
      ...(search !== '' && { search }),
      ...(selectedIds?.length > 0 && { selectedIds: selectedIds.join(',') }),
    },
  });

export const getProspectFiltersClientsList = async ({
  page,
  pageSize,
  search,
  selectedIds,
}: ProspectFiltersSequencesClientsPayload) =>
  api.get<ResponseSuccess>(`/client/minimal-list`, {
    params: {
      page,
      pageSize,
      ...(search !== '' && { search }),
      ...(selectedIds?.length > 0 && { selectedIds: selectedIds.join(',') }),
    },
  });

export const saveProspectFiltersView = async ({
  filterName,
  filterCriteria,
  isShared,
  teamIds,
}: ProspectFiltersSaveViewPayload) =>
  api.post<ResponseSuccess>(`/contacts/prospect-view`, {
    filterName,
    filterCriteria,
    isShared,
    ...(teamIds?.length > 0 && { teamIds }),
  });

export const updateProspectFiltersView = async ({
  id,
  filterName,
  filterCriteria,
  isShared,
  teamIds,
}: ProspectFiltersUpdateViewPayload) =>
  api.patch<ResponseSuccess>(`/contacts/prospect-view/${id}`, {
    filterName,
    filterCriteria,
    isShared,
    ...(filterName !== undefined && { filterName }),
    ...(filterCriteria !== undefined && { filterCriteria }),
    ...(isShared !== undefined && { isShared }),
    ...(teamIds !== undefined && { teamIds }),
  });

export const deleteProspectFiltersView = async (id: string) =>
  api.delete<ResponseSuccess>(`/contacts/prospect-view/${id}`);

export const getContactListByViewId = async (
  viewId: number,
  selectedField: string[],
) =>
  api.post<ResponseSuccess>(`/contacts/${viewId}/list`, {
    selectedField,
  });

export const getImportedFilesList = async (ids = '') =>
  api.get<ResponseSuccess>('/contacts/imported-files', {
    params: ids ? { ids } : {},
  });

export const updateProspectFieldById = async (payload: ProspectFieldPayload) =>
  api.post<ResponseSuccess>(`/contacts/${payload.contactId}/attribute`, {
    attributeValue: payload.attributeValue,
    fieldRef: payload.fieldRef,
  });

export const getLongTextByProspectAndColumnId = async (
  payload: ProspectFieldPayload,
) =>
  api.get<ResponseSuccess>(`/contacts/fields/long-text`, {
    params: {
      prospectId: Number(payload.contactId),
      fieldRefId: Number(payload.fieldRef),
    },
  });
