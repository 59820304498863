import i18n from 'i18next';
import planPermissionError from './index';

export const planError = (code?: number, message?: string) => {
  let title: string;
  let contents: string[];
  let showButtons: boolean;

  switch (code) {
    case 2002: {
      title = i18n.t('messages.plan_expired_message');
      contents = [i18n.t('messages.plan_expired_message_content')];
      showButtons = true;
      break;
    }

    case 2003: {
      title = i18n.t('messages.plan_expired_active_sequence_message');
      contents = [
        i18n.t('messages.plan_expired_active_sequence_content_1'),
        i18n.t('messages.plan_expired_active_sequence_content_2'),
      ];
      showButtons = true;
      break;
    }

    case 2004: {
      title = i18n.t('messages.plan_expired_clone_sequence_message');
      contents = [i18n.t('messages.subscription_warning')];
      showButtons = true;
      break;
    }

    case 2005: {
      title = i18n.t('messages.plan_expired_add_email_message');
      contents = [
        i18n.t('messages.plan_expired_add_email_content_1'),
        i18n.t('messages.plan_expired_add_email_content_2'),
      ];
      showButtons = true;
      break;
    }

    case 2007: {
      title = i18n.t('messages.plan_expired_invite_member_message');
      contents = [
        i18n.t('messages.plan_expired_invite_member_content_1'),
        i18n.t('messages.plan_expired_invite_member_content_2'),
      ];
      showButtons = true;
      break;
    }

    case 2009: {
      title = i18n.t('messages.plan_expired_connect_email_message');
      contents = [
        i18n.t('messages.plan_expired_connect_email_content_1'),
        i18n.t('messages.plan_expired_connect_email_content_2'),
      ];
      showButtons = true;
      break;
    }
    case 2010: {
      title = i18n.t('messages.plan_expired_create_api_key');
      contents = [i18n.t('messages.subscription_warning')];
      showButtons = true;
      break;
    }

    case 2011: {
      title = message;
      contents = [i18n.t('messages.lead_finder_low_credits')];
      break;
    }

    case 3002: {
      title = i18n.t('messages.plan_expired_message');
      contents = [i18n.t('messages.upgrade_plan_contact_admin')];
      showButtons = false;
      break;
    }

    case 3003: {
      title = i18n.t('messages.plan_expired_active_sequence_message');
      contents = [i18n.t('messages.upgrade_plan_contact_admin')];
      showButtons = false;
      break;
    }

    case 3004: {
      title = i18n.t('messages.plan_expired_clone_sequence_message');
      contents = [i18n.t('messages.upgrade_plan_contact_admin')];
      showButtons = false;
      break;
    }

    case 3005: {
      title = i18n.t('messages.plan_expired_add_email_message');
      contents = [i18n.t('messages.upgrade_plan_contact_admin')];
      showButtons = false;
      break;
    }

    case 3007: {
      title = i18n.t('messages.plan_expired_invite_member_message');
      contents = [i18n.t('messages.upgrade_plan_contact_admin')];
      showButtons = false;
      break;
    }

    case 3009: {
      title = i18n.t('messages.plan_expired_connect_email_message');
      contents = [i18n.t('messages.upgrade_plan_contact_admin')];
      showButtons = false;
      break;
    }

    case 5000: {
      title = i18n.t('labels.upgrade_required');
      contents = [i18n.t('messages.upgrade_plan_for_add_email_account_admin')];
      showButtons = true;
      break;
    }

    case 5001: {
      title = i18n.t('labels.upgrade_required');
      contents = [i18n.t('messages.upgrade_plan_for_add_email_account')];
      showButtons = true;
      break;
    }

    default: {
      title = message;
      contents = [i18n.t('messages.subscription_warning')];
    }
  }

  planPermissionError.showModal(title, contents, showButtons);
};
