import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Bulb,
  Check,
  ChevronLeft,
  ChevronRight,
  Messages,
  Star,
} from '@saleshandy/icons';
import { IProps } from './types';
import Button from '../../../../../../../../shared/design-system/components/atoms/button';
import {
  getInitialStepValue,
  getLeadFinderPlanMonthlyPrice,
  getLeadFinderPlanYearlyPrice,
  getPlanDetailsObj,
  getPlanIcon,
  getPlanPrice,
  getSelectedPlanProspects,
} from './utils/helper';
import { SelectPlanCTAText } from '../../../../../../../../shared/design-system/components/molecules/pricing-card/types';
import { OverlayTooltip } from '../../../../../../../../shared/design-system/components/overlay';
import { RangeInput } from '../range-input';
import { getCustomScalePlusPlanName, isAnnuallyPlan } from '../../utils/helper';
import { isLeadFinderScalePlusPlan } from '../plans-section/utils/helper';
import { getLeadFinderButtonLabel } from '../../../../../../../../shared/design-system/components/molecules/pricing-card/helpers';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';

const LeadFinderPlanCard: React.FC<IProps> = ({
  plan,
  customScalePlusPlans,
  isPurchaseInProgress,
  handlePlanSelect,
  sendGetLeadFinderSubscriptionResponse,
  sendGetLeadFinderPlansResponse,
  planCycle,
  isEmailPhonePlan,
}) => {
  const { t } = useTranslation();

  //* State */
  const [rangeInputValue, setRangeInputValue] = useState<number[]>([
    getInitialStepValue({
      planMode: sendGetLeadFinderSubscriptionResponse?.planMode,
      planCode: sendGetLeadFinderSubscriptionResponse?.planCode,
      planCycle,
      isEmailPhonePlan,
    }),
  ]);

  const [planDetails, setPlanDetails] = useState(getPlanDetailsObj(plan));

  const btnLabel = getLeadFinderButtonLabel({
    plan: planDetails,
    sendGetLeadFinderSubscriptionResponse,
    planCycle,
  });

  const getButtonTheme = () => {
    if (
      planDetails.isBestValuePlan &&
      btnLabel !== SelectPlanCTAText.CurrentPlan &&
      btnLabel !== SelectPlanCTAText.DowngradePlan
    ) {
      return Button.Theme.Solid;
    }
    return Button.Theme.Ghost;
  };

  const onRangeInputChange = (v: number[]) => {
    setRangeInputValue(v);

    //* Custom Scale Plus Plan */
    const scalePlusPlan = customScalePlusPlans.find(
      (p) =>
        p.planName ===
        getCustomScalePlusPlanName(
          v[0],
          isAnnuallyPlan(planCycle),
          isEmailPhonePlan,
        ),
    );

    if (scalePlusPlan) {
      setPlanDetails(getPlanDetailsObj(scalePlusPlan));
    }
  };

  const getPlanDiscountText = () => {
    if (isAnnuallyPlan(planCycle)) {
      return `Save $${
        (getLeadFinderPlanMonthlyPrice(
          planDetails.planCode,
          sendGetLeadFinderPlansResponse,
        ) - planDetails.price)*12
      } annually`;
    }

    return `Save $${
      (planDetails.price -
      getPlanPrice(
        getLeadFinderPlanYearlyPrice(
          planDetails.planCode,
          sendGetLeadFinderPlansResponse,
        ),
        12,
      ))*12
    } on annual plan`;
  };

  useEffect(() => {
    if (
      plan.isCustomPlan &&
      isLeadFinderScalePlusPlan(sendGetLeadFinderSubscriptionResponse?.planCode)
    ) {
      onRangeInputChange([
        getInitialStepValue({
          planMode: sendGetLeadFinderSubscriptionResponse?.planMode,
          planCode: sendGetLeadFinderSubscriptionResponse?.planCode,
          planCycle,
          isEmailPhonePlan,
        }),
      ]);
    }
  }, []);

  //* Class Variable */
  const cardClassName = classnames([
    'pricing-card',
    {
      'best-value-plan': planDetails.isBestValuePlan,
    },
  ]);

  const btnClassName = classnames([
    {
      'ghost-primary-new': !planDetails.isBestValuePlan,
    },
    {
      'ghost-primary-new-disable': btnLabel === SelectPlanCTAText.DowngradePlan,
    },
    {
      'solid-primary-new':
        btnLabel !== SelectPlanCTAText.CurrentPlan &&
        btnLabel !== SelectPlanCTAText.DowngradePlan &&
        planDetails.isBestValuePlan,
    },
    {
      'ghost-secondary-new-disable': btnLabel === SelectPlanCTAText.CurrentPlan,
    },
  ]);

  return (
    <div className={cardClassName}>
      <div className="best-value-plan__label-wrapper">
        {planDetails.isBestValuePlan && (
          <div className="best-value-plan__label">
            <Star color="#FFFFFF" />
            <span className="best-value-plan__label-text">
              {t('labels.most_popular')}
            </span>
          </div>
        )}
      </div>
      <div className="lead-finder-plan-card">
        <div className="card-body">
          <div className="pricing-card__details mb-2">
            <div className="pricing-card__details-header">
              <img src={getPlanIcon(planDetails.planName)} alt="plan-icon" />
              <span className="pricing-card__details-header-title">
                {planDetails.displayName}
              </span>
            </div>
            <div className="pricing-card__details-price-wrapper">
              {planDetails.price ? (
                <>
                  <div className="pricing-card__details-price-wrapper-currency">
                    <span className="pricing-card__details-price-wrapper-currency-amount">
                      ${planDetails.price}
                    </span>
                    {isAnnuallyPlan(planCycle) && (
                      <span className="pricing-card__details-price-wrapper-currency-amount-strikethrough">
                        $
                        {getLeadFinderPlanMonthlyPrice(
                          planDetails.planCode,
                          sendGetLeadFinderPlansResponse,
                        )}
                      </span>
                    )}
                    <span className="pricing-card__details-price-wrapper-currency-amount-suffix">
                      /Month
                    </span>
                  </div>
                  <div className="pricing-card__details-price-wrapper-discount">
                    <span className="pricing-card__details-price-wrapper-discount-text">
                      {getPlanDiscountText()}
                    </span>
                  </div>
                </>
              ) : (
                <div className="mt-1">
                  <Messages
                    width={28}
                    height={28}
                    className="popover-arrow-color-txt"
                  />
                </div>
              )}
            </div>

            <Button
              variant={Button.Variant.Primary}
              theme={getButtonTheme()}
              onClick={() =>
                handlePlanSelect(planDetails.id, planDetails.planName)
              }
              className={`w-100 ${btnClassName}`}
              isLoading={isPurchaseInProgress}
              disabled={
                btnLabel === SelectPlanCTAText.CurrentPlan ||
                btnLabel === SelectPlanCTAText.DowngradePlan ||
                isPurchaseInProgress
              }
            >
              {btnLabel}
            </Button>

            <div className="pricing-card__features">
              {plan?.isCustomPlan && (
                <div className="range-wrap">
                  <RangeInput
                    value={rangeInputValue}
                    onChange={onRangeInputChange}
                  />
                </div>
              )}
              {plan?.isCustomPlan && (
                <div className="pricing-card__features-slider-control">
                  <span
                    className="pricing-card__features-slider-control-icon"
                    {...(rangeInputValue[0] > 1 &&
                      accessibleOnClick(() =>
                        onRangeInputChange([rangeInputValue[0] - 1]),
                      ))}
                  >
                    <ChevronLeft height={14} width={14} color="#6B7280" />
                  </span>
                  <span className="pricing-card__features-slider-control-text">
                    <span className="font-semibold">
                      {getSelectedPlanProspects(rangeInputValue[0])}{' '}
                    </span>
                    <span>lead finder credits</span>
                  </span>
                  <span
                    className="pricing-card__features-slider-control-icon"
                    {...(rangeInputValue[0] < 4 &&
                      accessibleOnClick(() =>
                        onRangeInputChange([rangeInputValue[0] + 1]),
                      ))}
                  >
                    <ChevronRight height={14} width={14} color="#6B7280" />
                  </span>
                </div>
              )}
              <ul className="pricing-card__features-list">
                {planDetails.features?.map(
                  ({
                    label,
                    labelClass,
                    tooltipText,
                    strikeText,
                    highlight,
                  }) => (
                    <li
                      key={label}
                      className={classnames([
                        'pricing-card__features-list-item',
                        {
                          'bg-color': highlight,
                        },
                      ])}
                    >
                      <span className="pricing-card__features-list-item-check">
                        <Check color="#202020" />
                      </span>
                      <>
                        {tooltipText ? (
                          <OverlayTooltip text={tooltipText}>
                            <span
                              className={classnames([
                                'pricing-card__features-list-item-label',
                                'underline-dashed',
                                labelClass,
                              ])}
                            >
                              {strikeText && (
                                <span className="regular-1 gray-txt-15 mr-1 text-decoration-line-through">
                                  {strikeText}
                                </span>
                              )}
                              {label}
                            </span>
                          </OverlayTooltip>
                        ) : (
                          <span
                            className={classnames([
                              'pricing-card__features-list-item-label',
                              labelClass,
                            ])}
                          >
                            {strikeText && (
                              <span className="regular-1 gray-txt-15 mr-1 text-decoration-line-through">
                                {strikeText}
                              </span>
                            )}
                            {label}
                          </span>
                        )}
                      </>
                    </li>
                  ),
                )}
              </ul>
            </div>

            {planDetails.isBestValuePlan && (
              <div className="pricing-card__best-value-plan-insight">
                <Bulb color="#4F46E5" />
                <span className="pricing-card__best-value-plan-insight-text">
                  {isAnnuallyPlan(planCycle)
                    ? t('labels.best_value_plan_insight_yearly')
                    : t('labels.best_value_plan_insight_monthly')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadFinderPlanCard;
