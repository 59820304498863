import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from '../../../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../../../shared/types';
import {
  getAccountSubscriptionRequest,
  getLeadFinderSubscriptionRequest,
  getConnectedUsersAndEmailAccountsRequest,
  getInvoiceURLRequest,
  getPlansRequest,
  getLeadFinderPlansRequest,
  purchaseSubscriptionRequest,
  purchaseLeadFinderSubscriptionRequest,
  applyCouponCodeRequest,
  getEmailVerificationCreditsRequest,
  getEmailVerificationPlanRequest,
  purchaseEmailVerificationCreditsRequest,
  modifySubscriptionRequest,
  purchaseModifySubscriptionRequest,
  purchaseLeadFinderModifySubscriptionRequest,
  reactivateSubscriptionRequest,
  reactivateLeadFinderSubscriptionRequest,
  resumeSubscriptionRequest,
  resumeLeadFinderSubscriptionRequest,
  handleSubscriptionRequest,
  handleLeadFinderChurnkeySubscriptionRequest,
  calculatePayRequest,
  upgradePlanRequest,
  downgradePlanRequest,
  purchaseLtdPlanRequest,
  upgradeLtdPlanRequest,
  modifyLtdPlanRequest,
  getAccountSubscriptionPlanRequest,
  getPurchaseHistoryRequest,
  getPurchaseDetailsByIdRequest,
  exportCreditHistoryRequest,
  getLeadFinderOneTimePlansRequest,
  purchaseLeadFinderOneTimePlanRequest,
} from './extra-actions';
import {
  ApplyCouponCodeResponse,
  GetAccountSubscriptionResponse,
  GetAccountSubscriptionPlanResponse,
  GetLeadFinderSubscriptionResponse,
  GetConnectedUsersAndEmailAccountsResponse,
  GetEmailVerificationCreditsResponse,
  GetEmailVerificationPlanResponse,
  GetInvoiceURLResponse,
  GetPlansResponse,
  PurchaseModifySubscriptionResponse,
  PurchaseEmailVerificationCreditsResponse,
  ModifySubscriptionResponse,
  ReactivateResumeSubscriptionResponse,
  HandleSubscriptionResponse,
  SelectedPlanDetails,
  CalculatePayResponse,
} from './types';
import { PurchaseDetails, PurchaseHistory } from './types/purchase-history';
import { GetLeadFinderPlanResponse } from './types/response/get-lead-finder-credit-plans';
import { PurchaseLeadFinderCreditsResponse } from './types/purchase-lead-finder-credits';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  getAccountSubscriptionRequest: RequestState;
  getAccountSubscriptionPlanRequest: RequestState;
  getLeadFinderSubscriptionRequest: RequestState;
  getPlansRequest: RequestState;
  getLeadFinderPlansRequest: RequestState;
  getInvoiceURLRequest: RequestState;
  getConnectedUsersAndEmailAccountsRequest: RequestState;
  applyCouponCodeRequest: RequestState;
  purchaseSubscriptionRequest: RequestState;
  purchaseLeadFinderSubscriptionRequest: RequestState;
  getEmailVerificationCreditsRequest: RequestState;
  getEmailVerificationPlanRequest: RequestState;
  purchaseEmailVerificationCreditsRequest: RequestState;
  modifySubscriptionRequest: RequestState;
  purchaseModifySubscriptionRequest: RequestState;
  purchaseLeadFinderModifySubscriptionRequest: RequestState;
  reactivateSubscriptionRequest: RequestState;
  reactivateLeadFinderSubscriptionRequest: RequestState;
  resumeSubscriptionRequest: RequestState;
  resumeLeadFinderSubscriptionRequest: RequestState;
  getAccountSubscriptionResponse: GetAccountSubscriptionResponse;
  getAccountSubscriptionPlanResponse: GetAccountSubscriptionPlanResponse;
  getLeadFinderSubscriptionResponse: GetLeadFinderSubscriptionResponse;
  getPlansResponse: GetPlansResponse;
  getLeadFinderPlansResponse: GetPlansResponse;
  getInvoiceResponse: GetInvoiceURLResponse;
  getConnectedUsersAndEmailAccountsResponse: GetConnectedUsersAndEmailAccountsResponse;
  applyCouponCodeResponse: ApplyCouponCodeResponse;
  getEmailVerificationCreditsResponse: GetEmailVerificationCreditsResponse;
  getEmailVerificationPlanResponse: GetEmailVerificationPlanResponse[];
  cancelEmailVerificationPlanRequest: RequestState;
  purchaseEmailVerificationCreditsResponse: PurchaseEmailVerificationCreditsResponse;
  modifySubscriptionResponse: ModifySubscriptionResponse;
  purchaseModifySubscriptionResponse: PurchaseModifySubscriptionResponse;
  purchaseLeadFinderModifySubscriptionResponse: PurchaseModifySubscriptionResponse;
  reactivateSubscriptionResponse: ReactivateResumeSubscriptionResponse;
  reactivateLeadFinderSubscriptionResponse: ReactivateResumeSubscriptionResponse;
  resumeSubscriptionResponse: ReactivateResumeSubscriptionResponse;
  resumeLeadFinderSubscriptionResponse: ReactivateResumeSubscriptionResponse;
  handleSubscriptionRequest: RequestState;
  handleLeadFinderChurnkeySubscriptionRequest: RequestState;
  handleSubscriptionResponse: HandleSubscriptionResponse;
  handleLeadFinderChurnkeySubscriptionResponse: HandleSubscriptionResponse;
  selectedPlanDetails: SelectedPlanDetails;
  calculatePayRequest: RequestState;
  upgradePlanRequest: RequestState;
  downgradePlanRequest: RequestState;
  purchaseLtdPlanRequest: RequestState;
  upgradeLtdPlanRequest: RequestState;
  modifyLtdPlanRequest: RequestState;
  getCalculatePayResponse: CalculatePayResponse;
  purchaseLtdPlanResponse: ReactivateResumeSubscriptionResponse;
  upgradeLtdPlanResponse: ReactivateResumeSubscriptionResponse;
  modifyLtdPlanResponse: ReactivateResumeSubscriptionResponse;
  purchaseHistory: PurchaseHistory[];
  purchaseDetailsById: PurchaseDetails;
  getPurchaseHistoryRequest: RequestState;
  getPurchaseDetailsByIdRequest: RequestState;
  exportCreditHistoryRequest: RequestState;
  getLeadFinderOneTimePlansRequest: RequestState;
  getLeadFinderOneTimePlansResponse: GetLeadFinderPlanResponse[];
  purchaseLeadFinderOneTimePlanRequest: RequestState;
  purchaseLeadFinderOneTimePlanResponse: PurchaseLeadFinderCreditsResponse;
};

const initialState: State = {
  getAccountSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAccountSubscriptionPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getLeadFinderSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getInvoiceURLRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getPlansRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getLeadFinderPlansRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseLeadFinderSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getConnectedUsersAndEmailAccountsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  applyCouponCodeRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailVerificationCreditsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailVerificationPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseEmailVerificationCreditsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  cancelEmailVerificationPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  modifySubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseModifySubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseLeadFinderModifySubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  reactivateSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  reactivateLeadFinderSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  resumeSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  resumeLeadFinderSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  handleSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  handleLeadFinderChurnkeySubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  calculatePayRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  upgradePlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  downgradePlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseLtdPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  upgradeLtdPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  modifyLtdPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  selectedPlanDetails: null,
  getAccountSubscriptionResponse: null,
  getAccountSubscriptionPlanResponse: null,
  getLeadFinderSubscriptionResponse: null,
  getInvoiceResponse: null,
  getPlansResponse: null,
  getLeadFinderPlansResponse: null,
  getConnectedUsersAndEmailAccountsResponse: null,
  applyCouponCodeResponse: null,
  getEmailVerificationCreditsResponse: null,
  getEmailVerificationPlanResponse: null,
  purchaseEmailVerificationCreditsResponse: null,
  modifySubscriptionResponse: null,
  getCalculatePayResponse: null,
  purchaseModifySubscriptionResponse: null,
  purchaseLeadFinderModifySubscriptionResponse: null,
  reactivateSubscriptionResponse: null,
  reactivateLeadFinderSubscriptionResponse: null,
  resumeSubscriptionResponse: null,
  resumeLeadFinderSubscriptionResponse: null,
  handleSubscriptionResponse: null,
  handleLeadFinderChurnkeySubscriptionResponse: null,
  purchaseLtdPlanResponse: null,
  upgradeLtdPlanResponse: null,
  modifyLtdPlanResponse: null,
  purchaseHistory: [],
  purchaseDetailsById: null,
  getPurchaseHistoryRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getPurchaseDetailsByIdRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  exportCreditHistoryRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getLeadFinderOneTimePlansRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getLeadFinderOneTimePlansResponse: null,
  purchaseLeadFinderOneTimePlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseLeadFinderOneTimePlanResponse: null,
};

const billingSubscriptionSlice = createSlice({
  name: 'billingSubscription',
  initialState,
  reducers: {
    resetSubscription: (state) => {
      state.getAccountSubscriptionRequest =
        initialState.getAccountSubscriptionRequest;
      state.getAccountSubscriptionPlanRequest =
        initialState.getAccountSubscriptionPlanRequest;
      state.getLeadFinderSubscriptionRequest =
        initialState.getLeadFinderSubscriptionRequest;
      state.getAccountSubscriptionResponse =
        initialState.getAccountSubscriptionResponse;
      state.getAccountSubscriptionPlanResponse =
        initialState.getAccountSubscriptionPlanResponse;
      state.getLeadFinderSubscriptionResponse =
        initialState.getLeadFinderSubscriptionResponse;
      state.getInvoiceResponse = initialState.getInvoiceResponse;
      state.getInvoiceURLRequest = initialState.getInvoiceURLRequest;
      state.getPlansRequest = initialState.getPlansRequest;
      state.getLeadFinderPlansRequest = initialState.getLeadFinderPlansRequest;
      state.getLeadFinderPlansResponse =
        initialState.getLeadFinderPlansResponse;
      state.purchaseSubscriptionRequest =
        initialState.purchaseSubscriptionRequest;
      state.purchaseLeadFinderSubscriptionRequest =
        initialState.purchaseLeadFinderSubscriptionRequest;
      state.applyCouponCodeRequest = initialState.applyCouponCodeRequest;
      state.applyCouponCodeResponse = initialState.applyCouponCodeResponse;
      state.cancelEmailVerificationPlanRequest =
        initialState.cancelEmailVerificationPlanRequest;
      state.getEmailVerificationCreditsRequest =
        initialState.getEmailVerificationCreditsRequest;
      state.getEmailVerificationCreditsResponse =
        initialState.getEmailVerificationCreditsResponse;
      state.getEmailVerificationPlanRequest =
        initialState.getEmailVerificationPlanRequest;
      state.getEmailVerificationPlanResponse =
        initialState.getEmailVerificationPlanResponse;
      state.purchaseEmailVerificationCreditsRequest =
        initialState.purchaseEmailVerificationCreditsRequest;
      state.purchaseEmailVerificationCreditsResponse =
        initialState.purchaseEmailVerificationCreditsResponse;
      state.modifySubscriptionRequest = initialState.modifySubscriptionRequest;
      state.modifySubscriptionResponse =
        initialState.modifySubscriptionResponse;
      state.purchaseModifySubscriptionRequest =
        initialState.purchaseModifySubscriptionRequest;
      state.purchaseLeadFinderModifySubscriptionRequest =
        initialState.purchaseLeadFinderModifySubscriptionRequest;
      state.purchaseModifySubscriptionResponse =
        initialState.purchaseModifySubscriptionResponse;
      state.reactivateSubscriptionRequest =
        initialState.reactivateSubscriptionRequest;
      state.reactivateLeadFinderSubscriptionRequest =
        initialState.reactivateLeadFinderSubscriptionRequest;
      state.reactivateSubscriptionResponse =
        initialState.reactivateSubscriptionResponse;
      state.reactivateLeadFinderSubscriptionResponse =
        initialState.reactivateLeadFinderSubscriptionResponse;
      state.resumeSubscriptionRequest =
        initialState.reactivateSubscriptionRequest;
      state.resumeLeadFinderSubscriptionRequest =
        initialState.resumeLeadFinderSubscriptionRequest;
      state.resumeSubscriptionResponse =
        initialState.resumeSubscriptionResponse;
      state.resumeLeadFinderSubscriptionResponse =
        initialState.resumeLeadFinderSubscriptionResponse;
      state.handleSubscriptionRequest = initialState.handleSubscriptionRequest;
      state.handleLeadFinderChurnkeySubscriptionRequest =
        initialState.handleLeadFinderChurnkeySubscriptionRequest;
      state.handleSubscriptionResponse =
        initialState.handleSubscriptionResponse;
      state.handleLeadFinderChurnkeySubscriptionResponse =
        initialState.handleLeadFinderChurnkeySubscriptionResponse;

      state.purchaseLtdPlanRequest = initialState.purchaseLtdPlanRequest;
      state.upgradeLtdPlanRequest = initialState.upgradeLtdPlanRequest;
      state.modifyLtdPlanRequest = initialState.modifyLtdPlanRequest;
      state.purchaseLtdPlanResponse = initialState.purchaseLtdPlanResponse;
      state.upgradeLtdPlanResponse = initialState.upgradeLtdPlanResponse;
      state.modifyLtdPlanResponse = initialState.modifyLtdPlanResponse;

      state.calculatePayRequest = initialState.calculatePayRequest;
      state.getCalculatePayResponse = initialState.getCalculatePayResponse;

      state.getLeadFinderOneTimePlansRequest =
        initialState.getLeadFinderOneTimePlansRequest;
      state.purchaseLeadFinderOneTimePlanRequest =
        initialState.purchaseLeadFinderOneTimePlanRequest;
      state.getLeadFinderOneTimePlansResponse =
        initialState.getLeadFinderOneTimePlansResponse;
    },

    /** we're using purchase email verification from different places.
     *  so we need to reset from every places.
     */
    resetPurchaseEmailVerificationCreditsResponse: (state) => {
      state.purchaseEmailVerificationCreditsRequest =
        initialState.purchaseEmailVerificationCreditsRequest;
      state.purchaseEmailVerificationCreditsResponse =
        initialState.purchaseEmailVerificationCreditsResponse;
    },

    resetPurchaseModifySubscriptionResponse: (state) => {
      state.purchaseModifySubscriptionRequest =
        initialState.purchaseModifySubscriptionRequest;
      state.purchaseModifySubscriptionResponse =
        initialState.purchaseModifySubscriptionResponse;
    },

    resetPurchaseLeadFinderModifySubscriptionResponse: (state) => {
      state.purchaseLeadFinderModifySubscriptionRequest =
        initialState.purchaseLeadFinderModifySubscriptionRequest;
      state.purchaseLeadFinderModifySubscriptionResponse =
        initialState.purchaseLeadFinderModifySubscriptionResponse;
    },

    resetReactivateSubscriptionResponse: (state) => {
      state.reactivateSubscriptionRequest =
        initialState.reactivateSubscriptionRequest;
      state.reactivateSubscriptionResponse =
        initialState.reactivateSubscriptionResponse;
    },

    resetReactivateLeadFinderSubscriptionResponse: (state) => {
      state.reactivateLeadFinderSubscriptionRequest =
        initialState.reactivateLeadFinderSubscriptionRequest;
      state.reactivateLeadFinderSubscriptionResponse =
        initialState.reactivateLeadFinderSubscriptionResponse;
    },

    resetResumeSubscriptionResponse: (state) => {
      state.resumeSubscriptionRequest = initialState.resumeSubscriptionRequest;
      state.resumeSubscriptionResponse =
        initialState.resumeSubscriptionResponse;
    },

    resetResumeLeadFinderSubscriptionResponse: (state) => {
      state.resumeLeadFinderSubscriptionRequest =
        initialState.resumeLeadFinderSubscriptionRequest;
      state.resumeLeadFinderSubscriptionResponse =
        initialState.resumeLeadFinderSubscriptionResponse;
    },

    resetHandleSubscriptionResponse: (state) => {
      state.handleSubscriptionRequest = initialState.handleSubscriptionRequest;
      state.handleSubscriptionResponse =
        initialState.handleSubscriptionResponse;
    },

    resetHandleLeadFinderSubscriptionChurnkeyResponse: (state) => {
      state.handleLeadFinderChurnkeySubscriptionRequest =
        initialState.handleLeadFinderChurnkeySubscriptionRequest;
      state.handleLeadFinderChurnkeySubscriptionResponse =
        initialState.handleLeadFinderChurnkeySubscriptionResponse;
    },

    resetModifySubscriptionResponse: (state) => {
      state.modifySubscriptionRequest = initialState.modifySubscriptionRequest;
      state.modifySubscriptionResponse =
        initialState.modifySubscriptionResponse;
    },

    deleteAppliedCouponCode: (state) => {
      state.applyCouponCodeResponse = initialState.applyCouponCodeResponse;
      state.applyCouponCodeRequest = initialState.applyCouponCodeRequest;
    },
    resetConnectedUsersAndEmailAccountsRequest: (state) => {
      state.getConnectedUsersAndEmailAccountsRequest =
        initialState.getConnectedUsersAndEmailAccountsRequest;
    },

    // Reset Calculate Pay State
    resetCalculatePayState: (state) => {
      state.calculatePayRequest = initialState.calculatePayRequest;
      state.getCalculatePayResponse = initialState.getCalculatePayResponse;
    },

    // Reset Purchase LTD Plan State
    resetPurchaseLtdPlan: (state) => {
      state.purchaseLtdPlanRequest = initialState.purchaseLtdPlanRequest;
      state.purchaseLtdPlanResponse = initialState.purchaseLtdPlanResponse;
    },

    // Reset Upgrade LTD Plan State
    resetUpgradeLtdPlan: (state) => {
      state.upgradeLtdPlanRequest = initialState.upgradeLtdPlanRequest;
      state.upgradeLtdPlanResponse = initialState.upgradeLtdPlanResponse;
    },

    // Reset Modify LTD Plan State
    resetModifyLtdPlan: (state) => {
      state.modifyLtdPlanRequest = initialState.modifyLtdPlanRequest;
      state.modifyLtdPlanResponse = initialState.modifyLtdPlanResponse;
    },

    // Store the select plan details
    selectPlan: (state, action: PayloadAction<SelectedPlanDetails>) => {
      state.selectedPlanDetails = action.payload;
    },

    // Reset Invoice State
    resetInvoice: (state) => {
      state.getInvoiceURLRequest = initialState.getInvoiceURLRequest;
      state.getInvoiceResponse = initialState.getInvoiceResponse;
    },

    // Reset Purchase Details
    resetPurchaseDetails: (state) => {
      state.purchaseDetailsById = initialState.purchaseDetailsById;
    },

    // Reset Export Credit History
    resetExportCreditHistoryRequest: (state) => {
      state.exportCreditHistoryRequest =
        initialState.exportCreditHistoryRequest;
    },

    // Reset Purchase Lead Finder Plan
    resetPurchaseLeadFinderOneTimePlan: (state) => {
      state.purchaseLeadFinderOneTimePlanRequest =
        initialState.purchaseLeadFinderOneTimePlanRequest;
      state.purchaseLeadFinderOneTimePlanResponse =
        initialState.purchaseLeadFinderOneTimePlanResponse;
    },
  },
  extraReducers: (builder) => {
    // Get account subscription detail
    builder.addCase(getAccountSubscriptionRequest.pending, (state) => {
      state.getAccountSubscriptionRequest.status = RequestStatus.Pending;
      state.getAccountSubscriptionRequest.message = null;
      state.getAccountSubscriptionRequest.error = null;
    });
    builder.addCase(getAccountSubscriptionPlanRequest.pending, (state) => {
      state.getAccountSubscriptionPlanRequest.status = RequestStatus.Pending;
      state.getAccountSubscriptionPlanRequest.message = null;
      state.getAccountSubscriptionPlanRequest.error = null;
    });
    builder.addCase(
      getAccountSubscriptionRequest.fulfilled,
      (state, action) => {
        state.getAccountSubscriptionRequest.status = RequestStatus.Succeeded;
        state.getAccountSubscriptionResponse = action.payload.payload;
        state.getAccountSubscriptionResponse.nextBillingAt = state.getAccountSubscriptionResponse.nextBillingAt?.replace(
          /-/g,
          '/',
        );
        state.getAccountSubscriptionResponse.startAt = state.getAccountSubscriptionResponse.startAt?.replace(
          /-/g,
          '/',
        );
        state.getAccountSubscriptionRequest.message = null;
        state.getAccountSubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      getAccountSubscriptionPlanRequest.fulfilled,
      (state, action) => {
        state.getAccountSubscriptionPlanRequest.status =
          RequestStatus.Succeeded;
        state.getAccountSubscriptionPlanResponse = action.payload.payload;
        state.getAccountSubscriptionPlanResponse.startAt = state.getAccountSubscriptionPlanResponse.startAt?.replace(
          /-/g,
          '/',
        );
        state.getAccountSubscriptionPlanResponse.endAt = state.getAccountSubscriptionPlanResponse.endAt?.replace(
          /-/g,
          '/',
        );
        // state.getAccountSubscriptionPlanResponse.message = null;
        // state.getAccountSubscriptionPlanResponse.error = null;
      },
    );
    builder.addCase(getAccountSubscriptionRequest.rejected, (state, action) => {
      state.getAccountSubscriptionRequest.status = RequestStatus.Failed;
      state.getAccountSubscriptionRequest.message = action.payload.message;
      state.getAccountSubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });
    builder.addCase(
      getAccountSubscriptionPlanRequest.rejected,
      (state, action) => {
        state.getAccountSubscriptionRequest.status = RequestStatus.Failed;
        state.getAccountSubscriptionRequest.message = action.payload.message;
        state.getAccountSubscriptionRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Lead Finder subscription detail

    builder.addCase(getLeadFinderSubscriptionRequest.pending, (state) => {
      state.getLeadFinderSubscriptionRequest.status = RequestStatus.Pending;
      state.getLeadFinderSubscriptionRequest.message = null;
      state.getLeadFinderSubscriptionRequest.error = null;
    });
    builder.addCase(
      getLeadFinderSubscriptionRequest.fulfilled,
      (state, action) => {
        state.getLeadFinderSubscriptionRequest.status = RequestStatus.Succeeded;
        state.getLeadFinderSubscriptionResponse = action.payload.payload;
        state.getLeadFinderSubscriptionResponse.nextBillingAt = state.getLeadFinderSubscriptionResponse.nextBillingAt?.replace(
          /-/g,
          '/',
        );
        state.getLeadFinderSubscriptionResponse.startAt = state.getLeadFinderSubscriptionResponse.startAt?.replace(
          /-/g,
          '/',
        );
        state.getLeadFinderSubscriptionRequest.message = null;
        state.getLeadFinderSubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      getLeadFinderSubscriptionRequest.rejected,
      (state, action) => {
        state.getLeadFinderSubscriptionRequest.status = RequestStatus.Failed;
        state.getLeadFinderSubscriptionRequest.message = action.payload.message;
        state.getLeadFinderSubscriptionRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get invoice-url request
    builder.addCase(getInvoiceURLRequest.pending, (state) => {
      state.getInvoiceURLRequest.status = RequestStatus.Pending;
      state.getInvoiceURLRequest.message = null;
      state.getInvoiceURLRequest.error = null;
    });
    builder.addCase(getInvoiceURLRequest.fulfilled, (state, action) => {
      state.getInvoiceURLRequest.status = RequestStatus.Succeeded;
      state.getInvoiceResponse = action.payload.payload;
      state.getInvoiceURLRequest.message = null;
      state.getInvoiceURLRequest.error = null;
    });
    builder.addCase(getInvoiceURLRequest.rejected, (state, action) => {
      state.getInvoiceURLRequest.status = RequestStatus.Failed;
      state.getInvoiceURLRequest.message = action.payload.message;
      state.getInvoiceURLRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Purchase Subscription request
    builder.addCase(purchaseSubscriptionRequest.pending, (state) => {
      state.purchaseSubscriptionRequest.status = RequestStatus.Pending;
      state.purchaseSubscriptionRequest.message = null;
      state.purchaseSubscriptionRequest.error = null;
    });
    builder.addCase(purchaseSubscriptionRequest.fulfilled, (state, action) => {
      state.purchaseSubscriptionRequest.status = RequestStatus.Succeeded;
      state.purchaseSubscriptionRequest.message =
        action.payload.payload.message;
      state.purchaseSubscriptionRequest.error = null;
    });
    builder.addCase(purchaseSubscriptionRequest.rejected, (state, action) => {
      state.purchaseSubscriptionRequest.status = RequestStatus.Failed;
      state.purchaseSubscriptionRequest.message = action.payload.message;
      state.purchaseSubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Purchase Lead Finder Subscription request
    builder.addCase(purchaseLeadFinderSubscriptionRequest.pending, (state) => {
      state.purchaseLeadFinderSubscriptionRequest.status =
        RequestStatus.Pending;
      state.purchaseLeadFinderSubscriptionRequest.message = null;
      state.purchaseLeadFinderSubscriptionRequest.error = null;
    });
    builder.addCase(
      purchaseLeadFinderSubscriptionRequest.fulfilled,
      (state, action) => {
        state.purchaseLeadFinderSubscriptionRequest.status =
          RequestStatus.Succeeded;
        state.purchaseLeadFinderSubscriptionRequest.message =
          action.payload.payload.message;
        state.purchaseLeadFinderSubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      purchaseLeadFinderSubscriptionRequest.rejected,
      (state, action) => {
        state.purchaseLeadFinderSubscriptionRequest.status =
          RequestStatus.Failed;
        state.purchaseLeadFinderSubscriptionRequest.message =
          action.payload.message;
        state.purchaseLeadFinderSubscriptionRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Apply coupon code request
    builder.addCase(applyCouponCodeRequest.pending, (state) => {
      state.applyCouponCodeRequest.status = RequestStatus.Pending;
      state.applyCouponCodeRequest.message = null;
      state.applyCouponCodeRequest.error = null;
    });
    builder.addCase(applyCouponCodeRequest.fulfilled, (state, action) => {
      state.applyCouponCodeRequest.status = RequestStatus.Succeeded;
      state.applyCouponCodeRequest.message = action.payload.payload.message;
      state.applyCouponCodeRequest.error = null;
      state.applyCouponCodeResponse = action.payload.payload;
    });
    builder.addCase(applyCouponCodeRequest.rejected, (state, action) => {
      state.applyCouponCodeRequest.status = RequestStatus.Failed;
      state.applyCouponCodeRequest.message = action.payload.message;
      state.applyCouponCodeRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get plans request
    builder.addCase(getPlansRequest.pending, (state) => {
      state.getPlansRequest.status = RequestStatus.Pending;
      state.getPlansRequest.message = null;
      state.getPlansRequest.error = null;
    });
    builder.addCase(getPlansRequest.fulfilled, (state, action) => {
      state.getPlansRequest.status = RequestStatus.Succeeded;
      state.getPlansResponse = action.payload.payload.rows;
      state.getPlansRequest.message = null;
      state.getPlansRequest.error = null;
    });
    builder.addCase(getPlansRequest.rejected, (state, action) => {
      state.getPlansRequest.status = RequestStatus.Failed;
      state.getPlansRequest.message = action.payload.message;
      state.getPlansRequest.error = !action.payload.isHandled && action.payload;
    });

    // Get Lead Finder plans request
    builder.addCase(getLeadFinderPlansRequest.pending, (state) => {
      state.getLeadFinderPlansRequest.status = RequestStatus.Pending;
      state.getLeadFinderPlansRequest.message = null;
      state.getLeadFinderPlansRequest.error = null;
    });
    builder.addCase(getLeadFinderPlansRequest.fulfilled, (state, action) => {
      state.getLeadFinderPlansRequest.status = RequestStatus.Succeeded;
      state.getLeadFinderPlansResponse = action.payload.payload.rows;
      state.getLeadFinderPlansRequest.message = null;
      state.getLeadFinderPlansRequest.error = null;
    });
    builder.addCase(getLeadFinderPlansRequest.rejected, (state, action) => {
      state.getLeadFinderPlansRequest.status = RequestStatus.Failed;
      state.getLeadFinderPlansRequest.message = action.payload.message;
      state.getLeadFinderPlansRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get connected users and email accounts
    builder.addCase(
      getConnectedUsersAndEmailAccountsRequest.pending,
      (state) => {
        state.getConnectedUsersAndEmailAccountsRequest.status =
          RequestStatus.Pending;
        state.getConnectedUsersAndEmailAccountsRequest.message = null;
        state.getConnectedUsersAndEmailAccountsRequest.error = null;
      },
    );
    builder.addCase(
      getConnectedUsersAndEmailAccountsRequest.fulfilled,
      (state, action) => {
        state.getConnectedUsersAndEmailAccountsRequest.status =
          RequestStatus.Succeeded;
        // eslint-disable-next-line prefer-destructuring
        state.getConnectedUsersAndEmailAccountsResponse =
          action.payload.payload[0];
        state.getConnectedUsersAndEmailAccountsRequest.message = null;
        state.getConnectedUsersAndEmailAccountsRequest.error = null;
      },
    );
    builder.addCase(
      getConnectedUsersAndEmailAccountsRequest.rejected,
      (state, action) => {
        state.getConnectedUsersAndEmailAccountsRequest.status =
          RequestStatus.Failed;
        state.getConnectedUsersAndEmailAccountsRequest.message =
          action.payload.message;
        state.getConnectedUsersAndEmailAccountsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get email verification credits request
    builder.addCase(getEmailVerificationCreditsRequest.pending, (state) => {
      state.getEmailVerificationCreditsRequest.status = RequestStatus.Pending;
      state.getEmailVerificationCreditsRequest.message = null;
      state.getEmailVerificationCreditsRequest.error = null;
    });
    builder.addCase(
      getEmailVerificationCreditsRequest.fulfilled,
      (state, action) => {
        state.getEmailVerificationCreditsRequest.status =
          RequestStatus.Succeeded;
        state.getEmailVerificationCreditsResponse = action.payload.payload;
        state.getEmailVerificationCreditsRequest.message = null;
        state.getEmailVerificationCreditsRequest.error = null;
      },
    );
    builder.addCase(
      getEmailVerificationCreditsRequest.rejected,
      (state, action) => {
        state.getEmailVerificationCreditsRequest.status = RequestStatus.Failed;
        state.getEmailVerificationCreditsRequest.message =
          action.payload.message;
        state.getEmailVerificationCreditsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get email verification plans request
    builder.addCase(getEmailVerificationPlanRequest.pending, (state) => {
      state.getEmailVerificationPlanRequest.status = RequestStatus.Pending;
      state.getEmailVerificationPlanRequest.message = null;
      state.getEmailVerificationPlanRequest.error = null;
    });
    builder.addCase(
      getEmailVerificationPlanRequest.fulfilled,
      (state, action) => {
        state.getEmailVerificationPlanRequest.status = RequestStatus.Succeeded;
        state.getEmailVerificationPlanResponse = action.payload.payload.rows;
        state.getEmailVerificationPlanRequest.message = null;
        state.getEmailVerificationPlanRequest.error = null;
      },
    );
    builder.addCase(
      getEmailVerificationPlanRequest.rejected,
      (state, action) => {
        state.getEmailVerificationPlanRequest.status = RequestStatus.Failed;
        state.getEmailVerificationPlanRequest.message = action.payload.message;
        state.getEmailVerificationPlanRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Purchase email verification credits request
    builder.addCase(
      purchaseEmailVerificationCreditsRequest.pending,
      (state) => {
        state.purchaseEmailVerificationCreditsRequest.status =
          RequestStatus.Pending;
        state.purchaseEmailVerificationCreditsRequest.message = null;
        state.purchaseEmailVerificationCreditsRequest.error = null;
      },
    );
    builder.addCase(
      purchaseEmailVerificationCreditsRequest.fulfilled,
      (state, action) => {
        state.purchaseEmailVerificationCreditsRequest.status =
          RequestStatus.Succeeded;
        state.purchaseEmailVerificationCreditsResponse = action.payload.payload;
        state.purchaseEmailVerificationCreditsRequest.message =
          action.payload.payload.message;
        state.purchaseEmailVerificationCreditsRequest.error = null;
      },
    );
    builder.addCase(
      purchaseEmailVerificationCreditsRequest.rejected,
      (state, action) => {
        state.purchaseEmailVerificationCreditsRequest.status =
          RequestStatus.Failed;
        state.purchaseEmailVerificationCreditsRequest.message =
          action.payload.message;
        state.purchaseEmailVerificationCreditsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
    // Modify subscription request
    builder.addCase(modifySubscriptionRequest.pending, (state) => {
      state.modifySubscriptionRequest.status = RequestStatus.Pending;
      state.modifySubscriptionRequest.message = null;
      state.modifySubscriptionRequest.error = null;
    });
    builder.addCase(modifySubscriptionRequest.fulfilled, (state, action) => {
      state.modifySubscriptionRequest.status = RequestStatus.Succeeded;
      state.modifySubscriptionRequest.message = action.payload.payload.message;
      state.modifySubscriptionRequest.error = null;
      state.modifySubscriptionResponse = action.payload.payload;
    });
    builder.addCase(modifySubscriptionRequest.rejected, (state, action) => {
      state.modifySubscriptionRequest.status = RequestStatus.Failed;
      state.modifySubscriptionRequest.message = action.payload.message;
      state.modifySubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // Purchase modify subscription request
    builder.addCase(purchaseModifySubscriptionRequest.pending, (state) => {
      state.purchaseModifySubscriptionRequest.status = RequestStatus.Pending;
      state.purchaseModifySubscriptionRequest.message = null;
      state.purchaseModifySubscriptionRequest.error = null;
    });
    builder.addCase(
      purchaseModifySubscriptionRequest.fulfilled,
      (state, action) => {
        state.purchaseModifySubscriptionRequest.status =
          RequestStatus.Succeeded;
        state.purchaseModifySubscriptionResponse = action.payload?.payload;
        state.purchaseModifySubscriptionRequest.message = null;
        state.purchaseModifySubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      purchaseModifySubscriptionRequest.rejected,
      (state, action) => {
        state.purchaseModifySubscriptionRequest.status = RequestStatus.Failed;
        state.purchaseModifySubscriptionRequest.message =
          action.payload.message;
        state.purchaseModifySubscriptionRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Purchase Lead Finder modify subscription request
    builder.addCase(
      purchaseLeadFinderModifySubscriptionRequest.pending,
      (state) => {
        state.purchaseLeadFinderModifySubscriptionRequest.status =
          RequestStatus.Pending;
        state.purchaseLeadFinderModifySubscriptionRequest.message = null;
        state.purchaseLeadFinderModifySubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      purchaseLeadFinderModifySubscriptionRequest.fulfilled,
      (state, action) => {
        state.purchaseLeadFinderModifySubscriptionRequest.status =
          RequestStatus.Succeeded;
        state.purchaseLeadFinderModifySubscriptionResponse =
          action.payload?.payload;
        state.purchaseLeadFinderModifySubscriptionRequest.message =
          action.payload.payload.message;
        state.purchaseLeadFinderModifySubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      purchaseLeadFinderModifySubscriptionRequest.rejected,
      (state, action) => {
        state.purchaseLeadFinderModifySubscriptionRequest.status =
          RequestStatus.Failed;
        state.purchaseLeadFinderModifySubscriptionRequest.message =
          action.payload.message;
        state.purchaseLeadFinderModifySubscriptionRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Reactivate subscription request
    builder.addCase(reactivateSubscriptionRequest.pending, (state) => {
      state.reactivateSubscriptionRequest.status = RequestStatus.Pending;
      state.reactivateSubscriptionRequest.message = null;
      state.reactivateSubscriptionRequest.error = null;
    });
    builder.addCase(
      reactivateSubscriptionRequest.fulfilled,
      (state, action) => {
        state.reactivateSubscriptionRequest.status = RequestStatus.Succeeded;
        state.reactivateSubscriptionResponse = action.payload.payload;
        state.reactivateSubscriptionRequest.message = null;
        state.reactivateSubscriptionRequest.error = null;
      },
    );
    builder.addCase(reactivateSubscriptionRequest.rejected, (state, action) => {
      state.reactivateSubscriptionRequest.status = RequestStatus.Failed;
      state.reactivateSubscriptionRequest.message = action.payload.message;
      state.reactivateSubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Reactivate Lead Finder subscription request
    builder.addCase(
      reactivateLeadFinderSubscriptionRequest.pending,
      (state) => {
        state.reactivateLeadFinderSubscriptionRequest.status =
          RequestStatus.Pending;
        state.reactivateLeadFinderSubscriptionRequest.message = null;
        state.reactivateLeadFinderSubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      reactivateLeadFinderSubscriptionRequest.fulfilled,
      (state, action) => {
        state.reactivateLeadFinderSubscriptionRequest.status =
          RequestStatus.Succeeded;
        state.reactivateLeadFinderSubscriptionResponse = action.payload.payload;
        state.reactivateLeadFinderSubscriptionRequest.message = null;
        state.reactivateLeadFinderSubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      reactivateLeadFinderSubscriptionRequest.rejected,
      (state, action) => {
        state.reactivateLeadFinderSubscriptionRequest.status =
          RequestStatus.Failed;
        state.reactivateLeadFinderSubscriptionRequest.message =
          action.payload.message;
        state.reactivateLeadFinderSubscriptionRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Resume subscription request
    builder.addCase(resumeSubscriptionRequest.pending, (state) => {
      state.resumeSubscriptionRequest.status = RequestStatus.Pending;
      state.resumeSubscriptionRequest.message = null;
      state.resumeSubscriptionRequest.error = null;
    });
    builder.addCase(resumeSubscriptionRequest.fulfilled, (state, action) => {
      state.resumeSubscriptionRequest.status = RequestStatus.Succeeded;
      state.resumeSubscriptionResponse = action.payload?.payload;
      state.resumeSubscriptionRequest.message = null;
      state.resumeSubscriptionRequest.error = null;
    });
    builder.addCase(resumeSubscriptionRequest.rejected, (state, action) => {
      state.resumeSubscriptionRequest.status = RequestStatus.Failed;
      state.resumeSubscriptionRequest.message = action.payload.message;
      state.resumeSubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Resume Lead Finder subscription request
    builder.addCase(resumeLeadFinderSubscriptionRequest.pending, (state) => {
      state.resumeLeadFinderSubscriptionRequest.status = RequestStatus.Pending;
      state.resumeLeadFinderSubscriptionRequest.message = null;
      state.resumeLeadFinderSubscriptionRequest.error = null;
    });
    builder.addCase(
      resumeLeadFinderSubscriptionRequest.fulfilled,
      (state, action) => {
        state.resumeLeadFinderSubscriptionRequest.status =
          RequestStatus.Succeeded;
        state.resumeLeadFinderSubscriptionResponse = action.payload?.payload;
        state.resumeLeadFinderSubscriptionRequest.message = null;
        state.resumeLeadFinderSubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      resumeLeadFinderSubscriptionRequest.rejected,
      (state, action) => {
        state.resumeLeadFinderSubscriptionRequest.status = RequestStatus.Failed;
        state.resumeLeadFinderSubscriptionRequest.message =
          action.payload.message;
        state.resumeLeadFinderSubscriptionRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Handle Subscription Request
    builder.addCase(handleSubscriptionRequest.pending, (state) => {
      state.handleSubscriptionRequest.status = RequestStatus.Pending;
      state.handleSubscriptionRequest.message = null;
      state.handleSubscriptionRequest.error = null;
    });
    builder.addCase(handleSubscriptionRequest.fulfilled, (state, action) => {
      state.handleSubscriptionRequest.status = RequestStatus.Succeeded;
      state.handleSubscriptionResponse = action.payload.payload;
      state.handleSubscriptionRequest.message = null;
      state.handleSubscriptionRequest.error = null;
    });
    builder.addCase(handleSubscriptionRequest.rejected, (state, action) => {
      state.handleSubscriptionRequest.status = RequestStatus.Failed;
      state.handleSubscriptionRequest.message = action.payload.message;
      state.handleSubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Handle Lead Finder Subscription Request
    builder.addCase(
      handleLeadFinderChurnkeySubscriptionRequest.pending,
      (state) => {
        state.handleLeadFinderChurnkeySubscriptionRequest.status =
          RequestStatus.Pending;
        state.handleLeadFinderChurnkeySubscriptionRequest.message = null;
        state.handleLeadFinderChurnkeySubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      handleLeadFinderChurnkeySubscriptionRequest.fulfilled,
      (state, action) => {
        state.handleLeadFinderChurnkeySubscriptionRequest.status =
          RequestStatus.Succeeded;
        state.handleLeadFinderChurnkeySubscriptionResponse =
          action.payload.payload;
        state.handleLeadFinderChurnkeySubscriptionRequest.message = null;
        state.handleLeadFinderChurnkeySubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      handleLeadFinderChurnkeySubscriptionRequest.rejected,
      (state, action) => {
        state.handleLeadFinderChurnkeySubscriptionRequest.status =
          RequestStatus.Failed;
        state.handleLeadFinderChurnkeySubscriptionRequest.message =
          action.payload.message;
        state.handleLeadFinderChurnkeySubscriptionRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Calculate Pay Request
    builder.addCase(calculatePayRequest.pending, (state) => {
      state.calculatePayRequest.status = RequestStatus.Pending;
      state.calculatePayRequest.message = null;
      state.calculatePayRequest.error = null;
    });
    builder.addCase(calculatePayRequest.fulfilled, (state, action) => {
      state.calculatePayRequest.status = RequestStatus.Succeeded;
      state.getCalculatePayResponse = action.payload.payload;
      state.calculatePayRequest.message = action.payload.message;
      state.calculatePayRequest.error = null;
    });
    builder.addCase(calculatePayRequest.rejected, (state, action) => {
      state.calculatePayRequest.status = RequestStatus.Failed;
      state.calculatePayRequest.message = action.payload.message;
      state.calculatePayRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Upgrade Plan Request
    builder.addCase(upgradePlanRequest.pending, (state) => {
      state.upgradePlanRequest.status = RequestStatus.Pending;
      state.upgradePlanRequest.message = null;
      state.upgradePlanRequest.error = null;
    });
    builder.addCase(upgradePlanRequest.fulfilled, (state) => {
      state.upgradePlanRequest.status = RequestStatus.Succeeded;
      state.upgradePlanRequest.message = null;
      state.upgradePlanRequest.error = null;
    });
    builder.addCase(upgradePlanRequest.rejected, (state, action) => {
      state.upgradePlanRequest.status = RequestStatus.Failed;
      state.upgradePlanRequest.message = action.payload.message;
      state.upgradePlanRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Downgrade Plan Request
    builder.addCase(downgradePlanRequest.pending, (state) => {
      state.downgradePlanRequest.status = RequestStatus.Pending;
      state.downgradePlanRequest.message = null;
      state.downgradePlanRequest.error = null;
    });
    builder.addCase(downgradePlanRequest.fulfilled, (state) => {
      state.downgradePlanRequest.status = RequestStatus.Succeeded;
      state.downgradePlanRequest.message = null;
      state.downgradePlanRequest.error = null;
    });
    builder.addCase(downgradePlanRequest.rejected, (state, action) => {
      state.downgradePlanRequest.status = RequestStatus.Failed;
      state.downgradePlanRequest.message = action.payload.message;
      state.downgradePlanRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // LTD Purchase Plan Request
    builder.addCase(purchaseLtdPlanRequest.pending, (state) => {
      state.purchaseLtdPlanRequest.status = RequestStatus.Pending;
      state.purchaseLtdPlanRequest.message = null;
      state.purchaseLtdPlanRequest.error = null;
    });
    builder.addCase(purchaseLtdPlanRequest.fulfilled, (state, action) => {
      state.purchaseLtdPlanRequest.status = RequestStatus.Succeeded;
      state.purchaseLtdPlanRequest.message = action.payload.message;
      state.purchaseLtdPlanRequest.error = null;
      state.purchaseLtdPlanResponse = action.payload.payload;
    });
    builder.addCase(purchaseLtdPlanRequest.rejected, (state, action) => {
      state.purchaseLtdPlanRequest.status = RequestStatus.Failed;
      state.purchaseLtdPlanRequest.message = action.payload.message;
      state.purchaseLtdPlanRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // LTD Upgrade Plan Request
    builder.addCase(upgradeLtdPlanRequest.pending, (state) => {
      state.upgradeLtdPlanRequest.status = RequestStatus.Pending;
      state.upgradeLtdPlanRequest.message = null;
      state.upgradeLtdPlanRequest.error = null;
    });
    builder.addCase(upgradeLtdPlanRequest.fulfilled, (state, action) => {
      state.upgradeLtdPlanRequest.status = RequestStatus.Succeeded;
      state.upgradeLtdPlanRequest.message = action.payload.message;
      state.upgradeLtdPlanRequest.error = null;
      state.upgradeLtdPlanResponse = action.payload.payload;
    });
    builder.addCase(upgradeLtdPlanRequest.rejected, (state, action) => {
      state.upgradeLtdPlanRequest.status = RequestStatus.Failed;
      state.upgradeLtdPlanRequest.message = action.payload.message;
      state.upgradeLtdPlanRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // LTD Modify Plan Request
    builder.addCase(modifyLtdPlanRequest.pending, (state) => {
      state.modifyLtdPlanRequest.status = RequestStatus.Pending;
      state.modifyLtdPlanRequest.message = null;
      state.modifyLtdPlanRequest.error = null;
    });
    builder.addCase(modifyLtdPlanRequest.fulfilled, (state, action) => {
      state.modifyLtdPlanRequest.status = RequestStatus.Succeeded;
      state.modifyLtdPlanRequest.message = action.payload.message;
      state.modifyLtdPlanRequest.error = null;
      state.modifyLtdPlanResponse = action.payload.payload;
    });
    builder.addCase(modifyLtdPlanRequest.rejected, (state, action) => {
      state.modifyLtdPlanRequest.status = RequestStatus.Failed;
      state.modifyLtdPlanRequest.message = action.payload.message;
      state.modifyLtdPlanRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Purchase History
    builder.addCase(getPurchaseHistoryRequest.pending, (state) => {
      state.getPurchaseHistoryRequest.status = RequestStatus.Pending;
      state.getPurchaseHistoryRequest.error = null;
      state.getPurchaseHistoryRequest.message = null;
    });
    builder.addCase(getPurchaseHistoryRequest.fulfilled, (state, action) => {
      state.getPurchaseHistoryRequest.status = RequestStatus.Succeeded;
      state.purchaseHistory = action.payload.payload.history;
    });
    builder.addCase(getPurchaseHistoryRequest.rejected, (state, action) => {
      state.getPurchaseHistoryRequest.status = RequestStatus.Failed;
      state.getPurchaseHistoryRequest.message = action.payload.message;
      state.getPurchaseHistoryRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Purchase Details By Id
    builder.addCase(getPurchaseDetailsByIdRequest.pending, (state) => {
      state.getPurchaseDetailsByIdRequest.status = RequestStatus.Pending;
      state.getPurchaseDetailsByIdRequest.error = null;
      state.getPurchaseDetailsByIdRequest.message = null;
    });
    builder.addCase(
      getPurchaseDetailsByIdRequest.fulfilled,
      (state, action) => {
        state.getPurchaseDetailsByIdRequest.status = RequestStatus.Succeeded;
        state.purchaseDetailsById = action.payload.payload;
      },
    );
    builder.addCase(getPurchaseDetailsByIdRequest.rejected, (state, action) => {
      state.getPurchaseDetailsByIdRequest.status = RequestStatus.Failed;
      state.getPurchaseDetailsByIdRequest.message = action.payload.message;
      state.getPurchaseDetailsByIdRequest.error =
        !action.payload.isHandled && action.payload;
    });

    builder.addCase(exportCreditHistoryRequest.pending, (state) => {
      state.exportCreditHistoryRequest.status = RequestStatus.Pending;
      state.exportCreditHistoryRequest.error = null;
      state.exportCreditHistoryRequest.message = null;
    });

    builder.addCase(exportCreditHistoryRequest.fulfilled, (state, action) => {
      state.exportCreditHistoryRequest.status = RequestStatus.Succeeded;
      state.exportCreditHistoryRequest.message = action.payload.payload.message;
    });

    builder.addCase(exportCreditHistoryRequest.rejected, (state, action) => {
      state.exportCreditHistoryRequest.status = RequestStatus.Failed;
      state.exportCreditHistoryRequest.message = action.payload.message;
      state.exportCreditHistoryRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Lead Finder Plan Request
    builder.addCase(getLeadFinderOneTimePlansRequest.pending, (state) => {
      state.getLeadFinderOneTimePlansRequest.status = RequestStatus.Pending;
      state.getLeadFinderOneTimePlansRequest.message = null;
      state.getLeadFinderOneTimePlansRequest.error = null;
    });

    builder.addCase(getLeadFinderOneTimePlansRequest.fulfilled, (state, action) => {
      state.getLeadFinderOneTimePlansRequest.status = RequestStatus.Succeeded;
      state.getLeadFinderOneTimePlansResponse = action.payload.payload.rows;
      state.getLeadFinderOneTimePlansRequest.message = action.payload.payload.message;
    });

    builder.addCase(getLeadFinderOneTimePlansRequest.rejected, (state, action) => {
      state.getLeadFinderOneTimePlansRequest.status = RequestStatus.Failed;
      state.getLeadFinderOneTimePlansRequest.message = action.payload.message;
      state.getLeadFinderOneTimePlansRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Purchase Lead Finder Plan Request
    builder.addCase(purchaseLeadFinderOneTimePlanRequest.pending, (state) => {
      state.purchaseLeadFinderOneTimePlanRequest.status = RequestStatus.Pending;
      state.purchaseLeadFinderOneTimePlanRequest.message = null;
      state.purchaseLeadFinderOneTimePlanRequest.error = null;
    });

    builder.addCase(purchaseLeadFinderOneTimePlanRequest.fulfilled, (state, action) => {
      state.purchaseLeadFinderOneTimePlanRequest.status = RequestStatus.Succeeded;
      state.purchaseLeadFinderOneTimePlanResponse = action.payload.payload;
      state.purchaseLeadFinderOneTimePlanRequest.message = action.payload.payload.message;
      state.purchaseLeadFinderOneTimePlanRequest.error = null;
    });

    builder.addCase(purchaseLeadFinderOneTimePlanRequest.rejected, (state, action) => {
      state.purchaseLeadFinderOneTimePlanRequest.status = RequestStatus.Failed;
      state.purchaseLeadFinderOneTimePlanRequest.message = action.payload.message;
      state.purchaseLeadFinderOneTimePlanRequest.error =
        !action.payload.isHandled && action.payload;
    });
    
  },
});

export const {
  resetSubscription,
  deleteAppliedCouponCode,
  resetPurchaseEmailVerificationCreditsResponse,
  resetPurchaseModifySubscriptionResponse,
  resetPurchaseLeadFinderModifySubscriptionResponse,
  resetModifySubscriptionResponse,
  resetCalculatePayState,
  resetReactivateSubscriptionResponse,
  resetReactivateLeadFinderSubscriptionResponse,
  resetResumeSubscriptionResponse,
  resetResumeLeadFinderSubscriptionResponse,
  resetConnectedUsersAndEmailAccountsRequest,
  resetHandleSubscriptionResponse,
  resetHandleLeadFinderSubscriptionChurnkeyResponse,
  resetPurchaseLtdPlan,
  resetUpgradeLtdPlan,
  resetModifyLtdPlan,
  selectPlan,
  resetInvoice,
  resetPurchaseDetails,
  resetExportCreditHistoryRequest,
  resetPurchaseLeadFinderOneTimePlan,
} = billingSubscriptionSlice.actions;

export default billingSubscriptionSlice.reducer;
