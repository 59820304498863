import React, { useEffect, useState } from 'react';
import { NotificationBadgeProps } from './type';

const NotificationBadge: React.FC<NotificationBadgeProps> = ({
  userUnreadNotificationCount,
}) => {
  const [bounce, setBounce] = useState(true);

  useEffect(() => {
    // Function to toggle the classname
    const toggleClassName = () => {
      setBounce((prevState) => !prevState);
    };

    // Add the classname for 0.7 seconds, then remove it
    const interval = setInterval(() => {
      toggleClassName();
      setTimeout(() => {
        toggleClassName();
      }, 700); // 700 milliseconds = 0.7 seconds
    }, 10700); // 10700 milliseconds = 10.7 seconds

    // Clean up the interval to avoid memory leaks
    return () => clearInterval(interval);
  }, []);

  return (
    <span id="NF_badge_cont" className="NF_badge_cont NF_visible">
      <span
        id="NF_badge"
        className={`NF_badge NF_visible NF_animated  ${
          bounce ? 'NF_bounce' : ''
        }`}
      >
        {userUnreadNotificationCount > 9 ? '9+' : userUnreadNotificationCount}
      </span>
    </span>
  );
};

export default NotificationBadge;
