import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import { OAuthMethod } from './types';
import { getOAuthRedirectUrl, oauthAuthenticate } from '../../helpers/auth.api';
import { OAuthPayload } from '../../types';

type GetOAuthRedirectUrlRequestPayload = {
  method: OAuthMethod;
  payload: OAuthPayload;
};

type OAuthAuthenticateRequestPayload = {
  method: OAuthMethod;
  code: string;
  state?: string;
};

export const getOAuthRedirectUrlRequest = createAsyncThunk<
  ResponseSuccess,
  GetOAuthRedirectUrlRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('oauth/getRedirectUrl', async ({ method, payload }, thunkAPI) => {
  try {
    const response = await getOAuthRedirectUrl(method, payload);
    return response;
  } catch (err) {
    return thunkAPI.rejectWithValue(err as ResponseErrorWithHandled);
  }
});

export const oauthAuthenticateRequest = createAsyncThunk<
  ResponseSuccess,
  OAuthAuthenticateRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('oauth/authenticate', async ({ method, code, state }, thunkAPI) => {
  try {
    const response = await oauthAuthenticate(method, code, state);
    return response;
  } catch (err) {
    return thunkAPI.rejectWithValue(err as ResponseErrorWithHandled);
  }
});
