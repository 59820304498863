import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { ISSOCallbackState } from './types';
import { ssoCallbackRequest } from './extra-actions';

const initialState: ISSOCallbackState = {
  ssoCallbackRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: null,
    showError: false,
  },
  token: '',
  trackingId: '',
  firstName: '',
  lastName: '',
  email: '',
  redirectUrl: '',
  ssoWhitelabelDomain: '',
};

const ssoCallbackSlice = createSlice({
  name: 'ssoCallback',
  initialState,
  reducers: {
    hideError: (state) => {
      state.ssoCallbackRequest.showError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ssoCallbackRequest.pending, (state) => {
      state.ssoCallbackRequest.status = RequestStatus.Pending;
      state.ssoCallbackRequest.showError = false;
    });
    builder.addCase(ssoCallbackRequest.fulfilled, (state, action) => {
      state.ssoCallbackRequest.status = RequestStatus.Succeeded;
      state.ssoCallbackRequest.message = action.payload.message;
      state.token = action.payload.payload.accessToken;
      state.trackingId = action.payload.payload.trackingId;
      state.firstName = action.payload.payload.firstName;
      state.lastName = action.payload.payload.lastName;
      state.email = action.payload.payload.email;
      state.ssoWhitelabelDomain = action.payload.payload.userDomain;
    });
    builder.addCase(ssoCallbackRequest.rejected, (state, action) => {
      state.ssoCallbackRequest.status = RequestStatus.Failed;
      state.ssoCallbackRequest.error = action.payload;
      state.ssoWhitelabelDomain = action.payload?.payload?.userDomain;
      state.ssoCallbackRequest.showError = true;
    });
  },
});

export const { hideError } = ssoCallbackSlice.actions;
export default ssoCallbackSlice.reducer;
