import React, { useState } from 'react';
import Carousel from '../../design-system/ui/carousel';
import { Images } from '../../app-constants';

import { accessibleOnClick } from '../../utils/accessible-on-click';

type Testimonials = {
  id: number;
  message: React.ReactNode;
  name: string;
  profileURL: string;
  role: string;
};

const testimonials: Testimonials[] = [
  {
    id: 1,
    message: (
      <span>
        A must-have in your <span className="font-bold">outreach arsenal.</span>{' '}
        It has everything that you’re looking for your business growth.
      </span>
    ),
    name: 'Joshua Lim',
    profileURL: Images.Testimonial1,
    role: 'Managing Director, Social Hackrs',
  },
  {
    id: 2,
    message:
      'The perfect outreach platform for our business. You can add unlimited email accounts to increase volume at any scale.',
    name: 'Skyrocket',
    profileURL: Images.Testimonial2,
    role: 'Founder, SalesLink',
  },
  {
    id: 3,
    message: (
      <span>
        The <span className="font-bold">future of cadence</span> tools has
        arrived. The simplicity and straight forwardness of the product are
        great.
      </span>
    ),
    name: 'Thomas Foley',
    profileURL: Images.Testimonial3,
    role: 'HighTouch, Founder',
  },
];

const TestimonialsCarousel: React.FC = (): JSX.Element => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <div className="testimonials-carousel">
      <Carousel
        interval={15000}
        dynamicHeight={false}
        showIndicators={false}
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
      >
        {testimonials.map((testimonial) => (
          <div className="testimonial" key={testimonial.id}>
            <div className="quote">
              <img src={Images.Quotes} alt="quotes" />
            </div>

            <span className="font-medium">{testimonial.message}</span>
            <div className="testimonial--meta">
              <div className="testimonial--profile">
                <img src={testimonial.profileURL} alt="Profile" />
              </div>
              <div className="testimonial--details">
                <h3>{testimonial.name}</h3>
                <p>{testimonial.role}</p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="indicators">
        <div
          className={`indicator ${currentSlide === 0 ? 'active' : ''}`}
          {...accessibleOnClick(() => setCurrentSlide(0))}
        />
        <div
          className={`indicator ${currentSlide === 1 ? 'active' : ''}`}
          {...accessibleOnClick(() => setCurrentSlide(1))}
        />
        <div
          className={`indicator ${currentSlide === 2 ? 'active' : ''}`}
          {...accessibleOnClick(() => setCurrentSlide(2))}
        />
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
