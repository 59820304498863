// ** React Imports
import React, { memo } from 'react';
// ** Util Imports
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';

// ** Local Interfaces
export interface GroupOptionType {
  id: number;
  name?: string;
  label?: string;
}

export interface GroupType {
  label?: string;
  options: GroupOptionType[];
}

export interface CustomFilterGroupLabelProps {
  group: GroupType;
  handleParentGroupClick: (
    options: GroupOptionType[],
    isSelected: boolean,
  ) => void;
  isParentGroupSelected: boolean;
}

/**
 *
 * @description - This is react component to use Inside react-select dropdown to make option group actionable
 * @param group : Group contains react-select options group
 * @param handleParentGroupClick : handler for parent filter group
 * @param isParentGroupSelected : Boolean flag to check if parent group is selected or not
 * @returns React Component
 */
const CustomFilterGroupLabel: React.FC<CustomFilterGroupLabelProps> = ({
  group = null,
  handleParentGroupClick = () => {},
  isParentGroupSelected = false,
}) => (
  <div
    className={`parent-group-option ${isParentGroupSelected ? 'selected' : ''}`}
    {...accessibleOnClick(() =>
      handleParentGroupClick(group?.options ?? [], isParentGroupSelected),
    )}
  >
    <input
      className="parent-group-option-checkbox"
      type="checkbox"
      checked={isParentGroupSelected}
    />
    <span className="parent-group-option-label">{group?.label || ''}</span>
  </div>
);

export default memo(CustomFilterGroupLabel);
