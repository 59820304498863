/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Bookmark, Pencil, Trash } from '@saleshandy/icons';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  OverlayPopover,
  Placement,
} from '../../../../shared/design-system/components/overlay';
import SaveFilterModal from '../modal/save-filter-modal';
import { getFilterKeysWithExtraKeys } from '../../helpers/helper';
import {
  DEPARTMENT_DATA,
  INDUSTRY_DATA,
  LOCATION_DATA,
} from '../../helpers/filter-data';
import { getSavedFilterListAPI } from '../../helpers/leads.api';
import { SaveFilterModalVarient } from '../modal/save-filter-modal/types';
import Transfer from '../../../../shared/design-system/components/transfer/transfer';
import Button from '../../../../shared/design-system/components/atoms/button';
import Icon from '../../../../shared/design-system/components/atoms/icon';

export default function SavedFilter({ handleGeneratePayload, searchLeads, isLoading }) {
  const history = useHistory();
  const [savedFilters, setSavedFilters] = useState<any>([]);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState<any>();
  const [showPopOver, setshowPopOver] = useState<boolean>(false);
  const [showSaveFilter, setshowSaveFilter] = useState<boolean>(false);
  const [showEditFilter, setshowEditFilter] = useState<boolean>(false);
  const [showDeleteFilter, setshowDeleteFilter] = useState<boolean>(false);
  const [showAppliedFilter, setshowAppliedFilter] = useState<boolean>(false);

  const [activeFilter, setActiveFilter] = useState(null);

  const onToggle = () => {
    setshowPopOver(!showPopOver);
  };
  const onToggleAppliedFilter = () => {
    setshowAppliedFilter(!showAppliedFilter);
  };
  const hideSaveFilterModal = () => {
    setshowSaveFilter(false);
  };
  const hideEditSaveFilterModal = () => {
    setshowEditFilter(false);
  };
  const hideDeleteSavedFilterModal = () => {
    setshowDeleteFilter(false);
  };

  const getSavedFilters = async () => {
    const res = await getSavedFilterListAPI();
    if (res) {
      const mappedArray = res.payload.map((item) => ({
        ...item,
        key: item.name,
      }));
      setSavedFilters(mappedArray);
    }
  };
  const buildQueryString = (filterObject, history) => {
    const queryParams = [];
    queryParams.push(`start=${1}`);
    queryParams.push(`take=${25}`);
    Object.entries(filterObject).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        // Handle arrays
        if (key === 'location' || key === 'excludeLocation') {
          const keys = getFilterKeysWithExtraKeys(value, LOCATION_DATA);
          keys.forEach((item) => {
            queryParams.push(`${key}=${item}`);
          });
        } else if (key === 'department' || key === 'excludeDepartment') {
          const keys = getFilterKeysWithExtraKeys(value, DEPARTMENT_DATA);
          keys.forEach((item) => {
            queryParams.push(`${key}=${item}`);
          });
        } else if (key === 'industry' || key === 'excludeIndustry') {
          const keys = getFilterKeysWithExtraKeys(value, INDUSTRY_DATA);
          keys.forEach((item) => {
            queryParams.push(`${key}=${item}`);
          });
        } else {
          // Handle other arrays
          value.forEach((item) => {
            queryParams.push(`${key}=${item}`);
          });
        }
      } else {
        // Handle other types
        queryParams.push(`${key}=${value}`);
      }
    });

    const queryString = queryParams.join('&');
    const finalUrl = `/leads?${queryString}`;

    // Push to route
    history.push(finalUrl);
    searchLeads();
    setshowPopOver(false);
  };
  const applySavedFilter = (option: any) => {
    buildQueryString(option?.searchQuery, history);
  };

  useEffect(() => {
    getSavedFilters();
  }, []);

  useEffect(() => {
    if (savedFilters && savedFilters?.length > 0) {
      const payload = handleGeneratePayload();
      // eslint-disable-next-line no-restricted-syntax
      for (const f of savedFilters) {
        if (_.isEqual(f?.searchQuery, payload)) {
          setActiveFilter(f);
        }
      }
    }
    return () => {
      setActiveFilter(null);
    };
  }, [location?.search, savedFilters]);

  return (
    <div className="saved-filters-container">
      <div className='saved-filters-button'>
      <Button
        onClick={() => searchLeads()}
        variant={Button.Variant.Primary}
        iconLeft={<Icon identifier="search" className='search-icon'/>}
        disabled={!location?.search || isLoading}
      >
        <span className='search-txt'>Search</span>
      </Button>
      </div>
      <div className='saved-filters-details'>
        <div className="saved-filters">
          <OverlayPopover
            show={showPopOver}
            onToggle={onToggle}
            rootClose={true}
            className="prospect-tag-popover"
            content={
              <>
                <Transfer
                  options={savedFilters}
                  filterOption={(value, option) =>
                    option?.key?.toLowerCase().includes(value.toLowerCase())
                  }
                  optionRenderer={(option: any) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        setSelectedSavedFilter(option);
                        applySavedFilter(option);
                      }}
                    >
                      <p className="search-seved-filter">{option.key}</p>
                      <div className="d-flex">
                        <Pencil
                          className="edit-icon"
                          onClick={(e) => {
                            setshowEditFilter(true);
                            onToggle();
                            e?.stopPropagation();
                            setSelectedSavedFilter(option);
                          }}
                        />
                        <Trash
                          onClick={(e) => {
                            setshowDeleteFilter(true);
                            onToggle();
                            e?.stopPropagation();
                            setSelectedSavedFilter(option);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  showSearch
                  searchPlaceholder="Search"
                />
              </>
            }
            placement={Placement.TopStart}
          >
            <div className="d-flex align-items-center">
              <div className="icon">
                <Bookmark />
              </div>
              {/* eslint-disable-next-line */}
              <span onClick={() => setshowPopOver(true)}>Saved Filters</span>
            </div>
          </OverlayPopover>
          <OverlayPopover
            show={showAppliedFilter}
            onToggle={onToggleAppliedFilter}
            className="prospect-tag-applied"
            content={
              <div>
                <p className="saved-applied-filter">Saved Filter Applied</p>
                <p className="applied-filter-active">{activeFilter?.name}</p>
              </div>
            }
            placement={Placement.TopStart}
            rootClose={true}
          >
            <div className="d-flex align-items-center">
              {activeFilter && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  onClick={() => setshowAppliedFilter(true)}
                  className="saved-filter-dot d-block"
                />
              )}
            </div>
          </OverlayPopover>
        </div>

        <div className="save-filter">
          {/* eslint-disable-next-line */}
          <span
            onClick={() => {
              if (location?.search && !activeFilter) {
                setshowSaveFilter(true);
              }
            }}
            className={`save-filter-txt ${
              !location?.search || activeFilter ? 'disable-save-filter' : ''
            }`}
          >
            Save Filter
          </span>
        </div>

        {showSaveFilter && (
          <SaveFilterModal
            show={showSaveFilter}
            varient={SaveFilterModalVarient.Save}
            onHide={hideSaveFilterModal}
            isLoading={true}
            getSavedFilters={getSavedFilters}
            handleGeneratePayload={handleGeneratePayload}
          />
        )}
        {showEditFilter && (
          <SaveFilterModal
            show={showEditFilter}
            varient={SaveFilterModalVarient.Edit}
            onHide={hideEditSaveFilterModal}
            isLoading={true}
            selectedSavedFilter={selectedSavedFilter}
            getSavedFilters={getSavedFilters}
          />
        )}
        {showDeleteFilter && (
          <SaveFilterModal
            show={showDeleteFilter}
            varient={SaveFilterModalVarient.Delete}
            onHide={hideDeleteSavedFilterModal}
            isLoading={true}
            selectedSavedFilter={selectedSavedFilter}
            getSavedFilters={getSavedFilters}
          />
        )}
      </div>
    </div>
  );
}
