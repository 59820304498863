import { getSubtotalDiscountAmount } from '../../../../../../../../../shared/design-system/components/molecules/upgrade-plan-billing-section/helper/helper';

export const getTotalAmountForOutreachPlan = ({
  totalAmount,
  discountType,
  discount,
  sendGetCalculatePayResponse,
}) => {
  let subTotal = totalAmount;

  if (sendGetCalculatePayResponse) {
    subTotal = sendGetCalculatePayResponse?.amountToPay;
  }

  const discountedAmount = getSubtotalDiscountAmount(
    discountType,
    totalAmount,
    discount,
  );

  if (discountedAmount) {
    return (subTotal - discountedAmount).toFixed(2);
  }

  return subTotal.toFixed(2);
};
