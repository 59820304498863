import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';
import { UserStatusKeys } from '../enums/users-and-teams';
import { AgencyInviteUserRequestPayload } from '../types/request-payload';
import {
  AddTeamPayload,
  AddUserPayload,
  AssignTeamPayload,
  ChangeUserRolePayload,
  DatePickerFilter,
  DeleteUserFromTeamPayload,
  ModifyTeamPayload,
  TeamsFilter,
  UsersFilter,
} from '../types/users-and-teams';

// get Users List
export const getUsers = async ({ sortBy, ...params }: UsersFilter) =>
  api.get<ResponseSuccess>(`/team/members`, {
    params: {
      ...(sortBy && { sortBy }),
      ...params,
    },
  });

// Change Role
export const changeUserRole = async (payload: ChangeUserRolePayload) =>
  api.patch<ResponseSuccess>(`/user/role-change`, payload);

// Get Teams For Dropdown
export const getTeamsList = async () => api.get<ResponseSuccess>(`/team/list`);

// Assign Team
export const assignTeam = async ({ id, ...payload }: AssignTeamPayload) =>
  api.patch<ResponseSuccess>(`/user/${id}/assign-team`, payload);

// Delete User
export const deleteUser = async (id: number) =>
  api.delete<ResponseSuccess>(`/team/${id}/user`);

// Disable User
export const disableUser = async (id: number) =>
  api.patch<ResponseSuccess>(`/team/${id}/deactivate`);

// Resend Invite
export const resendInvite = async (id: number) =>
  api.patch<ResponseSuccess>(`/team/${id}/resend-invitation`);

// Reactivate User
export const reactivateUser = async (userId: number) =>
  api.patch<ResponseSuccess>(`/team/${userId}/reactivate`);

// Add User
export const addUser = async (payload: AddUserPayload) =>
  api.post<ResponseSuccess>(`/team/invite-user`, payload);

// Get Teams For Table
export const getTeams = async (params: TeamsFilter) =>
  api.get<ResponseSuccess>(`/team`, { params });

// Get All Members List
export const getMembersList = async (status: UserStatusKeys[]) =>
  api.get<ResponseSuccess>(`/team/members/list`, { params: { status } });

// Get All Team List With Members
export const getTeamsAndMembersList = async () =>
  api.get<ResponseSuccess>(`/user/team-member-list`);

// Modify Team
export const modifyTeam = async ({ teamId, ...payload }: ModifyTeamPayload) =>
  api.patch<ResponseSuccess>(`/team/${teamId}`, payload);

// Delete Team
export const deleteTeam = async (id: number) =>
  api.delete<ResponseSuccess>(`/team/${id}`);

// Add Team
export const addTeam = async (payload: AddTeamPayload) =>
  api.post<ResponseSuccess>(`/team/create-team`, payload);

// Get User Settings
export const getUserSetting = async (id: number) =>
  api.get<ResponseSuccess>(`/user/${id}/setting`);

// Delete User From Team
export const deleteUserFromTeam = async ({
  teamId,
  userId,
}: DeleteUserFromTeamPayload) =>
  api.delete<ResponseSuccess>(`/team/${teamId}/users/${userId}`);

export const agencyInviteUser = async (
  payload: AgencyInviteUserRequestPayload,
) => api.post<ResponseSuccess>(`/team/invite-user`, payload);

export const exportUsers = async (
  payload: DatePickerFilter,
) =>
  api.post<ResponseSuccess>(`/team/user-session/export`, payload);

export const deleteExpireSession = async (id: number) =>
  api.delete<ResponseSuccess>(`/team/user-session/${id}`);
