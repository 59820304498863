import { BillingOption } from '../../../../../utils/billing-options';
import { ModifyPlanAction } from '../../../../../utils/subscription-plans';

export const getTotalAmount = (
  billingOption,
  isModifySubscription,
  subTotal,
  sendGetCalculatePayResponse,
  action,
  alreadyPaid = 0,
) => {
  if (billingOption === BillingOption.LIFETIME) {
    return subTotal - alreadyPaid;
  }
  if (sendGetCalculatePayResponse) {
    subTotal = sendGetCalculatePayResponse?.amountToPay;
  }

  if (
    isModifySubscription &&
    action === ModifyPlanAction.AddSlots &&
    !sendGetCalculatePayResponse
  ) {
    return 0;
  }

  return billingOption === BillingOption.ANNUALLY && !isModifySubscription
    ? subTotal
    : subTotal - alreadyPaid;
};
