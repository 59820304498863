import React from 'react';
import { useStore } from 'react-redux';
import { getProspectParams } from './prospect-filters-resolver';
import { RootState } from '../../../store/root-reducer';
import { ProspectParams } from '../types';

/**
 * Updates the props `sendGetProspectRequest` and `sendGetProspectCountRequest` with the data of applied filters.
 */
const withProspectsFiltersAPI = (Component) => ({
  sendGetProspectRequest,
  sendGetProspectCountRequest,
  ...props
}) => {
  const store = useStore<RootState>();

  const getSequenceEmail = (params: ProspectParams) => {
    // Applied filters
    const { prospectsFilters, search } = store.getState().prospect;
    return sendGetProspectRequest(
      getProspectParams({ ...params, search }, prospectsFilters),
    );
  };
  const getProspectsCount = (params: ProspectParams) => {
    // Applied filters
    const { prospectsFilters, search } = store.getState().prospect;
    return sendGetProspectCountRequest(
      getProspectParams({ ...params, search }, prospectsFilters),
    );
  };

  return (
    <Component
      {...props}
      sendGetProspectRequest={getSequenceEmail}
      sendGetProspectCountRequest={getProspectsCount}
    />
  );
};

export default withProspectsFiltersAPI;
