import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import AdminSettings from './admin-settings';
import {
  getAdminSettingsRequest,
  getExclusionListRequest,
  getSSOOrganizationRequest,
  getSSOSetupUrlRequest,
  updateAdminSettingsRequest,
  updateSSOOrganizationRequest,
} from './extra-actions';
import { CreateSSOOrganizationRequestPayload, UpdateAdminSettingsRequestPayload } from '../../types/request-payload';
import {
  resetAdminSettings,
  resetUpdateAdminSettings,
  resetExclusionList,
} from './admin-settings-slice';
import {
  changeIntegrationSettingRequest,
  getIntegrationAdminSettingRequest,
} from '../api-tokens/extra-actions';
import { PaginationOptions } from './types';
import { getUserSettingsRequest } from '../../../home/extra-actions';

const mapStateToProps = (state: RootState) => ({
  getAdminSettingsRequestStatus:
    state.adminSettings.getAdminSettingsRequest.status,
  getAdminSettingsRequestMessage:
    state.adminSettings.getAdminSettingsRequest.message,
  getAdminSettingsRequestError:
    state.adminSettings.getAdminSettingsRequest.error,

  updateAdminSettingsStatus:
    state.adminSettings.updateAdminSettingsRequest.status,
  updateAdminSettingsMessage:
    state.adminSettings.updateAdminSettingsRequest.message,
  updateAdminSettingsError:
    state.adminSettings.updateAdminSettingsRequest.error,

  updateSSOOrganizationRequestStatus:
    state.adminSettings.updateSSOOrganizationRequest.status,
  updateSSOOrganizationRequestMessage:
    state.adminSettings.updateSSOOrganizationRequest.message,
  updateSSOOrganizationRequestError:
    state.adminSettings.updateSSOOrganizationRequest.error,

  getSSOOrganizationRequestStatus:
    state.adminSettings.getSSOOrganizationRequest.status,
  getSSOOrganizationRequestMessage:
    state.adminSettings.getSSOOrganizationRequest.message,
  getSSOOrganizationRequestError:
    state.adminSettings.getSSOOrganizationRequest.error,

  getSSOSetupUrlRequestStatus:
    state.adminSettings.getSSOSetupUrlRequest.status,
  getSSOSetupUrlRequestMessage:
    state.adminSettings.getSSOSetupUrlRequest.message,
  getSSOSetupUrlRequestError:
    state.adminSettings.getSSOSetupUrlRequest.error,

  getExclusionListRequestStatus:
    state.adminSettings.getExclusionListRequest.status,
  getExclusionListRequestMessage:
    state.adminSettings.getExclusionListRequest.message,
  getExclusionListRequestError:
    state.adminSettings.getExclusionListRequest.error,

  updateExclusionListRequestStatus:
    state.adminSettings.updateExclusionListRequest.status,
  updateExclusionListRequestMessage:
    state.adminSettings.updateExclusionListRequest.message,
  updateExclusionListRequestError:
    state.adminSettings.updateExclusionListRequest.error,

  integrationAdminSetting: state.apiToken.integrationSettings?.adminSetting,
  getIntegrationAdminSettingRequestStatus:
    state.apiToken.getIntegrationAdminSettingRequest.status,
  changeIntegrationSettingRequestStatus:
    state.apiToken.changeIntegrationSettingRequest.status,

  adminSettings: state.adminSettings.adminSettings,
  subscriptionPlan: state.home.subscription?.planCode,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
  agencyConfig: state.home.agencyConfig,
  atmRole: state.home.atmRole,
  ssoSetupUrl: state.adminSettings.ssoSetupUrl,
  exclusionList: state.adminSettings.exclusionList,
  exclusionListPaginationOptions:
    state.adminSettings.exclusionListPaginationOptions,
  organizationData: state.adminSettings.organizationData,
  organizationId: state.home.organizationId,
});

const mapDispatchToProps = {
  sendGetAdminDetailsRequest: () => getAdminSettingsRequest(),
  sendUpdateAdminDetailsRequest: (payload: UpdateAdminSettingsRequestPayload) =>
    updateAdminSettingsRequest(payload),
  resetUpdateAdminSettings: () => resetUpdateAdminSettings(),
  resetAdminSettings: () => resetAdminSettings(),
  getIntegrationSetting: () => getIntegrationAdminSettingRequest(),
  sendChangeIntegrationSetting: (isAllUser: boolean) =>
    changeIntegrationSettingRequest({ isAllUser }),
  sendGetExclusionListRequest: (payload: PaginationOptions) =>
    getExclusionListRequest(payload),
  resetExclusionList: () => resetExclusionList(),
  sendGetSSOOrganizationRequest: () => getSSOOrganizationRequest(),
  sendGetSSOSetupUrlRequest: () => getSSOSetupUrlRequest(),
  sendUpdateSSOOrganizationRequest: (payload: CreateSSOOrganizationRequestPayload) => 
    updateSSOOrganizationRequest(payload),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(AdminSettings);
