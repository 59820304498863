import { BillingOption } from '../../../../../../../shared/utils/billing-options';
import {
  getIsRequestIdeal,
  getIsRequestPending,
} from '../../../../../../../shared/utils/get-request-status';
import {
  PlanType,
  SubscriptionPlanModes,
  SubscriptionPlans,
} from '../../../../../../../shared/utils/subscription-plans';
import { GetAccountSubscriptionResponse } from '../../../types';
import { ModifySubscriptionSteps } from '../types';
import { isValueBasedPlan } from '../../outreach-plans/utils/helper';
import {
  checkIsSubscriptionPausedOrCancelled,
  checkIsUserPlan,
} from '../../../../../../../shared/design-system/components/molecules/subscription-plan-details/helper/helper';

export const billingOptions = [
  { label: 'Monthly', value: BillingOption.MONTHLY },
  { label: 'Annually', value: BillingOption.ANNUALLY },
];

export const getHighlightMessage = (planCycle) =>
  planCycle === BillingOption.MONTHLY
    ? 'monthly_outreach_highlight_message'
    : 'yearly_outreach_highlight_message';

export const gePlanCycleBasedOnPlanType = (planCode, planMode) => {
  if (
    planCode === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME ||
    planCode === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME
  ) {
    return BillingOption.LIFETIME;
  }

  if (planMode === SubscriptionPlanModes.MonthlyMode) {
    return BillingOption.MONTHLY;
  }

  if (planMode === SubscriptionPlanModes.YearlyMode) {
    return BillingOption.ANNUALLY;
  }

  return BillingOption.ANNUALLY;
};

export const getDisabledLabel = (
  sendGetAccountSubscriptionResponse: GetAccountSubscriptionResponse,
) => {
  if (
    sendGetAccountSubscriptionResponse?.planMode ===
    SubscriptionPlanModes.YearlyMode
  ) {
    return 'Monthly';
  }

  return null;
};

export const getDisabledTab = (
  planCode: SubscriptionPlans,
  planType: PlanType,
): ModifySubscriptionSteps => {
  if (
    planCode === SubscriptionPlans.PAID_MONTHLY ||
    planCode === SubscriptionPlans.PAID_YEARLY ||
    planCode === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME ||
    checkIsUserPlan(planType)
  ) {
    return ModifySubscriptionSteps.SelectPlan;
  }

  if (
    planCode === SubscriptionPlans.FREE ||
    planCode === SubscriptionPlans.TRIAL
  ) {
    return ModifySubscriptionSteps.SelectSlots;
  }

  return null;
};

export const getPlanName = (planCycle, planName, planCode = null) => {
  if (planCycle === BillingOption.ANNUALLY && planName) {
    return planName.replace('(Yearly)', '');
  }
  if (planCycle === BillingOption.MONTHLY && planName) {
    return planName.replace('(Monthly)', '');
  }

  if (planCycle === BillingOption.LIFETIME && planName) {
    if (
      planCode &&
      planCode === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME
    ) {
      return 'Outreach';
    }
    return planName;
  }

  return planName;
};

export const isBillingAndSubscriptionPageReady = ({
  sendGetAccountSubscriptionRequestStatus,
  sendGetPlansRequestStatus,
  sendGetAccountSubscriptionResponse,
  selectedPlanDetails,
}) =>
  getIsRequestPending(sendGetAccountSubscriptionRequestStatus) ||
  getIsRequestIdeal(sendGetAccountSubscriptionRequestStatus) ||
  getIsRequestPending(sendGetPlansRequestStatus) ||
  getIsRequestIdeal(sendGetPlansRequestStatus) ||
  !sendGetAccountSubscriptionResponse ||
  !selectedPlanDetails;

export const isColdEmailPlanBannerShow = (currentPlan) =>
  currentPlan === SubscriptionPlans.FREE ||
  currentPlan === SubscriptionPlans.TRIAL;

export const isValueBasedPlanVisible = (
  planCode,
  planType,
  subscriptionPlanStatus,
) =>
  isValueBasedPlan(planType) ||
  planCode === SubscriptionPlans.FREE ||
  planCode === SubscriptionPlans.TRIAL ||
  checkIsSubscriptionPausedOrCancelled(subscriptionPlanStatus);
