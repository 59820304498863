import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  LoginRequestPayload,
  ResendOtpPayload,
  VerifyOtpPayload,
} from '../../types';
import { login, resendOtp, verifyOtp } from '../../helpers/auth.api';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';

export const loginRequest = createAsyncThunk<
  ResponseSuccess,
  LoginRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('login/loginRequest', async (args, thunkAPI) => {
  try {
    return await login(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err as ResponseErrorWithHandled);
  }
});

export const verifyOtpRequest = createAsyncThunk<
  ResponseSuccess,
  VerifyOtpPayload,
  { rejectValue: ResponseErrorWithHandled }
>('login/verifyOtp', async (args, thunkAPI) => {
  try {
    return await verifyOtp(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err as ResponseErrorWithHandled);
  }
});

export const resendOtpRequest = createAsyncThunk<
  ResponseSuccess,
  ResendOtpPayload,
  { rejectValue: ResponseErrorWithHandled }
>('login/resendOtp', async (args, thunkAPI) => {
  try {
    return await resendOtp(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err as ResponseErrorWithHandled);
  }
});
