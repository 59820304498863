import { createAsyncThunk } from '@reduxjs/toolkit';
import { ssoLogin } from '../../helpers/auth.api';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../../../shared/types';

export const ssoLoginRequest = createAsyncThunk<
  ResponseSuccess,
  { email: string },
  { rejectValue: ResponseErrorWithHandled }
>('ssoLogin/ssoLoginRequest', async (data, thunkAPI) => {
  try {
    const response = await ssoLogin(data);
    return response;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
