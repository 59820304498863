import moment, { tz } from 'moment-timezone';
import getTimeZonesArray from './timezones-array';

export const getCurrentTimestamp = (): number => Math.floor(Date.now() / 1000);

export type Timezone = {
  name: string;
  value: string;
};

export const getTimezoneList = (): Timezone[] => {
  const timeZones = getTimeZonesArray();
  const timezoneList = [];
  timeZones.forEach((timezone) => {
    const name = timezone;
    const value = `${timezone} (GMT ${tz(timezone).format('Z')})`;
    timezoneList.push({ name, value });
  });
  return timezoneList;
};

export const getTimezoneValueByName = (name: string): string => {
  const timeZoneList = getTimezoneList();
  let timeZoneValue = 'UTC';
  timeZoneList.forEach((timeZoneObj) => {
    if (timeZoneObj.name === name) {
      timeZoneValue = timeZoneObj.value;
    }
  });
  return timeZoneValue;
};

export const getCurrentTimeZone = () => {
  let timeZone = tz.guess();
  if (
    timeZone === 'Asia/Calcutta' ||
    timeZone === 'Asia/calcutta' ||
    timeZone === 'Asia/kolkata'
  ) {
    timeZone = 'Asia/Kolkata';
  }
  return timeZone;
};

export const years = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const addDatePrefix = (luxonFormattedString: string) => {
  const datePart = +luxonFormattedString.substr(
    0,
    luxonFormattedString.indexOf(' '),
  );
  const monthAndYearPart = luxonFormattedString.substr(
    luxonFormattedString.indexOf(' ') + 1,
  );

  let suffix;

  if (datePart === 1 || datePart === 21 || datePart === 31) {
    suffix = 'st';
  } else if (datePart === 2 || datePart === 22) {
    suffix = 'nd';
  } else if (datePart === 3 || datePart === 23) {
    suffix = 'rd';
  } else {
    suffix = 'th';
  }
  return `${datePart}${suffix} ${monthAndYearPart}`;
};

export const formateDate = (date, format = 'DD MMM, yyyy') =>
  moment(date).format(format);
