import { Images } from '../../../../../../../shared/app-constants';
import { BillingOption } from '../../../../../../../shared/utils/billing-options';
import {
  SubscriptionPlanModes,
  SubscriptionPlans,
} from '../../../../../../../shared/utils/subscription-plans';

const ltdEmailOutreachPlanFeatures = [
  'Limited to 1 user account',
  'Unlimited email sequences',
  '50k prospects',
  'Unlimited shared templates',
];

const ltdEmailOutreachProPlanFeatures = [
  'Multiple user accounts',
  'Can stack upto 20 email accounts',
  'Unlimited email sequences',
  'Unlimited prospects',
  'Unlimited shared templates',
];

export const ltdPlans = [
  {
    name: 'Outreach',
    description:
      'Automate your cold email outreach through fully automated sequences.',
    features: ltdEmailOutreachPlanFeatures,
    headerImage: Images.Ellipse1,
  },
  {
    name: 'Outreach Pro',
    description:
      'Collaborate with your team with advanced team management & reports.',
    features: ltdEmailOutreachProPlanFeatures,
    headerImage: Images.Ellipse3,
    isBestValuePlan: true,
  },
];

export const getPlanCode = (planCycle) => {
  let planCode;

  if (planCycle === BillingOption.ANNUALLY) {
    planCode = SubscriptionPlans.PAID_YEARLY;
  } else if (planCycle === BillingOption.MONTHLY) {
    planCode = SubscriptionPlans.PAID_MONTHLY;
  } else if (planCycle === BillingOption.LIFETIME) {
    planCode = 'ltd';
  }

  return planCode;
};

export const getPlanMode = (planCycle) => {
  if (planCycle === BillingOption.MONTHLY) {
    return SubscriptionPlanModes.MonthlyMode;
  }
  if (planCycle === BillingOption.ANNUALLY) {
    return SubscriptionPlanModes.YearlyMode;
  }

  return SubscriptionPlanModes.LTDMode;
};

export const getPlanData = (planMode, getPlanResponse, planCode) => {
  if (planMode === SubscriptionPlanModes.LTDMode) {
    if (
      planCode === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME ||
      planCode === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME
    ) {
      return getPlanResponse.filter(
        (response) =>
          response?.planCode === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME ||
          response?.planCode === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME,
      );
    }
    return getPlanResponse.filter(
      (response) =>
        response?.planCode !== SubscriptionPlans.EMAIL_OUTREACH_LIFETIME &&
        response?.planCode !== SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME,
    );
  }

  return getPlanResponse.filter((response) => response.planMode === planMode);
};
