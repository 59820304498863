// eslint-disable-next-line import/prefer-default-export
export enum ConfirmationModalIconType {
  ALERT_TRIANGLE = 'alert-triange',
  INFO_CIRCLE = 'info-circle',
  INFO_CIRCLE_BLUE = 'info-circle-blue',
  INFO_CIRCLE_RED = 'info-circle-red',
  INFO_CIRCLE_YELLOW = 'info-circle-yellow',
  TRASH_RED = 'trash-red',
  ALERT_CIRCLE_ORANGE = 'alert-circle-orange',
}
