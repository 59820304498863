import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DotsVertical } from '@saleshandy/icons';
import { getActionPermissions } from '../utils/get-action-permissions';
import { Action } from '../../../../../../../shared/design-system/components/organisms/table/types';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { User } from '../../../../../types/users-and-teams';
import { UserStatusKeys } from '../../../../../enums/users-and-teams';

type IProps = {
  userId: number;
  row: User;
  actions: Action[];
  onAction: (key: string, row: User) => void;
  userTimezone?: string;
  isLastRow?: boolean;
  currentUserATMRole?: string;
  setIsDropdownOpen?: React.Dispatch<React.SetStateAction<number | null>>;
};

const ActionsCell: React.FC<IProps> = ({
  userId,
  row,
  actions,
  onAction,
  currentUserATMRole,
  setIsDropdownOpen,
}) => {
  let actionsCounter = 0;
  const outerAction = [];
  const dropdownActions = [];

  const filteredActions = actions.filter((action) =>
    getActionPermissions(
      userId,
      action,
      row.status,
      row.id,
      row.role,
      currentUserATMRole,
    ),
  );

  filteredActions.forEach((action) => {
    if (actionsCounter < 3 || filteredActions.length === 4) {
      outerAction.push(action);
      actionsCounter += 1;
    } else {
      dropdownActions.push(action);
    }
  });

  return (
    <div className="actionables">
      <Dropdown
        onToggle={(val) =>
          setIsDropdownOpen((prev) => {
            if (val) {
              return row.id;
            }
            return prev === row.id ? null : prev;
          })
        }
        bsPrefix="dropdown-custom"
        drop="down"
      >
        <Dropdown.Toggle
          bsPrefix="dropdown-toggle-custom"
          variant="dropdown-custom"
          id="dropdown-basic"
        >
          <DotsVertical />
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix="dropdown-menu-custom">
          {filteredActions.map((action) => (
            <Dropdown.Item
              key={action.key}
              {...accessibleOnClick(() => {
                onAction(action.key, row);
              })}
              className={`${
                (row.status === UserStatusKeys.Active &&
                  action.key === 'enable') ||
                (row.status === UserStatusKeys.InActive &&
                  action.key === 'disable')
                  ? 'enable-disable-btn'
                  : ''
              }`}
            >
              {action.iconElement} {action.displayName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ActionsCell;
