import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  loginRequest,
  resendOtpRequest,
  verifyOtpRequest,
} from './extra-actions';
import { MFAChannel } from '../../enums/mfa-channel';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
  showError?: boolean;
};

type State = {
  loginRequest: RequestState;
  verifyOtpRequest: RequestState;
  resendOtpRequest: RequestState;

  token: string;
  trackingId: string;
  firstName: string;
  lastName: string;
  email: string;
  mfaChannel: MFAChannel;
  isRedirectedFromAgency: boolean;
};

const initialState: State = {
  loginRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: '',
    showError: false,
  },
  verifyOtpRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  resendOtpRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  token: '',
  trackingId: null,
  firstName: null,
  lastName: null,
  email: null,
  mfaChannel: null,
  isRedirectedFromAgency: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    hideError: (state) => {
      state.loginRequest.showError = false;
    },
    resetMFAData: (state) => {
      state.email = null;
      state.mfaChannel = null;
      state.verifyOtpRequest = initialState.verifyOtpRequest;
      state.resendOtpRequest = initialState.resendOtpRequest;
    },
    setIsRedirectedFromAgency: (state, action) => {
      state.isRedirectedFromAgency = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginRequest.pending, (state) => {
      state.loginRequest.status = RequestStatus.Pending;
      state.loginRequest.showError = false;
    });
    builder.addCase(loginRequest.fulfilled, (state, action) => {
      state.loginRequest.status = RequestStatus.Succeeded;
      state.loginRequest.message = action?.payload?.message;
      state.email = action?.payload?.payload?.email;
      if (action?.payload?.payload?.mfaChannel) {
        state.mfaChannel = action.payload.payload.mfaChannel;
        return;
      }
      state.mfaChannel = null;
      state.token = action?.payload?.payload?.accessToken;
      state.trackingId = action?.payload?.payload?.trackingId;
      state.firstName = action?.payload?.payload?.firstName;
      state.lastName = action?.payload?.payload?.lastName;
    });
    builder.addCase(loginRequest.rejected, (state, action) => {
      state.loginRequest.status = RequestStatus.Failed;
      state.loginRequest.error = action.payload;
      state.loginRequest.showError = !action.payload.isHandled;
    });

    // Verify OTP
    builder.addCase(verifyOtpRequest.pending, (state) => {
      state.verifyOtpRequest.status = RequestStatus.Pending;
      state.verifyOtpRequest.error = null;
    });
    builder.addCase(verifyOtpRequest.fulfilled, (state, action) => {
      state.verifyOtpRequest.status = RequestStatus.Succeeded;
      state.verifyOtpRequest.message = action?.payload?.message;

      state.email = action?.payload?.payload?.email;
      state.token = action?.payload?.payload?.accessToken;
      state.trackingId = action?.payload?.payload?.trackingId;
      state.firstName = action?.payload?.payload?.firstName;
      state.lastName = action?.payload?.payload?.lastName;
    });
    builder.addCase(verifyOtpRequest.rejected, (state, action) => {
      state.verifyOtpRequest.status = RequestStatus.Failed;
      state.verifyOtpRequest.error = action.payload;
    });

    // Resend OTP
    builder.addCase(resendOtpRequest.pending, (state) => {
      state.resendOtpRequest.status = RequestStatus.Pending;
      state.resendOtpRequest.error = null;
    });
    builder.addCase(resendOtpRequest.fulfilled, (state, action) => {
      state.resendOtpRequest.status = RequestStatus.Succeeded;
      state.resendOtpRequest.message = action.payload.message;
    });
    builder.addCase(resendOtpRequest.rejected, (state, action) => {
      state.resendOtpRequest.status = RequestStatus.Failed;
      state.resendOtpRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  hideError,
  resetMFAData,
  setIsRedirectedFromAgency,
} = loginSlice.actions;
export { loginRequest };
export default loginSlice.reducer;
