/* eslint-disable react/no-array-index-key */
import React from 'react';
import { World } from '@saleshandy/icons';

import {
  filterOutExcludedAndDuplicateDomains,
  getDomainsFromEmails,
} from '../../helpers/helper';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';

const LeadDomains = ({ lead, expandedLeads, handleViewLess }) => {
  const domains = lead?.isRevealed
    ? getDomainsFromEmails(lead?.emails, lead?.current_employer_domain)
    : filterOutExcludedAndDuplicateDomains(lead?.teaser?.emails?.slice(0, 2));
  return (
    <>
      {lead?.isRevealed && domains.length > 0 && (
        <div
          className={`lead-company-details lead-company-email ${
            lead?.emails?.length === 0 && lead?.phones?.length === 0
              ? 'mt-0'
              : ''
          }`}
        >
          {domains.slice(0, 1).map((domain, emailIndex) => (
            <div key={`domain-${emailIndex}`} className="email-row">
              <a
                href={`https://${domain}`}
                target="_blank"
                rel="noreferrer"
                className="d-flex align-items-center lead-company-domain"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="icon">
                  <World className="icon-color mr-1" />
                </div>
                <span className="user-email">{domain || ''}</span>
              </a>
              {domains?.length > 1 && !expandedLeads.includes(lead?.id) && (
                <span
                  className="more-account"
                  {...accessibleOnClick(() => handleViewLess(lead))}
                >
                  +{domains?.length - 1} more
                </span>
              )}
            </div>
          ))}
        </div>
      )}

      {!lead?.isRevealed &&
        lead?.teaser?.emails?.length > 0 &&
        domains?.slice(0, 1).map((e: any, ix) => (
          <div
            className="lead-company-details lead-company-email-people-tab"
            key={`${lead?.id}-teaser-domain-${ix}`}
          >
            <a
              href={`https://${e}`}
              target="_blank"
              rel="noreferrer"
              className="d-flex align-items-center lead-company-domain"
              onClick={(event) => event.stopPropagation()}
            >
              <div className="icon">
                <World className="icon-color mr-1" />
              </div>
              <span className="user-email"> {e}</span>
            </a>
          </div>
        ))}
    </>
  );
};

export default LeadDomains;
