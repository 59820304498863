export enum PaginationOption {
  PAGE = 'page',
  LIMIT = 'limit',
  SORT_BY = 'sortBy',
  ORDER = 'order',
  PROGRESS = 'progress',
  SEARCH = 'search',
  OWNERS = 'owners',
  CLIENTS = 'clients',
}
